import React, {
	useState,
	FC,
	Dispatch,
	SetStateAction,
	FormEvent,
} from 'react';
import './login-inputs-section.styles.scss';
import { useDispatch } from 'react-redux';
import Input from '../login-input/login-input.component';
import * as authApi from '../../../api/requests/auth.api';
import { setAlert } from '../../../redux/General/general.actions';
import passwordIcon from '../../../assets/icons/login/password.svg';
import phoneIcon from '../../../assets/icons/login/phone.svg';
import { fireGaEvent } from '../../../utils/ga-event';
import { useNavigate } from 'react-router-dom';

interface Credentials {
	contactNumber: string;
	password: string;
}

type Props = {
	setLoginSubmitted: Dispatch<SetStateAction<boolean>>;
	setCredentials: Dispatch<SetStateAction<Credentials>>;
};

const LoginInputsSection: FC<Props> = ({
	setLoginSubmitted,
	setCredentials,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [state, setState] = useState({
		contactNumber: '',
		password: '',
	});

	fireGaEvent('Login', 'Login - Input Credentials Page');

	const getValue = (name: keyof Credentials, value: string) => {
		setState({
			...state,
			[name]: value.trim(),
		});
	};

	const handleLogin = (e: FormEvent) => {
		e.preventDefault();
		const payload = {
			...state,
			contactNumber: `+44${state.contactNumber}`,
		};
		authApi
			.login(payload)
			.then((res) => {
				if (res) {
					if (res.status === 404) {
						fireGaEvent('Login', 'Taps login (fail)');
						dispatch(
							setAlert({
								type: 'error',
								message: 'User Not Found',
								isVisible: true,
							}),
						);
						return;
					}
					fireGaEvent('Login', 'Taps login (success)');
					setCredentials(state);
					setLoginSubmitted(true);
				} else {
					fireGaEvent('Login', 'Taps login (fail)');
					dispatch(
						setAlert({
							type: 'error',
							message: 'Invalid credentials',
							isVisible: true,
						}),
					);
				}
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err?.message,
						isVisible: true,
					}),
				);
			});
	};

	const handleForgotPassword = () => {
		fireGaEvent('Login', 'Taps forgot password');
		navigate('/forgot-password', { replace: true });
	};

	return (
		<form className='login-inputs-section' onSubmit={handleLogin}>
			<div className='inputs'>
				<Input
					name='contactNumber'
					getValue={getValue}
					value={state.contactNumber}
					placeholder='Mobile number'
					icon={phoneIcon}
					type='tel'
				/>
				<Input
					name='password'
					getValue={getValue}
					value={state.password}
					placeholder='Password'
					icon={passwordIcon}
					type='password'
				/>
			</div>

			<div className='login-buttons'>
				<button type='submit' className='orka-btn login-button'>
					Login
				</button>
			</div>

			<div className='forgot-password'>
				<p onClick={handleForgotPassword}>Forgot Password?</p>
			</div>
		</form>
	);
};

export default LoginInputsSection;
