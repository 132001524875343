import { IOrganization } from '../../../types/interfaces/misc.interface';
import { IActivityItem } from '../../../types/interfaces/applicant.interface';
import { formatDateForDb } from '../../../utils/utilsFunctions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getRequiredFields = (formState: any): string[] => {
	let newRequiredFields = ['type', 'startAt'];

	const addressFields = !formState.reference.addedManually
		? ['region']
		: ['streetAddress', 'city', 'region', 'postcode', 'country'];
	switch (formState.type) {
		case 'employed':
			newRequiredFields = [
				...newRequiredFields,
				'jobTitle',
				'isPartTime',
				'canContactEmployer',
				'email',
				'name',
				...addressFields,
			];
			break;
		case 'volunteering':
		case 'studying':
			newRequiredFields = [
				...newRequiredFields,
				...addressFields,
				'email',
			];
			break;
		case 'self-employed':
			newRequiredFields = [...newRequiredFields];
			break;
		case 'career-break':
			newRequiredFields = [...newRequiredFields, 'careerBreakReasonType'];
			break;
		default:
			newRequiredFields = [...newRequiredFields];
	}
	if (
		formState.type === 'career-break' &&
		formState.careerBreakReasonType === 'another-reason'
	) {
		newRequiredFields = [...newRequiredFields, 'careerBreakReason'];
	}

	return newRequiredFields;
};

export const formatPayload = (
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	formState: any,
	organisationSelected: IOrganization | null,
): any => {
	const filteredFormState: any = {};

	for (const key in formState) {
		if (
			formState[key] !== '' &&
			!isAddress(key) &&
			key !== 'careerBreakReasonType'
		) {
			filteredFormState[key] = formState[key];
		}
	}

	const { streetAddress, city, postcode, country, region } = formState;
	const payload: any = {
		...filteredFormState,
	};

	if (formState.careerBreakReasonType) {
		payload.type = formState.careerBreakReasonType;
	}

	if (hasAddress(formState)) {
		payload.address = {
			streetAddress,
			city,
			postcode,
			country,
			region,
		};
	}

	payload.startAt = formatDateForDb(payload.startAt);
	payload.endAt = payload.endAt ? formatDateForDb(payload.endAt) : null;
	payload.reference = {
		addedManually: organisationSelected ? false : true,
	};
	if (formState.uuid) {
		payload.uuid = formState.uuid;
	}

	return payload;
};

const isAddress = (field: string) => {
	switch (field) {
		case 'streetAddress':
		case 'city':
		case 'region':
		case 'postcode':
		case 'country':
			return true;
		default:
			return false;
	}
};

const hasAddress = (formState: any) => {
	const addressKeys = [
		'streetAddress',
		'city',
		'postcode',
		'region',
		'country',
	];
	for (const key in formState) {
		if (addressKeys.includes(key)) {
			if (formState[key] !== '') {
				return true;
			}
			return false;
		}
	}
};

export const defaultFormState = (): any => {
	return {
		uuid: '',
		type: '',
		jobTitle: '',
		startAt: null,
		endAt: null,
		name: '',
		email: '',
		streetAddress: '',
		city: '',
		postcode: '',
		region: '',
		country: '',
		isPartTime: false,
		canContactEmployer: true,
		unemployedDetails: '',
		careerBreakReasonType: '',
		careerBreakReason: '',
		reasonForLeaving: '',
		reasonForLeavingText: '',
		reference: {
			addedManually: false,
		},
	};
};

export const setValidatedFormState = (selectedActivity: IActivityItem): any => {
	return {
		uuid: selectedActivity?.uuid,
		type: selectedActivity?.type,
		jobTitle: selectedActivity?.jobTitle,
		startAt: selectedActivity?.startAt,
		endAt: selectedActivity?.endAt,
		name: selectedActivity?.name,
		email: selectedActivity?.email,
		streetAddress: selectedActivity?.address?.streetAddress,
		city: selectedActivity?.address?.city,
		postcode: selectedActivity?.address?.postcode,
		region: selectedActivity?.address?.region,
		country: selectedActivity?.address?.country,
		isPartTime: selectedActivity?.isPartTime,
		reasonForLeaving: selectedActivity.reasonForLeaving,
		reasonForLeavingText: selectedActivity.reasonForLeavingText,
		canContactEmployer: selectedActivity?.canContactEmployer,
		careerBreakReasonType: selectedActivity?.careerBreakReasonType,
		careerBreakReason: selectedActivity?.careerBreakReason,
		reference: {
			addedManually: false,
		},
	};
};

export const validateActivityType = (
	type: string,
	careerBreakReasonType: string | null,
): { type: string; careerBreakReasonType: string | null } => {
	if (type !== 'career-break') {
		switch (type) {
			case 'carer':
			case 'medical-reasons':
			case 'travelling':
			case 'job-seeking':
			case 'parental-cover':
			case 'self-study':
			case 'another-reason':
				return {
					type: 'career-break',
					careerBreakReasonType: type,
				};
			default:
				return {
					type,
					careerBreakReasonType,
				};
		}
	} else {
		return {
			type,
			careerBreakReasonType,
		};
	}
};
