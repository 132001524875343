import React, { useState, FC } from 'react';
import LoginLayout from '../../components/general/login-layout/login-layout.component';
import './forgot-password.styles.scss';
import Input from '../../components/login/login-input/login-input.component';
import OrkaButton from '../../components/general/orka-button/orka-button.component';
import * as apiAuth from '../../api/requests/auth.api';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../redux/General/general.actions';
import phoneIcon from '../../assets/icons/login/phone.svg';
import { useNavigate } from 'react-router-dom';

const ForgotPassword: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [val, setVal] = useState<string>('');

	const getValue = (name: string, value: string) => {
		setVal(value);
	};

	const handleForgotPassword = () => {
		apiAuth
			.forgotPassword({ contactNumber: '+44' + val })
			.then(() => {
				dispatch(
					setAlert({
						type: 'success',
						message: 'Check your inbox for reset link',
						isVisible: true,
					}),
				);
				navigate('/login', { replace: true });
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
			});
	};

	return (
		<LoginLayout>
			<div className='reset-password-input-container'>
				<Input
					name='contactNumber'
					getValue={getValue}
					value={val}
					placeholder='Contact Number'
					icon={phoneIcon}
					type='tel'
				/>

				<div className='forgot-password-info'>
					<p>You will be emailed with a reset password link.</p>
				</div>

				<div className='reset-password-input-button'>
					<OrkaButton
						buttonClass='purple-filled'
						buttonContent='Reset'
						emitClicked={handleForgotPassword}
						disabled={!val}
					/>
				</div>
			</div>
		</LoginLayout>
	);
};

export default ForgotPassword;
