import React, { FC, useEffect, useState } from 'react';
import './activity-info.styles.scss';
import { IActivityItem } from '../../../types/interfaces/applicant.interface';

type Props = {
	activity: IActivityItem;
	type?: string;
};

const ActivityInfo: FC<Props> = ({ activity, type }) => {
	const [infoText, setInfoText] = useState('');
	useEffect(() => {
		if (activity && activity.files) {
			const text = activity.files.reduce((info, file) => {
				let count = 0;
				if (file.status === 'rejected') {
					count += 1;
				}
				if (count > 0) {
					info = `${count} document${
						count > 1 ? 's' : ''
					} has been rejected`;
				}
				return info;
			}, '');
			setInfoText(text);
		}
	}, []);

	const { writtenAttempts } = activity.reference || 0;
	return (
		<>
			{activity && activity.reference && (
				<article className={'activity-info-card'}>
					{type === 'info' && (
						<>
							{activity.type !== 'employed' &&
								activity.type !== 'studying' &&
								activity.canContactEmployer && (
									<p>
										We have contacted you by email{' '}
										{writtenAttempts}{' '}
										{writtenAttempts === 1
											? 'time'
											: 'times'}
										. Please get back to us with 2 documents
										to prove this activity or we will unable
										to progress your application
									</p>
								)}
							{(activity.type === 'employed' ||
								activity.type === 'studying') &&
								activity.canContactEmployer && (
									<p>
										{writtenAttempts} email
										{writtenAttempts !== 1
											? 's have '
											: ' has '}
										been sent to {activity.email} and we
										have not yet had a response.
									</p>
								)}

							{(activity.type === 'employed' ||
								activity.type === 'studying') &&
								!activity.canContactEmployer && (
									<p>
										We have been asked not to contact this
										organisation. Please get back to us with
										2 documents to prove this activity or we
										will unable to progress your application
									</p>
								)}
						</>
					)}
				</article>
			)}

			{infoText !== '' && (
				<article className={'activity-info-card red'}>
					<p>{infoText}</p>
				</article>
			)}
		</>
	);
};

export default ActivityInfo;
