import React, {
	FC,
	useState,
	useCallback,
	useEffect,
	Fragment,
	Dispatch,
	SetStateAction,
} from 'react';
import './activity-company.styles.scss';
import * as api from '../../../api/requests/activity.api';
import debounce from 'lodash.debounce';
import Input from '../../general/input/input.component';
import { IOrganization } from '../../../types/interfaces/misc.interface';
import OrganisationCard from '../organisation-card/organisation-card.component';

type Props = {
	name: string;
	handleActivityForm: any;
	setOrganisationSelected: Dispatch<SetStateAction<IOrganization | null>>;
	organisationSelected: IOrganization | null;
	continueClicked: boolean;
};

const ActivityCompany: FC<Props> = ({
	name,
	handleActivityForm,
	setOrganisationSelected,
	organisationSelected,
	continueClicked,
}) => {
	const [organisations, setOrganisations] = useState<IOrganization[]>([]);
	const [search, setSearch] = useState<string>('');

	useEffect(() => {
		organisationSelected && setOrganisations([]);
	}, [organisationSelected]);

	const sendQuery = (query: string) => {
		if (query) {
			return api
				.getOrganisations(query)
				.then((res: any) => {
					if (res.data && res.data.items) {
						setOrganisationSelected(null);
						setOrganisations(res.data.items);
					} else {
						setOrganisationSelected(null);
						setOrganisations([]);
					}
				})
				.catch((err: Error) => {
					setOrganisationSelected(null);
					setOrganisations([]);
					throw err;
				});
		} else {
			setOrganisations([]);
		}
	};

	const delayedQuery = useCallback(
		debounce(() => {
			sendQuery(search);
		}, 500),
		[search],
	);

	useEffect(() => {
		delayedQuery();
		return delayedQuery.cancel;
	}, [search, delayedQuery]);

	const handleEmitFunc = (val: string, inputName: string) => {
		setSearch(val);
		handleActivityForm(val, inputName);
	};

	return (
		<div>
			<Input
				autocomplete='off'
				inputName='name'
				title='Organisation name'
				placeholder='Tesco'
				value={name ? name : ''}
				emitValue={handleEmitFunc}
				continueClicked={continueClicked}
				required={true}
			/>
			{organisations.map((organisation) => {
				return (
					<Fragment key={organisation.uuid}>
						<OrganisationCard
							organisation={organisation}
							setOrganisationSelected={setOrganisationSelected}
						/>
					</Fragment>
				);
			})}
		</div>
	);
};

export default ActivityCompany;
