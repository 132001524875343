import React, { FC } from 'react';
import Header from '../../header/header/header.component';
import InnerLayout from '../../general/inner-layout/inner-layout.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import InfoCard from '../info-card/info-card.component';
import book from '../../../assets/icons/info-card/book.svg';

type Props = {
	image: string;
	title: string;
	body: string;
	button1Text: string;
	button2Text?: string;
	infoCardText?: string;
	handleBack: (origin: string) => void;
	emitButton1: () => void;
	emitButton2?: () => void;
	infoCardButton?: () => void;
};

const StaticExplainer: FC<Props> = ({
	image,
	title,
	body,
	button1Text,
	button2Text,
	infoCardText,
	handleBack,
	emitButton1,
	emitButton2,
	infoCardButton,
}) => {
	const isTablet = useSelector((state: RootState) => state.general.isTablet);
	const replaceWithBr = () => {
		return body.replace(/\n/g, '<br />');
	};
	return (
		<div>
			<Header
				text=''
				type='text'
				backFunction={handleBack}
				origin='main'
				hideStepsCounter={true}
				hideDesktopSteps={true}
			></Header>
			<InnerLayout>
				<div>
					<div className='margin-bottom-40 center'>
						{image && <img src={image} alt='info' />}
					</div>
					<div className='margin-bottom-24'>
						{title && <h2 style={{ fontSize: 24 }}>{title}</h2>}
					</div>

					{body && (
						<p
							dangerouslySetInnerHTML={{
								__html: replaceWithBr(),
							}}
						/>
					)}
					{isTablet && infoCardText && (
						<div
							onClick={infoCardButton}
							className={`margin-top-40 ${
								button2Text ? 'margin-bottom-40' : ''
							}`}
						>
							<InfoCard
								size='small'
								icon={book}
								text={infoCardText}
							/>
						</div>
					)}
					{(button1Text || button2Text) && (
						<div className='identity-footer'>
							{button1Text && (
								<button
									onClick={emitButton1}
									className='margin-bottom-8 orka-identity-button primary-filled'
								>
									{button1Text}
								</button>
							)}
							{button2Text && (
								<button
									onClick={emitButton2}
									className='margin-bottom-8 orka-identity-button grey-filled'
								>
									{button2Text}
								</button>
							)}
						</div>
					)}
				</div>
				<div>
					{infoCardText && (
						<div onClick={infoCardButton} className='margin-top-24'>
							<InfoCard
								size='small'
								icon={book}
								text={infoCardText}
							/>
						</div>
					)}
				</div>
			</InnerLayout>
		</div>
	);
};

export default StaticExplainer;
