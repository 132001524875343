import React, { FC, useState, Fragment } from 'react';
import './activity-item.styles.scss';
import { IActivityItem } from '../../../types/interfaces/applicant.interface';
import * as utils from '../../../utils/utilsFunctions';
import GreyTextCard from '../grey-text-card/grey-text-card.component';
import arrowUp from '../../../assets/icons/misc/chevron-up.svg';
import arrowDown from '../../../assets/icons/misc/chevron-down.svg';

type Props = {
	activity: IActivityItem;
	activityNum: number;
};

const ActivityItem: FC<Props> = ({ activity, activityNum }) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<article className='submission-activity-item-card'>
			<GreyTextCard>
				<div className='activity-item-header'>
					<h4 className='grey-text-card-bold'>
						Activity {activityNum}
					</h4>
					{activity && activity.uuid && (
						<button onClick={() => setIsOpen(!isOpen)}>
							<img
								src={!isOpen ? arrowDown : arrowUp}
								alt='open card'
							/>
						</button>
					)}
				</div>
				{activity.type === 'career-break' ? (
					<p>
						{utils.capitalizeFirstLetter(
							utils.removeHyphen(activity.type),
						)}
						{` - ${utils.removeHyphen(
							activity.careerBreakReasonType,
						)}`}
					</p>
				) : (
					<>
						<p>
							{utils.capitalizeFirstLetter(
								utils.removeHyphen(activity.type),
							)}
						</p>
						{activity.type === 'employed' ||
							activity.type === 'volunteering' ||
							(activity.type === 'studying' && (
								<p>
									{utils.capitalizeFirstLetter(activity.name)}
								</p>
							))}
					</>
				)}

				<div>
					<time
						dateTime={
							activity.startAt
								? activity.startAt.toString()
								: undefined
						}
					>
						{utils.formatDateForUser(activity.startAt)}
					</time>
					{' - '}
					<time
						dateTime={
							activity.endAt
								? activity.endAt.toString()
								: undefined
						}
					>
						{activity.endAt
							? utils.formatDateForUser(activity.endAt)
							: 'Current'}
					</time>
				</div>
				{isOpen && (
					<div className='submission-activity-item-extra'>
						<p>{activity.jobTitle}</p>
						<p>{activity.address?.streetAddress}</p>
						<p>{activity.address?.city}</p>
						<p>
							{activity.address?.postcode &&
								activity.address?.postcode.toUpperCase()}
						</p>
						<p>{activity.address?.country}</p>
						<p>{activity.email}</p>

						<br />
						<p>
							{activity.files.length} document
							{activity.files.length === 1 ? ' ' : 's '}uploaded
						</p>

						{activity.files &&
							activity.files.length > 0 &&
							activity.files.map(
								(fileItem, fileIndex: number) => {
									return (
										<Fragment key={fileItem.uuid}>
											<div className='review-doc-item'>
												<a
													href={fileItem.fileUrl}
													target='_blank'
													rel='noreferrer'
												>
													Document {fileIndex + 1}
												</a>
												<div className='document-status-tag'>
													{fileItem.status ===
														'accepted' && (
															<p className='accepted'>
																Accepted
															</p>
														)}
													{fileItem.status ===
														'rejected' && (
															<p className='rejected'>
																Rejected
															</p>
														)}
												</div>
											</div>
										</Fragment>
									);
								},
							)}
					</div>
				)}
			</GreyTextCard>
		</article>
	);
};

export default ActivityItem;
