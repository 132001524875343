import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './activity-reasons.styles.scss';
import {
	IActivityFormState,
	IActivityItem,
} from '../../../types/interfaces/applicant.interface';
import Header from '../../header/header/header.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import { dropdownData } from '../../../utils/data';
import { IDropdownStandard } from '../../../types/interfaces/misc.interface';
import Input from '../../general/input/input.component';
import { useDispatch, useSelector } from 'react-redux';
import { updateActivityItem } from '../../../redux/Applicant/applicant.actions';
import { RootState } from '../../../types/root-state';
import * as api from '../../../api/requests/activity.api';
import * as utils from '../../../utils/utilsFunctions';
import { fireGaEvent } from '../../../utils/ga-event';

type Props = {
	handleBack: (origin: string) => void;
	setShowForm: Dispatch<SetStateAction<boolean>>;
	selectedActivity: IActivityItem | null;
	setSelectedActivity: Dispatch<
		SetStateAction<IActivityItem | null | IActivityFormState>
	>;
	setShowList: Dispatch<SetStateAction<boolean>>;
	setShowUpload: Dispatch<SetStateAction<boolean>>;
	setShowReasons: Dispatch<SetStateAction<boolean>>;
};

const ActivityReasons: FC<Props> = ({
	handleBack,
	selectedActivity,
	setShowReasons,
	setShowList,
	setShowForm,
	setShowUpload,
}) => {
	const dispatch = useDispatch();
	const [formState, setFormState] = useState({
		reasonForLeaving: selectedActivity?.reasonForLeaving
			? selectedActivity.reasonForLeaving
			: '',
		reasonForLeavingText: selectedActivity?.reasonForLeavingText
			? selectedActivity.reasonForLeavingText
			: '',
	});
	const [reasonsForLeaving, setReasonsForLeaving] = useState<
		IDropdownStandard[]
	>([]);
	const [showOtherText, setShowOtherText] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const isMobile = useSelector((state: RootState) => state.general.isMobile);

	fireGaEvent(
		'Activity History',
		'Activity History - Reason for leaving Page',
	);

	useEffect(() => {
		utils.instantScroll();
		const reasons = dropdownData.reasonsForLeaving.map((reason) => {
			if (!formState.reasonForLeaving) {
				reason.class = 'grey-filled';
			}
			return reason;
		});
		setReasonsForLeaving(reasons);
	}, []);

	useEffect(() => {
		formState.reasonForLeaving === 'Other'
			? setShowOtherText(true)
			: setShowOtherText(false);

		setIsDisabled(handleDisabled());
	}, [formState.reasonForLeaving, formState.reasonForLeavingText]);

	useEffect(() => {
		selectedActivity?.reasonForLeavingText &&
			setFormState({
				...formState,
				reasonForLeavingText: selectedActivity.reasonForLeavingText,
			});

		selectedActivity?.reasonForLeaving &&
			setFormState({
				...formState,
				reasonForLeaving: selectedActivity.reasonForLeaving,
			});
	}, [
		selectedActivity?.reasonForLeavingText,
		selectedActivity?.reasonForLeaving,
	]);

	const handleAddActivity = () => {
		const updatedActivity = {
			uuid: selectedActivity?.uuid,
			...formState,
		};
		fireGaEvent('Activity History', 'Activity reason next button clicked');

		return api
			.updateActivity(updatedActivity)
			.then((res: any) => {
				if (res.status === 404) {
					throw res;
				}
				dispatch(updateActivityItem(updatedActivity));
				utils.handleAlerts(
					'success',
					'Successfully added activity details',
					dispatch,
				);
				handlePageSelect();
			})
			.catch((err: Error) => {
				utils.handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	const handlePageSelect = () => {
		setShowReasons(false);
		setShowForm(false);
		setShowUpload(true);
		setShowList(false);
	};

	const handleDisabled = () => {
		if (formState.reasonForLeaving === '') {
			return true;
		}

		if (
			formState.reasonForLeaving === 'Other' &&
			!formState.reasonForLeavingText.trim()
		) {
			return true;
		}

		return false;
	};

	const handleReasonText = (val: string) => {
		setFormState({
			...formState,
			reasonForLeavingText: val,
		});
	};

	const handleSelectReason = (reason: IDropdownStandard) => {
		fireGaEvent('Activity History', 'Activity reason for leaving clicked');
		setFormState({
			...formState,
			reasonForLeaving:
				typeof reason.dbValue === 'string' ? reason.dbValue : '',
		});
		const selectedReasons = reasonsForLeaving.map((reasonItem) => {
			if (reasonItem.dbValue === reason.dbValue) {
				reasonItem.class = 'blue-filled';
			} else {
				reasonItem.class = 'grey-filled';
			}
			return reasonItem;
		});
		setReasonsForLeaving(selectedReasons);
	};

	return (
		<div className='activity-reasons-container'>
			{isMobile && (
				<Header
					text='Why did you leave this job?'
					type='text'
					backFunction={handleBack}
					origin='reasons'
				/>
			)}

			<div
				className={
					isMobile
						? 'reason-page-section-mobile'
						: 'modal-content-scrollable reason-page-section'
				}
			>
				{reasonsForLeaving.map((reason: IDropdownStandard) => {
					return (
						<div key={reason.id} className='margin-bottom-8'>
							<OrkaButton
								buttonContent={reason.value}
								disabled={false}
								emitClicked={() => handleSelectReason(reason)}
								buttonClass={reason.class}
							/>
						</div>
					);
				})}

				{showOtherText && (
					<div className='margin-top-24 margin-bottom-24'>
						<Input
							inputName='reasonForLeavingText'
							title=''
							placeholder={'Please provide some extra details'}
							value={formState.reasonForLeavingText}
							isTextArea={true}
							emitValue={handleReasonText}
						/>
					</div>
				)}
			</div>

			<div className='page-form-footer-outer-container'>
				<div className='page-form-footer-inner-container'>
					<OrkaButton
						buttonContent='Next'
						disabled={isDisabled}
						emitClicked={handleAddActivity}
						buttonClass='purple-filled'
					/>
				</div>
			</div>
		</div>
	);
};

export default ActivityReasons;
