import React, { FC } from 'react';
import Header from '../../header/header/header.component';
import InnerLayout from '../../general/inner-layout/inner-layout.component';

type Props = {
	handleBack: (origin: string) => void;
	emitSelection: (origin: string) => void;
};

const RtwDocuments: FC<Props> = ({ handleBack, emitSelection }) => {
	return (
		<div>
			<Header
				text='Choose the document you want to use to complete your right to work check.'
				type='text'
				backFunction={handleBack}
				origin='main'
				hideStepsCounter={true}
				hideDesktopSteps={true}
			></Header>
			<InnerLayout>
				<div>
					<div className='identity-footer'>
						<button
							onClick={() => emitSelection('passport')}
							className='margin-bottom-8 orka-identity-button grey-filled'
						>
							UK/Irish Passport
							<h4>An active UK/Irish Passport</h4>
						</button>
						<button
							onClick={() => emitSelection('share-code')}
							className='margin-bottom-8 orka-identity-button grey-filled'
						>
							Share Code
							<h4>An active work permit</h4>
						</button>
						<button
							onClick={() => emitSelection('residence-card')}
							className='margin-bottom-8  orka-identity-button grey-filled'
						>
							BRP or BRC
							<h4>
								An active biometric residence card or permit
							</h4>
						</button>
						<button
							onClick={() => emitSelection('other-documents')}
							className='margin-bottom-8 orka-identity-button grey-filled'
						>
							Any other document
							<h4>UK Birth certificate, Paper Visa or others</h4>
						</button>
					</div>
				</div>
				<div></div>
			</InnerLayout>
		</div>
	);
};

export default RtwDocuments;
