import React, { FC } from 'react';
import Header from '../../../header/header/header.component';
import InfoCard from '../../../general/info-card/info-card.component';
import book from '../../../../assets/icons/info-card/book.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types/root-state';
import InnerLayout from '../../../general/inner-layout/inner-layout.component';

type Props = {
	handleBack: (origin: string) => void;
	emitSelection: (origin: string) => void;
};

const RtwPassport: FC<Props> = ({ handleBack, emitSelection }) => {
	const isTablet = useSelector((state: RootState) => state.general.isTablet);
	return (
		<div>
			<Header
				text='Do you want to continue using your UK/Irish passport for this right to work & Identity check?'
				type='text'
				backFunction={handleBack}
				origin='main'
				hideStepsCounter={true}
				hideDesktopSteps={true}
			></Header>
			<InnerLayout>
				<div>
					<p>
						Tap on continue if you have an active UK or Irish
						passport.
					</p>
					{isTablet && (
						<div
							onClick={() => emitSelection('expired-passport')}
							className='margin-top-24'
						>
							<InfoCard
								size='small'
								icon={book}
								text='What happens if I have an expired UK/Irish passport?'
							/>
						</div>
					)}

					<div className='identity-footer'>
						<button
							onClick={() => emitSelection('passport-online')}
							className='margin-bottom-8 orka-identity-button primary-filled'
						>
							Continue with passport
							<h4>Online (5 mins)</h4>
						</button>
					</div>
				</div>
				<div>
					<InfoCard
						size='small'
						icon={book}
						text='What happens if I have an expired UK/Irish passport?'
					/>
				</div>
			</InnerLayout>
		</div>
	);
};

export default RtwPassport;
