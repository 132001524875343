import React, { FC } from 'react';
import activityImage from './assets/images/activity_step_two.png';
import Accordion from '../../../accordion/accordion.component';

const PageTwo: FC = () => {
	return (
		<div className='guide-page-container'>
			<img src={activityImage} alt='guide info' />
			<p>
				We are going to ask you to provide activities for the last{' '}
				<strong>5 years.</strong>
			</p>
			<h2>Here are some examples</h2>
			<div className='accordion-wrapper'>
				<Accordion title='Employed'>
					<ul>
						<li>Two Bank Statements</li>
						<li>P60s and P45s</li>
						<li>Payslips and Contract of Employment.</li>
						<li>
							This documentation should be dated within 31 days of
							the date you are trying to evidence.
						</li>
					</ul>
				</Accordion>
				<Accordion title='Self Employed'>
					<ul>
						<li>HMRC Tax Returns for the past five years.</li>
						<li>
							A mixture of bank statements and invoices to support
							the time periods stated in your application.
						</li>
						<li>
							A letter from an Accountant confirming that
							you&apos;re self employed with full dates
						</li>
					</ul>
				</Accordion>
				<Accordion title='Career Break'>
					<ul>
						<li>
							If you have spent time Job Seeking, you might have
							received a Government Payment such as Jobseekers
							Allowance. We can use proof of these payments as
							supporting documentation.
						</li>

						<li>
							Please provide proof of Government payments by
							providing either all your Bank Statements for the
							relevant time periods stated or by showing payment
							confirmation from Department of Work and Pension
							(DWP) through the DWP website.
						</li>

						<li>
							If you weren&apos;t receiving Government payments
							during that time, you can provide screenshots of
							emails showing applications to work or responses
							from employers declining/arranging interviews etc.
						</li>

						<li>
							We can also consider supporting evidence from
							companies confirming they were helping you to find
							employment.
						</li>

						<li>
							In order for these emails to be considered, we need
							them to the show the full date, including year and
							the full email addresses of who it was sent to and
							from.
						</li>
					</ul>
				</Accordion>
				<Accordion title='Travelling'>
					<ul>
						<li>Passport Stamps</li>
						<li>Travel Itinerary</li>
						<li>Visa Application Letters</li>
						<li>Travel Tickets (Plane, Train, Coach, etc)</li>
						<li>
							Bank Statements (showing payments to travel
							agencies/companies)
						</li>
					</ul>
				</Accordion>
				<Accordion title='Studying'>
					<ul>
						<li>Certificates and Module Breakdowns.</li>
						<li>Proof of Start Date: Enrolment letters</li>
						<li>Leaving: Certificates</li>
						<li>
							Acceptance Letters can be provided but only when
							provided with other evidences
						</li>
						<li>
							If you are still studying confirmation from your
							place of study e.g. university can be accepted as
							long as it contains the following:
						</li>
						<li>
							Letter - this must be on letter headed paper and
							contain an official stamp from the place of study.
						</li>
						<li>
							Email - this must have their email visible and it
							must be addressed to you directly Please make sure
							either of the above are not older than 31 days and
							that they contain the full date of sending,
							including year.
						</li>
						<li>
							If you have been in education, then please send
							letters from the university/college/school with your
							name and course start/end dates on!
						</li>
					</ul>
				</Accordion>
				<Accordion title='Volunteering'>
					<ul>
						<li>
							Confirmation from the organisation, correspondence
							like emails with the full dates and and names
							showing
						</li>
					</ul>
				</Accordion>
			</div>
		</div>
	);
};

export default PageTwo;
