import React, { FC, useEffect, useState, useRef, Fragment } from 'react';
import './consent.styles.scss';
import Layout from '../../components/general/layout-component/layout.component';
import Header from '../../components/header/header/header.component';
import SignatureCanvas from 'react-signature-canvas';
import checkboxEmpty from '../../assets/icons/misc/checkbox-empty.svg';
import checkboxTicked from '../../assets/icons/misc/checkbox-filled.svg';
import pencil from '../../assets/icons/misc/pencil.svg';
import PageFooter from '../../components/general/page-footer/page-footer.component';
import InnerLayout from '../../components/general/inner-layout/inner-layout.component';
import * as utils from '../../utils/utilsFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../types/root-state';
import * as consentApi from '../../api/requests/consent.api';
import * as documentsApi from '../../api/requests/documents.api';
import { setApplicationStatus } from '../../redux/General/general.actions';
import InfoCard from '../../components/general/info-card/info-card.component';
import errorIcon from '../../assets/icons/misc/error.svg';
import * as apiMisc from '../../api/requests/misc.api';
import { fireGaEvent } from '../../utils/ga-event';
import { pdf, Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { consentFormData } from '../../utils/data';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleUploadDocuments } from '../../utils/document-uploads';

const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		backgroundColor: '#E4E4E4',
		padding: 32,
	},
	mainHeading: {
		textAlign: 'center',
		fontSize: '16px',
		fontWeight: 500,
		marginBottom: '6px',
	},
	section: {
		padding: 5,
		marginBottom: '4px',
	},
	sectionSmall: {
		padding: 5,
		marginBottom: 0,
	},
	heading: {
		fontSize: '12px',
		fontWeight: 500,
		marginBottom: '14px',
	},
	text: {
		fontSize: '12px',
		fontWeight: 400,
		marginBottom: '4px',
	},
	listItem: {
		fontSize: '12px',
		fontWeight: 400,
		marginBottom: '4px',
		marginTop: '8px',
	},
	image: {
		width: '250px',
		height: '200px',
	},
});

const Consent: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { state } = useLocation();
	const [pageName, setPageName] = useState<string | undefined>('');
	const [acceptedCheckboxes, setAcceptedCheckboxes] = useState<number[]>([]);
	const canvasRef = useRef<any>();
	const acceptConsent = useRef<HTMLDivElement>(null);
	const [isDisabled, setIsDisabled] = useState(false);
	const [signature, setSignature] = useState<any>();
	const [submissionValidationError, setSubmissionValidationError] = useState(false);
	const isMobile = useSelector((state: RootState) => state.general.isMobile);
	const isTablet = useSelector((state: RootState) => state.general.isTablet);

	fireGaEvent('Consent', 'Consent - Contract Page');

	useEffect(() => {
		utils.instantScroll();
		setPageName(utils.setPageName(state, navigate));
	}, []);

	useEffect(() => {
		if (acceptedCheckboxes.length === getDeclarationCheckboxesLength() && signature) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [acceptedCheckboxes, signature]);

	const [consentVersion, setConsentVersion] = useState<any>();

	const applicant = useSelector((state: RootState) => state.applicant.applicant);

	const handleCheckboxClick = (index: number) => {
		if (acceptedCheckboxes.includes(index)) {
			setAcceptedCheckboxes(acceptedCheckboxes.filter((item) => item !== index));
		} else {
			setAcceptedCheckboxes([...acceptedCheckboxes, index]);
		}
	};

	useEffect(() => {
		consentApi
			.getConsent()
			.then((res) => {
				setConsentVersion(res.data.data.version);
			})
			.catch((err) => {
				throw err;
			});
	}, []);

	const getDeclarationCheckboxesLength = () => {
		return consentFormData[1].list.length;
	};

	const handleOverallSubmission = async () => {
		utils.instantScroll();

		if (isDisabled) {
			fireGaEvent('Consent', 'Taps submit (validation failed)');
			setSubmissionValidationError(true);
			return;
		}
		fireGaEvent('Consent', 'Taps submit');
		return handleSignatureValidation().catch((err: Error) => {
			handlePostOverallSubmission();
			throw err;
		});
	};

	const handleSignatureValidation = async () => {
		const blob = await pdf(ConsentPdfArea).toBlob();

		const jpegBlog = utils.dataURItoBlob(signature);
		const generateFile = new File([jpegBlog], 'camera_jpeg', {
			type: 'image/jpeg',
		});

		await handleUploadDocuments(
			generateFile,
			applicant.workerUuid,
			'consentSignature',
			dispatch,
			'workerDocuments',
			undefined,
		).catch((err: Error) => {
			throw err;
		});

		const file = new File([blob], 'camera_jpeg', {
			type: 'application/pdf',
		});

		const res = await consentApi.getConsentUploadUrl('application/pdf').catch((err: Error) => {
			throw err;
		});
		const { fileName, writeUrl } = res.data.data.uploadUrl || '';
		if (writeUrl && fileName) {
			await documentsApi.uploadToGoogle(file, writeUrl).catch((err: Error) => {
				throw err;
			});

			await consentApi
				.saveConsentVersion(fileName, consentVersion)
				.then(() => {
					return handlePostOverallSubmission();
				})
				.catch((err: Error) => {
					throw err;
				});
		}
	};

	const handlePostOverallSubmission = () => {
		return apiMisc
			.setApplicationStatus('application-submitted')
			.then(() => {
				dispatch(setApplicationStatus('application-submitted'));
				return navigate('check/application-submitted', {
					replace: true,
				});
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	const ConsentPdfArea = (
		<Document>
			<Page size='A4' style={styles.page}>
				<Text style={styles.mainHeading}>Declaration</Text>
				{consentFormData.map((element: any, index: number) => {
					return (
						<View style={element.small ? styles.sectionSmall : styles.section} key={index}>
							<Text style={styles.heading}>{element.heading}</Text>
							{element.bodyText && <Text style={styles.text}>{element.bodyText}</Text>}
							{element.list &&
								element.list.map((listItem: string) => {
									return (
										<Text key={listItem} style={styles.listItem}>
											{listItem}
										</Text>
									);
								})}
						</View>
					);
				})}
				<View style={styles.sectionSmall}>
					<Text style={styles.text}>{utils.getFullName('', applicant.name, applicant.surname)}</Text>
					<Text style={styles.text}>Date Signed {utils.formatDateForUser(new Date())}</Text>
				</View>
				<View style={styles.sectionSmall}>
					<Image src={signature} style={styles.image} />
				</View>
			</Page>
		</Document>
	);

	const handleBack = () => {
		return navigate('/', { replace: true });
	};

	const handleClearSignature = () => {
		if (canvasRef.current) {
			canvasRef.current.clear();
			setSignature('');
		}
	};

	const handleScroll = () => {
		fireGaEvent('Consent', 'Tap scroll to bottom button');
		if (acceptConsent.current) {
			acceptConsent.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<div className='consent-page'>
			<Layout background=''>
				<Header text={pageName ? pageName : ''} type='text' backFunction={handleBack} origin='main' />
				<InnerLayout>
					<div>
						{isTablet && submissionValidationError && (
							<InfoCard
								size='small'
								icon={errorIcon}
								text="You can't complete this section until you click the checkbox and draw your signature"
								subText='If you wish to complete this section later you can tap the Deploi logo and your progress will be saved.'
								type='validation-card'
							/>
						)}
						<div className='scroll-to-bottom' onClick={handleScroll}>
							<img src={pencil} alt='pencil' />
							<h4>Scroll to the bottom to sign</h4>
						</div>
						<div className='consent-content-container'>
							{consentFormData.map((item: any, index: number) => (
								<div key={index}>
									<div className='consent-headingText'>{item.heading}</div>
									<p className='consent-bodyText'>{item.bodyText}</p>
									{item.list.map((checkbox: string, index: number) => (
										<div key={index} className='accept-consent-section' ref={acceptConsent}>
											<div className='accept-checkbox'>
												<img
													role='input'
													src={
														acceptedCheckboxes.includes(index)
															? checkboxTicked
															: checkboxEmpty
													}
													alt='checkbox'
													id={`accept-consent-${index}`}
													onClick={() => handleCheckboxClick(index)}
												/>
												<label htmlFor={`accept-consent-${index}`}>{checkbox}</label>
											</div>
										</div>
									))}
								</div>
							))}
						</div>

						<label className='please-sign'>Please sign below</label>
						<div className='canvas-container'>
							<SignatureCanvas
								penColor='black'
								canvasProps={{
									width: isMobile ? 327 : 350,
									height: 200,
									className: 'sig-canvas',
								}}
								ref={canvasRef}
								onEnd={() => {
									const data = canvasRef.current.toDataURL();
									setSignature(data);
								}}
							/>
						</div>

						<div
							onClick={handleClearSignature}
							role='button'
							className='clear-signature margin-bottom-16 margin-top-16'
						>
							<p>Clear Signature</p>
						</div>

						<PageFooter
							primaryButtonFunction={handleOverallSubmission}
							primaryButtonText='Submit'
							disabled={false}
						/>
						<div className='margin-bottom-120' />
					</div>
					<>
						{!isTablet && submissionValidationError && (
							<InfoCard
								size='small'
								icon={errorIcon}
								text="You can't complete this section until you click the checkbox and draw your signature"
								subText='If you wish to complete this section later you can tap the Deploi logo and your progress will be saved.'
								type='validation-card'
							/>
						)}
					</>
				</InnerLayout>
			</Layout>
		</div>
	);
};

export default Consent;
