import React, { FC, SetStateAction, Dispatch } from 'react';
import './main-header-desktop.styles.scss';
import ProgressBar from '../../general/progress-bar/progress-bar.component';
import TermsAgreed from '../terms-agreed/terms-agreed.component';
import * as utils from '../../../utils/utilsFunctions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import ApplicationStatusInfo from '../application-status-info/application-status-info.component';
import ActionInfoCard from '../../general/action-info-card/action-info-card.component';

type Props = {
	handleStartConsent: () => void;
	percentage: string;
	noComplete: number;
	actionCardText: string | null;
	actionCardEmphasisText: string | null;
	actionCardSubText: string | null;
	setShowProgress?: Dispatch<SetStateAction<boolean>> | null;
	postSubmissionStatus?: string;
	showProgress?: boolean;
	showModal?: boolean;
	setShowModal?: (showModal: boolean) => void;
};
const MainHeaderDesktop: FC<Props> = ({
	percentage,
	noComplete,
	postSubmissionStatus,
	actionCardText = null,
	actionCardEmphasisText = null,
	actionCardSubText = null,
	setShowModal = null,
}) => {
	const applicationStatus = useSelector(
		(state: RootState) => state.general.applicationStatus,
	);
	const applicant = useSelector(
		(state: RootState) => state.applicant.applicant,
	);

	const clearanceStatusHistory = useSelector(
		(state: RootState) => state.applicant.applicant.clearanceStatusHistory,
	);

	const lastComplianceDate: Date = utils.getLastComplianceDate(
		applicant.complianceStarted,
		7,
	);

	const numberOfDaysLeft = utils.getNumberOfDaysLeft(lastComplianceDate);

	return (
		<div className='desktop-header-container'>
			<div className='desktop-header-content'>
				{applicationStatus === 'application-submitted' &&
					clearanceStatusHistory?.provisionallyClearedDate &&
					clearanceStatusHistory?.clearanceStatus ===
					'partially_compliant' && (
						<ActionInfoCard
							text={actionCardText}
							emphasisText={actionCardEmphasisText}
							subText={actionCardSubText}
							clickFunction={setShowModal}
						/>
					)}

				<div className='desktop-header-flex'>
					<div className='your-background-check'>
						<h1 className='applicant-greeting'>
							<span className='greeting'>
								{utils.dayStatus()}
							</span>
							<span className='applicant-name'>
								{applicant.name}
							</span>
						</h1>
						{applicationStatus !== 'application-submitted' && (
							<p>
								{`We suggest you complete your check by `}
								<span className='extra-bold-font'>
									{' '}
									{lastComplianceDate.toLocaleDateString(
										'en-GB',
									)}{' '}
									(
									{numberOfDaysLeft < 0
										? '0'
										: numberOfDaysLeft}{' '}
									days left)
								</span>
							</p>
						)}
					</div>
					{percentage && noComplete !== 0 && (
						<div
							className={`desktop-header-progress-container ${applicationStatus === 'application-submitted'
								? 'application-sent'
								: ''
								}`}
						>
							<div className='header-progress'>
								<ProgressBar
									status={percentage}
									percentage={true}
								/>
								{(applicationStatus === 'terms-agreed' ||
									applicationStatus === 'not-started') && (
										<TermsAgreed />
									)}
								{postSubmissionStatus && (
									<ApplicationStatusInfo
										status={postSubmissionStatus}
									/>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default MainHeaderDesktop;
