import { ActionProps } from './rtw.actions';
import { CLEAR_RTW, SAVE_TO_RTW, SET_ID_CHECK_STATUS, SET_RTW_STATUS } from './rtw.types';

const initialState = {
	data: [],
	rtwStatus: {
		page: '',
		checkName: '',
		status: 'incomplete',
	},
	idCheckStatus: {
		page: '',
		checkName: '',
		status: 'incomplete',
	},
};

const rtwReducer = (state: any = initialState, action: ActionProps): any => {
	switch (action.type) {
		case SAVE_TO_RTW:
			return { ...state, data: [action.data] };
		case CLEAR_RTW:
			return initialState;
		case SET_RTW_STATUS:
			return {
				...state,
				rtwStatus: action.data,
			};
		case SET_ID_CHECK_STATUS:
			return {
				...state,
				idCheckStatus: action.data,
			};
		default:
			return state;
	}
};

export default rtwReducer;
