import React, { FC, Dispatch, SetStateAction, useContext } from 'react';
import './action-control.styles.scss';
import OrkaButton from '../orka-button/orka-button.component';
import { fireGaEvent } from '../../../utils/ga-event';
import crossWhite from '../../../assets/icons/misc/cross-white.svg';
import ActivityContext from '../../../context/ActivityContext';

type Props = {
	buttonClicked: (button: string) => void;
	gaPage?: string;
	setShowActionControl: Dispatch<SetStateAction<boolean>>;
};

const ActionControl: FC<Props> = ({
	buttonClicked,
	gaPage,
	setShowActionControl,
}) => {
	if (gaPage) {
		fireGaEvent(gaPage, 'Action control sheet page');
	}

	const { state } = useContext(ActivityContext);

	return (
		<div className='action-control-container'>
			<div className='action-control-close'>
				<button onClick={() => setShowActionControl(false)}>
					<img alt='close' src={crossWhite} />
				</button>
			</div>
			<div className='action-control-buttons'>
				{state?.allEvidenceReceived ? (
					<OrkaButton
						buttonContent='View Information'
						disabled={false}
						emitClicked={() => buttonClicked('information')}
						buttonClass='native-style'
					/>
				) : (
					<>
						<OrkaButton
							buttonContent='Edit'
							disabled={false}
							emitClicked={() => buttonClicked('edit')}
							buttonClass='native-style'
						/>
						<OrkaButton
							buttonContent='Documents'
							disabled={false}
							emitClicked={() => buttonClicked('docs')}
							buttonClass='native-style'
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default ActionControl;
