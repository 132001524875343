import React, { FC, useEffect, useState } from 'react';
import styles from './steps-dropdown-bottom.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import greenCircleTick from '../../../assets/icons/misc/green-circle-tick.svg';
import { handleNext } from '../../../utils/utilsFunctions';
import { setConsentModalState } from '../../../redux/Applicant/applicant.actions';
import * as utils from '../../../utils/utilsFunctions';
import { useNavigate } from 'react-router-dom';

const StepsDropdownBottom: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const stepData = useSelector(
		(state: RootState) => state.general.stepsComplete,
	);
	const consentSubmitted = useSelector(
		(state: RootState) => state.applicant.consentSubmitted,
	);

	const [currentPage, setCurrentPage] = useState<string>('');

	useEffect(() => {
		const currentUrl = window.location.pathname.replace('/check/', '');
		const mappedToStep = utils.capitalizeFirstLetterOfEach(
			utils.removeHyphen(currentUrl),
		);
		setCurrentPage(mappedToStep);
	}, [window.location.pathname]);
	return (
		<nav>
			<ul className={styles['dropdown-container']}>
				{stepData.map((step) => {
					return (
						<li
							key={step.title}
							role='option'
							onClick={() => {
								step.title !== currentPage
									? handleNext(
											step.title,
											dispatch,
											setConsentModalState,
											consentSubmitted,
											navigate,
										)
									: null;
							}}
							className={
								step.title === currentPage
									? styles['active-step']
									: ''
							}
						>
							<a className={`${styles['dropdown-option']}`}>
								{step.title}
							</a>
							{step.status === 'complete' && (
								<img
									src={greenCircleTick}
									alt='step complete'
								/>
							)}
						</li>
					);
				})}
			</ul>
		</nav>
	);
};

export default StepsDropdownBottom;
