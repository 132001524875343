import React, {
	Dispatch,
	FC,
	SetStateAction,
	useState,
	useEffect,
	useMemo,
} from 'react';
import './address-form.styles.scss';
import Header from '../../header/header/header.component';
import Input from '../../general/input/input.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import { useDispatch, useSelector } from 'react-redux';
import {
	addAddressHistoryItem,
	removeAddressHistoryItem,
	updateAddressItem,
} from '../../../redux/Applicant/applicant.actions';
import { RootState } from '../../../types/root-state';
import {
	IAddressHistoryItem,
	IAddressHistoryItemDb,
} from '../../../types/interfaces/applicant.interface';
import * as validations from '../../../utils/validations';
import ValidationLayer from '../../general/validation-layer/validation-layer.component';
import * as api from '../../../api/requests/address.api';
import * as utils from '../../../utils/utilsFunctions';
import { fireGaEvent } from '../../../utils/ga-event';
import AddressSearch from '../address-search/address-search.component';

type Props = {
	setShowForm: Dispatch<SetStateAction<boolean>>;
	setShowUpload: Dispatch<SetStateAction<boolean>>;
	isCurrent: boolean;
	handleBack: (origin: string) => void;
	selectedAddress: IAddressHistoryItemDb | null;
	setSelectedAddress: Dispatch<SetStateAction<IAddressHistoryItemDb | null>>;
	setRefetchAddressHistory: Dispatch<SetStateAction<boolean>>;
	refetchAddressHistory: boolean;
	handleDeleteDocument: () => Promise<void>;
};

const AddressForm: FC<Props> = ({
	setShowForm,
	setShowUpload,
	isCurrent,
	handleBack,
	selectedAddress,
	setSelectedAddress,
	setRefetchAddressHistory,
	refetchAddressHistory,
	handleDeleteDocument,
}) => {
	const isMobile = useSelector((state: RootState) => state.general.isMobile);
	const addressDocument = useSelector(
		(state: RootState) => state.applicant.addressDocuments,
	);
	const addressHistory = useSelector(
		(state: RootState) => state.applicant.addressHistory,
	);

	const updatePassport = useSelector(
		(state: RootState) =>
			state.applicant.applicant?.clearanceStatusHistory
				?.passportUpdateRequired,
	);

	const [formState, setFormState] = useState<IAddressHistoryItem>({
		uuid: '',
		streetAddress: '',
		city: '',
		postcode: '',
		country: '',
		startAt: null,
		endAt: null,
	});
	const [isDisabled, setIsDisabled] = useState(false);
	const [dateValidation, setDateValidation] = useState('');
	const [validInputs, setValidInputs] = useState([
		{ startAt: true },
		{ endAt: true },
	]);
	const [addressSelected, setAddressSelected] = useState<any>(null);
	const [continueClicked, setContinueClicked] = useState(false);

	const dispatch = useDispatch();

	fireGaEvent('Address History', 'Address History - Form Page');

	useEffect(() => {
		utils.instantScroll();
	}, []);

	useEffect(() => {
		if (selectedAddress !== null) {
			const { streetAddress, city, postcode, country } =
				selectedAddress.address;
			const editFormState = {
				...formState,
				uuid: selectedAddress.uuid,
				startAt: selectedAddress.startAt,
				endAt: selectedAddress.endAt,
				streetAddress,
				city,
				postcode,
				country,
			};
			setFormState(editFormState);
		}
	}, [selectedAddress]);

	useEffect(() => {
		if (addressSelected) {
			setFormState({
				...formState,
				...addressSelected,
			});
		}
	}, [addressSelected]);

	const handleAddressForm = (val: string, input: string) => {
		setFormState({
			...formState,
			[input]: val,
		});
	};

	const handleActionAddress = (action: string) => {
		setContinueClicked(true);
		if (isDisabled) {
			fireGaEvent(
				'Address History',
				action === 'add'
					? 'Clicked Add Address (Validation Failed)'
					: 'Clicked Edit Address (Validation Failed)',
			);
			return;
		} else {
			fireGaEvent(
				'Address History',
				action === 'add'
					? 'Clicked Add Address'
					: 'Clicked Edit Address',
			);
			action === 'add' ? handleAddAddress() : handleEditAddress();
		}
	};

	const formatPayload = () => {
		const { streetAddress, city, postcode, country, startAt, endAt } =
			formState;
		const payload: IAddressHistoryItemDb = {
			address: {
				streetAddress,
				city,
				postcode,
				country,
			},
			startAt,
			endAt,
		};
		if (formState.uuid) {
			payload.uuid = formState.uuid;
		}
		return payload;
	};

	const handleAddAddress = () => {
		return api
			.saveAddress(formatPayload())
			.then((res: any) => {
				const addressWithUuid = formatPayload();
				addressWithUuid.uuid = res.data.data.uuid;
				dispatch(addAddressHistoryItem(addressWithUuid));
				utils.handleAlerts(
					'success',
					'Successfully added address',
					dispatch,
				);
				setRefetchAddressHistory(!refetchAddressHistory);
				handleForwardNavigation();
			})
			.catch((err: Error) => {
				utils.handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	const handleEditAddress = () => {
		return api
			.updateAddress(formatPayload())
			.then((res: any) => {
				if (res.status === 404) {
					throw res;
				}
				utils.handleAlerts(
					'success',
					'Successfully updated address',
					dispatch,
				);
				dispatch(updateAddressItem(formatPayload()));
				setRefetchAddressHistory(!refetchAddressHistory);
				handleForwardNavigation();
			})
			.catch((err: Error) => {
				utils.handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	const handleRemoveAddress = () => {
		fireGaEvent('Address History', 'Clicked Remove Address History Form');

		const filteredAddress = addressHistory.filter((address) => {
			const keys = Object.keys(address);
			return keys.includes('uuid');
		});

		if (
			filteredAddress.length === 1 &&
			addressDocument &&
			addressDocument.length > 0
		) {
			return handleDeleteDocument().then(() => {
				return deleteAddress().catch((err: Error) => {
					throw err;
				});
			});
		} else {
			return deleteAddress().catch((err: Error) => {
				throw err;
			});
		}
	};

	const deleteAddress = () => {
		if (selectedAddress && selectedAddress.uuid) {
			return api
				.deleteAddressItem(selectedAddress.uuid)
				.then(() => {
					dispatch(removeAddressHistoryItem(selectedAddress));
					utils.handleAlerts(
						'success',
						'Successfully removed address',
						dispatch,
					);
					setShowForm(false);
					setRefetchAddressHistory(!refetchAddressHistory);
					setSelectedAddress(null);
				})
				.catch((err: Error) => {
					utils.handleAlerts('error', err.message, dispatch);
					throw err;
				});
		}
	};

	const handleForwardNavigation = () => {
		if (!formState.endAt && addressDocument.length === 0) {
			setShowForm(false);
			setShowUpload(true);
		} else {
			setShowForm(false);
		}
	};

	const requiredFields = useMemo(() => {
		const fields = ['streetAddress', 'city', 'country', 'startAt'];
		if (!isCurrent && !updatePassport) fields.push('endAt');
		return fields;
	}, [isCurrent]);

	return (
		<div className='address-form-container'>
			<ValidationLayer
				setIsDisabled={setIsDisabled}
				formState={formState}
				validInputs={validInputs}
				requiredChecks={[
					'requiredFields',
					'dateComparison',
					'validInputs',
				]}
				requiredFields={requiredFields}
				setDateValidation={setDateValidation}
			>
				{isMobile && (
					<Header
						text={
							isCurrent ? 'Where do you live now?' : 'Add address'
						}
						type='text'
						backFunction={() => handleBack('form')}
						origin='form'
						deleteFunction={handleRemoveAddress}
						editMode={!!selectedAddress}
					/>
				)}

				<div className={isMobile ? '' : 'modal-content-scrollable'}>
					<form className='address-form-section'>
						<AddressSearch
							streetAddress={formState.streetAddress}
							handleAddressForm={handleAddressForm}
							continueClicked={continueClicked}
							setAddressSelected={setAddressSelected}
							addressSelected={addressSelected}
						/>

						<Input
							inputName='city'
							title='City'
							placeholder='Preston'
							value={formState.city}
							emitValue={handleAddressForm}
							required={true}
							continueClicked={continueClicked}
						/>

						<Input
							inputName='postcode'
							title='Postal / Zip Code'
							placeholder='PR14 7VN'
							value={formState.postcode}
							emitValue={handleAddressForm}
							required={false}
							continueClicked={continueClicked}
						/>

						<Input
							inputName='country'
							title='Country'
							placeholder='United Kingdom'
							value={formState.country}
							emitValue={handleAddressForm}
							required={true}
							continueClicked={continueClicked}
						/>

						{isCurrent && !updatePassport && (
							<Input
								inputName='startAt'
								title={
									isCurrent
										? 'When did you move in?'
										: 'Start date'
								}
								placeholder='MM/YYYY'
								value={formState.startAt}
								emitValue={handleAddressForm}
								isDate={true}
								validationFunction={validations.dateValidation}
								validInputs={validInputs}
								setValidInputs={setValidInputs}
								required={true}
								continueClicked={continueClicked}
								dateFormat={['month', 'year']}
							/>
						)}

						{(!isCurrent || updatePassport) && (
							<>
								<Input
									inputName='startAt'
									title='Start date'
									placeholder='MM/YYYY'
									value={formState.startAt}
									emitValue={handleAddressForm}
									isDate={true}
									validationFunction={
										validations.dateValidation
									}
									validInputs={validInputs}
									setValidInputs={setValidInputs}
									required={true}
									continueClicked={continueClicked}
									dateFormat={['month', 'year']}
								/>

								<div className='end-date'>
									<Input
										inputName='endAt'
										title={
											updatePassport
												? 'End date (leave blank if current)'
												: 'End date'
										}
										placeholder='MM/YYYY'
										value={formState.endAt}
										emitValue={handleAddressForm}
										isDate={true}
										validationFunction={
											validations.dateValidation
										}
										validInputs={validInputs}
										setValidInputs={setValidInputs}
										required={!updatePassport}
										continueClicked={continueClicked}
										dateFormat={['month', 'year']}
									/>
								</div>
								{dateValidation && (
									<div className='validation-error'>
										<p>{dateValidation}</p>
									</div>
								)}
							</>
						)}
					</form>
				</div>

				<div
					className={`page-form-footer-outer-container ${
						isMobile && 'remove-fixed-footer'
					}`}
				>
					<div className='page-form-footer-inner-container'>
						<OrkaButton
							buttonContent={selectedAddress ? 'Save' : 'Add'}
							disabled={false}
							emitClicked={() =>
								handleActionAddress(
									selectedAddress ? 'update' : 'add',
								)
							}
							buttonClass='purple-filled'
						/>
						{selectedAddress && !isMobile && (
							<OrkaButton
								buttonContent='Delete'
								disabled={false}
								emitClicked={handleRemoveAddress}
								buttonClass='purple-outline'
							/>
						)}
					</div>
				</div>
				{/* {!isMobile && <div className='margin-bottom-120' />} */}
			</ValidationLayer>
		</div>
	);
};

export default AddressForm;
