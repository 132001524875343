import React, { FC } from 'react';
import './terms-page.styles.scss';

const TermsPage: FC = () => {
	return (
		<section className='terms-main-section'>
			<p>
				I confirm the information I provide on my application form will
				be true and complete to the best of my knowledge.
			</p>
			<p>
				I understand it may be a criminal offence to attempt to obtain
				employment by deception.
			</p>
			<p>
				I understand my employment with this company is subject to
				references and screening in accordance with BS7858
			</p>
		</section>
	);
};

export default TermsPage;
