import './check-passport-activity-list.styles.scss';

import React, {
	Dispatch,
	FC,
	Fragment,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';
import {
	IActivityFormState,
	IActivityItem,
} from '../../../types/interfaces/applicant.interface';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { instantScroll } from '../../../utils/utilsFunctions';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { setActivityHistory } from '../../../redux/Applicant/applicant.actions';
import * as api from '../../../api/requests/activity.api';
import * as utils from '../../../utils/utilsFunctions';

import Header from '../../header/header/header.component';
import InnerLayout from '../../general/inner-layout/inner-layout.component';
import ActionControl from '../../general/action-control/action-control.component';
import ActivityCard from '../../activity/activity-card/activity-card.component';
import GapCard from '../../general/gap-card/gap-card.component';
import PageFooter from '../../general/page-footer/page-footer.component';
import ActivityContext from '../../../context/ActivityContext';


type CheckPassportActivityListProps = {
	handleBack: (origin: string) => void;
	pageOrigin: string;
	pageName: string;
	setShowForm: Dispatch<SetStateAction<boolean>>;
	setShowList: Dispatch<SetStateAction<boolean>>;
	setShowUpload: Dispatch<SetStateAction<boolean>>;
	setViewInformation: Dispatch<SetStateAction<boolean>>;
	setSelectedActivity: Dispatch<
		SetStateAction<IActivityItem | null | IActivityFormState>
	>;
	handleComplete: () => void;
	isDisabled: boolean;
};

const CheckPassportActivityList: FC<CheckPassportActivityListProps> = ({
	handleBack,
	pageName,
	setShowForm,
	setShowList,
	setSelectedActivity,
	setShowUpload,
	handleComplete,
	setViewInformation,
}) => {
	const dispatch = useDispatch();

	const activityHistory = useSelector(
		(state: RootState) => state.applicant.activityHistory,
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isNotCompleteWithFiles, setIsNotCompleteWithFiles] =
		useState<boolean>(false);

	const [showActionControl, setShowActionControl] = useState(false);

	const { dispatchSetState } = useContext(ActivityContext);

	const hasGaps = activityHistory.some((activity) => activity.type === 'gap');

	const handleSelectActivity = (activityItem: IActivityItem) => {
		instantScroll();
		setShowActionControl(true);
		setSelectedActivity(activityItem);

		if (
			activityItem.reference.status === 'All Evidence Received' ||
			activityItem.reference.status === 'Documents Received'
		) {
			dispatchSetState(true);
		} else {
			dispatchSetState(false);
		}
	};

	const handleActionControlClicked = (action: string) => {
		setShowActionControl(false);
		setShowList(false);

		if (action === 'edit') {
			setShowForm(true);
		}
		if (action === 'docs') {
			setShowUpload(true);
		}
		if (action === 'information') {
			setViewInformation(true);
		}
	};

	const handleActivitySubmission = () => {
		const hasFiles = activityHistory.map((activityItem) => {
			if (activityItem.type === 'gap') {
				return;
			}
			return !!(activityItem.files && activityItem.files.length >= 1);
		});

		if (hasFiles.includes(false)) {
			utils.handleAlerts(
				'error',
				'Please upload missing documents',
				dispatch,
			);
			setShowList(true);
		} else {
			handleComplete();
		}
	};

	const handleAdd = () => {
		setSelectedActivity(null);
		setShowList(false);
		setShowForm(true);
	};

	useEffect(() => {
		const body = document.querySelector('body');
		if (showActionControl) {
			instantScroll();
			if (body) {
				disableBodyScroll(body);
			}
		} else {
			if (body) {
				enableBodyScroll(body);
			}
		}

		return () => {
			if (body) {
				enableBodyScroll(body);
			}
		};
	}, [showActionControl]);

	useEffect(() => {
		instantScroll();
		const getActivityHistory = () => {
			api.getActivityHistory()
				.then((res) => {
					dispatch(setActivityHistory(res.data));
				})
				.catch((err: Error) => {
					throw err;
				});
		};
		getActivityHistory();
	}, []);

	useEffect(() => {
		const reducedActivities = activityHistory.reduce(
			(activities: IActivityItem[], activity: IActivityItem) => {
				if (
					activity.type === 'gap' ||
					(activity.files && activity.files.length < 1)
				) {
					activities = [...activities, activity];
				}
				return activities;
			},
			[],
		);
		setIsNotCompleteWithFiles(reducedActivities.length > 0);
	}, [JSON.stringify(activityHistory)]);

	return (
		<div className='check-passport-activity-list-container'>
			<Header
				text={pageName}
				type='text'
				backFunction={handleBack}
				origin='main'
				hideStepsCounter={true}
				hideDesktopSteps={true}
				updatePassport={true}
			/>
			<InnerLayout>
				<div className='check-passport-activity-list-container-inner'>
					{activityHistory.length > 0 && !hasGaps && (
						<div className='check-passport-activity-list-note-container'>
							<p className='check-passport-activity-list-note'>
								Once all of your activity history is added, you
								can complete this section.
							</p>
							<p className='check-passport-activity-list-note'>
								However, if you have not started your next
								activity, you may skip this section by pressing
								&quot;Complete Section&quot; below.
							</p>
						</div>
					)}
					{activityHistory.length === 1 &&
						activityHistory[0].type === 'gap' && (
							<div className='check-passport-activity-list-section-empty'>
								<ActivityCard handleAdd={handleAdd} />
							</div>
						)}
					{activityHistory.length > 0 &&
						!(
							activityHistory.length === 1 &&
							activityHistory[0].type === 'gap'
						) && (
							<>
								{[...activityHistory]
									.reverse()
									.map((activityItem) => {
										if (activityItem.type === 'gap') {
											return (
												<Fragment
													key={activityItem.gapDays}
												>
													<GapCard
														days={
															activityItem.gapDays
																? activityItem.gapDays.toString()
																: ''
														}
														clickFunction={
															handleAdd
														}
													/>
												</Fragment>
											);
										} else {
											return (
												<Fragment
													key={activityItem.uuid}
												>
													<ActivityCard
														activity={activityItem}
														clickFunction={() =>
															handleSelectActivity(
																activityItem,
															)
														}
													/>
												</Fragment>
											);
										}
									})}
							</>
						)}
					{showActionControl && (
						<ActionControl
							buttonClicked={handleActionControlClicked}
							setShowActionControl={setShowActionControl}
						/>
					)}
					<PageFooter
						primaryButtonFunction={handleActivitySubmission}
					/>
					<div className='margin-bottom-120' />
				</div>
				<>
				</>
			</InnerLayout>
		</div>
	);
};

export default CheckPassportActivityList;
