import React, { FC } from 'react';
import './activity-card.styles.scss';
import { IActivityItem } from '../../../types/interfaces/applicant.interface';
import * as utils from '../../../utils/utilsFunctions';
import greenCircleTick from '../../../assets/icons/misc/green-circle-tick-2.svg';
import redCircleCross from '../../../assets/icons/misc/red-circle-cross.svg';
import OrkaButton from '../../general/orka-button/orka-button.component';

type Props = {
	activity?: IActivityItem;
	clickFunction?: () => void;
	handleAdd?: () => void;
};

const ActivityCard: FC<Props> = ({ activity, clickFunction, handleAdd }) => {
	return (
		<article
			className={
				activity
					? 'activity-card-container populated'
					: 'activity-card-container'
			}
			onClick={clickFunction}
		>
			{activity ? (
				<>
					{activity.type === 'career-break' ? (
						<h4>
							{utils.capitalizeFirstLetter(
								utils.removeHyphen(activity.type),
							)}
							{` - ${utils.removeHyphen(
								activity.careerBreakReasonType,
							)}`}
						</h4>
					) : (
						<h4>
							{utils.capitalizeFirstLetter(
								utils.removeHyphen(activity.type),
							)}
							{(activity.type === 'employed' ||
								activity.type === 'volunteering' ||
								activity.type === 'studying') &&
								` - ${utils.capitalizeFirstLetter(
									activity.name,
								)}`}
						</h4>
					)}

					<div className='activity-card-times'>
						<time
							dateTime={
								activity.startAt
									? activity.startAt.toString()
									: undefined
							}
						>
							{utils.formatDateForUser(activity.startAt)}
						</time>
						{' - '}
						<time
							dateTime={
								activity.endAt
									? activity.endAt.toString()
									: undefined
							}
						>
							{activity.endAt
								? utils.formatDateForUser(activity.endAt)
								: 'Current'}
						</time>
					</div>
				</>
			) : (
				<div className='activity-button-container'>
					<OrkaButton
						buttonContent={'Add first activity'}
						disabled={false}
						emitClicked={handleAdd}
						buttonClass={'purple-outline'}
					/>
				</div>
			)}

			<div className='activity-card-tags'>
				<div className='activity-card-tag'>
					<p>Documents</p>
					{activity && activity?.files?.length >= 1 && (
						<img src={greenCircleTick} alt='complete' />
					)}
					{activity &&
						(activity?.files?.length < 1 || !activity?.files) && (
							<img src={redCircleCross} alt='incomplete' />
						)}
				</div>
				<div className='activity-card-tag'>
					<p>Reference information</p>
					{activity && <img src={greenCircleTick} alt='complete' />}
				</div>
			</div>
		</article>
	);
};

export default ActivityCard;
