import React, {
	FC,
	useState,
	useCallback,
	useEffect,
	Dispatch,
	SetStateAction,
} from 'react';
import './address-search.styles.scss';
import * as api from '../../../api/requests/address.api';
import debounce from 'lodash.debounce';
import Input from '../../general/input/input.component';
import { IAddress } from '../../../types/interfaces/applicant.interface';
import Loader from 'react-loader-spinner';
import tip from '../../../assets/icons/misc/tip.svg';

type Props = {
	streetAddress: string;
	handleAddressForm: any;
	setAddressSelected: Dispatch<SetStateAction<any>>;
	addressSelected: IAddress | null;
	continueClicked: boolean;
};

const AddressSearch: FC<Props> = ({
	streetAddress,
	handleAddressForm,
	setAddressSelected,
	addressSelected,
	continueClicked,
}) => {
	const [placeList, setPlaceList] = useState<
		{ description: string; placeId: string }[]
	>([]);
	const [search, setSearch] = useState<string>('');
	const [isLoadingLocal, setIsLoadingLocal] = useState(false);

	useEffect(() => {
		if (addressSelected) {
			setAddressSelected(null);
			setPlaceList([]);
		}
	}, [addressSelected]);

	const sendQuery = (query: string) => {
		if (query) {
			return api
				.getAddressFromGoogle(query)
				.then((res: any) => {
					setPlaceList(res.data);
					setIsLoadingLocal(false);
				})
				.catch((err: Error) => {
					setIsLoadingLocal(false);
					throw err;
				});
		} else {
			setPlaceList([]);
		}
	};

	const delayedQuery = useCallback(
		debounce(() => {
			sendQuery(search);
		}, 500),
		[search],
	);

	useEffect(() => {
		delayedQuery();
		return delayedQuery.cancel;
	}, [search, delayedQuery]);

	const handleEmitFunc = (val: string, inputName: string) => {
		val ? setIsLoadingLocal(true) : setIsLoadingLocal(false);
		setSearch(val);
		handleAddressForm(val, inputName);
	};

	const handleSelectPlace = (placeId: string) => {
		return api
			.getPlaceById(placeId)
			.then((res: any) => {
				const {
					streetNumber,
					streetName,
					town,
					city,
					postalCode,
					country,
				} = res.data;
				const address: IAddress = {
					streetAddress: `${streetNumber ? streetNumber + ' ' : ''
						}${streetName}`,
					city: city ? city : town ? town : '',
					postcode: postalCode ? postalCode : '',
					country,
				};
				setIsLoadingLocal(false);
				setAddressSelected(address);
			})
			.catch((err: Error) => {
				setIsLoadingLocal(false);
				setPlaceList([]);
				throw err;
			});
	};

	return (
		<div className='address-search-container'>
			<div className='address-search-info'>
				<img src={tip} alt='tip' />
				<p>Start typing the address for suggestions</p>
			</div>
			<div className='address-search-input'>
				<Input
					inputName='streetAddress'
					title='Street address'
					placeholder='93 Alexander Road'
					value={streetAddress}
					emitValue={handleEmitFunc}
					required={true}
					continueClicked={continueClicked}
					autocomplete='off'
				/>
				<div className='loading-dots'>
					{isLoadingLocal && (
						<Loader
							type='ThreeDots'
							color='$primary-color'
							height={24}
							width={24}
						/>
					)}
				</div>
			</div>
			{placeList.map((place) => {
				return (
					<button
						key={place.placeId}
						onClick={() => handleSelectPlace(place.placeId)}
						className='google-place-item'
						type='button'
					>
						{place.description}
					</button>
				);
			})}
		</div>
	);
};

export default AddressSearch;
