import React, { useRef, FC } from 'react';
import './layout.styles.scss';

type Props = {
	children: React.ReactNode;
	long?: boolean;
	background: string;
};

const Layout: FC<Props> = ({ children, background }) => {
	const containerRef = useRef<HTMLDivElement>(null);

	return (
		<div ref={containerRef} className='layout-container'>
			<div className={`layout-center ${background}`}>{children}</div>
		</div>
	);
};

export default Layout;
