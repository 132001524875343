import * as api from '../api/requests/documents.api';
import * as utils from './utilsFunctions';
import { AnyAction, Dispatch as ReduxDispatch } from 'redux';
import { IActivityItem } from '../types/interfaces/applicant.interface';
import { setIsLoading } from '../redux/General/general.actions';
import { IUploadTypes } from '../types/interfaces/misc.interface';

export const handleUploadDocuments = (
	file: File,
	applicantUuid: string,
	tag: string,
	dispatch: ReduxDispatch<AnyAction>,
	uploadType: IUploadTypes,
	activityItem?: IActivityItem,
): Promise<any> => {
	return getSignedUrl(file, applicantUuid, uploadType)
		.then((file) => {
			dispatch(setIsLoading(true));
			return api
				.uploadToGoogle(file)
				.then(() => {
					const fileData = {
						tags: JSON.stringify(['unselected', tag]),
						fileName: file.fileName,
						name: `${getName(tag)}${file.fileName}`,
						workerUuid: applicantUuid,
					};
					return activityItem
						? saveWorkDocItem(fileData, dispatch, activityItem)
						: saveDocItem(fileData, dispatch);
				})
				.catch((err: Error) => {
					handleApiError(err, dispatch);
				});
		})
		.catch((err: Error) => {
			handleApiError(err, dispatch);
		});
};

const saveDocItem = (fileData: any, dispatch: any) => {
	return api
		.saveDocumentItem(fileData)
		.then((res: any) => {
			utils.handleAlerts(
				'success',
				'Successfully uploaded file',
				dispatch,
			);
			return res;
		})
		.catch((err: Error) => {
			handleApiError(err, dispatch);
		});
};

const saveWorkDocItem = (
	fileData: any,
	dispatch: any,
	activityItem: IActivityItem,
) => {
	const payload = {
		name: fileData.name,
		tags: fileData.tags,
	};
	return api
		.saveActivityDocumentItem(payload, activityItem.uuid)
		.then((res: any) => {
			utils.handleAlerts(
				'success',
				'Successfully uploaded file',
				dispatch,
			);
			return res;
		})
		.catch((err: Error) => {
			handleApiError(err, dispatch);
		});
};

const getName = (tag: string) => {
	switch (tag) {
		case 'proofOfAddress':
			return 'WORKER-UPLOAD/POA/';
		default:
			return '';
	}
};

const handleApiError = (err: Error, dispatch: any) => {
	utils.handleAlerts('error', err.message, dispatch);
	throw err;
};

const getSignedUrl = (
	file: any,
	applicantUuid: string,
	uploadType: IUploadTypes,
) => {
	const newFile = Array.isArray(file) ? file[0] : file;
	return api
		.getDocUploadUrl(applicantUuid, newFile.type, uploadType)
		.then(({ data }) => {
			const { fileName, readUrl, writeUrl } =
				uploadType === 'activityHistory' || uploadType === 'rtwUpload'
					? data
					: data.data.uploadUrl;
			newFile.fileName = fileName;
			newFile.readUrl = readUrl;
			newFile.writeUrl = writeUrl;
			return newFile;
		})
		.catch((err) => {
			throw err;
		});
};
