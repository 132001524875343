type IHeaders = {
	'content-type': string;
};

type IUrls = {
	GATEWAY_URL: string | undefined;
};

export const getDefaultHeaders = (): IHeaders => {
	return {
		'content-type': 'application/json',
	};
};

export const getUrls = (): IUrls => {
	return {
		GATEWAY_URL: process.env.REACT_APP_GATEWAY_URL,
	};
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getRequestURLFromParams = (url: string, params?: any): string => {
	if (typeof params !== 'undefined') {
		for (const index in params) {
			const param: string = ':' + index,
				value: any = params[index];
			if (url.includes(param)) {
				url = url.replace(new RegExp(param, 'g'), value);
			}
		}
	}
	return url;
};
