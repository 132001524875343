import {combineReducers} from 'redux';
import authReducer from './Auth/auth.reducer';
import generalReducer from './General/general.reducer';
import applicantReducer from './Applicant/applicant.reducer';
import rtwReducer from './RTW/rwt.reducer';
import licencesReducer from './Licences/licences.reducer';

const rootReducer = combineReducers({
	auth: authReducer,
	general: generalReducer,
	applicant: applicantReducer,
	rtw: rtwReducer,
	licences: licencesReducer,
});

export default rootReducer;
