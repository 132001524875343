import React from 'react';

const ActivityContext = React.createContext();

const initialState = {
	allEvidenceReceived: false,
};

export const ActivityProvider = ({ children }) => {
	const [state, setState] = React.useState(initialState);

	const dispatchSetState = (payload) => {
		setState({ ...state, allEvidenceReceived: payload });
	};

	return (
		<ActivityContext.Provider value={{ state, dispatchSetState }}>
			{children}
		</ActivityContext.Provider>
	);
};

export default ActivityContext;
