import React, {
	useEffect,
	useState,
	FC,
	Dispatch,
	SetStateAction,
} from 'react';
import PhoneInput from '../phone-input/phone-input.component';
import './login-phone.styles.scss';
import { fireGaEvent } from '../../../utils/ga-event';

type Props = {
	getCode: Dispatch<SetStateAction<string>>;
	setResendPhone: Dispatch<SetStateAction<boolean>>;
};

const LoginPhone: FC<Props> = ({ getCode, setResendPhone }) => {
	const [arr, setArr] = useState<any>([
		{ isFocused: true, val: '' },
		{ isFocused: false, val: '' },
		{ isFocused: false, val: '' },
		{ isFocused: false, val: '' },
		{ isFocused: false, val: '' },
		{ isFocused: false, val: '' },
	]);

	const [isPopulated, setIsPopulated] = useState<boolean>(false);
	const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false);
	const [countdown, setCountdown] = useState<number>(30);

	fireGaEvent('Login', 'Login - Phone Code Page');

	useEffect(() => {
		const trueArray = arr.map((item: any) => {
			if (item.val) {
				return true;
			} else {
				return false;
			}
		});

		if (trueArray.includes(false)) {
			setIsPopulated(false);
		} else {
			setIsPopulated(true);
		}
	}, [JSON.stringify(arr)]);

	useEffect(() => {
		if (isPopulated) {
			const codeString = getCodeString(arr);
			getCode(codeString);
		}
	}, [isPopulated]);

	const getCodeString = (codeArray: any) => {
		let codeString = '';
		codeArray.forEach((item: any) => {
			codeString += item.val;
		});

		return codeString;
	};

	useEffect(() => {
		startCountDown();
	}, []);

	const resend = () => {
		fireGaEvent('Login', 'Taps resend password');
		setResendPhone(true);
		startCountDown();
	};

	const startCountDown = () => {
		setIsResendDisabled(true);

		// Start the countdown
		const intervalId = setInterval(() => {
			setCountdown((prevCountdown) => {
				if (prevCountdown === 0) {
					// Reset the countdown and enable the button
					clearInterval(intervalId);
					setIsResendDisabled(false);
					return 30;
				} else {
					// Continue counting down
					return prevCountdown - 1;
				}
			});
		}, 1000);
	};

	return (
		<div className='sign-up-confirm-container'>
			<p className='reset-info-title'>
				We have sent a code to your mobile phone.
			</p>
			<div className='phone-code'>
				{arr.map((el: any, index: number) => {
					return (
						<div key={index}>
							<PhoneInput
								isFocused={el.isFocused}
								setArr={setArr}
								index={index}
								value={el.val}
								arr={arr}
							/>
						</div>
					);
				})}
			</div>
			<p className='reset-info'>
				If you have not received your code in the next 30 seconds, you
				can request that we resend the code using the button below.
			</p>
			<div className='sign-up-action-link'>
				<button onClick={resend} disabled={isResendDisabled}>
					{isResendDisabled
						? `Resend Code (${countdown}s)`
						: 'Resend Code'}
				</button>
			</div>
		</div>
	);
};

export default LoginPhone;
