import AddressDocument from '../../components/address/address-document/address-document.component';
import {
    LICENCES,
    ADD_NAME_HISTORY,
    SET_NAME_HISTORY,
    REMOVE_LICENCE,
    REMOVE_NAME_ITEM,
    UPDATE_LICENCE,
    UPDATE_NAME_HISTORY,
    ADD_LICENCE,
    SET_ADDRESS_HISTORY,
    UPDATE_ADDRESS_HISTORY,
    REMOVE_ADDRESS_ITEM,
    ADD_ADDRESS_ITEM,
    SET_ACTIVITY_HISTORY,
    UPDATE_ACTIVITY_HISTORY,
    REMOVE_ACTIVITY_ITEM,
    ADD_ACTIVITY_ITEM,
    SET_DISCLOSURES,
    UPDATE_DISCLOSURE,
    REMOVE_DISCLOSURE,
    ADD_DISCLOSURE,
    SET_STEPS_COMPLETE,
    APPLICANT,
    SET_CONSENT_SUBMITTED,
    SET_CONSENT_MODAL_STATE,
    UPDATE_APPLICANT,
    SET_ADDRESS_DOCUMENTS,
    CLEAR_APPLICANT_STATE,
} from './applicant.types';

export const setNameHistory = (nameHistory) => {
    return {
        type: SET_NAME_HISTORY,
        nameHistory
    }
};

export const addNameHistory = (nameHistory) => {
    return {
        type: ADD_NAME_HISTORY,
        nameHistory
    }
};

export const updateNameHistory = (nameHistoryItem) => {
    return {
        type: UPDATE_NAME_HISTORY,
        nameHistoryItem
    }
};

export const removeNameHistory = (nameHistoryItem) => {
    return {
        type: REMOVE_NAME_ITEM,
        nameHistoryItem
    }
};


export const setLicences = (licences) => {
    return {
        type: LICENCES,
        licences
    }
};

export const addLicence = (licenceItem) => {
    return {
        type: ADD_LICENCE,
        licenceItem
    }
};

export const updateLicences = (licenceItem) => {
    return {
        type: UPDATE_LICENCE,
        licenceItem
    }
};

export const removeLicence = (licenceItem) => {
    return {
        type: REMOVE_LICENCE,
        licenceItem
    }
};

export const setAddressHistory = (addressHistory) => {
    return {
        type: SET_ADDRESS_HISTORY,
        addressHistory
    }
};

export const updateAddressItem = (addressHistoryItem) => {
    return {
        type: UPDATE_ADDRESS_HISTORY,
        addressHistoryItem
    }
};

export const removeAddressHistoryItem = (addressHistoryItem) => {
    return {
        type: REMOVE_ADDRESS_ITEM,
        addressHistoryItem
    }
};

export const addAddressHistoryItem = (addressHistoryItem) => {
    return {
        type: ADD_ADDRESS_ITEM,
        addressHistoryItem
    }
};


export const setActivityHistory = (activityHistory) => {
    return {
        type: SET_ACTIVITY_HISTORY,
        activityHistory
    }
};

export const updateActivityItem = (activityItem) => {
    return {
        type: UPDATE_ACTIVITY_HISTORY,
        activityItem
    }
};

export const removeActivityItem = (activityItem) => {
    return {
        type: REMOVE_ACTIVITY_ITEM,
        activityItem
    }
};

export const addActivityItem = (activityItem) => {
    return {
        type: ADD_ACTIVITY_ITEM,
        activityItem
    }
};



export const setDisclosures = (disclosures) => {
    return {
        type: SET_DISCLOSURES,
        disclosures
    }
};

export const updateDisclosure = (disclosure) => {
    return {
        type: UPDATE_DISCLOSURE,
        disclosure
    }
};

export const removeDisclosure = (disclosure) => {
    return {
        type: REMOVE_DISCLOSURE,
        disclosure
    }
};

export const addDisclosure = (disclosure) => {
    return {
        type: ADD_DISCLOSURE,
        disclosure
    }
};

export const setApplicant = (applicant) => {
    return {
        type: APPLICANT,
        applicant
    }
}

export const setConsentSubmitted = () => {
    return {
        type: SET_CONSENT_SUBMITTED,

    }
}

export const setConsentModalState = (consentModalState) => {
    return {
        type: SET_CONSENT_MODAL_STATE,
        consentModalState,
    }
}

export const updateApplicant = (obj) => {
    return {
        type: UPDATE_APPLICANT,
        obj

    }
}

export const setAddressDocuments = (addressDocuments) => {
    return {
        type: SET_ADDRESS_DOCUMENTS,
        addressDocuments
    };
};

export const clearApplicantState = () => {
    return {
        type: CLEAR_APPLICANT_STATE
    }
}



