import './check-passport-update-activity.styles.scss';

import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetterOfEach } from '../../../utils/utilsFunctions';
import { IActivityItem } from '../../../types/interfaces/applicant.interface';

import Header from '../../header/header/header.component';

const CheckPassportUpdateActivity: FC<{
	latestActivity: IActivityItem;
}> = ({ latestActivity }) => {
	const navigate = useNavigate();

	const handleBack = () => {
		navigate('/check/update-home');
	};

	const handleYes = () => {
		navigate('/check/activity-update-document');
	};

	const handleNo = () => {
		navigate('/check/activity-leave-date');
	};

	const styledText = (text: string) => (
		<span className='check-passport-activity-type'>{text}</span>
	);

	const activityMessage = useMemo(() => {
		if (!latestActivity) {
			return '';
		}

		const getActivityMessage = (activityType: string) => {
			const formattedActivityType = activityType.replace('-', ' ');
			switch (activityType) {
				case 'employed':
					return (
						<>
							Are you still employed at{' '}
							{styledText(
								capitalizeFirstLetterOfEach(
									latestActivity.name,
								),
							)}
							?
						</>
					);
				case 'self-employed':
				case 'studying':
				case 'volunteering':
				case 'travelling':
					return (
						<>
							Are you currently{' '}
							{styledText(formattedActivityType)}?
						</>
					);
				case 'job-seeking':
				case 'medical-reasons':
				case 'parental-cover':
				case 'self-study':
					return (
						<>
							Are you currently on a career break for{' '}
							{styledText(formattedActivityType)}?
						</>
					);
				case 'carer':
					return (
						<>
							Are you currently on a career break as a{' '}
							{styledText(formattedActivityType)}?
						</>
					);
				case 'another-reason':
					return (
						<>
							Are you currently on a {styledText('career break')}?
						</>
					);
				default:
					return '';
			}
		};

		return <>{getActivityMessage(latestActivity.type)}</>;
	}, [latestActivity]);

	return (
		<div className='check-passport-update-activity-wrapper'>
			<Header
				text={activityMessage}
				type='text'
				backFunction={handleBack}
				origin=''
				hideStepsCounter={true}
				hideDesktopSteps={true}
				updatePassport={true}
			/>
			<div className='check-passport-update-activity-button-container'>
				<button
					className='margin-bottom-16 orka-identity-button primary-filled'
					onClick={handleYes}
				>
					Yes
				</button>
				<button
					className='margin-bottom-16 orka-identity-button primary-filled'
					onClick={handleNo}
				>
					No
				</button>
			</div>
		</div>
	);
};

export default CheckPassportUpdateActivity;
