import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as docsApi from '../api/requests/documents.api';
import { IFileItem } from '../types/interfaces/applicant.interface';
import { setAddressDocuments } from '../redux/Applicant/applicant.actions';

const useGetHistoryDocs = (refetch: boolean): any => {
	const dispatch = useDispatch();
	const [documents, setDocuments] = useState<IFileItem[]>([]);
	useEffect(() => {
		docsApi
			.getDocumentsByTag('proofOfAddress')
			.then((res: any) => {
				if (res.status !== 404) {
					dispatch(setAddressDocuments([res.data.data]));
					setDocuments([res.data.data]);
				} else {
					throw res;
				}
			})
			.catch(() => {
				dispatch(setAddressDocuments([]));
				setDocuments([]);
			});
	}, [refetch]);

	return documents;
};

export default useGetHistoryDocs;
