import React, { FC, useEffect, useState } from 'react';
import './name-history.styles.scss';
import Layout from '../../components/general/layout-component/layout.component';
import { ICheckboxAnswer } from '../../types/interfaces/misc.interface';
import NameHistoryFormComponent from '../../components/name-history/name-history-form/name-history-form.component';
import * as utils from '../../utils/utilsFunctions';
import NameHistoryList from '../../components/name-history/name-history-list/name-history-list.component';
import { INameHistoryItem } from '../../types/interfaces/applicant.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/root-state';
import Modal from '../../components/modals/modal/modal.component';
import { useNavigate, useLocation } from 'react-router-dom';

const NameHistory: FC = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const [pageName, setPageName] = useState<string | undefined>('');
	const [checkboxData, setCheckboxData] = useState([
		{
			question: 'Were you known by any other names in the last 5 years?',
			answers: [
				{ id: 1, selected: false, label: 'Yes' },
				{ id: 2, selected: false, label: 'No' },
			],
		},
	]);
	const [showForm, setShowForm] = useState(false);
	const [selectedName, setSelectedName] = useState<INameHistoryItem | null>(
		null,
	);

	const isMobile = useSelector((state: RootState) => state.general.isMobile);

	useEffect(() => {
		utils.instantScroll();
		setPageName(utils.setPageName(state, navigate));
	}, []);

	useEffect(() => {
		checkboxData[0].answers[0].selected
			? setShowForm(true)
			: setShowForm(false);
	}, [
		checkboxData[0].answers[0].selected,
		checkboxData[0].answers[1].selected,
	]);

	const handleBack = (origin: string) => {
		switch (origin) {
			case 'form':
				setShowForm(false);
				break;
			default:
				return navigate('/check', { replace: true });
		}
	};

	const handleSelection = (
		checkBoxAnswer: ICheckboxAnswer,
		question: string,
	) => {
		utils.handleSelection(
			checkBoxAnswer,
			question,
			checkboxData,
			setCheckboxData,
		);
	};

	return (
		<div className='name-history-page'>
			<Layout background=''>
				{!showForm && (
					<NameHistoryList
						pageName={pageName ? pageName : ''}
						handleBack={handleBack}
						checkboxData={checkboxData[0]}
						setCheckboxData={setCheckboxData}
						handleSelection={handleSelection}
						setShowForm={setShowForm}
						setSelectedName={setSelectedName}
					/>
				)}

				{showForm && isMobile && (
					<NameHistoryFormComponent
						title={selectedName ? 'Edit Name' : 'Add Name'}
						handleBack={handleBack}
						setShowForm={setShowForm}
						selectedName={selectedName}
						setSelectedName={setSelectedName}
					/>
				)}

				{!isMobile && showForm && (
					<Modal
						title={selectedName ? 'Edit Name' : 'Add Name'}
						setShowModal={setShowForm}
						showModal={false}
					>
						<NameHistoryFormComponent
							title={selectedName ? 'Edit Name' : 'Add Name'}
							handleBack={handleBack}
							setShowForm={setShowForm}
							selectedName={selectedName}
							setSelectedName={setSelectedName}
						/>
					</Modal>
				)}
			</Layout>
		</div>
	);
};

export default NameHistory;
