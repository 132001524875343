import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import './validation-layer.styles.scss';
import * as validations from '../../../utils/validations';

type Props = {
	children: React.ReactNode;
	setIsDisabled: Dispatch<SetStateAction<boolean>>;
	formState: any;
	requiredFields?: string[];
	requiredChecks?: string[];
	validInputs: {
		[val: string]: boolean | null | undefined;
	}[];
	setDateValidation?: Dispatch<SetStateAction<string>>;
};

const ValidationLayer: FC<Props> = ({
	children,
	setIsDisabled,
	formState,
	requiredFields,
	requiredChecks,
	validInputs,
	setDateValidation = null,
}) => {
	useEffect(() => {
		setIsDisabled(
			validations.checkIsDisabled(
				requiredChecks,
				requiredFields,
				formState,
				validInputs,
				setDateValidation,
			),
		);
	}, [
		JSON.stringify(formState),
		JSON.stringify(validInputs),
		JSON.stringify(requiredFields),
		JSON.stringify(requiredChecks),
	]);
	return <div>{children}</div>;
};

export default ValidationLayer;
