import { ISiaLicenceData } from '../../types/interfaces/applicant.interface';
import { SAVE_SIA_DATA } from './licences.types';

export type LicenceActionProps = {
	type: string;
	payload: any;
};

export const saveSIAData = (
	data: ISiaLicenceData | null,
): LicenceActionProps => ({
	type: SAVE_SIA_DATA,
	payload: data,
});
