import React, { FC } from 'react';
import './activity-complete.styles.scss';
import greenCircleTick from '../../../assets/icons/misc/green-circle-tick.svg';

type Props = {
	activityNumber: number;
};

const ActivityComplete: FC<Props> = ({ activityNumber }) => {
	return (
		<article className='activity-complete-card'>
			<p className='grey-text-card-bold'>Activity {activityNumber}</p>
			<div>
				<img src={greenCircleTick} alt='green tick' />
			</div>
		</article>
	);
};

export default ActivityComplete;
