import React, {
	useEffect,
	useState,
	useRef,
	FC,
	Dispatch,
	SetStateAction,
} from 'react';
import './modal.styles.scss';
import cross from '../../../assets/icons/misc/cross.svg';
import { CSSTransition } from 'react-transition-group';
import { instantScroll } from '../../../utils/utilsFunctions';

type Props = {
	title: string;
	children: React.ReactNode;
	setShowModal: Dispatch<SetStateAction<boolean>>;
	showModal: boolean;
	modalContentHeight?: string;
	modalContentWidth?: string;
	titleIcon?: string | null;
	closeFunction?: (origin: string) => void;
	pageOrigin?: string;
};

const Modal: FC<Props> = ({
	title,
	children,
	setShowModal,
	showModal,
	modalContentHeight = '85vh',
	modalContentWidth = '560px',
	titleIcon = null,
	closeFunction,
	pageOrigin,
}) => {
	const modalContent = useRef<HTMLDivElement>(null);
	const [fadeIn, setFade] = useState<boolean>(false);

	const handleEmitClose = () => {
		if (closeFunction && pageOrigin) {
			closeFunction(pageOrigin);
		} else {
			setShowModal(false);
		}
	};

	useEffect(() => {
		if (showModal) {
			instantScroll();
			setTimeout(() => {
				document.body.style.position = 'fixed';
			}, 50);
		} else {
			document.body.style.position = 'unset';
		}
		return () => {
			document.body.style.position = 'unset';
		};
	}, [showModal]);

	useEffect(() => {
		setTimeout(() => {
			setFade(true);
			if (modalContent.current) {
				modalContent.current.style.height = modalContentHeight;
				modalContent.current.style.maxWidth = modalContentWidth;
			}
		}, 100);
	}, [modalContentHeight]);

	return (
		<>
			<div className='modal-container'>
				<CSSTransition in={fadeIn} timeout={1000} classNames='my-node'>
					<div>
						{fadeIn && (
							<div className='modal-content' ref={modalContent}>
								<div className='modal-header'>
									<div className='modal-header-title'>
										{titleIcon && (
											<img
												src={titleIcon}
												alt='title icon'
											/>
										)}
										<h1>{title}</h1>
									</div>
									<button
										onClick={handleEmitClose}
										className='close-modal-button'
										autoFocus
									>
										<img alt='close' src={cross} />
									</button>
								</div>
								<div className='modal-center'>{children}</div>
							</div>
						)}
					</div>
				</CSSTransition>
			</div>

			<div className='modal-content-mobile'>
				<div className='modal-header'>
					<div className='modal-header-title'>
						{titleIcon && <img src={titleIcon} alt='title icon' />}
						<h1>{title}</h1>
					</div>
					<button
						onClick={handleEmitClose}
						className='close-modal-button'
						autoFocus
					>
						<img alt='close' src={cross} />
					</button>
				</div>
				<div className='modal-center'>{children}</div>
			</div>
		</>
	);
};

export default Modal;
