import './check-passport-update-activity-document.styles.scss';

import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../types/root-state';
import { handleUploadDocuments } from '../../../utils/document-uploads';
import { setActivityHistory } from '../../../redux/Applicant/applicant.actions';
import { IActivityItem } from '../../../types/interfaces/applicant.interface';
import { handleAlerts } from '../../../utils/utilsFunctions';
import { activityDocumentInfo } from '../../../utils/data';
import * as docApi from '../../../api/requests/documents.api';
import * as api from '../../../api/requests/activity.api';
import moment from 'moment';

import Header from '../../header/header/header.component';
import ActivityDocumentsUploadCard from '../../activity/activity-document-upload-card/activity-document-upload-card.component';
import InnerLayout from '../../general/inner-layout/inner-layout.component';

import successTick from '../../../assets/images/circle-tick.svg';

const CheckPassportUpdateDocument: FC<{
	latestActivity: IActivityItem;
}> = ({ latestActivity }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const location = useLocation();

	const applicant = useSelector(
		(state: RootState) => state.applicant.applicant,
	);

	const isTablet = useSelector((state: RootState) => state.general.isTablet);

	const [latestUploadedDocument, setLatestUploadedDocument] =
		useState<any>(null);

	const [overlayVisible, setOverlayVisible] = useState(false);

	const latestActivityEvidence = activityDocumentInfo?.find(
		(item) => item.activity === latestActivity?.type,
	);

	const comingFromLeaveActivity = (
		location.state as { comingFromLeaveActivity?: boolean }
	)?.comingFromLeaveActivity;

	const handleBack = () => {
		if (comingFromLeaveActivity) {
			navigate('/check/activity-leave-date');
		} else {
			navigate('/check/activity-update');
		}
	};

	const handleNext = () => {
		if (comingFromLeaveActivity) {
			navigate('/check/activity-history', {
				state: {
					comingFromLeaveActivity: comingFromLeaveActivity,
				},
			});
		} else {
			setOverlayVisible(true);
			setTimeout(() => {
				setOverlayVisible(false);
				return navigate('/check/update-other');
			}, 2000);
		}
	};

	const renderOverlay = () => (
		<div className='check-passport-update-activity-overlay'>
			<img src={successTick} alt='Success Tick'></img>
			<br />
			<h2>Done</h2>
			<p>Thank you!</p>
		</div>
	);

	const handleUpload = (file: any) => {
		return handleUploadDocuments(
			file,
			applicant.workerUuid,
			'documentStart',
			dispatch,
			'activityHistory',
			latestActivity,
		).then(() => {
			return fetchActivityHistory();
		});
	};

	const fetchActivityHistory = () => {
		return api
			.getActivityHistory()
			.then((res) => {
				dispatch(setActivityHistory(res.data));
				return res;
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	const handleRemoveDocument = (fileUuid: string) => {
		return docApi
			.removeActivityDoc(fileUuid, latestActivity.uuid)
			.then(() => {
				return fetchActivityHistory();
			})
			.catch((err: Error) => {
				handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	const checkDocumentUploaded = () => {
		if (latestActivity) {
			if (latestActivity?.files.length >= 1) {
				return false;
			}
			return true;
		}
		return true;
	};

	const formatActivityEndAtDate = () => {
		const endAtDate = moment(latestActivity?.endAt);
		return endAtDate.format('DD MMM YYYY');
	};

	useEffect(() => {
		if (latestActivity?.files) {
			// Get the date 31 days ago
			const date31DaysAgo = new Date();
			date31DaysAgo.setDate(date31DaysAgo.getDate() - 31);

			// Filter for files created in the last 31 days
			const filesFromLast31Days = latestActivity.files.filter(
				(file: any) => {
					const fileDate = new Date(file.createdAt);
					return fileDate > date31DaysAgo;
				},
			);

			setLatestUploadedDocument(filesFromLast31Days);
		}
	}, [latestActivity?.files]);

	return (
		<>
			<div className='check-passport-update-activity-document-wrapper'>
				<Header
					text={`You need to upload proof from within ${
						comingFromLeaveActivity
							? `31 days of ${formatActivityEndAtDate()}`
							: 'the last 31 days'
					}`}
					type='text'
					backFunction={handleBack}
					origin=''
					hideStepsCounter={true}
					hideDesktopSteps={true}
					updatePassport={true}
				/>
				<InnerLayout>
					<div className='check-passport-update-activity-document-body'>
						<p className='check-passport-update-activity-document-action'>
							Please provide a document to support this activity.
						</p>

						<ActivityDocumentsUploadCard
							handleUpload={handleUpload}
							documents={latestUploadedDocument}
							requiredDocsNumber={1}
							isActivityCard={true}
							removeActivityDoc={handleRemoveDocument}
						/>
					</div>
					<div className='check-passport-update-activity-document-info'>
						<p>What counts as proof?</p>
						<br />
						<ul>
							{latestActivityEvidence &&
								latestActivityEvidence.documentTypes.map(
									(docType, index) => (
										<li key={index}>{docType}</li>
									),
								)}
						</ul>
					</div>
				</InnerLayout>
				<br />
				{isTablet && (
					<div className='check-passport-update-activity-document-info'>
						<p>What counts as proof?</p>
						<br />
						<ul>
							{latestActivityEvidence &&
								latestActivityEvidence.documentTypes.map(
									(docType, index) => (
										<li key={index}>{docType}</li>
									),
								)}
						</ul>
					</div>
				)}
				<div className='check-passport-update-activity-document-button-container'>
					<button
						className='margin-bottom-16 orka-identity-button primary-filled'
						disabled={checkDocumentUploaded()}
						onClick={handleNext}
					>
						Next
					</button>
				</div>
			</div>
			{overlayVisible && renderOverlay()}
		</>
	);
};

export default CheckPassportUpdateDocument;
