import './check-passport-container.styles.scss';

import React, { FC, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../types/root-state';
import {
	setApplicationStatus,
	setOverallSubmissionStatus,
} from '../../../redux/General/general.actions';
import {
	setActivityHistory,
	setApplicant,
	setLicences,
} from '../../../redux/Applicant/applicant.actions';
import { getCandidateDetails } from '../../../api/requests/applicant.api';
import { getLatestActivity } from '../../../utils/utilsFunctions';
import { getLicences } from '../../../api/requests/licence.api';
import * as apiMisc from '../../../api/requests/misc.api';
import * as apiActivity from '../../../api/requests/activity.api';

import NotFound from '../../general/not-found/not-found.component';
import CheckPassportHome from '../check-passport-home/check-passport-home.component';
import CheckPassportUpdateHome from '../check-passport-update-home/check-passport-update-home.component';
import CheckPassportUpdateWhy from '../check-passport-update-home/check-passport-update-why.component';
import CheckPassportUpdateActivity from '../check-passport-update-activity/check-passport-update-activity.component';
import CheckPassportLeaveActivity from '../check-passport-leave-activity/check-passport-leave-activity.component';
import CheckPassportUpdateDocument from '../check-passport-update-activity-document/check-passport-update-activity-document.component';
import CheckPassportActivityHistory from '../check-passport-activity-history/check-passport-activity-history.component';
import CheckPassportUpdateOther from '../check-passport-update-other/check-passport-update-other.component';
import CheckPassportAddressHistory from '../check-passport-address-history/check-passport-address-history.component';
import CheckPassportDisclosures from '../check-passport-disclosures/check-passport-disclosures.component';
import CheckPassportName from '../check-passport-update-name/check-passport-update-name.component';
import useFetchData from '../../../utils/useFetchData';
import Licences from '../../../pages/Licences/licences.component';
import ErrorBoundary from '../../general/error-boundary/error-boundary.component';

const CheckPassportContainer: FC = () => {
	const dispatch = useDispatch();

	const applicationStatus = useSelector(
		(state: RootState) => state.general.applicationStatus,
	);
	const applicant = useSelector(
		(state: RootState) => state.applicant.applicant,
	);
	const overallSubmissionStatus = useSelector(
		(state: RootState) => state.general.overallSubmissionStatus,
	);

	const activityHistory = useSelector(
		(state: RootState) => state.applicant.activityHistory,
	);

	const clearanceStatusHistory = useSelector(
		(state: RootState) => state.applicant.applicant?.clearanceStatusHistory,
	);
	const applicantWorkerUuid = useSelector(
		(state: RootState) => state.applicant.applicant?.workerUuid,
	);

	const [latestActivity, setLatestActivity] = useState<any>(null);

	if (clearanceStatusHistory?.clearanceStatus !== 'fully_compliant') {
		return null;
	}

	useEffect(() => {
		const getApplicantDetails = () => {
			return getCandidateDetails()
				.then((res: any) => {
					dispatch(setApplicant(res.data));
				})
				.catch((err: Error) => {
					throw err;
				});
		};
		getApplicantDetails();
	}, []);

	useEffect(() => {
		const setApplicationStatusFunc = () => {
			return apiMisc
				.getApplicationStatus()
				.then((res: any) => {
					if (res.status === 404) {
						throw res;
					}
					dispatch(setApplicationStatus(res.data.status));
				})
				.catch((err: Error) => {
					dispatch(setApplicationStatus('not-started'));
					throw err;
				});
		};
		setApplicationStatusFunc();
	}, [applicationStatus]);

	const [fetchActivityHistoryFlag, setFetchActivityHistoryFlag] =
		useState(false);

	useEffect(() => {
		if (applicant) {
			const getActivityHistory = () => {
				return apiActivity
					.getActivityHistory()
					.then((res: any) => {
						dispatch(setActivityHistory(res.data));
						setFetchActivityHistoryFlag(false);
					})
					.catch((err: Error) => {
						throw err;
					});
			};
			getActivityHistory();
		}
	}, [applicant, overallSubmissionStatus, fetchActivityHistoryFlag]);

	useEffect(() => {
		if (applicant) {
			const setOverallSubmission = () => {
				return apiMisc
					.getOverallSubmission()
					.then((res: any) => {
						dispatch(
							setOverallSubmissionStatus(res.data.data.status),
						);
					})
					.catch((err: Error) => {
						throw err;
					});
			};

			setOverallSubmission();
		}
	}, [applicant, overallSubmissionStatus]);

	useEffect(() => {
		const latestActivity = getLatestActivity(activityHistory);
		setLatestActivity(latestActivity);
	}, [activityHistory]);

	useFetchData(getLicences, setLicences, 1, 'licences');

	return (
		<>
			<div className='check-passport-container'>
				<ErrorBoundary applicantWorkerUuid={applicantWorkerUuid}>
					<Routes>
						<Route path='/' element={<CheckPassportHome />} />
						<Route
							path='/licences'
							element={<Licences isPassport={true} />}
						/>
						<Route
							path='/update-home'
							element={<CheckPassportUpdateHome />}
						/>
						<Route
							path='/update-why'
							element={<CheckPassportUpdateWhy />}
						/>
						<Route
							path='/activity-update'
							element={
								<CheckPassportUpdateActivity
									latestActivity={latestActivity}
								/>
							}
						/>
						<Route
							path='/activity-update-document'
							element={
								<CheckPassportUpdateDocument
									latestActivity={latestActivity}
								/>
							}
						/>
						<Route
							path='/activity-leave-date'
							element={
								<CheckPassportLeaveActivity
									latestActivity={latestActivity}
								/>
							}
						/>
						<Route
							path='/activity-history'
							element={
								<CheckPassportActivityHistory
									latestActivity={latestActivity}
								/>
							}
						/>
						<Route
							path='/update-other'
							element={<CheckPassportUpdateOther />}
						/>
						<Route
							path='/update-address'
							element={<CheckPassportAddressHistory />}
						/>
						<Route
							path='/update-disclosures'
							element={<CheckPassportDisclosures />}
						/>
						<Route
							path='/update-names'
							element={<CheckPassportName />}
						/>
						<Route
							path='*'
							element={
								<NotFound type='noData' pageNotFound={true} />
							}
						/>
					</Routes>
				</ErrorBoundary>
			</div>
		</>
	);
};

export default CheckPassportContainer;
