import { getRequestURLFromParams } from '../utils/api.utils';
import { ENDPOINTS } from '../utils/endpoints';
import { axiosInstance } from '../utils/api.axiosInstance';
import { IShareCode } from '../../types/interfaces/applicant.interface';

export const saveShareCode = (data: Partial<IShareCode>): any => {
	const url = getRequestURLFromParams(ENDPOINTS.SHARE_CODE);
	return axiosInstance()
		.post(url, data)
		.catch((err) => {
			throw err;
		});
};
export const getYotiUrl = (flow: 'uk-irish-passport' | 'other-rtw'): any => {
	const url = getRequestURLFromParams(ENDPOINTS.YOTI, { flow });
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const getRtwStatuses = (workerUuid: string): any => {
	return new Promise((resolve) => {
		const url = getRequestURLFromParams(ENDPOINTS.RTW_ID_CHECK_STATUSES, {
			uuid: workerUuid,
		});
		axiosInstance()
			.get(url)
			.then(({ data }: any) => {
				if (data && data.rtw && data.idCheck) {
					const newData = {
						rtw: {
							yotiStatus: mapRtwData(data.rtw.yotiStatus),
							onfidoStatus: mapRtwData(data.rtw.onfidoStatus),
							shareCodeStatus: mapRtwData(
								data.rtw.shareCodeStatus,
							),
							manualUploadStatus: mapRtwData(
								data.rtw.manualUploadStatus,
							),
						},
						idCheck: {
							yotiStatus: mapRtwData(data.idCheck.yotiStatus),
							ambassadorCheckStatus: mapRtwData(
								data.idCheck.ambassadorCheckStatus,
							),
						},
					};
					resolve(newData);
				}
			})
			.catch((err) => {
				throw err;
			});
	});
};
const mapRtwData = (status: any) => {
	switch (status) {
		case 'in-progress':
		case 'processing':
		case 'unselected':
		case 'pending':
			return 'in-progress';
		case 'approved':
		case 'accepted':
		case 'passed':
			return 'complete';
		case 'rejected':
		case 'failed':
			return 'rejected';
		default:
			return null;
	}
};
