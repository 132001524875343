import React, { useEffect, useState, FC } from 'react';
import './sign-up-inputs.scss';
import Input from '../login-input/login-input.component';
import * as authApi from '../../../api/requests/auth.api';
import OrkaButton from '../../general/orka-button/orka-button.component';
import { setAlert } from '../../../redux/General/general.actions';
import { useDispatch } from 'react-redux';
import passwordIcon from '../../../assets/icons/login/password.svg';
import phoneIcon from '../../../assets/icons/login/phone.svg';
import { fireGaEvent } from '../../../utils/ga-event';
import { useNavigate } from 'react-router-dom';

type Props = {
	email: string;
};

const SignUpInputs: FC<Props> = ({ email }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [validPassword, setValidPassword] = useState<boolean>(false);
	const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true);
	const [phoneValid, setPhoneValid] = useState<boolean>(false);
	const [passwordErrorClass, setPasswordErrorClass] = useState<string>('');
	const [phoneErrorClass, setPhoneErrorClass] = useState<string>('');

	const [state, setState] = useState<any>({
		contactNumber: '',
		password: '',
		confirmPassword: '',
	});

	fireGaEvent('Sign Up', 'Sign up - Page');

	useEffect(() => {
		setValidPassword(validationHelper(state.password, 'password'));
	}, [state.password]);

	useEffect(() => {
		setPhoneValid(validationHelper('+44' + state.contactNumber, 'phone'));
	}, [state.contactNumber]);

	useEffect(() => {
		if (state.confirmPassword || state.password) {
			comparePasswords();
		}
	}, [state.confirmPassword, state.password]);

	const comparePasswords = () => {
		if (state.password.length > 0 && state.confirmPassword.length > 0) {
			if (state.password != state.confirmPassword) {
				setPasswordsMatch(false);
			} else {
				setPasswordsMatch(true);
			}
		} else {
			setPasswordsMatch(true);
		}
	};

	const handleSignUp = () => {
		if (!checkValidation()) {
			fireGaEvent(
				'Sign Up',
				'clicked sign up button (validation failed)',
			);
			return;
		}

		fireGaEvent('Sign Up', 'clicked sign up button');

		const payload = {
			password: state.password,
			contactNumber: '+44' + state.contactNumber,
			email: state.email,
		};
		payload.email = email;

		return authApi
			.create(payload)
			.then(() => {
				navigate('/login', { replace: true });
			})
			.catch((err) => {
				if (err?.data?.message) {
					dispatch(
						setAlert({
							type: 'error',
							message:
								err.data.message ===
								'applicant not on Non works flow'
									? 'Already signed up? Please login'
									: err.data.message,
							isVisible: true,
						}),
					);
				} else {
					dispatch(
						setAlert({
							type: 'error',
							message: err.message,
							isVisible: true,
						}),
					);
				}
			});
	};

	const getValue = (name: any, value: string) => {
		setState({
			...state,
			[name]: value,
		});
	};

	const checkValidation = () => {
		setPasswordErrorClass(
			!validPassword ? 'password-validation-error' : '',
		);
		setPhoneErrorClass(!phoneValid ? 'phone-validation-error' : '');
		return validPassword && phoneValid;
	};

	const validationHelper = (val: string, type: string) => {
		const validation =
			type === 'password'
				? /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[^a-zA-Z\d\s:]).{8,}$/
				: /^(\+44)(\d{10})$/;
		const regex = new RegExp(validation);
		return regex.test(val);
	};

	return (
		<div className='sign-up-inputs-container'>
			<div className='inputs'>
				<Input
					name='contactNumber'
					getValue={getValue}
					value={state.contactNumber}
					placeholder='Phone Number'
					icon={phoneIcon}
					clearValidation={setPhoneErrorClass}
					type='tel'
				/>

				<div className={`validation-info ${phoneErrorClass}`}>
					<p>Phone number must begin +44.</p>
				</div>
				<Input
					name='password'
					getValue={getValue}
					value={state.password}
					placeholder='Create Password'
					icon={passwordIcon}
					type='password'
					clearValidation={setPasswordErrorClass}
				/>
				<Input
					name='confirmPassword'
					getValue={getValue}
					value={state.confirmPassword}
					placeholder='Confirm Password'
					icon={passwordIcon}
					type='password'
				/>
				<div className='confirm-password-error-box validation-info'>
					{!passwordsMatch && (
						<div className='confirm-password-validation-error'>
							<p>Passwords do not match.</p>
						</div>
					)}
				</div>
				<div className={`validation-info ${passwordErrorClass}`}>
					<p>
						Password must contain at least 1 number, 1 capital
						letter, 1 non-alphanumeric character and be between 8
						and 16 characters long.
					</p>
				</div>
			</div>

			<div className='sign-up-button'>
				<OrkaButton
					buttonClass='purple-filled'
					buttonContent='Sign Up'
					emitClicked={handleSignUp}
					disabled={false}
				/>
			</div>
		</div>
	);
};

export default SignUpInputs;
