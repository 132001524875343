import React, {
	Dispatch,
	FC,
	SetStateAction,
	useState,
	Fragment,
	useEffect,
	useContext,
} from 'react';
import './activity-list.styles.scss';
import Header from '../../header/header/header.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import {
	IActivityFormState,
	IActivityItem,
} from '../../../types/interfaces/applicant.interface';
import PageFooter from '../../general/page-footer/page-footer.component';
import book from '../../../assets/icons/info-card/book.svg';
import errorIcon from '../../../assets/icons/misc/error.svg';
import InfoCard from '../../general/info-card/info-card.component';
import ActionControl from '../../general/action-control/action-control.component';
import InnerLayout from '../../general/inner-layout/inner-layout.component';
import DropdownInfo from '../../general/dropdown-info/dropdown-info.component';
import * as api from '../../../api/requests/activity.api';
import { setActivityHistory } from '../../../redux/Applicant/applicant.actions';
import InfoCardDropdown from '../../general/info-card-dropdown/info-card-dropdown.component';
import ActivityCard from '../activity-card/activity-card.component';
import GapCard from '../../general/gap-card/gap-card.component';
import { fireGaEvent } from '../../../utils/ga-event';
import { instantScroll } from '../../../utils/utilsFunctions';
import useResubmitStepStatus from '../../../utils/useResubmitStepStatus';
import { StepNamesEnum } from '../../../types/interfaces/misc.interface';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Modal from '../../modals/modal/modal.component';
import ActivityHelp from '../activity-help/activity-help.component';
import { activityHelpInfo } from '../../../utils/data';
import ActivityContext from '../../../context/ActivityContext';

type Props = {
	handleBack: (origin: string) => void;
	pageOrigin: string;
	pageName: string;
	setShowForm: Dispatch<SetStateAction<boolean>>;
	setShowList: Dispatch<SetStateAction<boolean>>;
	setShowUpload: Dispatch<SetStateAction<boolean>>;
	setViewInformation: Dispatch<SetStateAction<boolean>>;
	setSelectedActivity: Dispatch<
		SetStateAction<IActivityItem | null | IActivityFormState>
	>;
	handleComplete: () => void;
	setShowSubmission: Dispatch<SetStateAction<boolean>>;
	isDisabled: boolean;
};

const ActivityList: FC<Props> = ({
	handleBack,
	pageName,
	setShowForm,
	setShowList,
	setSelectedActivity,
	setShowUpload,
	handleComplete,
	setShowSubmission,
	setViewInformation,
	isDisabled,
}) => {
	const dispatch = useDispatch();
	const activityHistory = useSelector(
		(state: RootState) => state.applicant.activityHistory,
	);

	const [isNotCompleteWithFiles, setIsNotCompleteWithFiles] =
		useState<boolean>(false);

	const [showActionControl, setShowActionControl] = useState(false);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const isTablet = useSelector((state: RootState) => state.general.isTablet);
	const [submissionValidationError, setSubmissionValidationError] =
		useState(false);

	const { dispatchSetState } = useContext(ActivityContext);

	fireGaEvent('Activity History', 'Activity History - List Page');

	useEffect(() => {
		const body = document.querySelector('body');
		if (showActionControl) {
			instantScroll();
			if (body) {
				disableBodyScroll(body);
			}
		} else {
			if (body) {
				enableBodyScroll(body);
			}
		}

		return () => {
			if (body) {
				enableBodyScroll(body);
			}
		};
	}, [showActionControl]);

	useResubmitStepStatus(
		isDisabled,
		StepNamesEnum.activityHistory,
		'Activity History',
	);

	useEffect(() => {
		instantScroll();
		const getActivityHistory = () => {
			api.getActivityHistory()
				.then((res) => {
					dispatch(setActivityHistory(res.data));
				})
				.catch((err: Error) => {
					throw err;
				});
		};
		getActivityHistory();
	}, []);

	useEffect(() => {
		const reducedActivities = activityHistory.reduce(
			(activities: IActivityItem[], activity: IActivityItem) => {
				if (
					activity.type === 'gap' ||
					(activity.files && activity.files.length < 1)
				) {
					activities = [...activities, activity];
				}
				return activities;
			},
			[],
		);
		setIsNotCompleteWithFiles(reducedActivities.length > 0);
	}, [JSON.stringify(activityHistory)]);

	const handleSelectActivity = (activityItem: IActivityItem) => {
		instantScroll();
		setShowActionControl(true);
		setSelectedActivity(activityItem);

		if (
			activityItem.reference.status === 'All Evidence Received' ||
			activityItem.reference.status === 'Documents Received'
		) {
			dispatchSetState(true);
		} else {
			dispatchSetState(false);
		}
	};

	const handleActionControlClicked = (action: string) => {
		setShowActionControl(false);
		setShowList(false);
		fireGaEvent(
			'Activity History',
			'Clicked uploaded activity to open action sheet',
		);

		if (action === 'edit') {
			fireGaEvent('Activity History', 'Clicked to edit activity item');
			setShowForm(true);
		}
		if (action === 'docs') {
			fireGaEvent(
				'Activity History',
				'Clicked to upload document to activity',
			);
			setShowUpload(true);
		}
		if (action === 'information') {
			fireGaEvent(
				'Activity History',
				'Clicked to view activity information',
			);
			setViewInformation(true);
		}
	};

	const handleActivitySubmission = () => {
		if (isDisabled) {
			fireGaEvent(
				'Activity History',
				'Clicks Complete Activity Submission (validation failed)',
			);
			setSubmissionValidationError(true);
			return;
		}
		fireGaEvent('Activity History', 'Clicks Complete Activity Submission');
		const hasFiles = activityHistory.map((activityItem) => {
			return !!(activityItem.files && activityItem.files.length >= 2);
		});

		if (hasFiles.includes(false)) {
			setShowSubmission(true);
			setShowList(false);
		} else {
			handleComplete();
		}
	};

	const handleAdd = () => {
		fireGaEvent('Activity History', 'Clicked Add Activity');
		setSelectedActivity(null);
		setShowList(false);
		setShowForm(true);
	};

	return (
		<div className='activity-list-container'>
			<Header
				text={pageName ? pageName : ''}
				type='text'
				backFunction={handleBack}
				origin='main'
				informationText={`You may work on sensitive or high value sites as a security officer. We need to verify everything you've done for the past five years to check there's been no issues, concerns or times where we can't be confident you were where you say you were. By completing this accurately, you'll have a high level of clearance and show other companies you can be trusted.`}
			>
				{activityHistory.filter((activity) => activity.type === 'gap')
					.length > 0 && (
						<>
							<InfoCard
								size='small'
								text='Tap here for help on this section'
								icon={book}
								withPointer={false}
								clickFunction={() => setOpenModal(!openModal)}
							/>
							{openModal && (
								<div className='modal-wrapper'>
									<Modal
										title=''
										setShowModal={setOpenModal}
										showModal={openModal}
										modalContentHeight='40vh'
									>
										<ActivityHelp
											articles={activityHelpInfo}
											openModal={openModal}
											setOpenModal={setOpenModal}
										/>
									</Modal>
								</div>
							)}
						</>
					)}
			</Header>

			<InnerLayout>
				<div className='activity-list-container-inner'>
					{isTablet && submissionValidationError && (
						<div className='submission-validation-error'>
							<InfoCard
								size='small'
								icon={errorIcon}
								text='Ensure that each activity has at least one document uploaded.'
								subText='Please upload supporting documents before completing this section.'
								type='validation-card'
							/>
						</div>
					)}
					{activityHistory.length === 1 &&
						activityHistory[0].type === 'gap' && (
							<div className='activity-list-section-empty'>
								<ActivityCard handleAdd={handleAdd} />
							</div>
						)}
					{activityHistory.length > 0 &&
						!(
							activityHistory.length === 1 &&
							activityHistory[0].type === 'gap'
						) && (
							<>
								{activityHistory.map((activityItem) => {
									if (activityItem.type === 'gap') {
										return (
											<Fragment
												key={activityItem.gapDays}
											>
												<GapCard
													days={
														activityItem.gapDays
															? activityItem.gapDays.toString()
															: ''
													}
													clickFunction={handleAdd}
												/>
											</Fragment>
										);
									} else {
										return (
											<Fragment key={activityItem.uuid}>
												<ActivityCard
													activity={activityItem}
													clickFunction={() =>
														handleSelectActivity(
															activityItem,
														)
													}
												/>
											</Fragment>
										);
									}
								})}
							</>
						)}
					{showActionControl && (
						<ActionControl
							buttonClicked={handleActionControlClicked}
							setShowActionControl={setShowActionControl}
						/>
					)}
					<PageFooter
						primaryButtonFunction={handleActivitySubmission}
					/>
					<div className='margin-bottom-120' />
				</div>
				<>
					<DropdownInfo
						title={`Why do you need this information?`}
						text={`You may work on sensitive or high value sites as a security officer. We need to verify everything you've done for the past five years to check there's been no issues, concerns or times where we can't be confident you were where you say you were. By completing this accurately, you'll have a high level of clearance and show other companies you can be trusted.`}
						desktopVersion={true}
						handleClick={() =>
							fireGaEvent(
								'Activity History',
								'Tapped why do you need this information dropdown',
							)
						}
					/>

					{!isTablet && submissionValidationError && (
						<div className='submission-validation-error'>
							<InfoCard
								size='small'
								icon={errorIcon}
								text='Ensure that each activity has at least one document uploaded.'
								subText='Please upload supporting documents before completing this section.'
								type='validation-card'
							/>
						</div>
					)}
					{isNotCompleteWithFiles && (
						<div className='margin-bottom-24'>
							<a
								href='https://intercom.help/deploi/en/articles/9245649-activity-history-document-guidelines'
								target='_blank'
								rel='noreferrer'
								className='info-card-link'
							>
								<InfoCard
									size='small'
									text='What documents do I need?'
									icon={book}
									withPointer={false}
								/>
							</a>
							{openModal && (
								<div className='modal-wrapper'>
									<Modal
										title=''
										setShowModal={setOpenModal}
										showModal={openModal}
										modalContentHeight='40vh'
									>
										<ActivityHelp
											articles={activityHelpInfo}
											openModal={openModal}
											setOpenModal={setOpenModal}
										/>
									</Modal>
								</div>
							)}
							<div className='margin-bottom-24' />
							<InfoCard
								size='small'
								icon={book}
								text='This section can really slow down your application if not done correctly.'
							/>

							<InfoCardDropdown
								title={'How do I prevent this?'}
								text={`We need to verify every activity and this can take days or months depending on how much information you provide. `}
								bullets={[
									'Upload documents that may prove your time there (the more the better)',
									'Provide contact information so we can reference check you as quickly as possible ',
									'Be as accurate as possible',
								]}
							/>
						</div>
					)}

					{activityHistory.filter(
						(activity) => activity.type === 'gap',
					).length > 0 && (
							<InfoCard
								size='small'
								icon={book}
								text='Make sure there are no gaps more than 31 days between each submission.'
							/>
						)}
					<div className='margin-bottom-120' />
				</>
			</InnerLayout>
		</div>
	);
};

export default ActivityList;
