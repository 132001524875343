import React, { FC } from 'react';
import crossWhite from '../../assets/icons/misc/cross-white.svg';
import ImageModal from '../../components/modals/image-modal/image-modal.component';
import exampleDoc1 from '../../assets/images/doc-example-1.jpg';
import exampleDoc2 from '../../assets/images/doc-example-2.jpg';

const ActivityDocumentExamples: FC = () => {
	const closeTab = () => {
		window.opener = null;
		window.open('', '_self');
		window.close();
	};
	return (
		<div className='activityDocExample-container'>
			<div className='activityDocExample-content'>
				<header>
					<button className='back-btn' onClick={closeTab}>
						<img src={crossWhite} alt='back' />
					</button>
				</header>
				<main>
					<article>
						<h3>Documents you can use to prove your employment.</h3>
						<p>Important information is highlighted</p>
						<ul className='highlighted-info-list'>
							<li>Your Full Name</li>
							<li>Employers Name</li>
							<li>Start Date</li>
							<li>End Date</li>
						</ul>
						<h3>1. HMRC Document</h3>
						<p>
							If the employment is in the UK you can request for
							your five year employment history to be posted to
							you. It costs nothing to get it.
						</p>
						<p>
							You can call them Monday to Friday, 8am to 6pm.{' '}
							<strong>
								<a href='tel:03002003300'>0300 200 3300</a>
							</strong>
						</p>

						<p>
							The call will take about five minutes, and the form
							generally arrives within five days.
						</p>

						<div className='doc-example-container'>
							<p>
								<em>An example of what they will send you.</em>
							</p>
							<ImageModal
								imageSource={exampleDoc1}
								alt='HMRC document example'
							/>
						</div>
					</article>
					<article>
						<h3>2. HMRC Document</h3>
						<p>
							If the employment is in the UK you can request proof
							of this by calling{' '}
							<strong>
								<a href='tel:03002003300'>0300 200 3300</a>
							</strong>
						</p>

						<div className='doc-example-container'>
							<p>
								<em>An example of what they will send you.</em>
							</p>
							<ImageModal
								imageSource={exampleDoc2}
								alt='HMRC document example'
							/>
						</div>
					</article>
				</main>
			</div>
		</div>
	);
};

export default ActivityDocumentExamples;
