import React, { FC } from 'react';
import './terms-agreed.styles.scss';
import useSetStepsComplete from '../../../utils/useSetStepsComplete';

const TermsAgreed: FC = () => {
	const noComplete = useSetStepsComplete();
	return (
		<article className='header-progress-status'>
			<div>
				<h4>Your background check progress</h4>
				<p>{noComplete < 6 ? 'Not complete' : 'Please Review'}</p>
			</div>
			<div>
				<h4>{noComplete}/6</h4>
			</div>
		</article>
	);
};

export default TermsAgreed;
