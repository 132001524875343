import ReactGA from 'react-ga';
import store from '../redux/store';
import { RootState } from '../types/root-state';

export const fireGaEvent = (category: string, action: string): void => {
	const state: RootState = store.getState();
	const applicantUuid = state.applicant?.applicant?.uuid
		? state.applicant?.applicant?.uuid
		: 'Pre Login';

	ReactGA.event({
		category: `Applicant: ${applicantUuid} - ${category}`,
		action,
	});
};
