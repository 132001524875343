import React, { FC } from 'react';
import GuideCard from '../guide-card.component';
import activityImage from './assets/images/activity_step_three.png';
import lightning from './assets/icons/lightning.svg';
import star from './assets/icons/star.svg';
import doc from './assets/icons/doc.svg';
import mail from './assets/icons/mail.svg';
import phone from './assets/icons/phone.svg';

const PageThree: FC = () => {
	return (
		<div className='guide-page-container'>
			<img src={activityImage} alt='guide info' />
			<p>
				We need to verify when you started and finished every activity.
				No gaps longer than 31 days between activities.
			</p>
			<p>
				<strong>There are multiple ways to verify an activity.</strong>
			</p>
			<GuideCard
				title='Call HMRC (for free!)'
				titleIcon={phone}
				background='#CFD5FF'
				hasTag={true}
				tagTitle='Quickest'
				tagIcon={lightning}
				tagBackground='#4959EB'
				tagForeground='white'
			>
				<p>
					If you have employment in the UK you can request proof of
					this by calling <a href='tel:03002003300'>0300 200 3300</a>
				</p>
			</GuideCard>

			<GuideCard
				title='Provide documents'
				titleIcon={doc}
				background='#FFE1A5'
				hasTag={true}
				tagTitle='The best'
				tagIcon={star}
				tagBackground='#F9B429'
				tagForeground='white'
			>
				<p>
					We need documents that verify when you started and finished
					this activity. The documents need to be different types, and
					show the start and end dates (or very close) on them.
				</p>
			</GuideCard>
			<GuideCard
				title='Provide reference details'
				titleIcon={mail}
				background='#D3F1EE'
				hasTag={false}
			>
				<p>
					If you have no documents for an activity it is important to
					provide contact information so we can contact your
					reference.
				</p>
			</GuideCard>
			<p>
				Please try to provide as much information.{' '}
				<strong>
					This will speed up your application more than anything.
				</strong>
			</p>
		</div>
	);
};

export default PageThree;
