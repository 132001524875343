import React, { FC, useState } from 'react';
import './steps-dropdown.styles.scss';
import arrowDown from '../../../assets/icons/misc/arrow-down.svg';
import StepsDropdownBottom from '../steps-dropdown-bottom/steps-dropdown-bottom.component';

const StepsDropdown: FC = () => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div className='steps-dropdown-container-outer'>
			<button
				className='steps-dropdown-container'
				role='listbox'
				tabIndex={0}
				onClick={() => setIsOpen(!isOpen)}
			>
				<p>Steps</p>
				<img
					src={arrowDown}
					alt='arrow-down'
					className={isOpen ? 'rotate-180' : ''}
				/>
			</button>
			{isOpen && <StepsDropdownBottom />}
		</div>
	);
};

export default StepsDropdown;
