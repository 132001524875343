import './check-passport-activity-history.styles.scss';

import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { ActivityProvider } from '../../../context/ActivityContext';
import { IActivityItem } from '../../../types/interfaces/applicant.interface';
import {
	StepNamesEnum,
	StepStatusEnum,
} from '../../../types/interfaces/misc.interface';
import { updateSteps } from '../../../redux/General/general.actions';

import * as api from '../../../api/requests/activity.api';
import * as utils from '../../../utils/utilsFunctions';
import * as miscApi from '../../../api/requests/misc.api';
import moment from 'moment';

import VModal from '../../modals/modal/vmodal.component';
import Modal from '../../modals/modal/modal.component';
import CheckPassportActivityList from '../check-passport-activity-list/check-passport-activity-list.component';
import ActivityForm from '../../activity/activity-form/activity-form.component';
import ActivityDocuments from '../../activity/activity-documents/activity-documents.component';
import ActivityReasons from '../../activity/activity-reasons/activity-reasons.component';
import ActivityView from '../../activity/activity-view/activity-view.component';

import successTick from '../../../assets/images/circle-tick.svg';

interface CheckPassportActivityHistoryProps {
	latestActivity: IActivityItem;
}

const CheckPassportActivityHistory: FC<CheckPassportActivityHistoryProps> = ({
	latestActivity,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const activityHistory = useSelector(
		(state: RootState) => state.applicant.activityHistory,
	);

	const isMobile = useSelector((state: RootState) => state.general.isMobile);

	const [showForm, setShowForm] = useState(false);
	const [showUpload, setShowUpload] = useState(false);
	const [showList, setShowList] = useState(true);
	const [showReasons, setShowReasons] = useState(false);
	const [showInformation, setShowInformation] = useState(false);
	const [selectedActivity, setSelectedActivity] = useState<any>(null);
	const [overlayVisible, setOverlayVisible] = useState(false);

	const handleBack = (origin: string) => {
		switch (origin) {
			case 'form':
				setShowForm(false);
				setShowUpload(false);
				setShowReasons(false);
				setShowList(true);
				setShowInformation(false);
				break;
			case 'reasons':
				setShowForm(true);
				setShowUpload(false);
				setShowReasons(false);
				setShowList(false);
				setShowInformation(false);
				break;
			case 'edit':
				setShowForm(true);
				setShowUpload(false);
				setShowReasons(false);
				setShowList(false);
				setShowInformation(false);
				break;
			case 'upload':
				setShowForm(false);
				setShowUpload(false);
				setShowReasons(false);
				setShowList(true);
				setShowInformation(false);
				break;
			case 'information':
				setShowForm(false);
				setShowUpload(false);
				setShowReasons(false);
				setShowList(true);
				setShowInformation(false);
				break;
			default:
				return navigate('/', { replace: true });
		}
	};

	const handleNext = () => {
		setShowList(true);
		setShowForm(false);
		setShowUpload(false);
		setShowInformation(false);
	};

	const handleComplete = () => {
		return api
			.saveActivitySubmission()
			.then(() => {
				handleSubmitSection();
			})
			.catch((err: Error) => {
				utils.handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	const handleSubmitSection = () => {
		return miscApi
			.postStep({
				step: StepNamesEnum.activityHistory,
				status: StepStatusEnum.complete,
			})
			.then(() => {
				handleSuccess();
			})
			.catch((err: Error) => {
				utils.handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	const handleSuccess = () => {
		dispatch(updateSteps('Activity History', 'complete'));
		setOverlayVisible(true);
		setTimeout(() => {
			setOverlayVisible(false);
			return navigate('/check/update-other', {
				state: { comingFromLeaveActivity: true },
			});
		}, 2000);
	};

	const renderOverlay = () => (
		<div className='check-passport-activity-history-overlay'>
			<img src={successTick} alt='Success Tick'></img>
			<br />
			<h2>Added</h2>
			<p>Thank you!</p>
		</div>
	);

	const formatActivityEndAtDate = () => {
		if (latestActivity?.endAt === null) {
			return 'Activity History';
		} else {
			const endAtDate = moment(latestActivity?.endAt);
			return `What have you been doing since ${endAtDate.format(
				'DD MMM YYYY',
			)}?`;
		}
	};

	const checkDocumentUploaded = () => {
		if (selectedActivity) {
			if (selectedActivity.files?.length >= 1) {
				return false;
			}
			return true;
		}
		return true;
	};

	return (
		<ActivityProvider>
			<div className='check-passport-activity-history-wrapper'>
				{showList && (
					<CheckPassportActivityList
						handleBack={handleBack}
						pageOrigin={'main'}
						pageName={`${formatActivityEndAtDate()}`}
						setShowForm={setShowForm}
						setShowList={setShowList}
						setSelectedActivity={setSelectedActivity}
						setShowUpload={setShowUpload}
						handleComplete={handleComplete}
						setViewInformation={setShowInformation}
						isDisabled={false}
					/>
				)}
				{isMobile && (
					<>
						{showForm && (
							<ActivityForm
								handleBack={handleBack}
								setShowForm={setShowForm}
								selectedActivity={selectedActivity}
								setShowUpload={setShowUpload}
								setShowReasons={setShowReasons}
								setSelectedActivity={setSelectedActivity}
								isExternal={true}
							/>
						)}
						{showUpload && (
							<VModal
								title='Upload documents'
								setShowModal={setShowUpload}
								showModal={showUpload}
								backButton
								backButtonEvent={() => handleBack('edit')}
								rightButtonEvent={handleNext}
								leftButtonLabel={`I'll do it later`}
								leftButtonEvent={handleNext}
								disabled={checkDocumentUploaded()}
								pageOrigin='upload'
								rightButtonLabel='Complete'
							>
								<ActivityDocuments
									handleBack={handleBack}
									setShowForm={setShowForm}
									setShowList={setShowList}
									setShowUpload={setShowUpload}
									setShowReasons={setShowReasons}
									selectedActivity={selectedActivity}
									setSelectedActivity={setSelectedActivity}
								/>
							</VModal>
						)}
						{showReasons && (
							<ActivityReasons
								handleBack={handleBack}
								setShowForm={setShowForm}
								setShowList={setShowList}
								setShowUpload={setShowUpload}
								setShowReasons={setShowReasons}
								selectedActivity={selectedActivity}
								setSelectedActivity={setSelectedActivity}
							/>
						)}
						{showInformation && (
							<ActivityView
								activity={selectedActivity}
								headerTitle='Add Activity'
								onClick={() => handleBack('information')}
							/>
						)}
					</>
				)}
				{!isMobile && (
					<>
						{showForm && (
							<Modal
								title={
									selectedActivity
										? 'Edit Activity'
										: 'Add Activity'
								}
								setShowModal={setShowForm}
								showModal={showForm}
								closeFunction={handleBack}
								pageOrigin='form'
							>
								<ActivityForm
									handleBack={handleBack}
									setShowForm={setShowForm}
									selectedActivity={selectedActivity}
									setShowUpload={setShowUpload}
									setShowReasons={setShowReasons}
									setSelectedActivity={setSelectedActivity}
									isExternal={true}
								/>
							</Modal>
						)}

						{showUpload && (
							<VModal
								title='Upload documents'
								setShowModal={setShowUpload}
								showModal={showUpload}
								backButton
								backButtonEvent={() => handleBack('edit')}
								rightButtonEvent={handleNext}
								leftButtonLabel={`I'll do it later`}
								leftButtonEvent={handleNext}
								pageOrigin='upload'
								rightButtonLabel='Complete'
								disabled={checkDocumentUploaded()}
							>
								<ActivityDocuments
									handleBack={handleBack}
									setShowForm={setShowForm}
									setShowList={setShowList}
									setShowUpload={setShowUpload}
									setShowReasons={setShowReasons}
									selectedActivity={selectedActivity}
									setSelectedActivity={setSelectedActivity}
								/>
							</VModal>
						)}
						{showReasons && (
							<Modal
								title='Why did you leave this job?'
								setShowModal={setShowReasons}
								showModal={showReasons}
								closeFunction={handleBack}
								pageOrigin='reasons'
							>
								<ActivityReasons
									handleBack={handleBack}
									setShowForm={setShowForm}
									setShowList={setShowList}
									setShowUpload={setShowUpload}
									setShowReasons={setShowReasons}
									selectedActivity={selectedActivity}
									setSelectedActivity={setSelectedActivity}
								/>
							</Modal>
						)}
						{showInformation && (
							<ActivityView
								activity={selectedActivity}
								headerTitle='Add Activity'
								onClick={() => handleBack('information')}
							/>
						)}
					</>
				)}
			</div>
			{overlayVisible && renderOverlay()}
		</ActivityProvider>
	);
};

export default CheckPassportActivityHistory;
