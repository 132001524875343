import React, { FC, Dispatch, SetStateAction } from 'react';
import './activity-help.styles.scss';
import backBlue from '../../../assets/icons/misc/back-blue.svg';

type Props = {
	articles: any[];
	openModal?: boolean;
	setOpenModal: Dispatch<SetStateAction<boolean>>;
};

const ActivityHelp: FC<Props> = ({ articles, setOpenModal }) => {
	const handleClick = (): void => {
		setOpenModal(false);
	};

	return (
		<div className='activity-help-container'>
			<ul className='intercom-article-list'>
				{articles.map((item, index) => (
					<li key={index}>
						<a href={item.url} target='_blank' rel='noreferrer'>
							{item.title}
						</a>
						<img
							src={backBlue}
							alt={item.title}
							className='image-icon'
						/>
					</li>
				))}
			</ul>
			<button className='activity-back-button' onClick={handleClick}>
				<img src={backBlue} alt='back' />
			</button>
		</div>
	);
};

export default ActivityHelp;
