import React, {
	Dispatch,
	FC,
	SetStateAction,
	Fragment,
	useEffect,
	useState,
} from 'react';
import './address-list.styles.scss';
import Header from '../../header/header/header.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import book from '../../../assets/icons/info-card/book.svg';
import errorIcon from '../../../assets/icons/misc/error.svg';
import InfoCard from '../../general/info-card/info-card.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import ListCard from '../../general/list-card/list-card.component';
import { IAddressHistoryItemDb } from '../../../types/interfaces/applicant.interface';
import PageFooter from '../../general/page-footer/page-footer.component';
import * as utils from '../../../utils/utilsFunctions';
import InnerLayout from '../../general/inner-layout/inner-layout.component';
import DropdownInfo from '../../general/dropdown-info/dropdown-info.component';
import * as api from '../../../api/requests/address.api';
import { setAlert, updateSteps } from '../../../redux/General/general.actions';
import GapCard from '../../general/gap-card/gap-card.component';
import { fireGaEvent } from '../../../utils/ga-event';
import * as miscApi from '../../../api/requests/misc.api';
import * as docsApi from '../../../api/requests/documents.api';
import {
	StepNamesEnum,
	StepStatusEnum,
} from '../../../types/interfaces/misc.interface';
import trash from '../../../assets/icons/misc/trash.svg';
import useResubmitStepStatus from '../../../utils/useResubmitStepStatus';
import { useNavigate } from 'react-router-dom';

type Props = {
	pageName: string;
	handleBack: (origin: string) => void;
	isCurrent: boolean;
	setShowForm: Dispatch<SetStateAction<boolean>>;
	hasFile: boolean;
	setShowUpload: Dispatch<SetStateAction<boolean>>;
	setSelectedAddress: Dispatch<SetStateAction<IAddressHistoryItemDb | null>>;
	setPageOrigin: Dispatch<SetStateAction<string>>;
	setRefetchAddressDocs: Dispatch<SetStateAction<boolean>>;
	refetchAddressDocs: boolean;
	handleDeleteDocument: () => void;
	redirectUrl?: string;
};

const AddressList: FC<Props> = ({
	pageName,
	handleBack,
	isCurrent,
	setShowForm,
	hasFile,
	setShowUpload,
	setSelectedAddress,
	setPageOrigin,
	setRefetchAddressDocs,
	refetchAddressDocs,
	redirectUrl,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const addressHistory = useSelector(
		(state: RootState) => state.applicant.addressHistory,
	);
	const addressDocuments = useSelector(
		(state: RootState) => state.applicant.addressDocuments,
	);
	const updatePassport = useSelector(
		(state: RootState) =>
			state.applicant.applicant?.clearanceStatusHistory
				?.passportUpdateRequired,
	);
	const isTablet = useSelector((state: RootState) => state.general.isTablet);

	const [submissionValidationError, setSubmissionValidationError] =
		useState(false);

	const [isDisabled, setIsDisabled] = useState(false);

	fireGaEvent('Address History', 'Address History - Address List Page');

	useEffect(() => {
		utils.instantScroll();
	}, []);

	useEffect(() => {
		if (
			addressHistory.some((address) => address['type'] === 'gap') ||
			addressDocuments.length === 0
		) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [addressHistory.length]);

	useResubmitStepStatus(
		isDisabled,
		StepNamesEnum.addressHistory,
		'Address History',
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleAddAddress = () => {
		fireGaEvent('Address History', 'Clicked Add Address');
		setSelectedAddress(null);
		setShowForm(true);
	};

	const handleSelectAddress = (addressHistoryItem: IAddressHistoryItemDb) => {
		fireGaEvent('Address History', 'Clicked Address Card to Edit');
		setSelectedAddress(addressHistoryItem);
		setShowForm(true);
	};

	const handleComplete = () => {
		utils.instantScroll();
		if (isDisabled) {
			fireGaEvent(
				'Address History',
				'Clicked submit section (validation failed)',
			);
			setSubmissionValidationError(true);
			return;
		}
		fireGaEvent('Address History', 'Clicked submit section');
		return api
			.saveAddressSubmission()
			.then(() => {
				handleSubmitSection();
			})
			.catch((err: Error) => {
				utils.handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	const handleUploadDocument = () => {
		fireGaEvent('Address History', 'Clicked upload address document');
		setShowUpload(true);
		setPageOrigin('form');
	};

	const handleDeleteDocument = () => {
		const filteredAddress = addressHistory.filter((address) => {
			const keys = Object.keys(address);
			return keys.includes('uuid');
		});

		if (
			addressHistory &&
			addressHistory.length > 0 &&
			filteredAddress.length > 0
		) {
			if (typeof filteredAddress[0].uuid === 'string') {
				return docsApi
					.deleteDocument(
						addressDocuments[0].uuid,
						filteredAddress[0].uuid,
					)
					.then(() => {
						setRefetchAddressDocs(!refetchAddressDocs);
						utils.handleAlerts('success', 'Removed File', dispatch);
					})
					.catch((err: Error) => {
						handleErrors(err);
					});
			}
		}
	};

	const handleSubmitSection = () => {
		return miscApi
			.postStep({
				step: StepNamesEnum.addressHistory,
				status: StepStatusEnum.complete,
			})
			.then(() => {
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully submitted section',
						isVisible: true,
					}),
				);
				dispatch(updateSteps('Address History', 'complete'));
				return navigate(redirectUrl ? redirectUrl : '/', {
					replace: true,
				});
			})
			.catch((err: Error) => {
				handleErrors(err);
			});
	};

	const handleErrors = (err: Error) => {
		utils.handleAlerts('error', err.message, dispatch);
	};

	const renderEndDate = (addressHistoryItem: any) => {
		if (!addressHistoryItem.endAt && addressHistoryItem.isCurrent)
			return 'Current';
		if (!addressHistoryItem.endAt && !addressHistoryItem.isCurrent)
			return 'End date missing!';
		return utils.formatDateForUser(addressHistoryItem.endAt);
	};

	return (
		<>
			<div className='address-list-container'>
				{updatePassport ? (
					<>
						<Header
							text='Add New Address'
							type='text'
							backFunction={handleBack}
							origin='main'
							hideStepsCounter={true}
							hideDesktopSteps={true}
							informationText={`Some of the checks we perform go back up to five years, and are done to verify you by where you lived. Giving us this information will make sure we're looking at the right person when we do these checks.`}
							updatePassport={true}
						>
							<InfoCard
								size='small'
								text='If you no longer live at the most recent address listed, please tap on the address to edit the date you left.'
								icon={book}
							/>
						</Header>
					</>
				) : (
					<Header
						text={pageName ? pageName : ''}
						type='text'
						backFunction={handleBack}
						origin='main'
						informationText={`Some of the checks we perform go back up to five years, and are done to verify you by where you lived. Giving us this information will make sure we're looking at the right person when we do these checks.`}
					>
						{addressDocuments && addressDocuments.length === 0 && (
							<InfoCard
								size='small'
								text='You will need to provide a document to prove you live at this address. This should be a recent document.'
								icon={book}
							/>
						)}
					</Header>
				)}

				<InnerLayout>
					<div className='address-list-inner-container'>
						{isTablet && submissionValidationError && (
							<div className='submission-validation-error'>
								<InfoCard
									size='small'
									icon={errorIcon}
									text="You can't complete this section until you have added 5 years of address history and have uploaded a document to prove your current address."
									subText='If you wish to complete this section later you can tap the back arrow and your progress will be saved.'
									type='validation-card'
								/>
							</div>
						)}
						<h2 className='list-page-subheading'>
							Address History
						</h2>
						{isCurrent &&
							addressHistory.length === 1 &&
							addressHistory[0].type === 'gap' && (
								<div className='add-current-address'>
									<OrkaButton
										buttonContent='Add current address'
										disabled={false}
										emitClicked={() => handleAddAddress()}
										buttonClass='purple-filled'
									/>
								</div>
							)}
						<div className='address-history-items'>
							{addressHistory.length > 0 &&
								!(
									addressHistory.length === 1 &&
									addressHistory[0].type === 'gap'
								) && (
									<>
										{addressHistory.map(
											(addressHistoryItem: any) => {
												if (
													addressHistoryItem.type !==
														'gap' &&
													addressHistoryItem.uuid
												) {
													const item = {
														uuid: addressHistoryItem.uuid,
														title: addressHistoryItem
															.address
															.streetAddress,
														lineOne: `${
															addressHistoryItem
																.address.city
														} ${addressHistoryItem.address.postcode.toUpperCase()}`,
														lineTwo: `${utils.formatDateForUser(
															addressHistoryItem.startAt,
														)} - `,
														endAt: renderEndDate(
															addressHistoryItem,
														),
													};
													return (
														<Fragment
															key={
																addressHistoryItem.uuid
															}
														>
															<ListCard
																item={item}
																clickFunction={() =>
																	handleSelectAddress(
																		addressHistoryItem,
																	)
																}
															/>
														</Fragment>
													);
												}
												if (
													addressHistoryItem.type ===
													'gap'
												) {
													return (
														<Fragment
															key={
																addressHistoryItem.gapDays
															}
														>
															<GapCard
																days={
																	addressHistoryItem.gapDays
																		? addressHistoryItem.gapDays.toString()
																		: ''
																}
																clickFunction={
																	handleAddAddress
																}
															/>
														</Fragment>
													);
												}
											},
										)}
									</>
								)}
							{addressHistory.length >= 1 &&
								addressHistory.some(
									(address) => address['type'] !== 'gap',
								) && (
									<>
										<h2 className='list-page-subheading'>
											Current Address Document
										</h2>
										<div className='add-another-section'>
											{!hasFile && (
												<OrkaButton
													buttonContent={
														updatePassport
															? 'Add Document'
															: 'Upload current address document'
													}
													disabled={false}
													emitClicked={
														handleUploadDocument
													}
													buttonClass='purple-outline'
												/>
											)}
										</div>
									</>
								)}
							{hasFile && (
								<article className='document-card'>
									<a
										href={
											addressDocuments[0]?.readUrl
												? addressDocuments[0]?.readUrl
												: addressDocuments[0]?.fileUrl
										}
										target='_blank'
										rel='noreferrer'
										className='document-card-link'
									>
										Proof of current address
									</a>
									<button onClick={handleDeleteDocument}>
										<img src={trash} alt='delete file' />
									</button>
								</article>
							)}
						</div>
						<div className='margin-bottom-120' />
						{addressHistory.length > 0 &&
							!(
								addressHistory.length === 1 &&
								addressHistory[0].type === 'gap'
							) && (
								<PageFooter
									primaryButtonFunction={handleComplete}
									primaryButtonText='Submit'
								/>
							)}
					</div>
					<>
						<DropdownInfo
							title={`Why do you need this information?`}
							text={`Some of the checks we perform go back up to five years, and are done to verify you by where you lived. Giving us this information will make sure we're looking at the right person when we do these checks.`}
							desktopVersion={true}
							handleClick={() =>
								fireGaEvent(
									'Address History',
									'Tapped why do you need this information dropdown',
								)
							}
						/>
						{updatePassport ? (
							<InfoCard
								size='small'
								text='If you no longer live at the most recent address listed, please tap on the address to edit the date you left.'
								icon={book}
							/>
						) : addressDocuments &&
						  addressDocuments.length === 0 ? (
							<InfoCard
								size='small'
								text='You will need to provide a document to prove you live at this address. This should be a recent document'
								icon={book}
							/>
						) : null}

						{!isTablet && submissionValidationError && (
							<div className='submission-validation-error'>
								<InfoCard
									size='small'
									icon={errorIcon}
									text="You can't complete this section until you have added 5 years of address history and have uploaded a document to prove your current address."
									subText='If you wish to complete this section later you can tap the back arrow and your progress will be saved.'
									type='validation-card'
								/>
							</div>
						)}
					</>
				</InnerLayout>
			</div>
		</>
	);
};

export default AddressList;
