import React, { FC, useEffect, useState } from 'react';
import './sign-up.styles.scss';
import SignUpInputs from '../../components/login/sign-up-inputs/sign-up-inputs';
import LoginLayout from '../../components/general/login-layout/login-layout.component';
import ErrorBoundary from '../../components/general/error-boundary/error-boundary.component';
import { useSearchParams } from 'react-router-dom';

const SignUp: FC = () => {
	const [searchParams] = useSearchParams();
	const [email, setEmail] = useState('');

	useEffect(() => {
		const email = searchParams.get('email') || '';
		setEmail(email.replace(/\s/, '+'));
	}, []);

	return (
		<LoginLayout>
			<ErrorBoundary applicantWorkerUuid='None'>
				<div className='user-info'>
					<p>{email}</p>
				</div>
				<SignUpInputs email={email} />
				<p className='user-info'>
					Already signed up? <a href='/login'>Login here</a>
				</p>
			</ErrorBoundary>
		</LoginLayout>
	);
};

export default SignUp;
