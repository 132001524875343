import React, { FC } from 'react';
import './list-card.styles.scss';
import { IListCardItem } from '../../../types/interfaces/misc.interface';

type Props = {
	item: IListCardItem;
	clickFunction?: (item: IListCardItem) => void;
	licenceImage?: boolean;
};

const ListCard: FC<Props> = ({ item, clickFunction, licenceImage }) => {
	return (
		<button
			className={`list-card-container ${
				licenceImage ? 'licence-border' : 'licence-border-update'
			}`}
			onClick={() => {
				if (clickFunction) {
					clickFunction(item);
				}
			}}
		>
			<h4>{item.title}</h4>
			<p>{item.lineOne}</p>
			<p className='expiry-date'>
				{item.lineTwo instanceof Date
					? item.lineTwo.toDateString() // Format Date case
					: item.lineTwo}
				{item.endAt && (
					<span
						className={
							item.endAt === 'End date missing!'
								? 'error-text'
								: ''
						}
					>
						{item.endAt}
					</span>
				)}
			</p>
			{!licenceImage && (
				<p className='upload-licence-error'>{item.lineThree}</p>
			)}
		</button>
	);
};

export default ListCard;
