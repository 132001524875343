import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStepComplete } from '../types/interfaces/applicant.interface';
import { RootState } from '../types/root-state';

const useSetStepsComplete = (): any => {
	const stepsComplete = useSelector(
		(state: RootState) => state.general.stepsComplete,
	);
	const [noComplete, setNoComplete] = useState(0);

	useEffect(() => {
		const amountComplete = stepsComplete.reduce(
			(total: number, step: IStepComplete) => {
				if (step.status === 'complete') {
					total++;
				}
				return total;
			},
			0,
		);
		setNoComplete(amountComplete);
	}, [JSON.stringify(stepsComplete)]);
	return noComplete;
};

export default useSetStepsComplete;
