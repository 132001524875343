import React, { FC } from 'react';
import './verified-field.styles.scss';

type Props = {
	label: string;
	field: string;
};

const VerifiedField: FC<Props> = ({ label, field }) => {
	return (
		<div className='verified-input-container'>
			<label className='verified-input-label'>{label}</label>
			<p className='verified-input-value'>{field}</p>
		</div>
	);
};

export default VerifiedField;
