import './activity-view.styles.scss';
import React, { FC } from 'react';
import backArrow from '../../../assets/icons/misc/back-blue.svg';
import { capitalizeFirstLetter } from '../../../utils/utilsFunctions';
import { IActivityItem } from '../../../types/interfaces/applicant.interface';

interface ActivityViewProps {
	headerTitle: string;
	activity: IActivityItem | null;
	onClick: () => void;
}

const ActivityView: FC<ActivityViewProps> = ({
	headerTitle,
	activity,
	onClick,
}) => {
	const printLocaleDate = (date: any) => {
		return new Date(date).toLocaleDateString();
	};

	const hasAddress = (obj: any): boolean => {
		return Object.keys(obj).length > 0;
	};

	const activityLocation = () => {
		if (hasAddress(activity?.address)) {
			const address = activity?.address;
			return address?.country === 'United Kingdom' ? 'Yes' : 'No';
		} else {
			return 'N/A';
		}
	};

	return (
		<div className='activity-view-container'>
			<div className='activity-view-content'>
				<header className='activity-view-header'>
					<button className='back-button' onClick={onClick}>
						<img src={backArrow} alt='back' />
					</button>
					<h2 className='header-title'>{headerTitle}</h2>
				</header>
				<main className='activity-view-body'>
					<div className='activity-info'>
						<h4 className='activity-info-title'>
							What are you doing?
						</h4>
						<p>{capitalizeFirstLetter(activity?.type)}</p>
					</div>
					<div className='activity-info'>
						<h4 className='activity-info-title'>
							Was this in the UK?
						</h4>
						<p>{activityLocation()}</p>
					</div>
					<div className='activity-info'>
						<h4 className='activity-info-title'>Start Date</h4>
						<p>{printLocaleDate(activity?.startAt)}</p>
					</div>
					<div className='activity-info'>
						<h4 className='activity-info-title'>End Date</h4>
						<p>
							{activity?.endAt
								? printLocaleDate(activity?.endAt)
								: 'N/A'}
						</p>
					</div>
					<div className='activity-info'>
						<h4 className='activity-info-title'>Company Name</h4>
						<p>{activity?.name ? activity?.name : 'N/A'}</p>
					</div>
					<div className='activity-info'>
						<h4 className='activity-info-title'>Job Title</h4>
						<p>{activity?.jobTitle ? activity?.jobTitle : 'N/A'}</p>
					</div>
					<div className='activity-info'>
						<h4 className='activity-info-title'>Company Address</h4>
						<p>
							{!hasAddress(activity?.address)
								? 'N/A'
								: activity?.address.streetAddress +
									', ' +
									activity?.address.city +
									', ' +
									activity?.address.postcode}
						</p>
					</div>
					<div className='activity-info'>
						<h4 className='activity-info-title'>
							Full time / Part time
						</h4>
						<p>
							{activity?.isPartTime ? 'Part-time' : 'Full-time'}
						</p>
					</div>
				</main>
				<footer className='activity-view-footer'></footer>
			</div>
		</div>
	);
};

export default ActivityView;
