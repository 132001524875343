import React, { FC } from 'react';
import './login-logo.component.scss';
import logo from '../../../assets/logos/deploi-logo.svg';

const LoginLogo: FC = () => {
	return (
		<div className='logo'>
			<p>Get work ready with</p>
			<img src={logo} alt='logo' />
		</div>
	);
};

export default LoginLogo;
