import React, { FC } from 'react';
import activityImage from './assets/images/activity_step_one.png';

const PageOne: FC = () => {
	return (
		<div className='guide-page-container'>
			<img src={activityImage} alt='guide info' />
			<h2>Important!</h2>
			<p>
				The activity section will slow down your application if you do
				not complete it properly.
			</p>
			<p>
				Please read the following tips to ensure you have a speedy
				application.
			</p>
		</div>
	);
};

export default PageOne;
