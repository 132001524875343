import React, { FC, MouseEvent } from 'react';
import './gap-card.styles.scss';

type Props = {
	days: string;
	clickFunction: (val: string) => void;
};

const GapCard: FC<Props> = ({ days, clickFunction }) => {
	const handleClick = (e: MouseEvent) => {
		e.preventDefault();
		clickFunction('');
	};

	return (
		<article className='gap-card-container'>
			<p>{days} days missing</p>
			<button
				onClick={handleClick}
				type='button'
				className='fill-gap-button'
			>
				Fill gap
			</button>
		</article>
	);
};

export default GapCard;
