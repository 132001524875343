import React, { FC, useState, useRef } from 'react';
import './profile-settings.styles.scss';
import profileIcon from '../../../assets/icons/misc/profile.svg';
import arrowUp from '../../../assets/icons/misc/chevron-up-white.svg';
import arrowDown from '../../../assets/icons/misc/chevron-down-white.svg';
import { Link } from 'react-router-dom';
import * as api from '../../../api/requests/auth.api';
import { useDispatch, useSelector } from 'react-redux';
import { clearApplicantState } from '../../../redux/Applicant/applicant.actions';
import logout from '../../../assets/icons/misc/logout.svg';
import useOutsideAlerter from '../../../utils/useClickOutside';
import { RootState } from '../../../types/root-state';
import { useNavigate } from 'react-router-dom';
import { clearAuth } from '../../../redux/Auth/auth.actions';
import { clearGeneralState } from '../../../redux/General/general.actions';

const ProfileSettings: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showMenu, setShowMenu] = useState(false);
	const menuRef = useRef<HTMLMenuElement>(null);
	const applicationStatus = useSelector(
		(state: RootState) => state.general.applicationStatus,
	);

	useOutsideAlerter(menuRef, setShowMenu, showMenu);
	const handleLogout = () => {
		return api
			.logout()
			.then(() => {
				dispatch(clearApplicantState());
				dispatch(clearAuth());
				dispatch(clearGeneralState());
				localStorage.clear();
				return navigate('/login', { replace: true });
			})
			.catch((err: Error) => {
				dispatch(clearApplicantState());
				navigate('/', { replace: true });
				throw err;
			});
	};

	const handleClicked = (event: any) => {
		event.stopPropagation();
		setShowMenu(!showMenu);
	};

	return (
		<div className='profile-settings-container-outer'>
			<div className='profile-settings-container'>
				<button onClick={handleClicked}>
					<img src={profileIcon} alt='profile' />
					<img
						src={showMenu ? arrowUp : arrowDown}
						alt='toggle menu'
						className='arrow-toggle'
					/>
				</button>
			</div>
			{showMenu && (
				<menu ref={menuRef}>
					{applicationStatus !== 'application-submitted' && (
						<Link
							to='/check/review-application'
							state={{ fromSettings: true }}
						>
							Review Application
						</Link>
					)}
					<a onClick={handleLogout}>
						<img src={logout} alt='logout' />
						Logout
					</a>
				</menu>
			)}
		</div>
	);
};

export default ProfileSettings;
