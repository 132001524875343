import { IRtwImageData, RtwIdCheck } from '../../types/interfaces/applicant.interface';
import { SAVE_TO_RTW, SET_ID_CHECK_STATUS, SET_RTW_STATUS, CLEAR_RTW } from './rtw.types';

export type ActionProps = {
	type: string;
	data: any;
};

export const saveImageData = (data: IRtwImageData | null): ActionProps => ({
	type: SAVE_TO_RTW,
	data: data,
});

export const setRtwStatus = (rtwStatus: RtwIdCheck): ActionProps => {
	return {
		type: SET_RTW_STATUS,
		data: rtwStatus,
	};
};

export const setIdCheckStatus = (idCheckStatus: RtwIdCheck): ActionProps => {
	return {
		type: SET_ID_CHECK_STATUS,
		data: idCheckStatus,
	};
};
export const clearRTW = (): any => {
	return {
		type: CLEAR_RTW,
	};
};
