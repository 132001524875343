export const dropdownData = {
	licenceTypes: [
		{
			id: 0,
			value: 'Select',
			dbValue: 'select',
			class: 'dropdown-grey',
			selected: false,
			disabled: true,
		},
		{
			id: 1,
			value: 'Door supervision',
			dbValue: 'doorSupervisor',
			class: 'dropdown-grey',
			selected: false,
		},
		{
			id: 2,
			value: 'CCTV',
			dbValue: 'cctv',
			class: 'dropdown-grey',
			selected: false,
		},
		{
			id: 3,
			value: 'Close Protection',
			dbValue: 'closeProtection',
			class: 'dropdown-grey',
			selected: false,
		},
	],
	workPatterns: [
		{
			id: 0,
			value: 'Select',
			dbValue: 'select',
			class: 'dropdown-grey',
			selected: false,
			disabled: true,
		},
		{
			id: 1,
			value: 'Full Time',
			dbValue: false,
			class: 'dropdown-grey',
			selected: false,
		},
		{
			id: 2,
			value: 'Part Time',
			dbValue: true,
			class: 'dropdown-grey',
			selected: false,
		},
	],
	activityTypes: [
		{
			id: 0,
			dbValue: 'select',
			value: 'Select',
			fullView: true,
			class: 'dropdown-grey',
			disabled: true,
		},
		{
			id: 1,
			dbValue: 'employed',
			value: 'Employed',
			fullView: true,
			class: 'dropdown-grey',
		},
		{
			id: 2,
			dbValue: 'self-employed',
			value: 'Self Employed',
			fullView: false,
			class: 'dropdown-grey',
		},
		{
			id: 3,
			dbValue: 'volunteering',
			value: 'Volunteering',
			fullView: false,
			class: 'dropdown-grey',
		},
		{
			id: 4,
			dbValue: 'studying',
			value: 'Studying',
			fullView: true,
			class: 'dropdown-grey',
		},
		{
			id: 5,
			dbValue: 'career-break',
			value: 'Career Break',
			fullView: true,
			class: 'dropdown-grey',
		},
	],
	careerBreakOptions: [
		{
			id: 0,
			dbValue: 'select',
			value: 'Select',
			class: 'dropdown-grey',
			disabled: true,
		},
		{
			id: 1,
			dbValue: 'job-seeking',
			value: 'Job Seeking',
			class: 'dropdown-grey',
		},
		{
			id: 2,
			dbValue: 'medical-reasons',
			value: 'Medical Reasons',
			class: 'dropdown-grey',
		},
		{
			id: 3,
			dbValue: 'travelling',
			value: 'Travelling',
			class: 'dropdown-grey',
		},
		{ id: 4, dbValue: 'carer', value: 'Carer', class: 'dropdown-grey' },
		{
			id: 5,
			dbValue: 'parental-cover',
			value: 'Parental Cover',
			class: 'dropdown-grey',
		},
		{
			id: 6,
			dbValue: 'self-study',
			value: 'Self Study',
			class: 'dropdown-grey',
		},
		{
			id: 7,
			dbValue: 'another-reason',
			value: 'Another Reason',
			class: 'dropdown-grey',
		},
	],
	reasonsForLeaving: [
		{
			id: 1,
			value: 'Fixed Term Contract',
			dbValue: 'Fixed Term Contract',
			class: 'grey-filled',
		},
		{
			id: 2,
			value: 'Dismissal',
			dbValue: 'Dismissal',
			class: 'grey-filled',
		},
		{
			id: 3,
			value: 'Redundancy',
			dbValue: 'Redundancy',
			class: 'grey-filled',
		},
		{
			id: 4,
			value: 'Family Commitment',
			dbValue: 'Family Commitment',
			class: 'grey-filled',
		},
		{
			id: 5,
			value: 'New Employment',
			dbValue: 'Seek New Employment Opportunity',
			class: 'grey-filled',
		},
		{ id: 6, value: 'Other', dbValue: 'Other', class: 'grey-filled' },
	],
	genders: [
		{
			id: 0,
			value: 'Select',
			dbValue: 'select',
			class: 'grey-filled',
			disabled: true,
		},
		{ id: 1, value: 'Male', dbValue: 'male', class: 'grey-filled' },
		{ id: 2, value: 'Female', dbValue: 'female', class: 'grey-filled' },
		{
			id: 3,
			value: 'Non-binary',
			dbValue: 'non-binary',
			class: 'grey-filled',
		},
		{ id: 4, value: 'Other', dbValue: 'other', class: 'grey-filled' },
		{
			id: 5,
			value: 'Prefer not to say',
			dbValue: 'prefer-not-to-say',
			class: 'grey-filled',
		},
	],
	livingInUk: [
		{
			id: 0,
			value: 'Select',
			dbValue: 'select',
			class: 'grey-filled',
			disabled: true,
		},
		{ id: 1, value: 'Yes', dbValue: true, class: 'grey-filled' },
		{ id: 2, value: 'No', dbValue: false, class: 'grey-filled' },
	],
};

export const disclosureQuestions = [
	{
		question: 'Do you have any criminal convictions in any country (including traffic offences)?',
		answers: [
			{ id: 1, selected: false, label: 'Yes' },
			{ id: 2, selected: false, label: 'No' },
		],
		disclosures: [],
		type: 'conviction',
	},
	{
		question:
			'Have you ever been charged in any country with a criminal offence for which you have not yet been tried in court\n' +
			'(including traffic offences)?',
		answers: [
			{ id: 1, selected: false, label: 'Yes' },
			{ id: 2, selected: false, label: 'No' },
		],
		disclosures: [],
		type: 'awaiting trial',
	},
	{
		question:
			'Have you received any CCJs, made any Individual Voluntary Arrangements, or declared bankruptcy in the past 5 years?',
		answers: [
			{ id: 1, selected: false, label: 'Yes' },
			{ id: 2, selected: false, label: 'No' },
		],
		disclosures: [],
		type: 'bankruptcy',
	},
	{
		question:
			'In times of either peace or war have you ever been involved in, or suspected of involvement in, war crimes, crimes\n' +
			'against humanity or genocide?',
		answers: [
			{ id: 1, selected: false, label: 'Yes' },
			{ id: 2, selected: false, label: 'No' },
		],
		disclosures: [],
		type: 'war crimes',
	},
	{
		question:
			'Have you ever been involved in, supported, or encouraged terrorist activities in any country? Have you ever\n' +
			'been a member of, or given support to an organisation that has been concerned in terrorism?',
		answers: [
			{ id: 1, selected: false, label: 'Yes' },
			{ id: 2, selected: false, label: 'No' },
		],
		disclosures: [],
		type: 'terrorism',
	},
	{
		question:
			'Have you ever, by any means or medium, expressed views that justify or glorify terrorist violence or that may encourage others to terrorist acts or other serious criminal acts? ',
		answers: [
			{ id: 1, selected: false, label: 'Yes' },
			{ id: 2, selected: false, label: 'No' },
		],
		disclosures: [],
		type: 'terrorist views',
	},
	{
		question:
			'Have you engaged in any other activities that might indicate that you may not be considered a person of good character?',
		answers: [
			{ id: 1, selected: false, label: 'Yes' },
			{ id: 2, selected: false, label: 'No' },
		],
		disclosures: [],
		type: 'bad character',
	},
];
export const consentFormData = [
	{
		heading: 'Introduction',
		bodyText: `We require your authorisation to conduct specific pre-employment screening checks in line with legal, contractual and industry requirements (including in accordance with BS7858). The declarations below can be used to confirm your agreement to screening. Please read the declarations below carefully before providing your authorisation. Please note that once checks have been conducted, your authorisation cannot be revoked. Please note that in order to verify your candidate profile and to enable you to be screened against specific pre-employment requirements, Deploi will collect and process your personal data, including by transferring specific information to selected third parties (such as your prospective employer). For more information about how Deploi handles your personal data, please read our privacy notice at: deploi.uk/privacy-policy.`,
		list: [],
		small: false,
	},
	{
		heading: 'Declarations',
		bodyText: `We require your authorisation to conduct specific pre-employment screening checks in line with legal, contractual and industry requirements (including in accordance with BS7858). The declarations below can be used to confirm your agreement to screening. Please read the declarations below carefully before providing your authorisation. Please note that once checks have been conducted, your authorisation cannot be revoked. Please note that in order to verify your candidate profile and to enable you to be screened against specific pre-employment requirements, Deploi will collect and process your personal data, including by transferring specific information to selected third parties (such as your prospective employer). For more information about how Deploi handles your personal data, please read our privacy notice at: deploi.uk/privacy-policy.`,
		list: [
			`I agree to participate in pre-employment screening checks conducted by Deploi (including but not limited to identity and address verification, financial probity, academic and professional qualifications, employment history and criminal background checks) and understand that successful completion of such checks is required by my prospective employer. I understand that a failure to provide information when requested may result in my candidacy being discontinued.`,
			`I confirm that any information and documents provided for the purposes of participating in pre-employment screening by Deploi are complete, accurate and up to date. I understand that providing inaccurate, incomplete or misleading information could constitute a criminal offence and may result in adverse consequences including termination of my candidacy and employment without notice and, where applicable, notification to relevant licensing and supervisory authorities. `,
			`When participating in criminal background screening, I acknowledge that this is done to prevent or detect unlawful acts and/or in order to comply with regulatory requirements applicable to Deploi and/or my and I agree to provide a copy of the DBS certificate provided upon request.`,
			`I acknowledge that, by participating in pre-employment screening, my personal data may be processed in the ways and for the purposes described in the Deploi Privacy Notice as updated from time to time (current version available at Deploi.group/privacy-policy) which I have read and understood.`,
		],
		small: false,
	},
];

export const activityDocumentInfo = [
	{
		activity: 'employed',
		documentTypes: ['P60', 'Payslip', `P45`, `Bank statement showing income`, `Letter from the company`],
	},
	{
		activity: 'self-employed',
		documentTypes: [
			'Self-assessment tax records',
			'Confirmation of receiving UTR',
			'Invoices',
			'Bank Statements',
			'Accountant can provide reference',
		],
	},
	{
		activity: 'job-seeking',
		documentTypes: [
			'DWP website payment records',
			'Proof of Job Seekers Allowance',
			'Correspondence with companies submitting applications',
			'Engagement with the job centre',
		],
	},
	{
		activity: 'studying',
		documentTypes: [
			'Certificate of achievement',
			'Course transcript',
			'Correspondence with University via emails/letters (non circular)',
		],
	},
	{
		activity: 'travelling',
		documentTypes: [
			'Passport stamps',
			'Proof of visa application/awaiting status',
			'Travel Itineraries',
			'Bank statements (Showing payments to travel companies/abroad)',
			'Awaiting NiNo or Awaiting residency',
			'Correspondence from HMRC relating to residency',
		],
	},
	{
		activity: 'carer',
		documentTypes: [
			`HMRC confirmation of carer's allowance`,
			'Bank statements',
			'Correspondence from organisations proving time taken for caring',
		],
	},
	{
		activity: 'medical-reasons',
		documentTypes: [
			'Correspondence with medical practitioners from throughout time',
			'Correspondence with other companies acknowledging illness',
			'Sick notes',
		],
	},
	{
		activity: 'parental-cover',
		documentTypes: [
			'Proof of birth of child',
			'Correspondence from employment/government proving time taken away to cover',
			'Bank statements showing SMP, Parental leave pay, ShPP, Child tax credit, Child Benefit',
		],
	},
	{
		activity: 'volunteering',
		documentTypes: [
			'Correspondence from Organisation proving time spent Volunteering',
			'Emails from throughout the time (non circular)',
			`Candidate should request actioning of Deploi's request`,
		],
	},
	{
		activity: 'self-study',
		documentTypes: [
			'Receipt for course studied',
			'Confirmation of attendance of course (emails, transcripts)',
			`Direct correspondence from institute/organisation`,
			'Certificate of achievement',
		],
	},
	{
		activity: 'another-reason',
		documentTypes: ['Send an email explaining your situation in detail to: candidates@deploi.uk'],
	},
];

export const activityHelpInfo = [
	{
		title: 'What documents do I need to send for my Activity History?',
		url: 'https://intercom.help/deploi/en/articles/9245649-activity-history-document-guidelines',
	},
];
