import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './disclosures-questions.styles.scss';
import {
	ICheckboxAnswer,
	ICheckboxData,
	StepNamesEnum,
	StepStatusEnum,
} from '../../../types/interfaces/misc.interface';
import Header from '../../header/header/header.component';
import Checkbox from '../../general/checkbox/checkbox.component';
import PageFooter from '../../general/page-footer/page-footer.component';
import ListCard from '../../general/list-card/list-card.component';
import { IDisclosure } from '../../../types/interfaces/applicant.interface';
import OrkaButton from '../../general/orka-button/orka-button.component';
import InnerLayout from '../../general/inner-layout/inner-layout.component';
import DropdownInfo from '../../general/dropdown-info/dropdown-info.component';
import InfoCard from '../../general/info-card/info-card.component';
import { RootState } from '../../../types/root-state';
import { useSelector, useDispatch } from 'react-redux';
import errorIcon from '../../../assets/icons/misc/error.svg';
import * as utils from '../../../utils/utilsFunctions';
import { setAlert, updateSteps } from '../../../redux/General/general.actions';
import * as api from '../../../api/requests/disclosures.api';
import * as miscApi from '../../../api/requests/misc.api';
import { fireGaEvent } from '../../../utils/ga-event';
import { useNavigate } from 'react-router-dom';

type Props = {
	pageName: string;
	handleBack: (origin: string) => void;
	checkboxData: ICheckboxData[];
	handleSelection: (
		checkBoxAnswer: ICheckboxAnswer,
		question: string,
	) => void;
	setShowForm: Dispatch<SetStateAction<boolean>>;
	setSelectedDisclosure: Dispatch<SetStateAction<IDisclosure | null>>;
	isDisabled: boolean;
	setDisclosureType: Dispatch<SetStateAction<string>>;
	primaryButtonText?: string;
	hideDesktopSteps?: boolean;
	redirectUrl?: string;
};

const DisclosureQuestions: FC<Props> = ({
	pageName,
	handleBack,
	checkboxData,
	handleSelection,
	setShowForm,
	setSelectedDisclosure,
	isDisabled,
	setDisclosureType,
	primaryButtonText,
	hideDesktopSteps,
	redirectUrl,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [submissionValidationError, setSubmissionValidationError] =
		useState(false);
	const isTablet = useSelector((state: RootState) => state.general.isTablet);

	const updatePassport = useSelector(
		(state: RootState) =>
			state.applicant.applicant?.clearanceStatusHistory
				?.passportUpdateRequired,
	);
	const [continueClicked, setContinueClicked] = useState(false);

	fireGaEvent('Disclosures', 'Disclosures - List Page');

	useEffect(() => {
		utils.instantScroll();
	}, []);

	const handleSelectDisclosure = (disclosure: IDisclosure) => {
		fireGaEvent('Disclosures', 'Clicked on select disclosure');
		setShowForm(true);
		setSelectedDisclosure(disclosure);
	};

	const handleAddAnother = (checkboxItem: any) => {
		fireGaEvent('Disclosures', 'Clicked on add another disclosure');
		setDisclosureType(checkboxItem.type);
		setSelectedDisclosure(null);
		setShowForm(true);
	};

	const handleComplete = () => {
		utils.instantScroll();
		setContinueClicked(true);
		if (isDisabled) {
			fireGaEvent(
				'Disclosures',
				'Clicked on submit disclosures (validation failed)',
			);
			setSubmissionValidationError(true);
			return;
		}
		fireGaEvent('Disclosures', 'Clicked on submit disclosures');
		return api
			.submitDeclarations()
			.then(() => {
				return handleSubmitSection();
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	const handleSubmitSection = () => {
		return miscApi
			.postStep({
				step: StepNamesEnum.declaration,
				status: StepStatusEnum.complete,
			})
			.then(() => handleSuccess())
			.catch((err: Error) => {
				handleErrors(err);
			});
	};

	const handleSuccess = () => {
		dispatch(
			setAlert({
				type: 'success',
				message: 'Successfully submitted section',
				isVisible: true,
			}),
		);
		dispatch(updateSteps('Declaration', 'complete'));
		return navigate(redirectUrl ? redirectUrl : '/', { replace: true });
	};

	const handleErrors = (err: Error) => {
		utils.handleAlerts('error', err.message, dispatch);
	};

	return (
		<div className='disclosures-questions-container'>
			<Header
				text={pageName ? pageName : ''}
				type='text'
				backFunction={handleBack}
				origin='main'
				hideDesktopSteps={hideDesktopSteps}
				informationText={`We ask for these to give you an opportunity to provide any circumstances, so we know the full picture when we verify the information. The chances are that they will not affect your screening if you are upfront.`}
				updatePassport={updatePassport ? true : false}
			/>
			<InnerLayout>
				<>
					{isTablet && submissionValidationError && (
						<div className='submission-validation-error'>
							<InfoCard
								size='small'
								icon={errorIcon}
								text="You can't complete this section until you add a disclosure or select 'No' for each question."
								subText='If you wish to complete this section later you can tap the back arrow and your progress will be saved.'
								type='validation-card'
							/>
						</div>
					)}
					{checkboxData.length > 0 && (
						<div className='disclosure-questions-inner-container'>
							{checkboxData.map((checkboxItem) => {
								return (
									<div
										key={checkboxItem.question}
										className='disclosure-checkbox margin-bottom-40'
									>
										<Checkbox
											alignment='horizontal'
											checkboxData={checkboxItem}
											emitSelection={handleSelection}
											required={true}
											continueClicked={continueClicked}
										/>
										{checkboxItem.disclosures &&
											checkboxItem.disclosures.length >
												0 && (
												<>
													{checkboxItem.disclosures.map(
														(
															disclosure: IDisclosure,
														) => {
															const item = {
																uuid: disclosure.uuid,
																title: disclosure.details,
																lineOne:
																	disclosure.sentence,
																lineTwo:
																	disclosure.date
																		? utils.formatDateForUser(
																				disclosure.date,
																			)
																		: '',
															};
															return (
																<div
																	key={
																		disclosure.uuid
																	}
																	className='margin-top-16'
																>
																	<ListCard
																		item={
																			item
																		}
																		clickFunction={() =>
																			handleSelectDisclosure(
																				disclosure,
																			)
																		}
																	/>
																</div>
															);
														},
													)}
													<OrkaButton
														buttonContent={
															'Add another'
														}
														disabled={false}
														emitClicked={() =>
															handleAddAnother(
																checkboxItem,
															)
														}
														buttonClass='purple-outline'
													/>
												</>
											)}
									</div>
								);
							})}
						</div>
					)}
					<PageFooter
						disabled={false}
						primaryButtonText={primaryButtonText}
						primaryButtonFunction={handleComplete}
					/>
					<div className='margin-bottom-120' />
				</>
				<>
					<DropdownInfo
						title={`Why do you need this information?`}
						text={`We ask for these to give you an opportunity to provide any circumstances, so we know the full picture when we verify the information. The chances are that they will not affect your screening if you are upfront.`}
						desktopVersion={true}
						handleClick={() =>
							fireGaEvent(
								'Disclosures',
								'Tapped why do you need this information dropdown',
							)
						}
					/>
					{!isTablet && submissionValidationError && (
						<div className='submission-validation-error'>
							<InfoCard
								size='small'
								icon={errorIcon}
								text="You can't complete this section until you add a disclosure or select 'No' for each question."
								subText='If you wish to complete this section later you can tap the back arrow and your progress will be saved.'
								type='validation-card'
							/>
						</div>
					)}
				</>
			</InnerLayout>
		</div>
	);
};

export default DisclosureQuestions;
