import { getRequestURLFromParams } from '../utils/api.utils';
import { ENDPOINTS } from '../utils/endpoints';
import { axiosInstance } from '../utils/api.axiosInstance';
import { IApplicant, IShareFile } from '../../types/interfaces/applicant.interface';

export const updateCandidate = (applicantData: Partial<IApplicant>): Promise<any> => {
	const url = getRequestURLFromParams(ENDPOINTS.UPDATE_CANDIDATE, {
		applicantData,
	});
	return axiosInstance()
		.patch(url, applicantData)
		.catch((err) => {
			throw err;
		});
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const submitGeneralInfoSection = (workerUuid: string): Promise<any> => {
	return new Promise((resolve) => {
		resolve(true);
	});
};

export const getCandidateDetails = (): any => {
	const url = getRequestURLFromParams(ENDPOINTS.CANDIDATE);
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const shareBackgroundCheck = (payload: IShareFile): any => {
	const url = getRequestURLFromParams(ENDPOINTS.SHARE_FILE);
	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};
