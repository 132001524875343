import {
	ILoginPayload,
	IInvitePayload,
	ICreatePayload,
	IVerifySmsPayload,
	IResetPasswordPayload,
	IForgotPasswordPayload,
} from '../../types/interfaces/auth.interface';
import { axiosInstance } from '../utils/api.axiosInstance';

export const login = (payload: ILoginPayload): Promise<any> => {
	return axiosInstance()
		.post(`candidate/login`, payload)
		.catch((err) => {
			throw err;
		});
};

export const invite = (payload: IInvitePayload): Promise<any> => {
	const url = `user/invite`;
	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const create = (payload: Partial<ICreatePayload>): Promise<any> => {
	const url = `candidate/create`;
	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const verifySms = (payload: IVerifySmsPayload): Promise<any> => {
	const url = `candidate/verify-sms`;
	return axiosInstance()
		.post(url, payload)
		.then((res) => res)
		.catch((err) => {
			throw err;
		});
};

export const resetPassword = (
	payload: IResetPasswordPayload,
	token: string,
): Promise<any> => {
	const url = `candidate/reset-password`;
	return axiosInstance()
		.post(url, payload, { headers: { Authorization: `Bearer ${token}` } })
		.catch((err) => {
			throw err;
		});
};

export const forgotPassword = (
	payload: IForgotPasswordPayload,
): Promise<any> => {
	const url = `candidate/forgot-password`;
	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const logout = (): Promise<any> => {
	const url = `candidate/logout`;
	return axiosInstance()
		.post(url)
		.catch((err) => {
			throw err;
		});
};

export const getSeamlessLoginToken = (token: string): Promise<any> => {
	const url = `candidate/seamless-login`;
	const params = {
		token,
	};

	return axiosInstance()
		.get(url, { params })
		.catch((err) => {
			throw err;
		});
};
