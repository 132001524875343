import React, { FC } from 'react';
import './button-list.styles.scss';
import OrkaButton from '../orka-button/orka-button.component';
import {
	ICheckboxAnswer,
	ICheckboxData,
} from '../../../types/interfaces/misc.interface';

type Props = {
	checkboxData: ICheckboxData;
	handleSelection: (
		checkBoxAnswer: ICheckboxAnswer,
		question: string,
	) => void;
};

const ButtonList: FC<Props> = ({ checkboxData, handleSelection }) => {
	return (
		<div className='button-list-section'>
			<label>{checkboxData.question}</label>
			<div className='button-list-item-container'>
				{checkboxData.answers.map((answer) => {
					return (
						<div key={answer.id} className='button-list-item'>
							<OrkaButton
								buttonContent={answer.label}
								disabled={false}
								emitClicked={() =>
									handleSelection(
										answer,
										checkboxData.question,
									)
								}
								buttonClass={
									answer.selected
										? 'purple-filled'
										: 'purple-outline'
								}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default ButtonList;
