import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { RootState } from '../../../types/root-state';

type Props = {
	children: any;
};

const PrivateRoute: FC<Props> = ({ children }) => {
	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

	return isLoggedIn ? children : <Navigate to='/login' />;
};

export default PrivateRoute;
