import './check-passport-home.styles.scss';

import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../types/root-state';
import {
	dayStatus,
	formatDateToDayMonthYearFormat,
	getLatestActivity,
	getLatestAddress,
	getPassportUpdateByDate,
} from '../../../utils/utilsFunctions';
import moment from 'moment';

import CheckPassportHomeCard from '../check-passport-home-card/check-passport-home-card.component';
import * as validations from '../../../utils/validations';
import office from '../../../assets/icons/misc/office.svg';
import officeGrey from '../../../assets/icons/misc/office-grey.svg';
import house from '../../../assets/icons/misc/house.svg';
import houseGrey from '../../../assets/icons/misc/house-grey.svg';
import eye from '../../../assets/icons/misc/eye.svg';
import eyeGrey from '../../../assets/icons/misc/eye-grey.svg';
import licence from '../../../assets/icons/misc/licence.svg';
import arrowRight from '../../../assets/icons/misc/arrow-right-blue.svg';
import BottomSheetModal from '../../modals/bottom-sheet-modal/bottom-sheet-modal.component';
import Input from '../../general/input/input.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import * as utils from '../../../utils/utilsFunctions';
import { useDispatch } from 'react-redux';
import { shareBackgroundCheck } from '../../../api/requests/applicant.api';
import deploiLogo from '../../../assets/logos/deploi-logo-sm.svg';
const CheckPassportHome: FC = () => {
	const navigate = useNavigate();

	const [isBlurred, setIsBlurred] = useState(true);

	const applicant = useSelector((state: RootState) => state.applicant.applicant);

	const activityHistory = useSelector((state: RootState) => state.applicant.activityHistory);

	const clearanceStatusHistory = useSelector((state: RootState) => state.applicant.applicant.clearanceStatusHistory);

	const licences = useSelector((state: RootState) => state.applicant.licences);

	const latestActivity = getLatestActivity(activityHistory);
	const latestAddress = getLatestAddress(applicant.address);
	const isPending = applicant.overallSubmissionStatus === 'pending';
	const [showModal, setShowModal] = useState(false);
	const [email, setEmail] = useState('');
	const [validInputs, setValidInputs] = useState([{ email: true }]);
	const [continueClicked, setContinueClicked] = useState(false);
	const dispatch = useDispatch();
	const getActivityTexts = () => {
		if (!latestActivity) return { description: '', workplace: '' };

		switch (latestActivity.type) {
			case 'employed':
				return {
					description: 'Currently working at:',
					workplace: latestActivity.name,
				};
			case 'studying':
				return {
					description: 'Currently studying at:',
					workplace: latestActivity.name,
				};

			case 'self-employed':
			case 'volunteering':
			case 'travelling':
				return {
					description: `Currently ${latestActivity.type}`,
					workplace: '',
				};

			case 'job-seeking':
			case 'medical-reasons':
			case 'parental-cover':
			case 'self-study':
				return {
					description: `Currently on a career break for ${latestActivity.type.replace(/-/g, ' ')}`,
					workplace: '',
				};

			case 'carer':
				return {
					description: 'Currently on a career break as a carer',
					workplace: '',
				};

			case 'another-reason':
				return {
					description: 'Currently on a career break',
					workplace: '',
				};

			default:
				return { description: '', workplace: '' };
		}
	};

	const { description, workplace } = getActivityTexts();

	const getPassportUpdateBy = () => {
		return getPassportUpdateByDate(clearanceStatusHistory?.lastFullyClearedDate);
	};

	const getPassportUpdateByDaysLeft = () => {
		const updateDate = moment(getPassportUpdateBy());
		const currentDate = moment();
		let daysLeft = updateDate.diff(currentDate, 'days');

		if (daysLeft < 0) {
			daysLeft = Math.abs(daysLeft);
			return daysLeft === 1 ? `Renewal overdue by ${daysLeft} day` : `Renewal overdue by ${daysLeft} days`;
		}

		return daysLeft === 1 ? `${daysLeft} day` : `${daysLeft} days`;
	};

	const checkPassportStatus = () => {
		if (applicant.overallSubmissionStatus === 'cancelled' || applicant.paused || applicant.suspendedAt) {
			return true;
		} else {
			return false;
		}
	};

	const checkLicenceUpdateRequired = (): number => {
		return licences.filter(
			(licence) => licence.status === 'Active' && (!licence.frontReadUrl || !licence.backReadUrl),
		).length;
	};

	const getNumberOfLicences = (): number => {
		return licences.filter((licence) => licence.status === 'Active').length;
	};

	const requiredLicenceUpdates = checkLicenceUpdateRequired();

	const handleLicenceClick = () => {
		navigate('/check/licences');
	};

	const handleEmitInput = (val: string) => {
		setEmail(val);
	};
	const handleShareEmail = () => {
		setContinueClicked(true);
		if (!validInputs[0].email || !email) return;

		shareBackgroundCheck({ email })
			.then((res: any) => {
				if (res.status === 404) {
					throw res;
				}
				utils.handleAlerts('success', 'Successfully shared', dispatch);
				setShowModal(false);
			})
			.catch((err: Error) => {
				utils.handleAlerts('error', err?.message, dispatch);
			});
	};

	// const isWorks = () => {
	// 	return applicant.clientIds.find((id: string) => {
	// 		return id === '1';
	// 	});
	// };

	return (
		<>
			<div className='passport-home'>
				<div className='passport-home-body'>
					<div className='passport-welcome'>
						<div>
							<p className='day-status-text'>{dayStatus()}</p>
							<p className='name-text'>{applicant.name}</p>
						</div>
						<img src={deploiLogo} className='deploi-logo' />
					</div>
					<CheckPassportHomeCard
						niNumber={applicant.nationalInsuranceNumber}
						candidateFullName={applicant.name + ' ' + applicant.surname}
						DOB={applicant.DOB}
						renewalDaysLeft={getPassportUpdateByDaysLeft()}
						renewalByDate={getPassportUpdateBy()}
						updateRequired={clearanceStatusHistory.passportUpdateRequired}
						submissionStatus={applicant.overallSubmissionStatus}
						isDeactivated={checkPassportStatus()}
					/>

					{!checkPassportStatus() && (
						<div className='passport-home-other'>
							<div className='activity-detail'>
								<div className='activity-image-container'>
									<img src={isPending ? officeGrey : office} alt='office icon' />
								</div>
								<div className='activity-text-container'>
									<p className={`text-description ${isPending ? 'grey-text' : ''}`}>{description}</p>
									<p className={`text-workplace ${isPending ? 'grey-text' : ''}`}>{workplace}</p>
								</div>
							</div>
							<div className='address-detail'>
								<div className='address-image-container'>
									<img src={isPending ? houseGrey : house} alt='house icon' />
								</div>
								<div className='address-text-container'>
									<p className={`text-description ${isPending ? 'grey-text' : ''}`}>
										Currently living at:
									</p>
									{latestAddress && (
										<div className='text-address'>
											<p
												className={`text-address-first-line ${isBlurred ? 'blurred' : ''} ${isPending ? 'grey-text' : ''
													}`}
											>
												{latestAddress.address.streetAddress + ','}
											</p>
											<p
												className={`text-address-second-line ${isBlurred ? 'blurred' : ''} ${isPending ? 'grey-text' : ''
													}`}
											>
												{latestAddress.address.city + ', ' + latestAddress.address.postcode}
											</p>
											{isBlurred && (
												<div
													className={`reveal-address ${isPending ? 'grey-text' : ''}`}
													onClick={() => setIsBlurred(false)}
												>
													<img src={isPending ? eyeGrey : eye} alt='eye icon' />
													<span className={`${isPending ? 'grey-text' : ''}`}>View</span>
												</div>
											)}
										</div>
									)}
								</div>
							</div>
							<div className='last-confirmed'>
								<div className='last-confirmed-text-container'>
									<p className={`text-description ${isPending ? 'grey-text' : ''}`}>
										Last confirmed on:
									</p>
									<p className={`last-confirmed-date ${isPending ? 'grey-text' : ''}`}>
										{formatDateToDayMonthYearFormat(clearanceStatusHistory.lastFullyClearedDate)}
									</p>
								</div>
							</div>
							<div className='licence-details margin-bottom-32' onClick={handleLicenceClick}>
								<div className='licence-image-container'>
									<img src={licence} alt='licence icon' />
								</div>
								<div className='licence-text-container'>
									<p className='text-description'>SIA Licences</p>

									<div className='licence-details-container'>
										{requiredLicenceUpdates > 0 && (
											<p className='licence-update'>{requiredLicenceUpdates} Require Updating</p>
										)}
										<p className='licence-number'>{getNumberOfLicences()}</p>
										<img src={arrowRight} alt='arrow right'></img>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				{showModal && (
					<div className='bottom-sheet-modal-wrapper'>
						<BottomSheetModal setShowModal={setShowModal} showModal={showModal}>
							<h2 className='text-center margin-bottom-24'>Share your background check</h2>
							<p>
								{`Enter your future employer's email address to invite them to buy your file. By tapping
								'Share' you consent to sharing the data held in your vetting file.`}
							</p>
							<div className='margin-bottom-40'>
								<Input
									inputName='email'
									title='Organisation email'
									placeholder='firstname.surname@companyname.com'
									value={email}
									emitValue={handleEmitInput}
									type='email'
									required={true}
									continueClicked={continueClicked}
									validationFunction={validations.isValidEmail}
									validInputs={validInputs}
									setValidInputs={setValidInputs}
								/>
							</div>
							<OrkaButton
								buttonContent='Share'
								disabled={false}
								emitClicked={handleShareEmail}
								buttonClass='purple-filled'
							/>
						</BottomSheetModal>
					</div>
				)}
			</div>
			{/* {isWorks() && !checkPassportStatus() && (
				<div className='share-bg-check-btn'>
					<OrkaButton
						buttonClass='purple-gradient'
						buttonContent='Share your background check'
						disabled={false}
						emitClicked={() => {
							setContinueClicked(false);
							setValidInputs([{ email: true }]);
							setEmail('');
							setShowModal(true);
						}}
					/>
				</div>
			)} */}
		</>
	);
};

export default CheckPassportHome;
