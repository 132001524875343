import React, { FC, useRef, useEffect } from 'react';
import './progress-bar.styles.scss';

type Props = {
	status: string;
	percentage?: boolean;
};

const ProgressBar: FC<Props> = ({ status, percentage }) => {
	const progressRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (percentage && progressRef.current) {
			progressRef.current.style.width = status;
		}
	}, []);

	return (
		<div className='progress-bar-container'>
			<div
				className={`progress-bar-progress ${status}`}
				ref={progressRef}
			/>
		</div>
	);
};

export default ProgressBar;
