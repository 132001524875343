import React, { FC, useEffect, useState } from 'react';
import Layout from '../../../components/general/layout-component/layout.component';
import * as utils from '../../../utils/utilsFunctions';
import { useNavigate, useLocation } from 'react-router-dom';
import RtwDocuments from '../../../components/rtw-container/rtw-documents/rtw-documents';
import RtwPassport from '../../../components/rtw-container/rtw-documents/rtw-passport/rtw-passport';
import RtwExpiredPassport from '../../../components/rtw-container/rtw-documents/rtw-expired-passport/rtw-expired-passport';
import RtwIntegrator from '../../../components/rtw-container/rtw-documents/rtw-integrator/rtw-integrator';
import RtwShareCode from '../../../components/rtw-container/rtw-documents/rtw-sharecode/rtw-sharecode';
import StaticExplainer from '../../../components/general/static-explainer/static-explainer';
import Progress from '../../../assets/icons/explainer/progress.svg';
import CheckMark from '../../../assets/icons/explainer/check-mark.svg';
import CrossMark from '../../../assets/icons/explainer/cross-mark.svg';
import ResidenceCard from '../../../components/rtw-container/rtw-documents/rtw-residence-card/rtw-residence-card';
import RtwUploadPhoto from '../../../components/rtw-container/rtw-documents/rtw-upload-photo/rtw-upload-photo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';

const RtwIntro: FC = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { page }: any = state || 'intro';
	const [pageName, setPageName] = useState<any | undefined>(page);
	const [pageLayout, setPageLayout] = useState<any | undefined>('');
	const idCheckStatus = useSelector(
		(state: RootState) => state.rtw.idCheckStatus,
	);
	useEffect(() => {
		utils.instantScroll();
	}, []);
	useEffect(() => {
		showPage();
	}, [pageName]);

	const handleBack = () => {
		switch (pageName) {
			case 'passport':
			case 'residence-card':
			case 'share-code':
				if (page === 'share-code-rejected') {
					setPageName('share-code-rejected');
				} else {
					setPageName('intro');
				}

				break;
			case 'other-documents':
				setPageName('intro');
				break;
			case 'expired-passport':
			case 'passport-online':
				setPageName('passport');
				break;
			case 'passport-upload-photo':
				setPageName('other-documents');
				break;
			case 'passport-check-photo':
				setPageName('passport-upload-photo');
				break;
			case 'intro':
				if (page === 'share-code-rejected') {
					setPageName('share-code-rejected');
				} else {
					toDashboard();
				}
				break;

			default:
				toDashboard();
				break;
		}
	};
	const toDashboard = () => {
		navigate('/', { replace: true });
	};
	const handleButton = (pageName: any) => {
		setPageName(pageName);
	};
	const toShareCode = () => {
		setPageName('share-code');
	};
	const toDocuments = () => {
		setPageName('intro');
	};
	const toIntegrator = () => {
		setPageName('passport-online-id');
	};
	const infoCardButton = () => {
		utils.intercomShareCodeLink();
	};
	const showPage = () => {
		let page = null;
		switch (pageName) {
			case 'passport':
				page = (
					<RtwPassport
						handleBack={handleBack}
						emitSelection={handleButton}
					/>
				);
				break;
			case 'passport-online':
				page = <RtwIntegrator />;
				break;
			case 'passport-online-in-progress':
				page = (
					<StaticExplainer
						handleBack={handleBack}
						body={`We need to manually check your Right to Work and Identity Check, which usually takes a few minutes. We'll let you know when is all ready.`}
						title={`Thanks! We're checking your Right to Work and Identity Check`}
						button1Text='Back to Dashboard'
						emitButton1={toDashboard}
						image={Progress}
					/>
				);
				break;
			case 'passport-online-complete':
				page = (
					<StaticExplainer
						handleBack={handleBack}
						body={`Congratulations, you've passed your Right to work and Identity check! \n \n Go back to to see the next steps.`}
						title='Your Right to work & Identity check was successful'
						button1Text='Back to Dashboard'
						emitButton1={toDashboard}
						image={CheckMark}
					/>
				);
				break;
			case 'passport-online-rejected':
			case 'other-documents-rejected':
			case 'onfido-rejected':
				page = (
					<StaticExplainer
						handleBack={toDashboard}
						body={`Unfortunately, we were not able to pass your Right to Work ${
							pageName === 'passport-online-rejected'
								? '& Identity Check.'
								: ''
						}

				The most common reasons for this happening is:
				- The photos you uploaded were blurry, too dark or low quality
				- You've submitted the incorrect document
				- Your documents are not in date or valid

				If you submitted the correct and valid documents, get in touch with the team for a manual review.`}
						title={`Your Right to work ${
							pageName === 'passport-online-rejected'
								? '& Identity Check'
								: ''
						} was unsuccessful`}
						button1Text='Back to Dashboard'
						emitButton1={toDashboard}
						image={CrossMark}
					/>
				);
				break;
			case 'passport-online-id':
				page = <RtwIntegrator idCheckOnly={true} />;
				break;
			case 'expired-passport':
			case 'other-documents':
				page = (
					<RtwExpiredPassport
						handleBack={handleBack}
						emitSelection={handleButton}
					/>
				);
				break;
			case 'passport-upload-photo':
				page = (
					<RtwUploadPhoto
						handleBack={handleBack}
						emitSelection={handleButton}
					/>
				);
				break;
			case 'passport-check-photo':
			case 'other-documents-in-progress':
			case 'onfido-in-progress':
				page = (
					<StaticExplainer
						handleBack={toDashboard}
						body={`A representative from our Deploi team will shortly be checking your right to work document to complete part one of your right to work check. This usually takes around one working day.
						We'll let you know when this is done. `}
						title={`Thanks! We're checking your right to work document`}
						button1Text='Back to Dashboard'
						emitButton1={toDashboard}
						image={Progress}
					/>
				);
				break;
			case 'other-documents-complete':
				page = (
					<StaticExplainer
						handleBack={toDashboard}
						body={`Thanks, part one of your right to work check is done! \n
						For part two you have to send your right to work document to the Deploi team. \n
						You can find further instructions by clicking the button below.`}
						title='Part one of your Right to work check passed'
						button1Text='Back to Dashboard'
						emitButton1={toDashboard}
						image={CheckMark}
					/>
				);
				break;
			case 'share-code':
				page = (
					<RtwShareCode
						handleBack={handleBack}
						emitSelection={handleButton}
					/>
				);
				break;
			case 'share-code-submitted':
			case 'share-code-in-progress':
				page = (
					<StaticExplainer
						handleBack={handleBack}
						body={`We need to manually check your share code, which usually takes around one working day. We'll let you know when the code has been checked.`}
						title={`Thanks! We're checking your share code`}
						button1Text='Back to Dashboard'
						emitButton1={toDashboard}
						image={Progress}
					/>
				);
				break;
			case 'share-code-complete':
			case 'onfido-complete':
				page = (
					<StaticExplainer
						handleBack={handleBack}
						body={`Congratulations, you've passed your right to work check!
						Complete your identity and address check now.`}
						title='Your Right to Work check was successful'
						button1Text={`${
							idCheckStatus.status === 'incomplete'
								? 'Start ID Check'
								: 'Back to Dashboard'
						}`}
						emitButton1={
							idCheckStatus.status === 'incomplete'
								? toIntegrator
								: toDashboard
						}
						image={CheckMark}
					/>
				);
				break;
			case 'share-code-rejected':
				page = (
					<StaticExplainer
						handleBack={handleBack}
						body={`You've failed your Right to Work!

					Submit yours checks again, using the correct share code.`}
						title='Your Right to work check was unsuccessful '
						button1Text='Resubmit Share code'
						emitButton1={toShareCode}
						button2Text='Select other document'
						emitButton2={toDocuments}
						image={CrossMark}
						infoCardText='How to generate share code?'
						infoCardButton={infoCardButton}
					/>
				);
				break;

			case 'residence-card':
				page = (
					<ResidenceCard
						handleBack={handleBack}
						emitSelection={handleButton}
					/>
				);
				break;

			case 'id-check-complete':
				page = (
					<StaticExplainer
						handleBack={toDashboard}
						body={`Congratulations, you've passed your Identity check!`}
						title='Identity check successful'
						button1Text='Back to Dashboard'
						emitButton1={toDashboard}
						image={CheckMark}
					/>
				);
				break;
			case 'id-check-rejected':
				page = (
					<StaticExplainer
						handleBack={toDashboard}
						body={`Unfortunately, we were not able to pass your Identity check`}
						title='Your Identity check was unsuccessful '
						button1Text='Back to Dashboard'
						emitButton1={toDashboard}
						image={CrossMark}
					/>
				);
				break;
			case 'id-check-in-progress':
				page = (
					<StaticExplainer
						handleBack={handleBack}
						body={`We need to manually check your Identity, which usually takes around one working day. We'll let you know once is done.`}
						title={`Thanks! We're checking Identity Check`}
						button1Text='Back to Dashboard'
						emitButton1={toDashboard}
						image={Progress}
					/>
				);
				break;

			default:
				page = (
					<RtwDocuments
						handleBack={handleBack}
						emitSelection={handleButton}
					/>
				);
				break;
		}
		setPageLayout(page);
	};
	return (
		<div>
			<Layout background=''>{pageLayout}</Layout>
		</div>
	);
};

export default RtwIntro;
