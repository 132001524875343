import React, { FC, useState, useEffect } from 'react';
import { getYotiUrl } from '../../../../api/requests/rtw.api';
import * as utils from '../../../../utils/utilsFunctions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type Props = {
	idCheckOnly?: boolean;
};
const RtwIntegrator: FC<Props> = ({ idCheckOnly }) => {
	const [url, setUrl] = useState<string>('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		getYotiUrl(idCheckOnly ? 'other-rtw' : 'uk-irish-passport')
			.then((result: any) => {
				setUrl(result.data.url);
			})
			.catch((err: any) => {
				utils.handleAlerts(
					'error',
					err.message
						? err.message
						: err.data.message
							? err.data.message
							: `There's been an error`,
					dispatch,
				);
				navigate('/', { replace: true });
			});
	}, []);

	const iframeData = `<iframe id="docscan-frame" style="border: 0px; height:86vh;" allow="camera" src="${url}" width="100%"></iframe>`;
	return (
		<div
			dangerouslySetInnerHTML={{
				__html: iframeData,
			}}
		/>
	);
};

export default RtwIntegrator;
