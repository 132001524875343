import React, { FC, useState } from 'react';
import './dropdown-info.styless.scss';
import arrowDown from '../../../assets/icons/misc/arrow-down.svg';

type Props = {
	title: string;
	text: string;
	desktopVersion?: boolean;
	handleClick?: () => void;
};

const DropdownInfo: FC<Props> = ({
	title,
	text,
	desktopVersion = false,
	handleClick,
}) => {
	const [isOpen, setIsOpen] = useState(desktopVersion);

	const handleOpen = () => {
		if (desktopVersion) return;
		if (handleClick) {
			handleClick();
		}
		setIsOpen(!isOpen);
	};
	return (
		<article
			className={`dropdown-info-container ${desktopVersion && 'desktop'}`}
		>
			<div className='dropdown-info-top'>
				<div className='dropdown-info-top-left'>
					<p>{title}</p>
				</div>
				{!desktopVersion && (
					<button
						onClick={handleOpen}
						className='dropdown-info-open-btn'
					>
						<img src={arrowDown} alt='open' />
					</button>
				)}
			</div>
			{isOpen && (
				<div className='dropdown-info-bottom'>
					<p>{text}</p>
				</div>
			)}
		</article>
	);
};

export default DropdownInfo;
