import React, { useEffect, useState, FC } from 'react';
import './login.styles.scss';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/Auth/auth.actions';
import { setAlert, setIsLoading } from '../../redux/General/general.actions';
import LoginInputsSection from '../../components/login/login-inputs-section/login-inputs-section.component';
import LoginPhone from '../../components/login/login-phone/login-phone.component';
import * as authApi from '../../api/requests/auth.api';
import LoginLayout from '../../components/general/login-layout/login-layout.component';
import ErrorBoundary from '../../components/general/error-boundary/error-boundary.component';
import { fireGaEvent } from '../../utils/ga-event';
import { useNavigate } from 'react-router-dom';

const Login: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loginSubmitted, setLoginSubmitted] = useState(false);
	const [code, getCode] = useState('');
	const [credentials, setCredentials] = useState({
		contactNumber: '',
		password: '',
	});
	const [resendPhone, setResendPhone] = useState(false);

	useEffect(() => {
		dispatch(setIsLoading(false));
	}, []);

	useEffect(() => {
		if (code.length === 6) {
			handleLogin();
		}
	}, [code]);

	const handleLogin = () => {
		const payload = {
			contactNumber: '+44' + credentials.contactNumber,
			verificationCode: Number(code),
		};

		return authApi
			.verifySms(payload)
			.then(() => {
				setCredentials({
					contactNumber: '',
					password: '',
				});
				fireGaEvent('Login', 'Code works');
				dispatch(login());
				navigate('/check', { replace: true });
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err?.data?.message
							? err?.data?.message
							: err?.message
								? err?.message
								: JSON.stringify(err),
						isVisible: true,
					}),
				);
			});
	};

	useEffect(() => {
		if (resendPhone) {
			const payload = {
				...credentials,
				contactNumber: '+44' + credentials.contactNumber,
			};
			authApi
				.login(payload)
				.then(() => {
					setResendPhone(false);
				})
				.catch(() => {
					setResendPhone(false);
					dispatch(
						setAlert({
							type: 'error',
							message: "couldn't resend code",
							isVisible: true,
						}),
					);
				});
		}
	}, [resendPhone]);

	return (
		<LoginLayout>
			<ErrorBoundary applicantWorkerUuid='None'>
				{!loginSubmitted ? (
					<LoginInputsSection
						setLoginSubmitted={setLoginSubmitted}
						setCredentials={setCredentials}
					/>
				) : (
					<LoginPhone
						getCode={getCode}
						setResendPhone={setResendPhone}
					/>
				)}
			</ErrorBoundary>
		</LoginLayout>
	);
};

export default Login;
