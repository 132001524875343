import './take-photo.styles.scss';
import React, { FC, useState, useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveImageData } from '../../redux/RTW/rtw.actions';
import { handleUploadDocuments } from '../../utils/document-uploads';
import { RootState } from '../../types/root-state';
import Webcam from 'react-webcam';
import * as utils from '../../utils/utilsFunctions';
import { handleUploadSIAImages } from '../../utils/licence-uploads';
import Loader from '../loader/loader.component';
import posterPlaceholder from '../../assets/images/take-photo-cover.svg';

type FacingModeType = 'front' | 'back';
type DocumentType = 'SIA' | 'RTW';

interface ImageSource {
	type: DocumentType;
	facingMode: FacingModeType;
	data: string;
}

interface Props {
	type: DocumentType;
	title: string;
	poster?: string;
	submitButtonLabel: string;
	facingMode: FacingModeType;
	handleSubmit: () => void;
}

const TakePhoto: FC<Props> = ({
	facingMode,
	type,
	title,
	poster,
	submitButtonLabel,
	handleSubmit,
}) => {
	const [imgSrc, setImgSrc] = useState<ImageSource | null>(null);
	const [streaming, setStreaming] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [isExistingLicence, setIsExistingLicence] = useState<boolean>(false);
	const [selectedLicenceNumber, setSelectedLicenceNumber] = useState<
		string | undefined
	>('');

	const dispatch = useDispatch();
	const webcamRef = useRef<any>(null);

	const applicant = useSelector(
		(state: RootState) => state.applicant.applicant,
	);

	const licences = useSelector(
		(state: RootState) => state.applicant.licences,
	);

	const selectedLicence = useSelector((state: RootState) => state.licences);

	const videoConstraints = {
		width: 1280,
		height: 720,
		facingMode: navigator.maxTouchPoints > 1 ? 'environment' : 'user',
	};

	useEffect(() => {
		let foundLicenceNumber: string | undefined;
		for (let i = 0; i < licences.length; i++) {
			if (!licences[i].uuid) {
				foundLicenceNumber = licences[i].number;
				setIsExistingLicence(false);
				break;
			}
		}

		if (!foundLicenceNumber && selectedLicence.data.length > 0) {
			foundLicenceNumber = selectedLicence.data[0].number;
			setIsExistingLicence(true);
		}

		setSelectedLicenceNumber(foundLicenceNumber);
	}, [licences, selectedLicence]);

	const handleRTWUpload = () => {
		if (imgSrc) {
			dispatch(saveImageData(imgSrc));

			const blob = utils.base64toBlob(imgSrc?.data);
			const file = new File([blob], `rtw_image_${imgSrc.facingMode}`, {
				type: 'image/jpeg',
			});
			return handleUploadDocuments(
				file,
				applicant.workerUuid,
				'rightToWorkManual',
				dispatch,
				'rtwUpload',
			)
				.then(() => handleSubmit())
				.catch((err) => {
					console.error(err);
				});
		}
	};

	const handleSIAUpload = () => {
		if (imgSrc) {
			// Store Licence Front data in local storage
			if (imgSrc.facingMode === 'front') {
				localStorage.setItem('siaFrontUrl', imgSrc.data);
				handleSubmit();
			} else {
				const licenceNumber = selectedLicenceNumber;
				const frontUrl = localStorage.getItem('siaFrontUrl') || '';
				const backUrl = imgSrc.data;

				localStorage.removeItem('siaFrontUrl');

				const blobFront = utils.base64toBlob(frontUrl);
				const fileFront = new File(
					[blobFront],
					`sia_image_${'front'}`,
					{
						type: 'image/jpeg',
					},
				);

				const blobBack = utils.base64toBlob(backUrl);
				const fileBack = new File([blobBack], `sia_image_${'back'}`, {
					type: 'image/jpeg',
				});

				const licenceFormDetails = licences[licences.length - 1];

				return handleUploadSIAImages(
					fileFront,
					fileBack,
					licenceNumber,
					licenceFormDetails,
					false,
					applicant.workerUuid,
					dispatch,
					'rtwUpload',
					isExistingLicence,
				)
					.then(() => handleSubmit())
					.catch((err) => {
						console.error(err);
					});
			}
		}
	};

	const onSubmit = useCallback(() => {
		if (imgSrc) {
			switch (imgSrc.type) {
				case 'RTW':
					handleRTWUpload();
					break;
				case 'SIA':
					handleSIAUpload();
					break;
				default:
					console.error('Error: Payload has no type!');
			}
		} else {
			console.error('Error: Failed to upload image!');
			return;
		}
	}, [imgSrc]);

	const capture = useCallback(() => {
		window.scrollTo(0, 0);

		if (webcamRef.current) {
			const imgData = webcamRef.current.getScreenshot();
			setImgSrc({
				...imgSrc,
				type: type,
				facingMode: facingMode,
				data: imgData,
			});

			setStreaming(false);
		}
	}, [webcamRef, setImgSrc]);

	const retake = () => {
		window.scrollTo(0, 0);
		setImgSrc(null);
		setStreaming(true);
		setLoading(true);
	};

	const handleUserMedia = () => setTimeout(() => setLoading(false), 1000);

	return (
		<div className='take-photo-container'>
			<header className='header'>
				{streaming ? (
					<div
						className='webcam-container'
						style={{
							transform:
								navigator.maxTouchPoints > 1
									? 'scaleX(-1)'
									: 'scaleX(1)',
						}}
					>
						<>
							{loading && <Loader />}
							<Webcam
								ref={webcamRef}
								audio={false}
								height={720}
								width={1280}
								className={
									navigator.maxTouchPoints > 1
										? 'back-camera'
										: 'front-camera'
								}
								videoConstraints={videoConstraints}
								screenshotFormat='image/jpeg'
								screenshotQuality={1}
								forceScreenshotSourceSize
								onUserMedia={handleUserMedia}
							/>
						</>
					</div>
				) : (
					<>
						{imgSrc ? (
							<div className='webcam-container'>
								<img src={imgSrc.data} alt={imgSrc.type} />
							</div>
						) : (
							<img
								src={poster || posterPlaceholder}
								alt='Take Photo'
							/>
						)}
					</>
				)}

				<div className='header-title'>
					{imgSrc ? 'Is your photo clear?' : title}
				</div>
			</header>

			<main className='main'>
				<p>Please make sure your photo:</p>
				<ul>
					<li>
						Is clear and in focus with all information easy to read
					</li>
					<li>Has no glare</li>
					<li>Shows the entire document</li>
				</ul>
			</main>

			<footer className='footer identity-footer'>
				{streaming ? (
					<button
						className='orka-identity-button primary-filled'
						onClick={capture}
						disabled={loading}
					>
						{loading ? 'Wait...' : 'Take Photo'}
					</button>
				) : (
					<>
						{imgSrc && (
							<button
								className='orka-identity-button primary-filled margin-bottom-16'
								onClick={onSubmit}
							>
								{submitButtonLabel}
							</button>
						)}
						<button
							className={
								imgSrc
									? 'orka-identity-button secondary-filled'
									: 'orka-identity-button primary-filled'
							}
							onClick={retake}
						>
							{imgSrc ? 'Retake' : 'Enable Camera'}
						</button>
					</>
				)}
			</footer>
		</div>
	);
};

export default TakePhoto;
