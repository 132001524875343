import {
	ADD_NAME_HISTORY,
	SET_NAME_HISTORY,
	UPDATE_NAME_HISTORY,
	REMOVE_NAME_ITEM,
	LICENCES,
	ADD_LICENCE,
	SET_ADDRESS_HISTORY,
	ADD_ADDRESS_ITEM,
	UPDATE_ADDRESS_HISTORY,
	REMOVE_ADDRESS_ITEM,
	SET_ACTIVITY_HISTORY,
	ADD_ACTIVITY_ITEM,
	UPDATE_ACTIVITY_HISTORY,
	REMOVE_ACTIVITY_ITEM,
	SET_DISCLOSURES,
	ADD_DISCLOSURE,
	UPDATE_DISCLOSURE,
	REMOVE_DISCLOSURE,
	REMOVE_LICENCE,
	UPDATE_LICENCE,
	APPLICANT,
	SET_CONSENT_SUBMITTED,
	SET_CONSENT_MODAL_STATE,
	UPDATE_APPLICANT,
	SET_ADDRESS_DOCUMENTS,
	CLEAR_APPLICANT_STATE,
} from './applicant.types';
import { removeItem, updateItem } from '../reducer-helpers';

const INITIAL_STATE = {
	nameHistory: [],
	licences: [],
	addressHistory: [],
	activityHistory: [],
	disclosures: [],
	applicant: null,
	consentSubmitted: false,
	consentModalState: false,
	addressDocuments: [],
};

const applicantReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_NAME_HISTORY:
			return {
				...state,
				nameHistory: action.nameHistory,
			};
		case ADD_NAME_HISTORY:
			return {
				...state,
				nameHistory: [...state.nameHistory, action.nameHistory],
			};
		case UPDATE_NAME_HISTORY:
			return {
				...state,
				nameHistory: updateItem(
					state.nameHistory,
					action.nameHistoryItem,
				),
			};
		case REMOVE_NAME_ITEM:
			return {
				...state,
				nameHistory: removeItem(
					state.nameHistory,
					action.nameHistoryItem,
				),
			};

		case LICENCES:
			return {
				...state,
				licences: action.licences,
			};
		case ADD_LICENCE:
			return {
				...state,
				licences: [...state.licences, action.licenceItem],
			};
		case REMOVE_LICENCE:
			return {
				...state,
				licences: removeItem(state.licences, action.licenceItem),
			};
		case UPDATE_LICENCE:
			return {
				...state,
				licences: updateItem(state.licences, action.licenceItem),
			};

		case SET_ADDRESS_HISTORY:
			return {
				...state,
				addressHistory: action.addressHistory,
			};
		case ADD_ADDRESS_ITEM:
			return {
				...state,
				addressHistory: [
					...state.addressHistory,
					action.addressHistoryItem,
				],
			};
		case UPDATE_ADDRESS_HISTORY:
			return {
				...state,
				addressHistory: updateItem(
					state.addressHistory,
					action.addressHistoryItem,
				),
			};
		case REMOVE_ADDRESS_ITEM:
			return {
				...state,
				addressHistory: removeItem(
					state.addressHistory,
					action.addressHistoryItem,
				),
			};
		case SET_ACTIVITY_HISTORY:
			return {
				...state,
				activityHistory: action.activityHistory,
			};
		case ADD_ACTIVITY_ITEM:
			return {
				...state,
				activityHistory: [
					...state.activityHistory,
					action.activityItem,
				],
			};
		case UPDATE_ACTIVITY_HISTORY:
			return {
				...state,
				activityHistory: updateItem(
					state.activityHistory,
					action.activityItem,
				),
			};
		case REMOVE_ACTIVITY_ITEM:
			return {
				...state,
				activityHistory: removeItem(
					state.activityHistory,
					action.activityItem,
				),
			};
		case SET_DISCLOSURES:
			return {
				...state,
				disclosures: action.disclosures,
			};
		case ADD_DISCLOSURE:
			return {
				...state,
				disclosures: [...state.disclosures, action.disclosure],
			};
		case UPDATE_DISCLOSURE:
			return {
				...state,
				disclosures: updateItem(state.disclosures, action.disclosure),
			};
		case REMOVE_DISCLOSURE:
			return {
				...state,
				disclosures: removeItem(state.disclosures, action.disclosure),
			};
		case APPLICANT:
			return {
				...state,
				applicant: action.applicant,
			};
		case UPDATE_APPLICANT:
			for (let key in action.obj) {
				if (action.obj[key] !== '') {
					state.applicant[key] = action.obj[key];
				}
			}
			const updatedApplicant = state.applicant;
			return {
				...state,
				applicant: {
					...updatedApplicant,
				},
			};
		case SET_CONSENT_SUBMITTED:
			return {
				...state,
				consentSubmitted: true,
			};
		case SET_CONSENT_MODAL_STATE:
			return {
				...state,
				consentModalState: action.consentModalState,
			};
		case SET_ADDRESS_DOCUMENTS:
			return {
				...state,
				addressDocuments: action.addressDocuments,
			};
		case CLEAR_APPLICANT_STATE:
			return INITIAL_STATE;
		default:
			return state;
	}
};

export default applicantReducer;
