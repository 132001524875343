import React, { FC, useState } from 'react';
import Header from '../../../header/header/header.component';
import InfoCard from '../../../general/info-card/info-card.component';
import book from '../../../../assets/icons/info-card/book.svg';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../types/root-state';
import InnerLayout from '../../../general/inner-layout/inner-layout.component';
import Input from '../../../general/input/input.component';
import { IShareCode } from '../../../../types/interfaces/applicant.interface';
import * as validations from '../../../../utils/validations';
import * as utils from '../../../../utils/utilsFunctions';
import ValidationLayer from '../../../general/validation-layer/validation-layer.component';
import * as api from '../../../../api/requests/rtw.api';

type Props = {
	handleBack: (origin: string) => void;
	emitSelection: (origin: string) => void;
};

const RtwShareCode: FC<Props> = ({ handleBack, emitSelection }) => {
	const dispatch = useDispatch();
	const isTablet = useSelector((state: RootState) => state.general.isTablet);
	const [continueClicked, setContinueClicked] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [formState, setFormState] = useState<Partial<IShareCode>>({
		shareCode: '',
		dob: '',
	});
	const [validInputs, setValidInputs] = useState([
		{ dob: true },
		{ shareCode: true },
	]);
	const handleEmitInput = (val: string, input: string) => {
		setFormState({
			...formState,
			[input]: val,
		});
	};
	const handleComplete = async () => {
		setContinueClicked(true);
		if (isDisabled) {
			return;
		}

		const payload = {
			...formState,
			dob: formState.dob ? utils.formatDateForDb(formState.dob) : null,
		};
		return api
			.saveShareCode(payload)
			.then((res: any) => {
				if (res.status === 404) {
					handleErrors(res);
					throw res;
				} else {
					emitSelection('share-code-submitted');
				}
			})
			.catch((err: Error) => {
				handleErrors(err);
			});
	};
	const handleErrors = (err: Error) => {
		if (err && err.message) {
			utils.handleAlerts('error', err.message, dispatch);
		}
	};

	const openIntercomLink = () => {
		utils.intercomShareCodeLink();
	};

	return (
		<div>
			<Header
				text='Submit your right to work share code?'
				type='text'
				backFunction={handleBack}
				origin='main'
				hideStepsCounter={true}
				hideDesktopSteps={true}
			></Header>
			<ValidationLayer
				setIsDisabled={setIsDisabled}
				formState={formState}
				validInputs={validInputs}
				requiredChecks={['requiredFields', 'validInputs']}
				requiredFields={['shareCode', 'dob']}
			>
				<InnerLayout>
					<div>
						<p className='margin-bottom-40'>
							Submit your share code, you can do so below.
						</p>
						<Input
							inputName='shareCode'
							title='Share code *'
							placeholder=''
							value={formState.shareCode}
							emitValue={handleEmitInput}
							required={true}
							continueClicked={continueClicked}
							validationFunction={validations.shareCodeValidation}
							validInputs={validInputs}
							setValidInputs={setValidInputs}
						/>
						<div className='margin-bottom-64 margin-top-24'>
							<Input
								inputName='dob'
								title='Date of Birth *'
								placeholder='dd/mm/yyyy'
								value={
									formState.dob
										? new Date(formState.dob)
										: null
								}
								emitValue={handleEmitInput}
								isDate={true}
								validationFunction={validations.checkIsOver18}
								validInputs={validInputs}
								setValidInputs={setValidInputs}
								required={true}
								continueClicked={continueClicked}
							/>
						</div>

						{isTablet && (
							<div
								onClick={openIntercomLink}
								className='margin-top-24'
							>
								<InfoCard
									size='small'
									icon={book}
									text='How to generate share code?'
								/>
							</div>
						)}

						<div className='identity-footer'>
							<button
								onClick={handleComplete}
								className='margin-bottom-8 orka-identity-button primary-filled'
							>
								Submit Code
							</button>
						</div>
					</div>
					<div onClick={openIntercomLink} className='margin-top-24'>
						<InfoCard
							size='small'
							icon={book}
							text='How to generate share code?'
						/>
					</div>
				</InnerLayout>
			</ValidationLayer>
		</div>
	);
};

export default RtwShareCode;
