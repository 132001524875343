import React, { FC } from 'react';
import LoginLogo from '../../login/login-logo/login-logo.component';
import './login-layout.styles.scss';
type Props = {
	children: React.ReactNode;
};

const LoginLayout: FC<Props> = ({ children }) => {
	return (
		<div className='login-layout'>
			<div className='layout-container'>
				<LoginLogo />
				{children}
			</div>
		</div>
	);
};

export default LoginLayout;
