export const removeItem = (state, actionItem) => {
    return state.filter(item => {
        return item.uuid !== actionItem.uuid;
    });
}

export const updateItem = (state, actionItem) => {
    return state.map(item => {
        if (item.uuid === actionItem.uuid) {
            item = actionItem
        }
        return item;
    })
}
