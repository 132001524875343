import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	MouseEvent,
} from 'react';
import './activity-submission.styles.scss';
import activitySubmissionIcon from '../../../assets/icons/activity/activity-submission.svg';
import { fireGaEvent } from '../../../utils/ga-event';

type Props = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
	handleSubmitActivities: () => void;
	handleBack?: (origin: string) => void;
};

const ActivitySubmission: FC<Props> = ({ setShowModal, handleBack }) => {
	fireGaEvent(
		'Activity History',
		'Activity History - Submission Message Page',
	);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleBackToDocs = (event: MouseEvent<HTMLAnchorElement>) => {
		fireGaEvent(
			'Activity History',
			'Activity history submission clicked back to docs',
		);
		event.preventDefault();
		setShowModal(false);
		if (handleBack) {
			handleBack('submission');
		}
	};

	return (
		<section className='activity-submission-container'>
			<header className='activity-submission-header'>
				<img src={activitySubmissionIcon} alt='activity submission' />
				<h2>
					Based on what you have submitted your application is going
					to take a while!
				</h2>
			</header>

			<div className='activity-submission-main'>
				<h4>How can I speed this up?</h4>
				<ul>
					<li>
						Upload documents that may prove your time there (the
						more the better)
						<br />
						<br />
						<a href='#' onClick={handleBackToDocs}>
							Go back to upload more documents
						</a>
					</li>
					<li>
						You can email us with any further documents at{' '}
						<a
							href='mailto:candidates@deploi.uk'
							target='_blank'
							rel='noreferrer'
						>
							candidates@deploi.uk
						</a>
						.
					</li>

					<li>
						You can call HMRC on{' '}
						<a href='tel:0300 200 3300'>0300 200 3300</a> or use
						their live chat and request your 5 year history.
						(It&apos;s free!)
					</li>
				</ul>
			</div>
		</section>
	);
};

export default ActivitySubmission;
