import React, { FC } from 'react';
import './info-card.styles.scss';

type Props = {
	size: string;
	icon?: string;
	text: any;
	heading?: string;
	withPointer?: boolean;
	subText?: string;
	componentType?: string;
	type?: string;
	clickFunction?: () => void;
};

const InfoCard: FC<Props> = ({
	size,
	icon,
	text,
	heading,
	withPointer,
	subText,
	componentType = 'helper-card-2',
	type,
	clickFunction,
}) => {
	return (
		<article
			className={`info-card-container ${componentType} ${type} ${
				clickFunction && 'cursor-pointer'
			}`}
			onClick={clickFunction}
		>
			{withPointer && <div className='info-card-pointer' />}
			{size === 'large' && (
				<div className='info-card-large'>
					{icon && <img src={icon} alt='info' />}
					<div>
						<h4>{heading}</h4>
						<p>{text}</p>
					</div>
				</div>
			)}
			{size === 'small' && (
				<div className='info-card-small'>
					<div className='info-card-small-top'>
						{icon && <img src={icon} alt='info' />}
						<div>{text}</div>
					</div>
					{subText && (
						<div className='info-card-small-bottom'>
							<p>{subText}</p>
						</div>
					)}
				</div>
			)}
		</article>
	);
};

export default InfoCard;
