import React, { FC } from 'react';
import './review-card.styles.scss';
import edit from '../../../assets/icons/misc/pencil-purple.svg';
import { useDispatch } from 'react-redux';
import { setApplicationStatus } from '../../../redux/General/general.actions';
import * as apiMisc from '../../../api/requests/misc.api';
import { fireGaEvent } from '../../../utils/ga-event';
import { useNavigate } from 'react-router-dom';

type Props = {
	children: React.ReactNode;
	title: string;
};

const ReviewCard: FC<Props> = ({ children, title }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleNavigate = (val: string) => {
		return apiMisc
			.setApplicationStatus('terms-agreed')
			.then(() => {
				fireGaEvent('Review', `Taps Edit ${title}`);
				dispatch(setApplicationStatus('terms-agreed'));
				return navigate('/check', {
					state: { goTo: val },
					replace: true,
				});
			})
			.catch((err: Error) => {
				throw err;
			});
	};
	return (
		<div className='review-card-container'>
			<h2>
				<img
					alt='edit'
					onClick={() => handleNavigate(title)}
					role='link'
					src={edit}
				/>
				<span>{title}</span>
			</h2>
			<div className='review-card-contents'>{children}</div>
		</div>
	);
};

export default ReviewCard;
