import './licences.styles.scss';
import React, { FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { RootState } from '../../types/root-state';
import { ICheckboxAnswer } from '../../types/interfaces/misc.interface';
import { ILicenceItem } from '../../types/interfaces/applicant.interface';
import { setLicences } from '../../redux/Applicant/applicant.actions';
import { getLicences } from '../../api/requests/licence.api';
import * as utils from '../../utils/utilsFunctions';
import useFetchData from '../../utils/useFetchData';
import Layout from '../../components/general/layout-component/layout.component';
import Modal from '../../components/modals/modal/modal.component';
import LicencesList from '../../components/licences/licences-list/licences-list.component';
import LicencesForm from '../../components/licences/licences-form/licences-form.component';
import LicencesConfirm from '../../components/licences/licences-confirm/licences-confirm.component';
import LicencesUploadPhotoFront from '../../components/licences/licences-upload-photo//licences-upload-photo-front/licences-upload-photo-front';
import LicencesUploadPhotoBack from '../../components/licences/licences-upload-photo/licences-upload-photo-back/licences-upload-photo-back';
import LicencesView from '../../components/licences/licences-view/licences-view.component';

type Props = {
	isPassport?: boolean;
};

const Licences: FC<Props> = ({ isPassport = false }) => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const [pageName, setPageName] = useState<string | undefined>('');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [selectedLicence, setSelectedLicence] = useState<ILicenceItem | null>(
		null,
	);
	const isMobile = useSelector((state: RootState) => state.general.isMobile);
	const licences = useSelector(
		(state: RootState) => state.applicant.licences,
	);
	const [checkboxData, setCheckboxData] = useState([
		{
			question: 'Do you have an SIA licence?',
			answers: [
				{ id: 1, selected: false, label: 'Yes' },
				{ id: 2, selected: false, label: 'No' },
			],
		},
	]);

	const [showForm, setShowForm] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const [showUploadFront, setShowUploadFront] = useState(false);
	const [showUploadBack, setShowUploadBack] = useState(false);
	const [showLicence, setShowLicence] = useState(false);
	const [isLicenceImageRequired, setIsLicenceImageRequired] = useState(false);

	useEffect(() => {
		if (!isPassport) {
			window.scrollTo(0, 0);
			setPageName(utils.setPageName(state, navigate));
		}
	}, []);

	useFetchData(getLicences, setLicences, 1, 'licences');

	useEffect(() => {
		checkboxData[0].answers[0].selected
			? setShowForm(true)
			: setShowForm(false);
	}, [
		checkboxData[0].answers[0].selected,
		checkboxData[0].answers[1].selected,
	]);

	useEffect(() => {
		const isLicenceNeeded = licences.some(
			(licence) =>
				licence.status === 'Active' &&
				(!licence.fileNameFront || !licence.fileNameBack),
		);

		setIsLicenceImageRequired(isLicenceNeeded);
	}, [licences]);

	const handleSelection = (
		checkBoxAnswer: ICheckboxAnswer,
		question: string,
	) => {
		utils.handleSelection(
			checkBoxAnswer,
			question,
			checkboxData,
			setCheckboxData,
		);
	};

	const handleScreen = (origin: string) => {
		switch (origin) {
			case 'form':
				setShowForm(false);
				setShowConfirm(false);
				setShowUploadFront(false);
				setShowUploadBack(false);
				setShowLicence(false);
				break;
			case 'confirm':
				setShowForm(true);
				setShowConfirm(false);
				setShowUploadFront(false);
				setShowUploadBack(false);
				setShowLicence(false);
				break;
			case 'upload-start':
				setShowForm(false);
				setShowConfirm(true);
				setShowUploadFront(false);
				setShowUploadBack(false);
				setShowLicence(false);
				break;
			case 'upload-front':
				setShowForm(false);
				setShowConfirm(false);
				setShowUploadFront(true);
				setShowUploadBack(false);
				setShowLicence(false);
				break;
			case 'upload-back':
				setShowForm(false);
				setShowConfirm(false);
				setShowUploadFront(false);
				setShowUploadBack(true);
				setShowLicence(false);
				break;
			case 'view-licence':
				setShowForm(false);
				setShowConfirm(false);
				setShowUploadFront(false);
				setShowUploadBack(false);
				setShowLicence(true);
				break;
			default:
				return navigate('/', { replace: true });
		}
	};

	const getPageName = () => {
		if (isPassport) return 'SIA Licences';
		if (pageName === 'Licences') return 'SIA Licences';
		return pageName || '';
	};

	return (
		<div className='licences-container'>
			<Layout background=''>
				{!showForm &&
					!showConfirm &&
					!showUploadFront &&
					!showUploadBack &&
					!showLicence && (
						<LicencesList
							pageName={getPageName()}
							handleScreen={handleScreen}
							checkboxData={checkboxData[0]}
							handleSelection={handleSelection}
							setShowForm={setShowForm}
							setSelectedLicence={setSelectedLicence}
							setCheckboxData={setCheckboxData}
							isLicenceImageRequired={isLicenceImageRequired}
							isPassport={isPassport}
						/>
					)}
				{showForm &&
					!showConfirm &&
					!showUploadFront &&
					!showUploadBack &&
					!showLicence &&
					isMobile && (
						<LicencesForm
							handleScreen={handleScreen}
							setShowForm={setShowForm}
							setShowConfirm={setShowConfirm}
							selectedLicence={selectedLicence}
						/>
					)}

				{!showForm &&
					showConfirm &&
					!showUploadFront &&
					!showUploadBack &&
					!showLicence &&
					isMobile && <LicencesConfirm handleScreen={handleScreen} />}

				{!showForm &&
					!showConfirm &&
					showUploadFront &&
					!showUploadBack &&
					!showLicence &&
					isMobile && (
						<LicencesUploadPhotoFront handleScreen={handleScreen} />
					)}

				{!showForm &&
					!showConfirm &&
					!showUploadFront &&
					showUploadBack &&
					!showLicence &&
					isMobile && (
						<LicencesUploadPhotoBack handleScreen={handleScreen} />
					)}

				{!showForm &&
					!showConfirm &&
					!showUploadFront &&
					!showUploadBack &&
					showLicence &&
					isMobile && <LicencesView handleScreen={handleScreen} />}

				{showForm &&
					!showConfirm &&
					!showUploadFront &&
					!showUploadBack &&
					!isMobile && (
						<Modal
							title={
								selectedLicence
									? 'Edit Licence'
									: 'Add an SIA Licence'
							}
							setShowModal={setShowForm}
							showModal={showForm}
						>
							<LicencesForm
								handleScreen={handleScreen}
								setShowForm={setShowForm}
								setShowConfirm={setShowConfirm}
								selectedLicence={selectedLicence}
							/>
						</Modal>
					)}

				{!showForm &&
					showConfirm &&
					!showUploadFront &&
					!showUploadBack &&
					!showLicence &&
					!isMobile && (
						<LicencesConfirm
							handleScreen={handleScreen}
							isPassport={isPassport}
						/>
					)}

				{!showForm &&
					!showConfirm &&
					showUploadFront &&
					!showUploadBack &&
					!showLicence &&
					!isMobile && (
						<LicencesUploadPhotoFront handleScreen={handleScreen} />
					)}

				{!showForm &&
					!showConfirm &&
					!showUploadFront &&
					showUploadBack &&
					!showLicence &&
					!isMobile && (
						<LicencesUploadPhotoBack handleScreen={handleScreen} />
					)}

				{!showForm &&
					!showConfirm &&
					!showUploadFront &&
					!showUploadBack &&
					showLicence &&
					!isMobile && (
						<LicencesView
							handleScreen={handleScreen}
							isPassport={isPassport}
						/>
					)}
			</Layout>
		</div>
	);
};

export default Licences;
