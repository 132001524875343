export const ENDPOINTS = {
	START_COMPLIANCE: '/worker/start-compliance',
	ACTIVITY_SUBMISSION: '/worker/work-history/submission',
	ACTIVITY: '/worker/work-history/item',
	ACTIVITY_UPLOAD_URL: '/candidate/work-history/document/url',
	ACTIVITY_DOCUMENT_SAVE:
		'/candidate/work-history/item/:activityUuid/document/attach',
	ACTIVITY_DOCUMENT_REMOVE:
		'/candidate/work-history/item/:activityUuid/document/:fileUuid',
	ALL_ACTIVITY: `/worker/work-history/item/gaps`,
	ACTIVITY_ITEM_BY_UUID: '/worker/work-history/item/:uuid',
	CANDIDATE_ACTIVITY_ITEM_BY_UUID: '/candidate/work-history/item/:uuid',
	ORGANISATIONS: '/worker/companies/references',

	ADDRESS_ITEM: '/worker/address-history/item',
	ALL_ADDRESS: '/worker/address-history/items/gaps',
	ADDRESS_ITEM_BY_UUID: '/worker/address-history/item/:itemUuid',
	CANDIDATE_ADDRESS_BY_UUID: '/candidate/address-history/item/:itemUuid',
	ADDRESS_SUBMISSION: '/worker/address-history/submission',
	DELETE_ADDRESS_DOCUMENT:
		'/candidate/address-history/item/:uuid/document/:documentUuid',

	UPLOAD_URL: '/worker/documents',
	DOCUMENTS_SAVE: '/worker/documents/save',
	GET_FILES_BY_TAG: '/worker/documents/tag/:tagName',

	NAME_BY_UUID: '/worker/name-history/item/:itemUuid',
	NAME: '/worker/name-history/item',
	ALL_NAMES: '/worker/name-history/items',
	NAME_SUBMISSION: '/worker/name-history/submission',

	LICENCE_IS_VALID: '/worker/licences/isValid/:licenceNumber',
	ALL_LICENCES: '/worker/licences/items',
	LICENCE_TYPES: '/worker/licences/types',
	LICENCE_SUBMISSION: '/worker/licences/submission',
	LICENCE_IMAGE_UPLOAD: '/candidate/licences/imageUpload',

	SAVE_DISCLOSURE_ITEM: '/worker/worker-declaration/item',
	GET_DISCLOSURE_ITEMS: '/worker/worker-declaration/items',
	DISCLOSURE_SUBMISSION: '/worker/worker-declaration/submission',
	CONSENT: '/worker/overall-submission/consent-form',
	OVERALL_SUBMISSION: '/worker/overall-submission/save',
	SHARE_CODE: '/worker/sharecode',
	APPLICANT: '/worker/applicant',

	APPLICATION_STATUS: '/candidate/application-status',
	STEPS: '/candidate/steps',
	STEPS_UPDATE: '/candidate/steps/update',
	UPDATE_CANDIDATE: '/candidate/update',

	ADDRESS_SEARCH: '/candidate/locations/search',
	ADDRESS_PLACE: '/candidate/locations/place/:placeId',
	RTW_MANUAL_UPLOAD: '/candidate/right-to-work/document/url',
	CANDIDATE: '/candidate',
	YOTI: '/yoti/url?flow=:flow',
	RTW_ID_CHECK_STATUSES: '/candidate/status',
	SHARE_FILE: '/candidate/share-file',
};
