import {
    SET_ALERT_INFO,
    CLEAR_ALERT_INFO,
    SET_IS_MOBILE,
    SET_APPLICATION_STATUS,
    UPDATE_STEPS,
    SET_STEPS_COMPLETE,
    SET_OVERALL_SUBMISSION,
    SET_IS_LOADING,
    CLEAR_GENERAL_STATE,
	SET_IS_TABLET,
} from './general.types';

export const setAlert = (alertInfo) => {
    return {
        type: SET_ALERT_INFO,
        alertInfo
    };
};

export const clearAlert = () => {
    return {
        type: CLEAR_ALERT_INFO
    };
};

export const setIsMobile = (isMobile) => {
    return {    
        type: SET_IS_MOBILE,
        isMobile
    }
}


export const setIsTablet = (isTablet) => {
    return {    
        type: SET_IS_TABLET,
        isTablet
    }
}
export const setApplicationStatus = (applicationStatus) => {
    return {
        type: SET_APPLICATION_STATUS,
        applicationStatus
    }
} 

export const updateSteps = (title, status) => {
    return {
        type: UPDATE_STEPS,
        title,
        status,
    }
}

export const setStepsComplete = (stepsComplete) => {
    return {
        type: SET_STEPS_COMPLETE,
        stepsComplete
    }
}

export const setOverallSubmissionStatus = (overallSubmissionStatus) => {
    return {
        type: SET_OVERALL_SUBMISSION,
        overallSubmissionStatus
    }
}

export const setIsLoading = (isLoading) => {
    return {
        type: SET_IS_LOADING,
        isLoading
    }
}

export const clearGeneralState = () => {
    return {
        type: CLEAR_GENERAL_STATE
    }
}



