import React, { FC } from 'react';
import './review-card-item.styles.scss';

type Props = {
	children: React.ReactNode[];
};

const ReviewCardItem: FC<Props> = ({ children }) => {
	return (
		<div className='review-card-item'>
			<div className='review-card-item-left'>{children[0]}</div>
			<div className='review-card-item-right'>{children[1]}</div>
		</div>
	);
};

export default ReviewCardItem;
