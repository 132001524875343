import React from 'react';

interface ScrollLock {
	enableScroll: () => void;
	disableScroll: () => void;
}

export const useScrollLock = (): ScrollLock => {
	const enableScroll = React.useCallback(() => {
		document.body.style.overflow = '';
	}, []);

	const disableScroll = React.useCallback(() => {
		document.body.style.overflow = 'hidden';
	}, []);

	return { enableScroll, disableScroll };
};
