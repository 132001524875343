import React, { FC, useCallback } from 'react';
import './upload-button.styles.scss';
import { useDropzone } from 'react-dropzone';
import OrkaButton from '../orka-button/orka-button.component';

type Props = {
	handleUpload: (file: any) => void;
	type: string;
	maxLength: boolean;
};

const UploadButton: FC<Props> = ({ handleUpload, type, maxLength }) => {
	const onDrop = useCallback((file: any) => {
		handleUpload(file);
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});

	return (
		<div className='file-upload-container margin-top-8'>
			<div
				{...getRootProps()}
				className={
					isDragActive
						? 'orka-check-file-bucket active'
						: 'orka-check-file-bucket'
				}
			>
				<input {...getInputProps()} multiple={false} />
				<OrkaButton
					buttonClass={type}
					buttonContent='Upload'
					disabled={maxLength}
					emitClicked={() => {
						return;
					}}
				/>
			</div>
		</div>
	);
};

export default UploadButton;
