import React, { FC } from 'react';
import './loader.styles.scss';

const Loader: FC = () => {
	return (
		<div className='loader-container'>
			<div className='loader-inner'>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	);
};

export default Loader;
