import { ENDPOINTS } from '../utils/endpoints';
import { axiosInstance } from '../utils/api.axiosInstance';
import { getRequestURLFromParams } from '../utils/api.utils';
import { ILicenceItem } from '../../types/interfaces/applicant.interface';

export const checkLicenceValid = (licenceNumber: string): Promise<any> => {
	const url = getRequestURLFromParams(ENDPOINTS.LICENCE_IS_VALID, {
		licenceNumber,
	});
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const saveLicence = (licence: Partial<ILicenceItem>): any => {
	const url = getRequestURLFromParams(ENDPOINTS.ALL_LICENCES);
	return axiosInstance()
		.post(url, licence)
		.catch((err) => {
			throw err;
		});
};

export const getLicences = (): any => {
	const url = getRequestURLFromParams(ENDPOINTS.ALL_LICENCES);
	return axiosInstance()
		.get(url)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const saveLicenceSubmission = (): any => {
	const url = getRequestURLFromParams(ENDPOINTS.LICENCE_SUBMISSION);
	return axiosInstance()
		.post(url)
		.catch((err) => {
			throw err;
		});
};

export const saveLicenceImage = (fileData: string): Promise<any> => {
	const url = getRequestURLFromParams(ENDPOINTS.LICENCE_IMAGE_UPLOAD);
	return axiosInstance()
		.patch(url, fileData)
		.catch((err) => {
			throw err;
		});
};
