import React, { FC, useEffect } from 'react';

import NotFound from '../general/not-found/not-found.component';
import ErrorBoundary from '../general/error-boundary/error-boundary.component';
import { Routes, Route } from 'react-router-dom';
import RtwIntro from '../../pages/rtw-pages/rtw-intro/rtw-intro';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/root-state';

const RtwContainer: FC = () => {
	const applicantWorkerUuid = useSelector(
		(state: RootState) => state.applicant.applicant?.workerUuid,
	);
	useEffect(() => {
		if (!window.history.state.usr) {
			window.history.pushState({ page: 1 }, '', '');
		}
	}, []);

	return (
		<div className='check-container'>
			<ErrorBoundary applicantWorkerUuid={applicantWorkerUuid}>
				<Routes>
					<Route path='/rtw-intro' element={<RtwIntro />} />

					<Route
						path='*'
						element={<NotFound type='noData' pageNotFound={true} />}
					/>
				</Routes>
			</ErrorBoundary>
		</div>
	);
};

export default RtwContainer;
