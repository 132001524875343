import React, { FC, useState, useEffect } from 'react';
import './top-bar.styles.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import deploiSmall from '../../../assets/logos/deploi-logo-sm-white.svg';
import deploiLarge from '../../../assets/logos/deploi-logo-white.svg';
import moment from 'moment';
import ProfileSettings from '../profile-settings/profile-settings.component';

const TopBar: FC = () => {
	const [showFakeScreener, setShowFakeScreener] = useState(false);
	const applicant = useSelector(
		(state: RootState) => state.applicant.applicant,
	);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (applicant) {
			const canShowFakeScreener = moment(applicant.complianceStarted).add(
				72,
				'hours',
			);
			setShowFakeScreener(canShowFakeScreener < moment());
		}
	}, [applicant.complianceStarted]);

	return (
		<div
			className={
				showFakeScreener
					? 'top-bar-container fake-screener'
					: 'top-bar-container'
			}
		>
			<img src={deploiSmall} alt="Deploi Logo Small" className="deploi-logo-small" />
			<img src={deploiLarge} alt="Deploi Logo Large" className="deploi-logo-large" />
			<ProfileSettings />
		</div>
	);
};

export default TopBar;
