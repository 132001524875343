import './licences-upload-photo-back.scss';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import * as utils from '../../../../utils/utilsFunctions';
import Header from '../../../header/header/header.component';
import InnerLayout from '../../../general/inner-layout/inner-layout.component';
import TakePhoto from '../../../camera/take-photo.component';
import posterBack from '../../../../assets/images/licence-back-example.png';

type Props = {
	handleScreen: (origin: string) => void;
};

const LicencesUploadPhotoBack: FC<Props> = ({ handleScreen }) => {
	const dispatch = useDispatch();

	const handleNext = () => {
		utils.handleAlerts(
			'success',
			'Successfully uploaded licence',
			dispatch,
		);
		handleScreen('form');

		//Reload to update licence list in redux (state.applicant.licences)
		window.location.reload();
	};

	return (
		<div>
			<Header
				text=''
				type='text'
				backFunction={() => handleScreen('upload-start')}
				//backFunction={() => resetPage()}
				origin='upload-start'
				hideStepsCounter={true}
				hideDesktopSteps={true}
			></Header>
			<InnerLayout>
				{/* Left Column */}
				<div className='left-column'>
					<TakePhoto
						title='Take a photo of your SIA Licence (Back)'
						poster={posterBack}
						submitButtonLabel={`Submit Photo`}
						type='SIA'
						facingMode='back'
						handleSubmit={handleNext}
					/>
				</div>

				{/* Right Column */}
				<div className='right-column'></div>
			</InnerLayout>
		</div>
	);
};

export default LicencesUploadPhotoBack;
