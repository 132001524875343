import './advisory-card.styles.scss';
import React, { FC, SetStateAction, Dispatch } from 'react';

// Assets
import leftChevronIcon from '../../assets/icons/misc/leftChevronIcon.svg';

type Props = {
	children: React.ReactNode;
	title?: string;
	headerImageSource?: string;
	buttonLabel?: string;
	setShowAdvisory: Dispatch<SetStateAction<boolean>>;
	onClick?: React.MouseEventHandler;
};

const AdvisoryCard: FC<Props> = ({
	children,
	title,
	buttonLabel,
	headerImageSource,
	setShowAdvisory,
	onClick,
}) => {
	const closeAdvisoryCard = (): void => {
		setShowAdvisory(false);
	};

	return (
		<div className='advisory-card-container'>
			<div className='advisory-card-content'>
				<header className='advisory-card-header'>
					<nav className='advisory-card-header-nav'>
						<button
							className='back-button'
							onClick={closeAdvisoryCard}
						>
							<img src={leftChevronIcon} alt='back' />
						</button>
						<div className='advisory-header-content'>
							{headerImageSource && (
								<img
									className='cover-photo'
									src={headerImageSource}
									alt='cover photo'
								/>
							)}
							{title && (
								<h1 className='advisory-card-title'>{title}</h1>
							)}
						</div>
					</nav>
				</header>
				<main className='advisory-card-main'>{children}</main>
				<footer className='advisory-card-footer'>
					<button className='advisory-card-button' onClick={onClick}>
						{buttonLabel}
					</button>
				</footer>
			</div>
		</div>
	);
};

export default AdvisoryCard;
