import { ENDPOINTS } from '../utils/endpoints';
import { axiosInstance } from '../utils/api.axiosInstance';
import { getRequestURLFromParams } from '../utils/api.utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const saveDisclosure = (disclosure: any): any => {
	const url = getRequestURLFromParams(ENDPOINTS.SAVE_DISCLOSURE_ITEM);

	return axiosInstance()
		.post(url, disclosure)
		.catch((err) => {
			throw err;
		});
};

export const submitDeclarations = (): any => {
	const url = getRequestURLFromParams(ENDPOINTS.DISCLOSURE_SUBMISSION);
	return axiosInstance()
		.post(url)
		.catch((err) => {
			throw err;
		});
};

export const getCriminalRecordData = (): any => {
	const url = getRequestURLFromParams(ENDPOINTS.GET_DISCLOSURE_ITEMS);
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};
