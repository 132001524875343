import { RefObject, useEffect, SetStateAction, Dispatch } from 'react';

const useOutsideAlerter = (
	ref: RefObject<any>,
	setShowDropdown: Dispatch<SetStateAction<boolean>>,
	showDropdown: boolean,
): any => {
	useEffect(() => {
		/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
		const handleClickOutside = (event: any) => {
			setTimeout(() => {
				if (ref.current && !ref.current.contains(event.target)) {
					if (showDropdown) {
						setShowDropdown(false);
					}
				}
			}, 200);
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, showDropdown]);
};

export default useOutsideAlerter;
