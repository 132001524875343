import { ENDPOINTS } from '../utils/endpoints';
import { axiosInstance } from '../utils/api.axiosInstance';
import { getRequestURLFromParams } from '../utils/api.utils';
import { INameHistoryItem } from '../../types/interfaces/applicant.interface';

export const getNameHistory = (): Promise<any> => {
	const url = getRequestURLFromParams(ENDPOINTS.ALL_NAMES);
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const saveName = (name: Partial<INameHistoryItem>): any => {
	const url = getRequestURLFromParams(ENDPOINTS.NAME);
	return axiosInstance()
		.post(url, name)
		.catch((err) => {
			throw err;
		});
};

export const updateName = (name: Partial<INameHistoryItem>): any => {
	const url = getRequestURLFromParams(ENDPOINTS.NAME_BY_UUID, {
		itemUuid: name.uuid,
	});
	return axiosInstance()
		.patch(url, name)
		.catch((err) => {
			throw err;
		});
};

export const deleteName = (nameUuid: string): any => {
	const url = getRequestURLFromParams(ENDPOINTS.NAME_BY_UUID, {
		itemUuid: nameUuid,
	});
	return axiosInstance()
		.delete(url)
		.catch((err) => {
			throw err;
		});
};

export const saveNameSubmission = (): any => {
	const url = getRequestURLFromParams(ENDPOINTS.NAME_SUBMISSION);
	return axiosInstance()
		.post(url)
		.catch((err) => {
			throw err;
		});
};
