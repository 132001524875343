import React, { FC } from 'react';
import Header from '../../../header/header/header.component';
import InnerLayout from '../../../general/inner-layout/inner-layout.component';
import book from '../../../../assets/icons/info-card/book.svg';
import InfoCard from '../../../general/info-card/info-card.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types/root-state';

type Props = {
	handleBack: (origin: string) => void;
	emitSelection: (origin: string) => void;
};

const RtwExpiredPassport: FC<Props> = ({ handleBack, emitSelection }) => {
	const isTablet = useSelector((state: RootState) => state.general.isTablet);
	return (
		<div>
			<Header
				text='Continue if you have one of the following Right to work documents:'
				type='text'
				backFunction={handleBack}
				origin='main'
				hideStepsCounter={true}
				hideDesktopSteps={true}
			></Header>
			<InnerLayout>
				<div>
					<p className='bullet-primary demi-bold'>
						UK birth or adoption certificate
					</p>
					<h4 className='margin-left-16 margin-bottom-24'>
						This must be issued by the UK Home Office
					</h4>

					<p className='bullet-primary demi-bold'>
						UK/Irish passport
					</p>
					<h4 className='margin-left-16 margin-bottom-24'>
						A valid/expired UK/Irish passport
					</h4>

					<p className='bullet-primary demi-bold'>
						Immigration status document
					</p>
					<h4 className='margin-left-16 margin-bottom-24'>
						This must be issued by the UK Home Office
					</h4>

					<p className='bullet-primary demi-bold'>
						Certificate of British citizenship
					</p>
					<h4 className='margin-left-16 margin-bottom-24'>
						A certificate of registration or naturalisation as a
						British citizen
					</h4>

					<p className='bullet-primary demi-bold'>
						Any other type of paper visa
					</p>
					<h4 className='margin-left-16 margin-bottom-24'>
						Any valid paper visa
					</h4>
					{isTablet && (
						<div className='margin-top-40'>
							<InfoCard
								size='small'
								icon={book}
								text={
									<>
										<p>
											These checks have to be done
											manually and will require you to
											post your right to work documents to
											us when cleared. This can take up to
											five days.
										</p>

										<p className='margin-top-16'>
											Learn more
										</p>
									</>
								}
							/>
						</div>
					)}

					<div className='identity-footer'>
						<button
							onClick={() =>
								emitSelection('passport-upload-photo')
							}
							className='margin-bottom-8 orka-identity-button primary-filled'
						>
							Continue
							<h4>Manually (5 days)</h4>
						</button>
					</div>
				</div>
				<div>
					<div>
						<InfoCard
							size='small'
							icon={book}
							text={
								<>
									<p>
										These checks have to be done manually
										and will require you to post your right
										to work documents to us when cleared.
										This can take up to five days.
									</p>

									<p className='margin-top-16'>Learn more</p>
								</>
							}
						/>
					</div>
				</div>
			</InnerLayout>
		</div>
	);
};

export default RtwExpiredPassport;
