import React, { FC } from 'react';
import './application-status-info.styles.scss';

type Props = {
	status: string;
};

const ApplicationStatusInfo: FC<Props> = ({ status }) => {
	return (
		<div className='application-info-status-container'>
			<h4>
				{status.includes('activities verified')
					? 'Where are we up to?'
					: 'Where are you up to?'}
			</h4>
			<p className='application-info-status'>{status}</p>
		</div>
	);
};

export default ApplicationStatusInfo;
