import React, {
	useEffect,
	useRef,
	FC,
	Dispatch,
	SetStateAction,
	FormEvent,
} from 'react';
import './phone-input.styles.scss';

type Props = {
	isFocused: boolean;
	setArr: Dispatch<SetStateAction<{ isFocused: boolean; val: string }[]>>;
	index: number;
	value: string;
	arr: any;
};

const PhoneInput: FC<Props> = ({ isFocused, setArr, index, value, arr }) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const handleChange = (e: FormEvent<HTMLInputElement>) => {
		const newArr = arr.map((el: any, i: number) => {
			if (i === index) {
				el.val = e.currentTarget.value;
				el.isFocused = false;
			}
			if (i === index + 1) {
				if (e.currentTarget.value !== '') {
					el.isFocused = true;
				}
			}
			return el;
		});
		setArr(newArr);
	};

	useEffect(() => {
		if (isFocused && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isFocused]);

	return (
		<div className='phone-input'>
			<input
				ref={inputRef}
				type='tel'
				maxLength={1}
				onChange={handleChange}
				value={value}
			/>
		</div>
	);
};

export default PhoneInput;
