import React, { FC } from 'react';

// Components
import Header from '../../../header/header/header.component';
import InnerLayout from '../../../general/inner-layout/inner-layout.component';
import TakePhoto from '../../../camera/take-photo.component';

type Props = {
	handleBack: (origin: string) => void;
	emitSelection: (origin: string) => void;
};

const RtwUploadPhoto: FC<Props> = ({ handleBack, emitSelection }) => {
	// Handles what happens when photos are submitted
	const handleNext = () => {
		emitSelection('passport-check-photo');
	};

	return (
		<div className='rtw-upload-photo'>
			{/* Header */}
			<Header
				text=''
				type='text'
				backFunction={handleBack}
				origin='main'
				hideStepsCounter={true}
				hideDesktopSteps={true}
			/>

			{/* Main Layout */}
			<InnerLayout>
				{/* Left Column */}
				<div className='left-column'>
					<TakePhoto
						title='Take a photo of your Right to work document'
						submitButtonLabel={`Submit Photo`}
						type='RTW'
						facingMode='front'
						handleSubmit={handleNext}
					/>
				</div>

				{/* Right Column */}
				<div className='right-column'></div>
			</InnerLayout>
		</div>
	);
};

export default RtwUploadPhoto;
