import React, { FC } from 'react';
import './not-found.styles.scss';
import notFound from '../../../assets/icons/misc/not-found.svg';

type Props = {
	type?: string;
	pageNotFound?: boolean;
};

const NotFound: FC<Props> = ({ type, pageNotFound = false }) => {
	return (
		<div className='not-found' data-cy='no-data'>
			<img src={notFound} alt='tumbleweed' />
			<p>
				{!pageNotFound &&
					(type === 'noUsers'
						? 'Looks like there’s noone here ...'
						: "Looks like there's no info here ...")}

				{pageNotFound && 'Page not found'}
			</p>
		</div>
	);
};

export default NotFound;
