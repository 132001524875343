import React, { FC } from 'react';
import LoginLayout from '../../components/general/login-layout/login-layout.component';
import ResetPasswordInput from '../../components/login/reset-password-input/reset-password-input.component';
import './reset-password.styles.scss';

const ResetPassword: FC = () => {
	return (
		<LoginLayout>
			<ResetPasswordInput />
		</LoginLayout>
	);
};

export default ResetPassword;
