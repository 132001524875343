import * as api from '../api/requests/licence.api';
import * as documentsApi from '../api/requests/documents.api';
import * as utils from './utilsFunctions';
import { AnyAction, Dispatch as ReduxDispatch } from 'redux';
import { setIsLoading } from '../redux/General/general.actions';
import { IUploadTypes } from '../types/interfaces/misc.interface';
import { ILicenceItem } from '../types/interfaces/applicant.interface';

export const handleUploadSIAImages = (
	fileFront: File,
	fileBack: File,
	licenceNumber: string | undefined,
	licenceFormDetails: Partial<ILicenceItem>,
	lastImage: boolean,
	applicantUuid: string,
	dispatch: ReduxDispatch<AnyAction>,
	uploadType: IUploadTypes,
	isExistingLicence: boolean,
): Promise<any> => {
	return getSignedUrl(fileFront, applicantUuid, uploadType, lastImage)
		.then((file) => {
			dispatch(setIsLoading(true));
			return documentsApi
				.uploadToGoogle(file)
				.then(() => {
					localStorage.setItem(
						'siaFrontImageURL',
						file.fileNameFront,
					);
					//If first image has been uploaded to GCP, upload second image
					if (!lastImage) {
						lastImage = true;
						return getSignedUrl(
							fileBack,
							applicantUuid,
							uploadType,
							lastImage,
						).then((file) => {
							return documentsApi
								.uploadToGoogle(file)
								.then(() => {
									const storedFileNameFront =
										localStorage.getItem(
											'siaFrontImageURL',
										) || '';
									const payload = {
										number: licenceNumber,
										fileNameFront: storedFileNameFront,
										fileNameBack: file.fileNameBack,
									};
									localStorage.removeItem('siaFrontImageURL');

									return saveImageItem(
										payload,
										dispatch,
										licenceFormDetails,
										isExistingLicence,
									);
								})
								.catch((err: Error) => {
									handleApiError(err, dispatch);
								});
						});
					}
				})
				.catch((err: Error) => {
					handleApiError(err, dispatch);
				});
		})
		.catch((err: Error) => {
			handleApiError(err, dispatch);
		});
};

const saveImageItem = (
	payload: any,
	dispatch: any,
	licenceFormDetails: Partial<ILicenceItem>,
	isExistingLicence?: boolean,
) => {
	const saveLicencePromise = isExistingLicence
		? Promise.resolve()
		: api.saveLicence(licenceFormDetails);

	return saveLicencePromise
		.then(() => {
			return api.saveLicenceImage(payload).catch((err: Error) => {
				handleApiError(err, dispatch);
			});
		})
		.catch((err: Error) => {
			utils.handleAlerts('error', err.message, dispatch);
			throw err;
		});
};

const handleApiError = (err: Error, dispatch: any) => {
	utils.handleAlerts('error', err.message, dispatch);
	throw err;
};

const getSignedUrl = (
	file: any,
	applicantUuid: string,
	uploadType: IUploadTypes,
	lastImage: boolean,
) => {
	if (lastImage) {
		const newFile = Array.isArray(file) ? file[0] : file;
		return documentsApi
			.getDocUploadUrl(applicantUuid, newFile.type, uploadType)
			.then(({ data }) => {
				const { fileName, readUrl, writeUrl } = data;
				newFile.fileNameBack = fileName;
				newFile.readUrl = readUrl;
				newFile.writeUrl = writeUrl;
				return newFile;
			})
			.catch((err) => {
				throw err;
			});
	} else {
		const newFile = Array.isArray(file) ? file[0] : file;
		return documentsApi
			.getDocUploadUrl(applicantUuid, newFile.type, uploadType)
			.then(({ data }) => {
				const { fileName, readUrl, writeUrl } = data;
				newFile.fileNameFront = fileName;
				newFile.readUrl = readUrl;
				newFile.writeUrl = writeUrl;
				return newFile;
			})
			.catch((err) => {
				throw err;
			});
	}
};
