import React, { FC, Fragment, useState, useEffect } from 'react';
import './activity-document-upload-card.styles.scss';
import UploadButton from '../../general/upload-button/upload-button.component';
import { IFileItem } from '../../../types/interfaces/applicant.interface';
import trash from '../../../assets/icons/misc/trash-white.svg';
import successTick from '../../../assets/icons/misc/success-tick.svg';
import redCircleCross from '../../../assets/icons/misc/red-circle-cross-bold.svg';
import doc from '../../../assets/icons/misc/doc-gray.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';

type Props = {
	handleUpload: (file: File) => void;
	documents: IFileItem[];
	requiredDocsNumber: number;
	isActivityCard: boolean;
	removeActivityDoc?: (docUuid: string) => any;
};

const ActivityDocumentsUploadCard: FC<Props> = ({
	handleUpload,
	documents,
	requiredDocsNumber,
	isActivityCard,
	removeActivityDoc,
}) => {
	const [fileArray, setFileArray] = useState<IFileItem[]>([]);
	const [fileLength, setFileLength] = useState<number>(0);

	useEffect(() => {
		const docLength = documents && documents.length ? documents.length : 0;
		let docArray: any = documents && documents.length ? [...documents] : [];

		for (let i = docLength; i < requiredDocsNumber; i++) {
			docArray = [...docArray, { uuid: i + 1 }];
		}
		setFileLength(docLength);
		setFileArray(docArray);
	}, [JSON.stringify(documents)]);

	const [applicantActivityDate] = useSelector(
		(state: RootState) => state.applicant.activityHistory,
	);

	const getActivityDate = (date: any) => {
		return new Date(date).toLocaleDateString('en-GB');
	};

	return (
		<article className='activity-document-upload-container'>
			<ul>
				{fileArray.map((document, index: number) => {
					const readUrl = isActivityCard
						? document.fileUrl
						: document.fileUrl
							? document.fileUrl
							: document.readUrl;
					return (
						<Fragment key={document.uuid}>
							<li
								className={
									readUrl
										? 'document-upload-list-item with-file'
										: 'document-upload-list-item'
								}
							>
								{readUrl ? (
									<>
										<a
											href={readUrl}
											className={'has-file'}
											target='_blank'
											rel='noreferrer'
										>
											<span>Document {index + 1}</span>
											{document.status === 'accepted' ? (
												<img
													src={successTick}
													alt='Accepted'
												/>
											) : document.status ===
											  'rejected' ? (
												<img
													src={redCircleCross}
													alt='Rejected'
												/>
											) : (
												<React.Fragment>
													{' '}
												</React.Fragment>
											)}
										</a>
										{removeActivityDoc &&
											document.status !== 'accepted' &&
											document.status !== 'rejected' && (
												<button
													onClick={() =>
														removeActivityDoc(
															document.uuid,
														)
													}
												>
													<img
														src={trash}
														alt='delete file'
													/>
												</button>
											)}
									</>
								) : (
									<>
										{isActivityCard ? (
											<p className='doc-upload-area'>
												<img
													src={doc}
													alt='Add Document'
												/>{' '}
												<span>
													{index === 0
														? getActivityDate(
																applicantActivityDate.startAt,
															)
														: index === 1
															? getActivityDate(
																	applicantActivityDate.endAt,
																)
															: 'Extra document (optional)'}
												</span>
											</p>
										) : (
											<p>Document {index + 1}</p>
										)}
									</>
								)}
							</li>
						</Fragment>
					);
				})}
			</ul>
			<UploadButton
				maxLength={fileLength === 50}
				handleUpload={handleUpload}
				type='purple-outline'
			/>
		</article>
	);
};

export default ActivityDocumentsUploadCard;
