import React, { Dispatch, FC, SetStateAction } from 'react';
import OrkaButton from '../../general/orka-button/orka-button.component';
import './limited-clearance-expiry.scss';

interface ILimitedClearanceExpiry {
	daysLeft: number;
	setShowProgress: Dispatch<SetStateAction<boolean>>;
	setShowModal: Dispatch<SetStateAction<boolean>>;
}

const LimitedClearanceExpiry: FC<ILimitedClearanceExpiry> = ({
	daysLeft,
	setShowModal,
	setShowProgress,
}) => {
	const handleViewProgress = () => {
		setShowModal(false);
		setShowProgress(true);
	};

	const Title: FC = () => {
		return (
			<div className='limited-clearance-expiry__title'>
				<div>
					Limited clearance expiring in{' '}
					<div className='limited-clearance-expiry__title--emphasis'>
						{`${daysLeft} DAYS`}
					</div>
				</div>
			</div>
		);
	};

	const Copy: FC = () => {
		return (
			<div className='limited-clearance-expiry__copy'>
				<div className='limited-clearance-expiry__copy__header'>
					{`If we cannot fully clear you in this time, you will not be able to work with us.`}
				</div>

				<div className='limited-clearance-expiry__copy__body'>
					<p>
						{`Limited clearance means that we have not been able to verify all of your activities yet. We are working on this but if you would like to help, you can look for any supporting documents that help us verify your activities. You can find out which ones we're waiting on by clicking on your profile.`}
					</p>
					<p>
						{`Your limited clearance lasts for 84 days from the day we clear you, and you can work any jobs in that time. If we cannot fully clear you in this time, you will not be able to work with us.`}
					</p>
					<p className='limited-clearance-expiry__copy__sign-off'>
						{`Help us verify your activities`}
					</p>
				</div>
			</div>
		);
	};

	const Button: FC = () => {
		return (
			<div className='limited-clearance-expiry__button'>
				<OrkaButton
					buttonContent={'View progress'}
					disabled={false}
					emitClicked={handleViewProgress}
					buttonClass={'yellow-filled'}
				/>
			</div>
		);
	};

	return (
		<div className='limited-clearance-expiry'>
			<Title />
			<Copy />
			<Button />
		</div>
	);
};

export default LimitedClearanceExpiry;
