import './licences-upload-photo-front.scss';
import React, { FC } from 'react';
import Header from '../../../header/header/header.component';
import InnerLayout from '../../../general/inner-layout/inner-layout.component';
import TakePhoto from '../../../camera/take-photo.component';
import posterFront from '../../../../assets/images/licence-front-example.png';

type Props = {
	handleScreen: (origin: string) => void;
};

const LicencesUploadPhotoFront: FC<Props> = ({ handleScreen }) => {
	const handleNext = () => {
		handleScreen('upload-back');
	};

	return (
		<div>
			<Header
				text=''
				type='text'
				backFunction={() => handleScreen('upload-start')}
				// backFunction={() => resetPage()}
				origin='upload-start'
				hideStepsCounter={true}
				hideDesktopSteps={true}
			></Header>
			<InnerLayout>
				{/* Left Column */}
				<div className='left-column'>
					<TakePhoto
						title='Take a photo of your SIA Licence (Front)'
						poster={posterFront}
						submitButtonLabel={`Yes, It's Clear`}
						type='SIA'
						facingMode='front'
						handleSubmit={handleNext}
					/>
				</div>

				{/* Right Column */}
				<div className='right-column'></div>
			</InnerLayout>
		</div>
	);
};

export default LicencesUploadPhotoFront;
