import { ENDPOINTS } from '../utils/endpoints';
import { axiosInstance } from '../utils/api.axiosInstance';
import { getRequestURLFromParams } from '../utils/api.utils';

export const getConsent = (): Promise<any> => {
	const url = getRequestURLFromParams(ENDPOINTS.CONSENT);

	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const getConsentUploadUrl = (contentType: string): any => {
	const url = getRequestURLFromParams(ENDPOINTS.CONSENT);

	return axiosInstance()
		.post(url, { content_type: contentType })
		.catch((err) => {
			throw err;
		});
};

export const saveConsentVersion = (fileName: string, version: string): any => {
	const url = getRequestURLFromParams(ENDPOINTS.OVERALL_SUBMISSION);
	return axiosInstance()
		.post(url, { fileName, version })
		.catch((err) => {
			throw err;
		});
};
