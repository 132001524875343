import React, { FC, useEffect, useState } from 'react';
import './address-history.styles.scss';
import Layout from '../../components/general/layout-component/layout.component';
import AddressList from '../../components/address/address-list/address-list.component';
import AddressForm from '../../components/address/address-form/address-form.component';
import AddressDocument from '../../components/address/address-document/address-document.component';
import { IAddressHistoryItemDb } from '../../types/interfaces/applicant.interface';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../types/root-state';
import Modal from '../../components/modals/modal/modal.component';
import VModal from '../../components/modals/modal/vmodal.component';
import { getAddressHistory } from '../../api/requests/address.api';
import { setAddressHistory } from '../../redux/Applicant/applicant.actions';
import * as utils from '../../utils/utilsFunctions';
import { fireGaEvent } from '../../utils/ga-event';
import useFetchData from '../../utils/useFetchData';
import useGetHistoryDocs from '../../utils/useGetHistoryDocs';
import { useNavigate, useLocation } from 'react-router-dom';
import * as docsApi from '../../api/requests/documents.api';

const AddressHistory: FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { state } = useLocation();
	const isMobile = useSelector((state: RootState) => state.general.isMobile);

	const [refetchAddressDocs, setRefetchAddressDocs] = useState(false);

	const [showForm, setShowForm] = useState(false);
	const [showUpload, setShowUpload] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [pageName, setPageName] = useState<string | undefined>('');
	const [isCurrent, setIsCurrent] = useState<boolean>(false);
	const [pageOrigin, setPageOrigin] = useState('');
	const [hasFile, setHasFile] = useState(false);
	const [refetchAddressHistory, setRefetchAddressHistory] = useState(false);
	const [selectedAddress, setSelectedAddress] =
		useState<IAddressHistoryItemDb | null>(null);
	const addressHistory = useSelector(
		(state: RootState) => state.applicant.addressHistory,
	);

	const fetchedAddressHistory: IAddressHistoryItemDb[] = useFetchData(
		getAddressHistory,
		setAddressHistory,
		2,
		'addressHistory',
		refetchAddressHistory,
	);

	const setCurrent = () => {
		if (addressHistory && addressHistory.length > 0) {
			const timeSinceAddressStartDates: number[] = addressHistory?.reduce(
				(result, element) => {
					if (typeof element.startAt === 'string') {
						const timeSinceStartDate =
							Date.now() - Date.parse(element.startAt);
						result.push(timeSinceStartDate);
					}
					return result;
				},
				[] as number[],
			);

			const mostRecentStartDate = Math.min(...timeSinceAddressStartDates);
			const indexOfCurrent = addressHistory.findIndex((address) => {
				if (typeof address.startAt === 'string') {
					return (
						Date.now() - Date.parse(address.startAt) ===
						mostRecentStartDate
					);
				}
			});
			const updatedAddressHistory = addressHistory;
			updatedAddressHistory[indexOfCurrent].isCurrent = true;

			dispatch(setAddressHistory(updatedAddressHistory));
		}
	};

	useEffect(() => {
		if (fetchedAddressHistory) setAddressHistory(fetchedAddressHistory);
	}, [fetchedAddressHistory]);

	useEffect(() => {
		if (addressHistory) setCurrent();
	}, [addressHistory]);

	useGetHistoryDocs(refetchAddressDocs);
	const addressDocuments = useSelector(
		(state: RootState) => state.applicant.addressDocuments,
	);

	useEffect(() => {
		// this first conditional is like this as addressHistory array is
		// returned with an item in even before any addresses are added
		if (addressHistory && addressHistory.length <= 1) {
			setIsCurrent(true);
		} else if (
			addressHistory &&
			selectedAddress &&
			selectedAddress.isCurrent
		) {
			setIsCurrent(true);
		} else {
			setIsCurrent(false);
		}
	}, [addressHistory, selectedAddress]);

	useEffect(() => {
		utils.instantScroll();
		setPageName(utils.setPageName(state, navigate));
	}, []);

	useEffect(() => {
		addressDocuments && addressDocuments.length > 0
			? setHasFile(true)
			: setHasFile(false);
	}, [addressDocuments.length]);

	const handleBack = (origin: string) => {
		setPageOrigin('');
		switch (origin) {
			case 'form':
				fireGaEvent('Address History', 'Clicked Back from Form');
				setShowForm(false);
				setShowUpload(false);
				break;
			case 'upload':
				fireGaEvent(
					'Address History',
					'Clicked Back from Document Upload',
				);
				setShowForm(false);
				setShowUpload(false);
				break;
			default:
				fireGaEvent('Address History', 'Clicked Back from List');
				return navigate('/', { replace: true });
		}
	};

	const handleDeleteDocument = () => {
		const filteredAddress = addressHistory.filter((address) => {
			const keys = Object.keys(address);
			return keys.includes('uuid');
		});

		if (
			addressHistory &&
			addressHistory.length > 0 &&
			filteredAddress.length > 0
		) {
			if (typeof filteredAddress[0].uuid === 'string') {
				return docsApi
					.deleteDocument(
						addressDocuments[0].uuid,
						filteredAddress[0].uuid,
					)
					.then(() => {
						setRefetchAddressDocs(!refetchAddressDocs);
						utils.handleAlerts('success', 'Removed File', dispatch);
					})
					.catch((err: Error) => {
						handleErrors(err);
					});
			}
		}
	};

	const handleErrors = (err: Error) => {
		utils.handleAlerts('error', err.message, dispatch);
	};

	const handleSkip = () => {
		fireGaEvent(
			'Address History',
			`Clicked I'll Do This Later Button On Document Page`,
		);
		setShowUpload(false);
		setShowForm(false);
	};

	const handleComplete = () => {
		setShowUpload(false);
		setShowForm(false);
	};

	return (
		<div className='address-page'>
			<Layout background=''>
				{!showForm && !showUpload && (
					<AddressList
						handleBack={handleBack}
						pageName={'5 Year Address History'}
						isCurrent={isCurrent}
						setShowForm={setShowForm}
						hasFile={hasFile}
						setShowUpload={setShowUpload}
						setSelectedAddress={setSelectedAddress}
						setPageOrigin={setPageOrigin}
						setRefetchAddressDocs={setRefetchAddressDocs}
						refetchAddressDocs={refetchAddressDocs}
						handleDeleteDocument={handleDeleteDocument}
					/>
				)}

				{isMobile && (
					<>
						{showForm && !showUpload && (
							<AddressForm
								setShowForm={setShowForm}
								setShowUpload={setShowUpload}
								handleBack={handleBack}
								selectedAddress={selectedAddress}
								setSelectedAddress={setSelectedAddress}
								setRefetchAddressHistory={
									setRefetchAddressHistory
								}
								refetchAddressHistory={refetchAddressHistory}
								handleDeleteDocument={handleDeleteDocument}
								isCurrent={isCurrent}
							/>
						)}
						{!showForm && showUpload && (
							<VModal
								title='Upload document'
								setShowModal={setShowUpload}
								showModal={showUpload}
								closeFunction={handleBack}
								pageOrigin='upload'
								rightButtonLabel='Complete'
								rightButtonEvent={handleComplete}
								leftButtonLabel='Skip'
								leftButtonEvent={handleSkip}
							>
								<AddressDocument
									setShowForm={setShowForm}
									setShowUpload={setShowUpload}
									handleBack={handleBack}
									setHasFile={setHasFile}
									pageOrigin={pageOrigin}
								/>
							</VModal>
						)}
					</>
				)}
				{!isMobile && (
					<>
						{showForm && !showUpload && (
							<Modal
								title='Add address'
								setShowModal={setShowForm}
								showModal={showForm}
								closeFunction={handleBack}
								pageOrigin='form'
							>
								<AddressForm
									setShowForm={setShowForm}
									setShowUpload={setShowUpload}
									isCurrent={isCurrent}
									handleBack={handleBack}
									selectedAddress={selectedAddress}
									setSelectedAddress={setSelectedAddress}
									setRefetchAddressHistory={
										setRefetchAddressHistory
									}
									refetchAddressHistory={
										refetchAddressHistory
									}
									handleDeleteDocument={handleDeleteDocument}
								/>
							</Modal>
						)}

						{!showForm && showUpload && (
							<VModal
								title='Upload document'
								setShowModal={setShowUpload}
								showModal={showUpload}
								closeFunction={handleBack}
								pageOrigin='upload'
								rightButtonLabel='Complete'
								rightButtonEvent={handleComplete}
								leftButtonLabel='Skip'
								leftButtonEvent={handleSkip}
							>
								<AddressDocument
									setShowForm={setShowForm}
									setShowUpload={setShowUpload}
									handleBack={handleBack}
									setHasFile={setHasFile}
									pageOrigin={pageOrigin}
								/>
							</VModal>
						)}
					</>
				)}
			</Layout>
		</div>
	);
};

export default AddressHistory;
