import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
	useRef,
} from 'react';
import './activity-form.styles.scss';
import Header from '../../header/header/header.component';
import {
	addActivityItem,
	removeActivityItem,
	updateActivityItem,
} from '../../../redux/Applicant/applicant.actions';
import { useDispatch, useSelector } from 'react-redux';
import {
	IActivityFormState,
	IActivityItem,
	IAddress,
} from '../../../types/interfaces/applicant.interface';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import {
	IDropdownStandard,
	IOrganization,
} from '../../../types/interfaces/misc.interface';
import * as utils from '../../../utils/utilsFunctions';
import Input from '../../general/input/input.component';
import { RootState } from '../../../types/root-state';
import checkboxEmpty from '../../../assets/icons/misc/checkbox-empty.svg';
import checkboxTicked from '../../../assets/icons/misc/checkbox-filled.svg';
import * as validations from '../../../utils/validations';
import ValidationLayer from '../../general/validation-layer/validation-layer.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import * as api from '../../../api/requests/activity.api';
import * as activityFormHelpers from './activity-form.helpers';
import ActivityCompany from '../activity-company/activity-company.component';
import VerifiedField from '../../general/verified-field/verified-field.component';
import useInitActivityDropdown from '../useInitActivityDropdown.component';
import { fireGaEvent } from '../../../utils/ga-event';
import { validateActivityType } from './activity-form.helpers';
import AddressSearch from '../../address/address-search/address-search.component';
import GuideCard from '../activity-guide/guide-card.component';
import doc from '../../../assets/icons/misc/doc.svg';
import phone from '../../../assets/icons/misc/phone.svg';
import book from '../../../assets/icons/info-card/book.svg';
import AdvisoryCard from '../../advisory-card/advisory-card.component';
import InfoCard from '../../general/info-card/info-card.component';
import ActivityDocumentExamples from '../activity-documents/activity-document-example.component';
import moment from 'moment';

type Props = {
	handleBack: (origin: string) => void;
	setShowForm: Dispatch<SetStateAction<boolean>>;
	selectedActivity: IActivityItem | null;
	setShowUpload: Dispatch<SetStateAction<boolean>>;
	setShowReasons: Dispatch<SetStateAction<boolean>>;
	setSelectedActivity: Dispatch<
		SetStateAction<IActivityItem | null | IActivityFormState>
	>;
	isExternal?: boolean;
};

const ActivityForm: FC<Props> = ({
	handleBack,
	setShowForm,
	selectedActivity,
	setShowUpload,
	setShowReasons,
	setSelectedActivity,
	isExternal = false,
}) => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.applicant.applicant,
	);
	const isMobile = useSelector((state: RootState) => state.general.isMobile);

	const [formState, setFormState] = useState<any>(
		activityFormHelpers.defaultFormState(),
	);
	const [addressSelected, setAddressSelected] = useState<any>(null);

	const [isDisabled, setIsDisabled] = useState(false);
	const [validInputs, setValidInputs] = useState([
		{ startAt: true },
		{ endAt: true },
	]);
	const [dateValidation, setDateValidation] = useState('');
	const [requiredFields, setRequiredFields] = useState<string[]>([
		'type',
		'startAt',
	]);
	const [showAdvisory, setShowAdvisory] = useState<boolean>(false);
	const [checked, setChecked] = useState<boolean>(false);
	const [showFullForm, setShowFullForm] = useState(false);
	const [continueClicked, setContinueClicked] = useState(false);
	const [organisationSelected, setOrganisationSelected] =
		useState<IOrganization | null>(null);

	const [showDocExample, setShowDocExample] = useState<boolean>(false);

	const selectRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (selectedActivity && !selectedActivity.reference.addedManually) {
			const organisation = {
				company: selectedActivity.name,
				email: selectedActivity.email,
				address: selectedActivity?.address?.streetAddress,
				city: selectedActivity?.address?.city,
				country: selectedActivity?.address?.country,
				postcode: selectedActivity?.address?.postcode,
				region: selectedActivity?.address?.region,
			};
			setOrganisationSelected(organisation);
		}
	}, [selectedActivity]);

	useEffect(() => {
		utils.instantScroll();
		if (selectRef.current !== null) selectRef.current.scrollTop = 0;
	}, [formState.type]);

	useEffect(() => {
		const handleShowFullForm = (type: string) => {
			switch (type) {
				case 'employed':
				case 'studying':
				case 'volunteering':
					setShowFullForm(true);
					break;
				default:
					setShowFullForm(false);
					break;
			}
		};
		if (formState.type) {
			handleShowFullForm(formState.type);
			setRequiredFields(activityFormHelpers.getRequiredFields(formState));
		}
	}, [
		formState.type,
		formState.reference.addedManually,
		formState.careerBreakReasonType,
	]);

	useEffect(() => {
		const doNotShow = localStorage.getItem('doNotShow');
		if (doNotShow) setChecked(JSON.parse(doNotShow));
	}, []);

	const {
		defaultActivityHook,
		activityTypesHook,
		defaultWorkPattenHook,
		workPatternsHook,
		defaultCareerBreakReasonHook,
		careerBreakReasonsHook,
	} = useInitActivityDropdown(selectedActivity);

	const [defaultActivity, setDefaultActivity] = useState<IDropdownStandard>();
	const [defaultWorkPatten, setDefaultWorkPattern] =
		useState<IDropdownStandard>();
	const [defaultCareerBreakReason, setDefaultCareerBreakReason] =
		useState<IDropdownStandard>();

	fireGaEvent('Activity History', 'Activity History - Form Page');

	useEffect(() => {
		if (addressSelected) {
			setFormState({
				...formState,
				...addressSelected,
			});
		}
	}, [addressSelected]);

	useEffect(() => {
		const state =
			selectedActivity && !formState.uuid
				? activityFormHelpers.setValidatedFormState(selectedActivity)
				: formState;

		const { careerBreakReasonType, type } = validateActivityType(
			state.type,
			state.careerBreakReasonType,
		);
		state.type = type;
		state.careerBreakReasonType = careerBreakReasonType;

		const filteredActivity = activityTypesHook.filter(
			(item: IDropdownStandard) => {
				return item.dbValue === state.type;
			},
		);
		setDefaultActivity(filteredActivity[0]);

		if (organisationSelected) {
			setFormState({
				...state,
				name: organisationSelected.company,
				region: organisationSelected.region,
				email: organisationSelected.email,
				phone: organisationSelected.contactNumber,
				streetAddress: organisationSelected.address,
				city: organisationSelected.city,
				country: organisationSelected.country,
				postcode: organisationSelected.postcode,
				reference: {
					addedManually: false,
				},
			});
		} else {
			setFormState({
				...state,
				reference: {
					addedManually: true,
				},
			});
		}
	}, [activityTypesHook, organisationSelected, JSON.stringify(formState)]);

	const handleAddActivity = () => {
		setContinueClicked(true);
		setTimeout(() => {
			if (isDisabled) {
				fireGaEvent(
					'Activity Form',
					'Clicked to add activity item (validation failed)',
				);
				return;
			}
			fireGaEvent('Activity Form', 'Clicked to add activity item');
			return handlePostActivity();
		}, 500);
	};

	const handlePostActivity = () => {
		utils.instantScroll();
		return api
			.saveActivity(
				activityFormHelpers.formatPayload(
					formState,
					organisationSelected,
				),
			)
			.then((res: any) => {
				handleSuccessfulUpload(res);
			})
			.catch((err: Error) => {
				utils.handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	const handleSuccessfulUpload = (res: any) => {
		utils.handleAlerts('success', 'Successfully added activity', dispatch);
		const newItem = {
			...activityFormHelpers.formatPayload(
				formState,
				organisationSelected,
			),
			uuid: res.data.data.uuid,
		};
		dispatch(addActivityItem(newItem));
		setSelectedActivity({
			...selectedActivity,
			...newItem,
		});

		if (formState.type === 'employed' && formState.endAt) {
			setShowForm(false);
			setShowReasons(true);
		} else {
			setShowForm(false);
			setShowUpload(true);
		}
	};

	const handleUpdateActivity = () => {
		fireGaEvent('Activity Form', 'Clicked to update activity item');
		setContinueClicked(true);
		if (isDisabled) {
			return;
		}
		return api
			.updateActivity(
				activityFormHelpers.formatPayload(
					formState,
					organisationSelected,
				),
			)
			.then(() => {
				const newItem = {
					...activityFormHelpers.formatPayload(
						formState,
						organisationSelected,
					),
				};

				dispatch(updateActivityItem(newItem));
				setSelectedActivity({
					...selectedActivity,
					...newItem,
				});
				utils.handleAlerts(
					'success',
					'Successfully updated activity',
					dispatch,
				);
				setShowForm(false);
				formState.type === 'employed' && formState.endAt
					? setShowReasons(true)
					: setShowUpload(true);
			})
			.catch((err: Error) => {
				utils.handleAlerts(
					'error',
					'unable to update activity',
					dispatch,
				);
				throw err;
			});
	};

	const handleRemoveActivity = () => {
		fireGaEvent('Activity Form', 'Clicked to remove activity item');
		if (selectedActivity) {
			return api
				.deleteActivity(applicant.uuid, selectedActivity.uuid)
				.then(() => {
					utils.handleAlerts(
						'success',
						'Successfully removed activity',
						dispatch,
					);
					dispatch(removeActivityItem(selectedActivity));
					handleBack('form');
				})
				.catch((err: Error) => {
					utils.handleAlerts('error', err.message, dispatch);
					throw err;
				});
		}
	};

	const getDropdownVal = (val: IDropdownStandard) => {
		setContinueClicked(false);
		const selectedActivity = activityTypesHook.filter(
			(item: IDropdownStandard) => {
				return item.dbValue === val.dbValue;
			},
		);
		setDefaultActivity(selectedActivity[0]);
		setOrganisationSelected(null);
		const updatedState = {
			...formState,
			careerBreakReason:
				formState.type === 'career-break'
					? ''
					: formState.careerBreakReason,
			careerBreakReasonType:
				formState.type === 'career-break'
					? ''
					: formState.careerBreakReasonType,
			type: selectedActivity[0].dbValue,
			streetAddress: '',
			city: '',
			country: '',
			region: '',
			postcode: '',
			name: '',
			email: '',
		};
		setFormState(updatedState);
	};

	const getCareerBreakReasonDropdownVal = (val: IDropdownStandard) => {
		setContinueClicked(false);
		const careerBreakReason = careerBreakReasonsHook.filter(
			(item: IDropdownStandard) => {
				return item.dbValue === val.dbValue;
			},
		);
		setDefaultCareerBreakReason(careerBreakReason[0]);
		setFormState({
			...formState,
			careerBreakReasonType: val.dbValue,
			careerBreakReason: '',
		});
	};

	const getDropdownValPattern = (val: IDropdownStandard) => {
		setContinueClicked(false);
		const workPattern = workPatternsHook.filter(
			(item: IDropdownStandard) => {
				return item.dbValue === val.dbValue;
			},
		);
		setDefaultWorkPattern(workPattern[0]);
		setFormState({
			...formState,
			isPartTime: val.dbValue,
		});
	};

	useEffect(() => {
		if (formState.type === 'employed' && !formState.endAt) {
			setFormState({ ...formState, canContactEmployer: true });
		}
	}, [formState.endAt]);

	const handleCanContactEmployer = () => {
		setFormState({
			...formState,
			canContactEmployer: !formState.canContactEmployer,
		});
	};

	const handleActivityForm = (val: string, input: keyof IAddress) => {
		setFormState({
			...formState,
			[input]: val,
		});
	};

	const handleTextAreaChange = (val: any) => {
		setFormState({
			...formState,
			careerBreakReason: val,
		});
	};

	const handleBlur = (obj: { [key: string]: string }) => {
		const val: string = Object.values(obj)[0];
		if (!val.trim()) {
			const key: string = Object.keys(obj)[0];
			setFormState({
				...formState,
				[key]: '',
			});
		}
	};

	const hasError = (obj: any): boolean => {
		const errors = [];
		const required = [...requiredFields];

		for (const key in obj) {
			if (required.includes(key)) {
				if (obj[key] === null || obj[key] === '') {
					errors.push(obj);
				}
			}
		}
		return errors.length ? true : false;
	};

	const handleSelectedActivity = () => {
		if (selectedActivity) {
			handleUpdateActivity();
		} else {
			handleAddActivity();
		}
	};

	const handleNext = (): void => {
		const errors = hasError(formState);

		if (checked || errors) {
			handleSelectedActivity();
		} else {
			if (isDisabled) {
				return;
			}
			setShowAdvisory(true);
		}
	};

	const handleCheckbox = (e: any) => {
		localStorage.setItem('doNotShow', JSON.stringify({ state: !checked }));
		setChecked(e.target.checked);
	};

	const isActivityOlderThan31Days = (createdAt?: string): boolean => {
		if (!createdAt) {
			return false;
		}

		const thirtyOneDaysLater = utils.getDaysLater(createdAt, 31);

		return moment().isAfter(thirtyOneDaysLater);
	};

	return (
		<>
			<div className='activity-form-container'>
				<ValidationLayer
					setIsDisabled={setIsDisabled}
					requiredFields={requiredFields}
					requiredChecks={[
						'requiredFields',
						'dateComparison',
						'validInputs',
					]}
					formState={formState}
					validInputs={validInputs}
					setDateValidation={setDateValidation}
				>
					{isMobile && (
						<Header
							text={
								selectedActivity
									? 'Edit Activity'
									: 'Add Activity'
							}
							type='text'
							backFunction={() => handleBack('form')}
							origin='form'
							deleteFunction={
								selectedActivity &&
								!(
									isExternal &&
									isActivityOlderThan31Days(
										selectedActivity?.createdAt,
									)
								)
									? handleRemoveActivity
									: undefined
							}
							editMode={!!selectedActivity}
						/>
					)}

					<div ref={selectRef} className='contact-employer-checkbox'>
						{formState.type && (
							<GuideCard
								title='Provide reference details'
								titleIcon={doc}
								background='#D3F1EE'
								hasTag={false}
							>
								<p>
									It is important to provide contact
									information so we can contact your reference
									and prove this activity.{' '}
									<strong>
										All activities have to be checked.
									</strong>
								</p>
							</GuideCard>
						)}

						<div className='activity-dropdown'>
							<label>What were you doing?</label>
							{defaultActivityHook && (
								<Dropdown
									type='activityTypes'
									dropdownData={activityTypesHook}
									defaultData={
										defaultActivity
											? defaultActivity
											: defaultActivityHook
									}
									getDropdownVal={getDropdownVal}
									width='327'
									position='bottom-center'
									showValidationError={
										continueClicked && !formState.type
									}
								/>
							)}
						</div>

						<div className='activity-dropdown margin-top-40 margin-bottom-40'>
							{defaultCareerBreakReasonHook &&
								defaultActivity &&
								defaultActivity.dbValue === 'career-break' && (
									<>
										<label>
											Select one of the following
										</label>
										<Dropdown
											type='careerBreakReasons'
											dropdownData={
												careerBreakReasonsHook
											}
											defaultData={
												defaultCareerBreakReason
													? defaultCareerBreakReason
													: defaultCareerBreakReasonHook
											}
											getDropdownVal={
												getCareerBreakReasonDropdownVal
											}
											width='327'
											position='bottom-center'
											showValidationError={
												continueClicked &&
												!formState.careerBreakReasonType
											}
										/>
										{formState.careerBreakReasonType ===
											'another-reason' && (
											<div className='margin-top-40 margin-bottom-40'>
												<Input
													inputName='careerBreakReason'
													title='Please give a detailed
                                                description of why you are
                                                taking a career break'
													placeholder={
														'Please provide some extra details'
													}
													value={
														formState.careerBreakReason
															? formState.careerBreakReason
															: ''
													}
													isTextArea={true}
													emitValue={
														handleTextAreaChange
													}
													required={true}
													continueClicked={
														continueClicked
													}
													handleBlur={handleBlur}
												/>
											</div>
										)}
									</>
								)}
						</div>

						<div className='activity-form-section'>
							{showFullForm && (
								<ActivityCompany
									name={formState.name}
									handleActivityForm={handleActivityForm}
									setOrganisationSelected={
										setOrganisationSelected
									}
									organisationSelected={organisationSelected}
									continueClicked={continueClicked}
								/>
							)}
							{!formState.reference.addedManually && (
								<VerifiedField
									label={'Region'}
									field={formState.region}
								/>
							)}
							{formState.type === 'employed' && (
								<Input
									inputName='jobTitle'
									title='Job title'
									placeholder='Door supervisor'
									value={
										formState.jobTitle
											? formState.jobTitle
											: ''
									}
									emitValue={handleActivityForm}
									required={true}
									continueClicked={continueClicked}
								/>
							)}

							{showFullForm && (
								<>
									{!formState.reference.addedManually ? (
										<VerifiedField
											label={'Organisation email'}
											field={formState.email}
										/>
									) : (
										<Input
											inputName='email'
											title='Organisation email'
											placeholder='references@exemple.com'
											value={formState.email}
											emitValue={handleActivityForm}
											type='email'
											required={true}
											continueClicked={continueClicked}
										/>
									)}
								</>
							)}

							<Input
								inputName='startAt'
								title='Start date'
								placeholder='dd/mm/yyyy'
								value={formState.startAt}
								emitValue={handleActivityForm}
								isDate={true}
								validationFunction={validations.dateValidation}
								validInputs={validInputs}
								setValidInputs={setValidInputs}
								required={true}
								continueClicked={continueClicked}
							/>

							<div className='end-date'>
								<p className='leave-blank'>
									(You can leave blank if this is current)
								</p>
								<Input
									inputName='endAt'
									title='End date'
									placeholder='dd/mm/yyyy'
									value={formState.endAt}
									emitValue={handleActivityForm}
									isDate={true}
									validationFunction={
										validations.dateValidation
									}
									validInputs={validInputs}
									setValidInputs={setValidInputs}
								/>
							</div>
							{dateValidation && (
								<div className='validation-error'>
									<p>{dateValidation}</p>
								</div>
							)}

							{formState.type === 'employed' &&
								!formState.endAt && (
									<div className='can-contact'>
										<p>
											Please tick if you do not want us to
											contact your current employer.
										</p>
										<div className='checkbox-control'>
											<img
												src={
													formState.canContactEmployer
														? checkboxEmpty
														: checkboxTicked
												}
												alt='checkbox'
												role='checkbox'
												aria-checked={
													formState.canContactEmployer
												}
												onClick={
													handleCanContactEmployer
												}
											/>
											<span>Do not contact</span>
										</div>
									</div>
								)}

							{showFullForm &&
								formState.reference.addedManually && (
									<>
										<AddressSearch
											streetAddress={
												formState.streetAddress
											}
											handleAddressForm={
												handleActivityForm
											}
											continueClicked={continueClicked}
											setAddressSelected={
												setAddressSelected
											}
											addressSelected={addressSelected}
										/>

										<Input
											inputName='city'
											title='Town or city'
											placeholder='Preston'
											value={formState.city}
											emitValue={handleActivityForm}
											required={true}
											continueClicked={continueClicked}
										/>

										<Input
											inputName='region'
											title='Region'
											placeholder='North West'
											value={formState.region}
											emitValue={handleActivityForm}
											required={true}
											continueClicked={continueClicked}
										/>

										<Input
											inputName='postcode'
											title='Postcode'
											placeholder='PR14 7VN'
											value={formState.postcode}
											emitValue={handleActivityForm}
											required={true}
											continueClicked={continueClicked}
										/>

										<Input
											inputName='country'
											title='Country'
											placeholder='United Kingdom'
											value={formState.country}
											emitValue={handleActivityForm}
											required={true}
											continueClicked={continueClicked}
										/>
									</>
								)}

							{formState.type === 'employed' && (
								<div className='activity-dropdown margin-top-40 margin-bottom-40'>
									<label>Full time / part time</label>
									{defaultWorkPattenHook && (
										<Dropdown
											type='workPatterns'
											dropdownData={workPatternsHook}
											defaultData={
												defaultWorkPatten
													? defaultWorkPatten
													: defaultWorkPattenHook
											}
											getDropdownVal={
												getDropdownValPattern
											}
											width='327'
											position='top-center'
										/>
									)}
								</div>
							)}
						</div>
					</div>
					<div className='page-form-footer-outer-container remove-fixed-footer'>
						<div className='page-form-footer-inner-container'>
							<OrkaButton
								buttonContent='Next'
								disabled={false}
								emitClicked={handleNext}
								buttonClass='purple-filled'
							/>
							{selectedActivity &&
								!isMobile &&
								!(
									isExternal &&
									isActivityOlderThan31Days(
										selectedActivity?.createdAt,
									)
								) && (
									<OrkaButton
										buttonContent='Delete'
										disabled={false}
										emitClicked={handleRemoveActivity}
										buttonClass='purple-outline'
									/>
								)}
						</div>
					</div>
				</ValidationLayer>
				{showAdvisory && (
					<AdvisoryCard
						title='Verifying this activity'
						buttonLabel='Next'
						setShowAdvisory={setShowAdvisory}
						onClick={handleSelectedActivity}
					>
						<GuideCard
							title='Call HMRC (for free!)'
							titleIcon={phone}
							background='#F8C96A32'
							hasTag={false}
						>
							<p>
								If you have employment in the UK you can request
								proof of this by calling{' '}
								<a href='tel:03002003300'>
									<strong>0300 200 3300</strong>
								</a>
							</p>
						</GuideCard>

						<div className='advisory-content'>
							<p>
								HMRC will send you a document in the mail that
								you can then take a picture and upload to Deploi
								 on the activity page or email it to us at
								{` `}
								<strong>
									<a href='mailto:candidates@deploi.uk'>
									candidates@deploi.uk
									</a>
								</strong>
								.
							</p>
							<a
								href='/check/example-document'
								target='_blank'
								rel='noreferrer'
							>
								Show example document
							</a>

							<InfoCard
								size='small'
								text='If all your activities are all employment in the UK, this document is all you will need for us to verify you. Easy!'
								icon={book}
								withPointer={false}
							/>

							<div className='checkbox-container'>
								<div className='advisory-checkbox-control'>
									<input
										id='advisory-checkbox'
										type='checkbox'
										name='advisory-checkbox'
										checked={checked}
										aria-hidden={true}
										onChange={handleCheckbox}
									/>
								</div>
								<label htmlFor='advisory-checkbox'>
									Do not show again
								</label>
							</div>
						</div>
					</AdvisoryCard>
				)}
			</div>
			{showDocExample && (
				<ActivityDocumentExamples
					setShowDocExample={setShowDocExample}
				/>
			)}
		</>
	);
};

export default ActivityForm;
