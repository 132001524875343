import './check-passport-address-history.styles.scss';

import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAddressHistory } from '../../../api/requests/address.api';
import { setAddressHistory } from '../../../redux/Applicant/applicant.actions';
import { IAddressHistoryItemDb } from '../../../types/interfaces/applicant.interface';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../types/root-state';
import * as docsApi from '../../../api/requests/documents.api';
import * as utils from '../../../utils/utilsFunctions';
import useGetHistoryDocs from '../../../utils/useGetHistoryDocs';
import useFetchData from '../../../utils/useFetchData';

import AddressForm from '../../../components/address/address-form/address-form.component';
import Modal from '../../../components/modals/modal/modal.component';
import AddressList from '../../address/address-list/address-list.component';
import CheckPassportAddressDocument from '../check-passport-address-document/check-passport-address-document.component';
import VModal from '../../modals/modal/vmodal.component';

const CheckPassportAddressHistory: FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const addressHistory = useSelector(
		(state: RootState) => state.applicant.addressHistory,
	);

	const isMobile = useSelector((state: RootState) => state.general.isMobile);

	const [refetchAddressDocs, setRefetchAddressDocs] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [showUpload, setShowUpload] = useState(false);
	const [isCurrent, setIsCurrent] = useState<boolean>(false);
	const [pageOrigin, setPageOrigin] = useState('');
	const [hasFile, setHasFile] = useState(false);
	const [refetchAddressHistory, setRefetchAddressHistory] = useState(false);
	const [selectedAddress, setSelectedAddress] =
		useState<IAddressHistoryItemDb | null>(null);

	const fetchedAddressHistory: IAddressHistoryItemDb[] = useFetchData(
		getAddressHistory,
		setAddressHistory,
		2,
		'addressHistory',
		refetchAddressHistory,
	);

	const setCurrent = () => {
		if (addressHistory && addressHistory.length > 0) {
			const timeSinceAddressStartDates: number[] = addressHistory?.reduce(
				(result, element) => {
					if (typeof element.startAt === 'string') {
						const timeSinceStartDate =
							Date.now() - Date.parse(element.startAt);
						result.push(timeSinceStartDate);
					}
					return result;
				},
				[] as number[],
			);

			const mostRecentStartDate = Math.min(...timeSinceAddressStartDates);
			const indexOfCurrent = addressHistory.findIndex((address) => {
				if (typeof address.startAt === 'string') {
					return (
						Date.now() - Date.parse(address.startAt) ===
						mostRecentStartDate
					);
				}
			});

			if (indexOfCurrent !== -1) {
				const updatedAddressHistory = addressHistory;
				updatedAddressHistory[indexOfCurrent].isCurrent = true;

				dispatch(setAddressHistory(updatedAddressHistory));
			}
		}
	};

	useEffect(() => {
		if (fetchedAddressHistory) setAddressHistory(fetchedAddressHistory);
	}, [fetchedAddressHistory]);

	useEffect(() => {
		if (addressHistory) setCurrent();
	}, [addressHistory]);

	useGetHistoryDocs(refetchAddressDocs);
	const addressDocuments = useSelector(
		(state: RootState) => state.applicant.addressDocuments,
	);

	useEffect(() => {
		if (addressHistory && addressHistory.length <= 1) {
			setIsCurrent(true);
		} else if (
			addressHistory &&
			selectedAddress &&
			selectedAddress.isCurrent
		) {
			setIsCurrent(true);
		} else {
			setIsCurrent(false);
		}
	}, [addressHistory, selectedAddress]);

	useEffect(() => {
		addressDocuments && addressDocuments.length > 0
			? setHasFile(true)
			: setHasFile(false);
	}, [addressDocuments.length]);

	const handleBack = (origin: string) => {
		setPageOrigin('');
		switch (origin) {
			case 'form':
				setShowForm(false);
				setShowUpload(false);
				break;
			case 'upload':
				setShowForm(false);
				setShowUpload(false);
				break;
			default:
				return navigate('/check/update-other', { replace: true });
		}
	};

	const handleDeleteDocument = () => {
		const filteredAddress = addressHistory.filter((address) => {
			const keys = Object.keys(address);
			return keys.includes('uuid');
		});
		if (
			addressHistory &&
			addressHistory.length > 0 &&
			filteredAddress.length > 0
		) {
			if (typeof filteredAddress[0].uuid === 'string') {
				return docsApi
					.deleteDocument(
						addressDocuments[0].uuid,
						filteredAddress[0].uuid,
					)
					.then(() => {
						setRefetchAddressDocs(!refetchAddressDocs);
						utils.handleAlerts('success', 'Removed File', dispatch);
					})
					.catch((err: Error) => {
						handleErrors(err);
					});
			}
		}
	};

	const handleErrors = (err: Error) => {
		utils.handleAlerts('error', err.message, dispatch);
	};

	const handleComplete = () => {
		setShowUpload(false);
		setShowForm(false);
	};

	return (
		<div className='check-passport-address-history-container'>
			{!showForm && !showUpload && (
				<AddressList
					handleBack={handleBack}
					pageName=''
					isCurrent={isCurrent}
					setShowForm={setShowForm}
					hasFile={hasFile}
					setShowUpload={setShowUpload}
					setSelectedAddress={setSelectedAddress}
					setPageOrigin={setPageOrigin}
					setRefetchAddressDocs={setRefetchAddressDocs}
					refetchAddressDocs={refetchAddressDocs}
					handleDeleteDocument={handleDeleteDocument}
					redirectUrl='/check/update-other'
				/>
			)}

			{isMobile && (
				<>
					{showForm && !showUpload && (
						<AddressForm
							setShowForm={setShowForm}
							setShowUpload={setShowUpload}
							handleBack={handleBack}
							selectedAddress={selectedAddress}
							setSelectedAddress={setSelectedAddress}
							setRefetchAddressHistory={setRefetchAddressHistory}
							refetchAddressHistory={refetchAddressHistory}
							handleDeleteDocument={handleDeleteDocument}
							isCurrent={isCurrent}
						/>
					)}
					{!showForm && showUpload && (
						<CheckPassportAddressDocument
							setShowForm={setShowForm}
							setShowUpload={setShowUpload}
							handleComplete={handleComplete}
							setHasFile={setHasFile}
							pageOrigin={pageOrigin}
						/>
					)}
				</>
			)}
			{!isMobile && (
				<>
					{showForm && !showUpload && (
						<Modal
							title='Add Address'
							setShowModal={setShowForm}
							showModal={showForm}
							closeFunction={handleBack}
							pageOrigin='form'
						>
							<AddressForm
								setShowForm={setShowForm}
								setShowUpload={setShowUpload}
								isCurrent={isCurrent}
								handleBack={handleBack}
								selectedAddress={selectedAddress}
								setSelectedAddress={setSelectedAddress}
								setRefetchAddressHistory={
									setRefetchAddressHistory
								}
								refetchAddressHistory={refetchAddressHistory}
								handleDeleteDocument={handleDeleteDocument}
							/>
						</Modal>
					)}

					{!showForm && showUpload && (
						<VModal
							title='Upload Document'
							setShowModal={setShowUpload}
							showModal={showUpload}
							closeFunction={handleBack}
							pageOrigin='upload'
							rightButtonLabel='Submit'
							rightButtonEvent={handleComplete}
						>
							<CheckPassportAddressDocument
								setShowForm={setShowForm}
								setShowUpload={setShowUpload}
								handleComplete={handleComplete}
								setHasFile={setHasFile}
								pageOrigin={pageOrigin}
							/>
						</VModal>
					)}
				</>
			)}
		</div>
	);
};

export default CheckPassportAddressHistory;
