import moment from 'moment';
import * as data from './data.js';
import {
	ICheckboxAnswer,
	ICheckboxData,
	StepStatusEnum,
} from '../types/interfaces/misc.interface';
import { dropdownData } from './data.js';
import { setAlert, updateSteps } from '../redux/General/general.actions';
import { setConsentModalState } from '../redux/Applicant/applicant.actions';
import { fireGaEvent } from './ga-event.ts';
import * as miscApi from '../api/requests/misc.api';
import { LogLevel } from '@deploi-uk/package-monitoring/lib/enums/log-level.enum.js';
import { LogApplicationOrkaCheck } from '@deploi-uk/package-monitoring/lib/enums/log-application-orka-check.enum.js';
import { createLog } from '@deploi-uk/package-monitoring/lib/log.js';
import { sendLog } from '../api/requests/misc.api';

export const getFullName = (title, name, surname) => {
	const fullName = `${title || ''} ${name} ${surname}`;
	return fullName.trim();
};

export const getFullNameHistory = (forename, middleName, surname) => {
	return `${forename} ${middleName ? middleName + ' ' : ''}${surname}`;
};

export const formatDateForUser = (date) => {
	const newDate = new Date(date);

	return newDate instanceof Date && !isNaN(newDate) && date !== null
		? moment(newDate).format('DD/MM/YYYY')
		: 'N/A';
};

export const formatDateForDb = (date) => {
	if (!date) {
		return null;
	}
	return moment(new Date(date)).format('YYYY/MM/DD');
};

export const formatDateWithHyphen = (date) => {
	if (!date) {
		return null;
	}
	return moment(new Date(date)).format('YYYY-MM-DD');
};

// inputDate must be in the format YYYY-MM-DD
export const formatDateToDayMonthYearFormat = (inputDate) => {
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const dateParts = inputDate.split('-');
	const year = dateParts[0];
	const month = months[parseInt(dateParts[1], 10) - 1];
	const day = parseInt(dateParts[2], 10);

	return `${day} ${month} ${year}`;
};

export const refactorHyphenDate = (date) => {
	if (!date) {
		return null;
	}
	const [year, month, day] = date.split('-');
	return `${day}/${month}/${year}`;
};

export const getDropdownData = (type, applicant) => {
	return data.dropdownData[type];
};

const mergeArray = (dropDownData, type, data) => {
	let newData = dropDownData;
	return newData;
};

export const sortByDate = (array) => {
	return array.sort((a, b) => {
		return +new Date(b.startAt) - +new Date(a.startAt);
	});
};

export const removeHyphen = (string) => {
	if (!string) {
		return;
	}
	return string.replace(/-/g, ' ');
};

export const getTenDaysLater = (date) => {
	return moment(date).add(84, 'days').format('DD/MM/YYYY');
};
export const getDaysLater = (date, days) => {
	return moment(date).add(days, 'days');
};

export const capitalizeFirstLetter = (string) => {
	if (!string) {
		return;
	}
	return string[0].toUpperCase() + string.substring(1);
};

export const capitalizeFirstLetterOfEach = (string) => {
	if (!string) {
		return;
	}
	return string
		.split(' ')
		.map((item) => capitalizeFirstLetter(item))
		.join(' ');
};

export const handleSelection = (
	checkBoxAnswer,
	question,
	checkboxData,
	setCheckboxData,
) => {
	const newCheckboxData = checkboxData.map((checkboxData) => {
		if (checkboxData.question === question) {
			handleCheckboxChange(checkboxData, checkBoxAnswer);
		}
		return checkboxData;
	});
	setCheckboxData(newCheckboxData);
};

const handleCheckboxChange = (checkboxData, checkboxAnswer) => {
	checkboxData.answers = checkboxData.answers.map((mappedCheckboxAnswer) => {
		if (checkboxAnswer.id === mappedCheckboxAnswer.id) {
			mappedCheckboxAnswer.selected = !checkboxAnswer.selected;
		} else {
			mappedCheckboxAnswer.selected = false;
		}
		return mappedCheckboxAnswer;
	});
	return checkboxData;
};

export const handleNext = (
	val,
	dispatch,
	setConsentModalState,
	applicationStatus,
	navigate,
) => {
	instantScroll();
	if (applicationStatus === 'not-started') {
		navigate('/check/terms', { replace: true });
		return;
	}
	fireGaEvent('Home', `Taps on ${val} card`);
	switch (val) {
		case 'General Information':
			navigate('/check/general-information', {
				state: {
					page: 'General Information',
				},
				replace: true,
			});
			break;
		case 'NI Number':
			navigate('/check/ni-number', {
				state: {
					page: 'NI Number',
				},
				replace: true,
			});
			break;
		case 'Name History':
			navigate('/check/name-history', {
				state: {
					page: 'Name History',
				},
				replace: true,
			});
			break;
		case 'Declaration':
			navigate('/check/declaration', {
				state: {
					page: 'Declaration',
				},
				replace: true,
			});
			break;
		case 'Licences':
			navigate('/check/licences', {
				state: {
					page: 'Licences',
				},
				replace: true,
			});
			break;
		case 'Address History':
			navigate('/check/address-history', {
				state: {
					page: 'Address History',
				},
				replace: true,
			});
			break;
		case 'Activity History':
			navigate('/check/activity-history', {
				state: {
					page: 'Activity History',
				},
				replace: true,
			});
			break;
		default:
			return;
	}
};

export const instantScroll = () => {
	history.scrollRestoration = 'manual';
	window.scroll({ top: 0, left: 0, behavior: 'instant' });
};

export const handleAlerts = (type, message, dispatch) => {
	dispatch(setAlert({ type, message: message, isVisible: true }));
};

export const setPageName = (state, navigate) => {
	if (state && state.page) {
		return state.page;
	} else {
		navigate('/', { replace: true });
	}
};

export const splitCamelCase = (value) => {
	return value.replace(/([a-z](?=[A-Z]))/g, '$1 ');
};

export const getValidatedKey = (stepKey) => {
	const splitKey = splitCamelCase(stepKey);
	return splitKey[0].toUpperCase() + splitKey.substring(1);
};

export const validateStepForDb = (step) => {
	const joinedStep = step.split(' ').join('');
	return joinedStep[0].toLowerCase() + joinedStep.substring(1);
};

export const resetSectionSubmission = (historyEnum, dispatch) => {
	return miscApi
		.postStep({ step: historyEnum, status: StepStatusEnum.inProgress })
		.then(() => {
			dispatch(updateSteps(historyEnum, 'in-progress'));
		})
		.catch((err) => {
			throw err;
		});
};

export const dataURItoBlob = (dataURI) => {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
	var byteString = atob(dataURI.split(',')[1]);

	// separate out the mime component
	var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to an ArrayBuffer
	var ab = new ArrayBuffer(byteString.length);

	// create a view into the buffer
	var ia = new Uint8Array(ab);

	// set the bytes of the buffer to the correct values
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	// write the ArrayBuffer to a blob, and you're done
	var blob = new Blob([ab], { type: mimeString });
	return blob;
};

export const base64toBlob = (dataURI) => {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
	const byteString = atob(dataURI.split(',')[1]);
	// separate out the mime component
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	// write the bytes of the string to an ArrayBuffer
	const ab = new ArrayBuffer(byteString.length);
	// create a view into the buffer
	const ia = new Uint8Array(ab);
	// set the bytes of the buffer to the correct values
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	// write the ArrayBuffer to a blob, and you're done
	const blob = new Blob([ab], { type: mimeString });
	return blob;
};

export const changeStepTitle = (array, currentValue, newValue) => {
	const result = array.map((item) => {
		if (item.title === currentValue) item.title = newValue;
		return item;
	});
	return result;
};

// Get the last compliance date (7 days post application)
export const getLastComplianceDate = (date, days) => {
	const complianceDate = new Date(date);
	complianceDate.setDate(complianceDate.getDate() + days);
	return complianceDate;
};

// Get how many days left before compliance expires
export const getNumberOfDaysLeft = (expiryDate) => {
	const now = new Date();
	const diff = new Date(expiryDate).getTime() - now.getTime();
	return Math.ceil(diff / (1000 * 3600 * 24));
};

export const getNumberOfDaysLeftFromClearedDate = (clearedDate) => {
	const formattedClearedDate = new Date(clearedDate);
	formattedClearedDate.setDate(formattedClearedDate.getDate() + 82);

	const now = new Date();
	const diff = new Date(formattedClearedDate).getTime() - now.getTime();

	return Math.ceil(diff / (1000 * 3600 * 24));
};

export const intercomShareCodeLink = () => {
	window.location.href =
		'https://intercom.help/orkaworks/en/articles/6550115-right-to-work-share-codes';
};

export const getPassportUpdateByDate = (fullyClearedDate) => {
	const clearedDate = moment(fullyClearedDate);
	const updatedDate = clearedDate.add(90, 'days');
	return updatedDate.format('DD MMM YYYY');
};

export const dayStatus = () => {
	const today = new Date();
	const curHr = today.getHours();

	if (curHr < 12) {
		return 'Morning';
	} else if (curHr < 18) {
		return 'Afternoon';
	} else {
		return 'Evening';
	}
};

export const getLatestActivity = (activityHistory) => {
	// Filtering out any activities with type 'gap'.
	const validActivities = activityHistory.filter(
		(activity) => activity.type !== 'gap',
	);

	// Checking if there's any activity with endAt being null.
	const activityWithNoEnd = validActivities.find(
		(activity) => activity.endAt === null,
	);
	if (activityWithNoEnd) {
		return activityWithNoEnd;
	}

	// If no activity with null endAt is found, sort by endAt in descending order.
	validActivities.sort((a, b) => {
		// Safely handling potential null values for endAt.
		const aEnd = a.endAt ? new Date(a.endAt).getTime() : 0;
		const bEnd = b.endAt ? new Date(b.endAt).getTime() : 0;

		if (bEnd - aEnd !== 0) {
			return bEnd - aEnd; // Sort in descending order of end dates.
		}

		// If the end dates are the same, use startAt as a tiebreaker.
		const aStart = a.startAt ? new Date(a.startAt).getTime() : 0;
		const bStart = b.startAt ? new Date(b.startAt).getTime() : 0;
		return bStart - aStart;
	});

	return validActivities[0];
};

export const getLatestAddress = (addressHistory) => {
	if (!addressHistory || addressHistory.length === 0) return null;

	let latestAddress = addressHistory.find(
		(address) => address.endAt === null,
	);

	if (!latestAddress) {
		const sortedAddresses = [...addressHistory].sort((a, b) => {
			if (a.endAt && b.endAt) {
				return (
					new Date(b.endAt).getTime() - new Date(a.endAt).getTime()
				);
			}
			return 0;
		});

		latestAddress = sortedAddresses[0];
	}

	return latestAddress;
};

export const logData = (
	logName,
	level, // 'CRITICAL' | 'ERROR' | 'WARNING' | 'DEBUG' | 'INFO',
	logIdentifier = '',
	stackError,
	applicantWorkerUuid = 'None',
) => {
	const data = {
		applicantUuid: applicantWorkerUuid,
		level: LogLevel[level],
		message: logName,
		application: LogApplicationOrkaCheck.NON_WORKS_JOURNEY,
		environment: capitalizeFirstLetter(process.env.REACT_APP_ENV),
		time: new Date().toISOString(),
		logIdentifier: logIdentifier,
		version: process.env.REACT_APP_VERSION
			? process.env.REACT_APP_VERSION
			: '',
		context: window.location.href,
		error: {
			stack: JSON.stringify(stackError),
		},
	};
	const log = createLog(data);
	sendLog(JSON.stringify(log));
};
