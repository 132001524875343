import './check-passport-home-card.styles.scss';

import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import orkaPassportCardLogoBackground from '../../../assets/logos/orka-passport-card-logo-bg.svg';
import orkaPassportCardLogoBackgroundGrey from '../../../assets/logos/orka-passport-card-logo-bg-grey.svg';
import chevronDown from '../../../assets/icons/misc/chevron-up-light.svg';
import chevronUp from '../../../assets/icons/misc/chevron-down-light.svg';
import deploiLogo from '../../../assets/logos/deploi-logo-sm-white.svg';

type PassportCardDetails = {
	niNumber: string;
	candidateFullName: string;
	DOB: string;
	renewalDaysLeft: string;
	renewalByDate: string | Date;
	updateRequired: boolean;
	submissionStatus: string;
	isDeactivated: boolean;
};

const CheckPassportHomeCard: FC<PassportCardDetails> = ({
	niNumber,
	candidateFullName,
	DOB,
	renewalDaysLeft,
	renewalByDate,
	updateRequired,
	submissionStatus,
	isDeactivated,
}) => {
	const navigate = useNavigate();

	const [isCardExpanded, setCardExpanded] = useState(false);

	const getDaysInfo = (daysLeft: string) => {
		const matches = daysLeft.match(/(\d+) (days?)/);
		if (matches) {
			const [, daysValue, daysWord] = matches;
			return { daysValue, daysWord };
		}
		return { daysValue: '', daysWord: '' };
	};

	const { daysValue, daysWord } = getDaysInfo(renewalDaysLeft);

	const getDaysLeftClass = (daysLeft: string) => {
		if (daysLeft.includes('overdue')) return 'days-overdue';
		const daysAsNumber = parseInt(daysValue, 10);
		return daysAsNumber <= 30 ? 'days-below-thirty' : 'days-above-thirty';
	};

	const daysLeftClass = getDaysLeftClass(renewalDaysLeft);

	const toggleCardExpansion = () => {
		setCardExpanded(!isCardExpanded);
	};

	const handleUpdateClick = () => {
		navigate('/check/update-home');
	};

	const renderRenewalDetails = () =>
		daysLeftClass === 'days-overdue' ? (
			<>
				<p>Renewal overdue by</p>
				<p
					className={`renewal-days-left ${daysLeftClass}`}
				>{`${daysValue} ${daysWord}`}</p>
				<p>since</p>
				<p className='renewal-by-date'>
					{renewalByDate instanceof Date
						? renewalByDate.toDateString() // Format Date case
						: renewalByDate}
				</p>
			</>
		) : (
			<>
				<p>Renewal due in</p>
				<p
					className={`renewal-days-left ${daysLeftClass}`}
				>{`${daysValue} ${daysWord}`}</p>
				<p>on</p>
				<p className='renewal-by-date'>
					{renewalByDate instanceof Date
						? renewalByDate.toDateString() // Format Date case
						: renewalByDate}
				</p>
			</>
		);

	return (
		<div className='passport-home-card-container'>
			<div
				className={`passport-home-card ${
					isDeactivated ? 'deactivated' : ''
				} ${isDeactivated || isCardExpanded ? 'expanded' : ''}`}
			>
				<div className='card-top-line-container'>
					<p className='ni-number'>{niNumber}</p>
					<div className='passport-logo'>
						<p> PASSPORT </p>
						<img
							src={deploiLogo}
							alt='deploi passport logo'
							className='passport-card-logo'
						/>
					</div>
				</div>
				<div
					className={`card-middle-line-container ${
						isDeactivated || isCardExpanded ? 'visible' : ''
					}`}
				>
					{(isDeactivated || isCardExpanded) && (
						<>
							<p className='candidate-name'>
								{candidateFullName}
							</p>
							<p className='candidate-dob'>{DOB}</p>
						</>
					)}
				</div>
				<div className='card-bottom-line-container'>
					{isDeactivated ? (
						<p className='deactivated-text'>
							You cannot update your passport right now. Should we
							need you to update anything, we will get in touch.
						</p>
					) : (
						renderRenewalDetails()
					)}
				</div>
				{!isDeactivated && (
					<img
						src={isCardExpanded ? chevronDown : chevronUp}
						alt='toggle card size'
						className='toggle-chevron'
						onClick={toggleCardExpansion}
					/>
				)}
			</div>
			{!isDeactivated && updateRequired === true && (
				<div className='update-required' onClick={handleUpdateClick}>
					<p className='update-required-text'>
						Click here to update your passport
					</p>
				</div>
			)}
			{!isDeactivated &&
				updateRequired === false &&
				submissionStatus === 'pending' && (
					<div className='in-review'>
						<p className='in-review-text'>In Review</p>
					</div>
				)}
		</div>
	);
};

export default CheckPassportHomeCard;
