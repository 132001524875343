import React, { FC } from 'react';
import './action-info-card.styles.scss';
import chevronRight from '../../activity/activity-submission/assets/icons/chevron-right.svg';

type Props = {
	text: string | null;
	emphasisText?: string | null;
	subText: string | null;
	clickFunction: ((showModal: boolean) => void) | null | undefined;
};

const ActionInfoCard: FC<Props> = ({
	text,
	emphasisText,
	subText,
	clickFunction,
}) => {
	return (
		<article
			className='action-info-card-container'
			onClick={() => clickFunction && clickFunction(true)}
		>
			<div className='action-info-card-content'>
				<div className='action-info-card-text'>
					<div className='action-info-card-top-text'>
						<p>
							<span>{text}</span>
							{emphasisText && <span> </span>}
							{emphasisText && (
								<span className='action-info-card-emphasis-text'>{`${emphasisText}`}</span>
							)}
						</p>
					</div>
					<br />
					<div className='action-info-card-bottom-text'>
						<p>{subText}</p>
					</div>
				</div>
				<div className='action-info-card-chevron'>
					<img src={chevronRight} alt='chevron' />
				</div>
			</div>
		</article>
	);
};

export default ActionInfoCard;
