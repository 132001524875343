import React, { FC, Dispatch, SetStateAction, useState, useRef } from 'react';
import OrkaButton from '../../general/orka-button/orka-button.component';
import './activity-guide.styles.scss';
import backBlue from '../../../assets/icons/misc/back-blue.svg';

// Pages
import PageOne from './pages/pageOne.component';
import PageTwo from './pages/pageTwo.component';
import PageThree from './pages/pageThree.component';
import { useNavigate } from 'react-router-dom';
import { useScrollLock } from '../../../hooks';

// Types
type Props = {
	setShowGuide: Dispatch<SetStateAction<boolean>>;
};

const ActivityGuide: FC<Props> = ({ setShowGuide }) => {
	const [page, setPage] = useState<number>(0);

	const navigate = useNavigate();
	const { enableScroll } = useScrollLock();
	const mainRef = useRef<HTMLDivElement>(null);

	const handleNext = (): void => {
		if (mainRef.current !== null) mainRef.current.scrollTop = 0;
		if (page === pages.length - 1) {
			localStorage.setItem('guideState', JSON.stringify({ read: true }));
			setShowGuide(false);
		} else {
			setPage((current) => current + 1);
		}
	};
	const handleBack = (): void => {
		if (page === 0) {
			enableScroll();
			navigate('/check', { replace: true });
		} else setPage((current) => current - 1);
	};

	const pages: Array<any> = [PageOne, PageTwo, PageThree];

	const getPage = () => {
		if (page === 0) {
			return <PageOne />;
		} else if (page === 1) {
			return <PageTwo />;
		} else {
			return <PageThree />;
		}
	};

	const progressStatus =
		page === 0
			? (page / pages.length) * 100
			: (page / (pages.length - 1)) * 100;

	return (
		<div className='activity-guide-container'>
			<div className='activity-guide-content'>
				<header>
					<button className='back-button' onClick={handleBack}>
						<img src={backBlue} alt='back' />
					</button>
					<div className='progress-bar'>
						<div
							className='progress-indicator'
							style={{ width: `${progressStatus}%` }}
						></div>
					</div>
					<div></div>
				</header>
				<main ref={mainRef}>{getPage()}</main>
				<footer>
					<OrkaButton
						buttonClass='purple-filled'
						buttonContent={
							page === pages.length - 1 ? "Let's go" : 'Next'
						}
						disabled={false}
						emitClicked={handleNext}
					/>
				</footer>
			</div>
		</div>
	);
};

export default ActivityGuide;
