import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './disclosure-form.styles.scss';
import Header from '../../header/header/header.component';
import Input from '../../general/input/input.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { IDisclosure } from '../../../types/interfaces/applicant.interface';
import * as validations from '../../../utils/validations';
import ValidationLayer from '../../general/validation-layer/validation-layer.component';
import * as api from '../../../api/requests/disclosures.api';
import * as utils from '../../../utils/utilsFunctions';
import { fireGaEvent } from '../../../utils/ga-event';

type Props = {
	handleBack: (origin: string) => void;
	setShowForm: Dispatch<SetStateAction<boolean>>;
	selectedDisclosure: IDisclosure | null;
	setSelectedDisclosure: Dispatch<SetStateAction<IDisclosure | null>>;
	disclosureType: string;
	setRefetchDisclosures: Dispatch<SetStateAction<boolean>>;
	refetchDisclosures: boolean;
};

const DisclosureForm: FC<Props> = ({
	handleBack,
	setShowForm,
	selectedDisclosure,
	disclosureType,
	setRefetchDisclosures,
	refetchDisclosures,
}) => {
	const [formState, setFormState] = useState<Partial<IDisclosure>>({
		details: '',
		sentence: '',
		date: null,
		type: disclosureType,
	});
	const [isDisabled, setIsDisabled] = useState(false);
	const [validInputs, setValidInputs] = useState([{ date: true }]);
	const isMobile = useSelector((state: RootState) => state.general.isMobile);
	const [continueClicked, setContinueClicked] = useState(false);

	fireGaEvent('Disclosures', 'Disclosures - Disclosures Form Page');

	useEffect(() => {
		utils.instantScroll();
	}, []);

	useEffect(() => {
		if (selectedDisclosure) {
			setFormState(selectedDisclosure);
		}
	}, [selectedDisclosure]);

	const handleForm = (val: string, input: string) => {
		setFormState({
			...formState,
			[input]: val,
		});
	};

	const handleAddDisclosure = () => {
		setContinueClicked(true);
		if (isDisabled) {
			fireGaEvent(
				'Disclosures',
				'Clicked add disclosure on form (validation failed)',
			);
			return;
		}
		fireGaEvent('Disclosures', 'Clicked add disclosure on form');
		const payload = {
			...formState,
			date: utils.formatDateForDb(formState.date),
		};
		return api
			.saveDisclosure(payload)
			.then(() => {
				setRefetchDisclosures(!refetchDisclosures);
				setShowForm(false);
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	return (
		<div className='disclosure-form-container'>
			<ValidationLayer
				setIsDisabled={setIsDisabled}
				requiredChecks={['requiredFields', 'validInputs']}
				requiredFields={['details', 'sentence', 'date']}
				formState={formState}
				validInputs={validInputs}
			>
				<div className={isMobile ? '' : 'modal-content-scrollable'}>
					{isMobile && (
						<Header
							text='Please Give Details'
							type='text'
							backFunction={() => handleBack('form')}
							origin='form'
						/>
					)}

					<Input
						inputName='details'
						title='Details of offence'
						placeholder='Please enter some details about the offence'
						value={formState.details}
						emitValue={handleForm}
						isTextArea={true}
						required={true}
						continueClicked={continueClicked}
					/>

					<Input
						inputName='sentence'
						title='Sentence / Disposal'
						placeholder='Offence'
						value={formState.sentence}
						emitValue={handleForm}
						required={true}
						continueClicked={continueClicked}
					/>

					<Input
						inputName='date'
						title='Date of offence'
						placeholder='dd/mm/yyyy'
						value={formState.date}
						emitValue={handleForm}
						isDate={true}
						validationFunction={validations.dateValidation}
						validInputs={validInputs}
						setValidInputs={setValidInputs}
						required={true}
						continueClicked={continueClicked}
					/>

					<div className='page-form-footer-outer-container'>
						<div className='page-form-footer-inner-container'>
							<OrkaButton
								buttonContent='Add'
								disabled={false}
								emitClicked={handleAddDisclosure}
								buttonClass='purple-filled'
							/>
						</div>
					</div>
					{isMobile && <div className='margin-bottom-136' />}
				</div>
			</ValidationLayer>
		</div>
	);
};

export default DisclosureForm;
