import React, { FC, useEffect } from 'react';
import './sign-up.styles.scss';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../redux/General/general.actions';
import LoginLayout from '../../components/general/login-layout/login-layout.component';
import ErrorBoundary from '../../components/general/error-boundary/error-boundary.component';
import { useNavigate, useParams } from 'react-router-dom';
import { isSeamless, login } from '../../redux/Auth/auth.actions';
import { getSeamlessLoginToken } from '../../api/requests/auth.api';
import { fireGaEvent } from '../../utils/ga-event';

const SeamlessLogin: FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { token } = useParams();

	useEffect(() => {
		token &&
			getSeamlessLoginToken(token)
				.then(() => {
					fireGaEvent('Login', 'Code works');
					dispatch(login());
					dispatch(isSeamless());
					navigate('/check');
				})
				.catch((err) => {
					dispatch(
						setAlert({
							type: 'error',
							message: err?.data?.message
								? err?.data?.message
								: err?.message
									? err?.message
									: JSON.stringify(err),
							isVisible: true,
						}),
					);
				});
	}, []);

	return (
		<LoginLayout>
			<ErrorBoundary applicantWorkerUuid='None'>
				<div />
			</ErrorBoundary>
		</LoginLayout>
	);
};

export default SeamlessLogin;
