import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './name-history-form.styles.scss';
import Checkbox from '../../general/checkbox/checkbox.component';
import { ICheckboxAnswer } from '../../../types/interfaces/misc.interface';
import Input from '../../general/input/input.component';
import Header from '../../header/header/header.component';
import { useDispatch } from 'react-redux';
import {
	removeNameHistory,
	addNameHistory,
	updateNameHistory,
} from '../../../redux/Applicant/applicant.actions';
import { RootState } from '../../../types/root-state';
import { useSelector } from 'react-redux';
import { INameHistoryItem } from '../../../types/interfaces/applicant.interface';
import * as validations from '../../../utils/validations';
import * as utils from '../../../utils/utilsFunctions';
import ValidationLayer from '../../general/validation-layer/validation-layer.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import * as api from '../../../api/requests/names.api';
import { fireGaEvent } from '../../../utils/ga-event';

type Props = {
	handleBack: (origin: string) => void;
	setShowForm: Dispatch<SetStateAction<boolean>>;
	selectedName: INameHistoryItem | null;
	setSelectedName: Dispatch<SetStateAction<INameHistoryItem | null>>;
	title: string;
	setRefetchNames?: Dispatch<SetStateAction<boolean>>;
	refetchNames?: boolean;
};

const NameHistoryFormComponent: FC<Props> = ({
	handleBack,
	setShowForm,
	selectedName,
	setSelectedName,
	title,
	refetchNames,
	setRefetchNames,
}) => {
	const dispatch = useDispatch();
	const isMobile = useSelector((state: RootState) => state.general.isMobile);
	const [continueClicked, setContinueClicked] = useState(false);
	const [dateValidation, setDateValidation] = useState('');
	const [validInputs, setValidInputs] = useState([
		{ startAt: true },
		{ endAt: true },
	]);
	const [isDisabled, setIsDisabled] = useState(false);
	const [formState, setFormState] = useState<Partial<INameHistoryItem>>({
		forename: '',
		middleNames: '',
		surname: '',
		startAt: null,
		endAt: null,
		nameType: '',
	});
	const [checkboxData, setCheckboxData] = useState([
		{
			question: 'What type of name is this? (select one) *',
			answers: [
				{
					id: 1,
					selected: false,
					label: 'Legal Name',
					info: 'e.g marriage, divorce, adoption',
				},
				{
					id: 2,
					selected: false,
					label: 'Alias Name',
					info: 'e.g nickname eg. Ellen > Eleanor',
				},
			],
		},
	]);

	fireGaEvent('Other Names', 'Names - Form Page');

	useEffect(() => {
		if (checkboxData[0].answers[0].selected) {
			setFormState({
				...formState,
				nameType: 'legal',
			});
		}
		if (checkboxData[0].answers[1].selected) {
			setFormState({
				...formState,
				nameType: 'alias',
			});
		}
	}, [
		checkboxData[0].answers[0].selected,
		checkboxData[0].answers[1].selected,
	]);

	useEffect(() => {
		utils.instantScroll();
	}, []);

	useEffect(() => {
		if (selectedName) {
			const selectedNameUpdate = {
				...selectedName,
				startAt: utils.formatDateForDb(selectedName.startAt),
				endAt: utils.formatDateForDb(selectedName.endAt),
			};
			setFormState(selectedNameUpdate);
			handleSelectCheckbox(selectedName.nameType);
		}
	}, [selectedName]);

	const handleSelection = (
		checkBoxAnswer: ICheckboxAnswer,
		question: string,
	) => {
		utils.handleSelection(
			checkBoxAnswer,
			question,
			checkboxData,
			setCheckboxData,
		);
	};

	const handleSelectCheckbox = (nameType: string) => {
		const answer = checkboxData[0].answers.filter((answer) =>
			answer.label.toLowerCase().includes(nameType.toLowerCase()),
		)[0];

		utils.handleSelection(
			answer,
			checkboxData[0].question,
			checkboxData,
			setCheckboxData,
		);
	};

	const handleEmitInput = (val: string, input: string) => {
		setFormState({
			...formState,
			[input]: val,
		});
	};

	const handleAddName = () => {
		utils.instantScroll();
		const payload = {
			...formState,
			startAt: utils.formatDateForDb(formState.startAt),
			endAt: utils.formatDateForDb(formState.endAt),
		};
		return api
			.saveName(payload)
			.then((res: any) => {
				if (setRefetchNames) {
					setRefetchNames(!refetchNames);
				}
				dispatch(addNameHistory(res.data.data));
				handleSuccess('Successfully added name');
			})
			.catch((err: Error) => {
				handleErrors(err);
			});
	};

	const handleEditName = () => {
		utils.instantScroll();
		const payload = {
			...formState,
			startAt: utils.formatDateForDb(formState.startAt),
			endAt: utils.formatDateForDb(formState.endAt),
		};
		return api
			.updateName(payload)
			.then((res: any) => {
				if (res.status === 404) {
					handleErrors(res);
					throw res;
				}
				dispatch(updateNameHistory(payload));
				if (setRefetchNames) {
					setRefetchNames(!refetchNames);
				}
				setSelectedName(null);
				handleSuccess('Successfully updated name');
			})
			.catch((err: any) => {
				handleErrors(err);
			});
	};

	const handleComplete = async (val: string) => {
		setContinueClicked(true);
		if (isDisabled) {
			fireGaEvent(
				'Other Names',
				`${
					val === 'add'
				} ? 'Clicked Add Name on Form (validation failed)' : 'Clicked Edit Name of Form (validation failed)'`,
			);
			return;
		}
		fireGaEvent(
			'Other Names',
			`${
				val === 'add'
			} ? 'Clicked Add Name on Form' : 'Clicked Edit Name of Form'`,
		);
		setTimeout(() => {
			if (!isDisabled) {
				val === 'add' ? handleAddName() : handleEditName();
			}
		}, 500);
	};

	const handleErrors = (err: Error) => {
		utils.handleAlerts('error', err.message, dispatch);
	};

	const handleSuccess = (message: string) => {
		utils.handleAlerts('success', message, dispatch);
		setShowForm(false);
	};

	const handleRemoveName = () => {
		fireGaEvent('Other Names', 'Clicked delete other name');
		if (selectedName) {
			return api
				.deleteName(selectedName.uuid)
				.then(() => {
					dispatch(removeNameHistory(formState));
					setSelectedName(null);
					if (setRefetchNames) {
						setRefetchNames(!refetchNames);
					}
					handleSuccess('Successfully deleted name');
				})
				.catch((err: Error) => {
					handleErrors(err);
				});
		}
	};

	return (
		<div className='name-history-form'>
			<ValidationLayer
				setIsDisabled={setIsDisabled}
				formState={formState}
				validInputs={validInputs}
				requiredChecks={[
					'requiredFields',
					'dateComparison',
					'validInputs',
				]}
				requiredFields={[
					'forename',
					'surname',
					'startAt',
					'endAt',
					'nameType',
				]}
				setDateValidation={setDateValidation}
			>
				{isMobile && (
					<Header
						text={title}
						type='text'
						backFunction={() => handleBack('form')}
						origin='form'
						deleteFunction={handleRemoveName}
						editMode={!!selectedName}
					/>
				)}

				<div className={isMobile ? '' : 'modal-content-scrollable'}>
					<Input
						inputName='forename'
						title='First Name *'
						placeholder='Andrew'
						value={formState.forename}
						emitValue={handleEmitInput}
						required={true}
						continueClicked={continueClicked}
					/>
					<Input
						inputName='middleNames'
						title='Middle Names'
						placeholder='David'
						value={formState.middleNames}
						emitValue={handleEmitInput}
					/>
					<Input
						inputName='surname'
						title='Last Name *'
						placeholder='Smith'
						value={formState.surname}
						emitValue={handleEmitInput}
						required={true}
						continueClicked={continueClicked}
					/>
					<Input
						inputName='startAt'
						title='Start Date *'
						placeholder='dd/mm/yyyy'
						value={formState.startAt}
						emitValue={handleEmitInput}
						isDate={true}
						validationFunction={validations.dateValidation}
						validInputs={validInputs}
						setValidInputs={setValidInputs}
						required={true}
						continueClicked={continueClicked}
					/>
					<Input
						inputName='endAt'
						title='End Date *'
						placeholder='dd/mm/yyyy'
						value={formState.endAt}
						emitValue={handleEmitInput}
						isDate={true}
						validationFunction={validations.dateValidation}
						validInputs={validInputs}
						setValidInputs={setValidInputs}
						required={true}
						continueClicked={continueClicked}
					/>
					{dateValidation && (
						<div className='validation-error'>
							<p>{dateValidation}</p>
						</div>
					)}
					<Checkbox
						alignment='vertical'
						checkboxData={checkboxData[0]}
						emitSelection={handleSelection}
						required={true}
						continueClicked={continueClicked}
					/>
				</div>

				<div
					className={`page-form-footer-outer-container ${
						isMobile && 'remove-fixed-footer'
					}`}
				>
					<div className='page-form-footer-inner-container'>
						<OrkaButton
							buttonContent={title}
							disabled={false}
							emitClicked={() =>
								handleComplete(selectedName ? 'edit' : 'add')
							}
							buttonClass={'purple-filled'}
						/>
						{selectedName && !isMobile && (
							<OrkaButton
								buttonContent='Delete'
								disabled={false}
								emitClicked={handleRemoveName}
								buttonClass='purple-outline'
							/>
						)}
					</div>
				</div>
			</ValidationLayer>
		</div>
	);
};

export default NameHistoryFormComponent;
