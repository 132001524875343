import React, { useRef, useEffect, FC, Dispatch, SetStateAction } from 'react';
import './vmodal.styles.scss';

// Assets
import cross from '../../../assets/icons/misc/cross.svg';
import leftChevron from '../../../assets/icons/misc/back-blue.svg';

type Props = {
	title?: string;
	children: React.ReactNode;
	showModal: boolean;
	modalContentHeight?: string;
	modalContentWidth?: string;
	titleIcon?: string | null;
	pageOrigin?: string;
	leftButtonLabel?: string;
	rightButtonLabel?: string;
	disabled?: boolean;
	setShowModal: Dispatch<SetStateAction<boolean>>;
	closeFunction?: (origin: string) => void;
	backButton?: boolean;
	backButtonEvent?: (arg?: any) => void;
	leftButtonEvent?: (arg?: any) => void;
	rightButtonEvent?: (arg?: any) => void;
};

const VModal: FC<Props> = ({
	title,
	children,
	setShowModal,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	showModal,
	titleIcon = null,
	closeFunction,
	backButton,
	pageOrigin,
	leftButtonLabel,
	rightButtonLabel = 'Next',
	backButtonEvent,
	leftButtonEvent,
	rightButtonEvent,
	disabled,
}) => {
	const modalContent = useRef<HTMLDivElement>(null);

	const handleEmitClose = () => {
		if (closeFunction && pageOrigin) {
			closeFunction(pageOrigin);
		} else {
			setShowModal(false);
		}
	};

	useEffect(() => {
		if (showModal) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}

		return () => {
			document.body.style.overflow = 'unset';
		};
	}, [showModal]);

	return (
		<>
			<div className='vmodal-container'>
				<div className='vmodal-content' ref={modalContent}>
					<div
						className='vmodal-header'
						style={{
							justifyContent: `${
								closeFunction && 'space-between'
							}`,
						}}
					>
						{backButton && (
							<button
								onClick={backButtonEvent}
								className='vmodal-back-button'
								autoFocus
							>
								<img alt='back' src={leftChevron} />
							</button>
						)}
						<div className='vmodal-title'>
							{titleIcon && (
								<img src={titleIcon} alt='title icon' />
							)}
							<h1>{title}</h1>
						</div>

						{closeFunction && (
							<button
								onClick={handleEmitClose}
								className='vmodal-close-button'
								autoFocus
							>
								<img alt='close dialog' src={cross} />
							</button>
						)}
					</div>
					<div className='vmodal-body'>{children}</div>
					<div
						className='vmodal-footer'
						style={{
							justifyContent: `${
								leftButtonLabel ? 'space-between' : 'flex-end'
							}`,
						}}
					>
						{leftButtonLabel && (
							<button
								onClick={leftButtonEvent}
								className='secondary'
							>
								{leftButtonLabel}
							</button>
						)}
						<button onClick={rightButtonEvent} disabled={disabled}>
							{rightButtonLabel}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default VModal;
