import React, { FC, useState } from 'react';
import './info-card-dropdown.styles.scss';
import arrowDown from '../../../assets/icons/misc/arrow-down.svg';

type Props = {
	title: string;
	text: string;
	bullets: string[];
};

const InfoCardDropdown: FC<Props> = ({ title, text, bullets }) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div className={`info-card-dropdown-container`} role='button'>
			<div
				className='info-card-dropdown-container-top'
				onClick={() => setIsOpen(!isOpen)}
			>
				<div className='info-card-dropdown-container-left'>
					<p>{title}</p>
				</div>
				{<img src={arrowDown} alt='open' />}
			</div>
			{isOpen && (
				<div className='info-card-dropdown-container-bottom'>
					<p>{text}</p>
					<ul>
						{bullets.map((element) => {
							return <li key={element}>{element}</li>;
						})}
					</ul>
				</div>
			)}
		</div>
	);
};

export default InfoCardDropdown;
