import './check-passport-disclosures.styles.scss';

import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../types/root-state';
import {
	ICheckboxAnswer,
	ICheckboxData,
} from '../../../types/interfaces/misc.interface';
import { IDisclosure } from '../../../types/interfaces/applicant.interface';
import { disclosureQuestions } from '../../../utils/data';
import {
	removeDisclosure,
	setDisclosures,
} from '../../../redux/Applicant/applicant.actions';
import * as api from '../../../api/requests/disclosures.api';
import * as utils from '../../../utils/utilsFunctions';
import useFetchData from '../../../utils/useFetchData';
import moment from 'moment';

import Modal from '../../../components/modals/modal/modal.component';
import DisclosureQuestions from '../../../components/disclosures/disclosure-questions/disclosure-questions.component';
import DisclosureForm from '../../../components/disclosures/disclosure-form/disclosure-form.component';

const CheckPassportDisclosures: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isMobile = useSelector((state: RootState) => state.general.isMobile);
	const [checkboxData, setCheckboxData] =
		useState<ICheckboxData[]>(disclosureQuestions);
	const [showForm, setShowForm] = useState(false);
	const [selectedDisclosure, setSelectedDisclosure] =
		useState<IDisclosure | null>(null);
	const [isDisabled, setIsDisabled] = useState(false);
	const [disclosureType, setDisclosureType] = useState('');
	const [refetchDisclosures, setRefetchDisclosures] = useState(false);

	const disclosures: IDisclosure[] = useFetchData(
		api.getCriminalRecordData,
		setDisclosures,
		2,
		'',
		refetchDisclosures,
	);

	useEffect(() => {
		const now = new Date();
		const mappedQuestions = checkboxData.map((checkboxItem) => {
			checkboxItem.disclosures = disclosures.filter(
				(disclosure: IDisclosure) => {
					const createdAt = utils.getDaysLater(
						disclosure.createdAt,
						30,
					);
					return (
						moment(createdAt) > moment(now) &&
						checkboxItem.type === disclosure.type
					);
				},
			);
			if (checkboxItem.disclosures.length > 0) {
				checkboxItem.answers[0].selected = true;
				checkboxItem.answers[1].selected = false;
				if (checkboxItem.answers[0].selected) {
					checkboxItem.answers[1].disabled = true;
				}
			}
			return checkboxItem;
		});
		setCheckboxData(mappedQuestions);
	}, [JSON.stringify(disclosures)]);

	useEffect(() => {
		let disabled = false;
		checkboxData.forEach((checkboxItem) => {
			if (
				checkboxItem.answers[0].selected &&
				checkboxItem.disclosures?.length === 0
			) {
				disabled = true;
			}

			if (
				!checkboxItem.answers[0].selected &&
				!checkboxItem.answers[1].selected
			) {
				disabled = true;
			}
		});
		setIsDisabled(disabled);
	}, [JSON.stringify(checkboxData)]);

	useEffect(() => {
		const resetCheckBoxData = checkboxData.map((checkboxItem) => {
			if (
				checkboxItem.disclosures?.length === 0 &&
				checkboxItem.answers[0].selected &&
				!showForm
			) {
				checkboxItem.answers[0].selected = false;
				checkboxItem.answers[1].selected = false;
			}
			if (!checkboxItem.answers[0].selected) {
				checkboxItem.answers[1].disabled = false;
			}
			return checkboxItem;
		});
		setCheckboxData(resetCheckBoxData);
	}, [showForm]);

	const handleBack = (origin: string) => {
		switch (origin) {
			case 'form':
				setShowForm(false);
				break;
			default:
				return navigate('/check/update-other', { replace: true });
		}
	};

	const handleSelection = (
		checkBoxAnswer: ICheckboxAnswer,
		question: string,
	) => {
		utils.handleSelection(
			checkBoxAnswer,
			question,
			checkboxData,
			setCheckboxData,
		);
		const filteredCheckbox = checkboxData.filter(
			(item) => item.question === question,
		)[0];

		if (filteredCheckbox.type) {
			if (checkBoxAnswer.label === 'Yes') {
				setDisclosureType(filteredCheckbox.type);
				setShowForm(true);
			} else {
				handleRemoveAllForQuestion(filteredCheckbox.type);
			}
		}
	};

	const handleRemoveAllForQuestion = (type: string) => {
		const itemsToRemove = disclosures.filter(
			(disclosure) => disclosure.type === type,
		);

		itemsToRemove.forEach((item) => {
			dispatch(removeDisclosure(item));
		});
	};
	return (
		<div className='check-passport-disclosures-container'>
			{!showForm && (
				<DisclosureQuestions
					pageName='Disclosure'
					handleBack={handleBack}
					checkboxData={checkboxData}
					handleSelection={handleSelection}
					setShowForm={setShowForm}
					setSelectedDisclosure={setSelectedDisclosure}
					isDisabled={isDisabled}
					setDisclosureType={setDisclosureType}
					primaryButtonText='Submit'
					hideDesktopSteps={true}
					redirectUrl='/check/update-other'
				/>
			)}
			{showForm && isMobile && (
				<DisclosureForm
					handleBack={handleBack}
					setShowForm={setShowForm}
					selectedDisclosure={selectedDisclosure}
					setSelectedDisclosure={setSelectedDisclosure}
					disclosureType={disclosureType}
					setRefetchDisclosures={setRefetchDisclosures}
					refetchDisclosures={refetchDisclosures}
				/>
			)}

			{!isMobile && showForm && (
				<Modal
					title='Please Give Details'
					setShowModal={setShowForm}
					showModal={showForm}
				>
					<DisclosureForm
						handleBack={handleBack}
						setShowForm={setShowForm}
						selectedDisclosure={selectedDisclosure}
						setSelectedDisclosure={setSelectedDisclosure}
						disclosureType={disclosureType}
						setRefetchDisclosures={setRefetchDisclosures}
						refetchDisclosures={refetchDisclosures}
					/>
				</Modal>
			)}
		</div>
	);
};

export default CheckPassportDisclosures;
