import React, { FC } from 'react';
import './not-started.styles.scss';
import GreyTextCard from '../../../components/submission-complete/grey-text-card/grey-text-card.component';
import pencilHand from '../../../assets/icons/submission-complete/pencil-hand.svg';
import { fireGaEvent } from '../../../utils/ga-event';

const NotStarted: FC = () => {
	return (
		<section className='submission-complete-container'>
			<div className='submission-complete-header'>
				<img src={pencilHand} alt='pencil hand' />
				<h2>We are working on your application...</h2>
			</div>
			<div className='submission-complete-body'>
				<GreyTextCard>
					<p>
						If you{' '}
						<span className='grey-text-card-bold'>
							have submitted documents
						</span>
						, we will review them and let you know if anything more
						is needed via email.
					</p>
				</GreyTextCard>

				<GreyTextCard>
					<p>
						If you{' '}
						<span className='grey-text-card-bold'>
							have not submitted documents
						</span>
						, we will need to contact the organisations provided in
						your activity history for references- this could take a
						little while.
					</p>
				</GreyTextCard>

				<GreyTextCard>
					<p>
						If you would like{' '}
						<span className='grey-text-card-bold'>
							to speed up your verification
						</span>
						, you can call HMRC on{' '}
						<a
							href='tel:0300 200 3300'
							onClick={() =>
								fireGaEvent(
									'Submission',
									'Click HMRC phone number',
								)
							}
						>
							0300 200 3300
						</a>{' '}
						or use their live chat (link) and request your 5 year
						history (It’s free!).
					</p>
				</GreyTextCard>

				<p className='submission-complete-contact margin-bottom-8'>
					You can also email us any further questions or documents at{' '}
					<a
						href='mailto:candidates@deploi.uk'
						target='_blank'
						rel='noreferrer'
						onClick={() =>
							fireGaEvent('Submission', 'Click email Deploi')
						}
					>
						candidates@deploi.uk
					</a>
				</p>

				<p className='submission-complete-contact'>
					Explore our{' '}
					<a
						href='https://intercom.help/deploi/en/collections/9332235-candidate-help'
						target='_blank'
						rel='noreferrer'
						onClick={() =>
							fireGaEvent('Submission', 'Click go to FAQs')
						}
					>
						FAQ{`'`}s
					</a>
				</p>
			</div>
		</section>
	);
};

export default NotStarted;
