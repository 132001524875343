import './check-passport-update-other.styles.scss';

import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	setApplicationStatus,
	setOverallSubmissionStatus,
} from '../../../redux/General/general.actions';
import * as apiMisc from '../../../api/requests/misc.api';

import Header from '../../header/header/header.component';
import PageFooter from '../../general/page-footer/page-footer.component';

import successTick from '../../../assets/images/circle-tick.svg';

const CheckPassportUpdateOther: FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const comingFromLeaveActivity = (
		location.state as { comingFromLeaveActivity?: boolean }
	)?.comingFromLeaveActivity;

	const [overlayVisible, setOverlayVisible] = useState(false);

	const handleBack = () => {
		if (comingFromLeaveActivity) {
			navigate('/check/activity-history');
		} else {
			navigate('/check/activity-update-document');
		}
	};

	const handleAddNewAddress = () => {
		navigate('/check/update-address');
	};

	const handleAddNewDisclosure = () => {
		navigate('/check/update-disclosures');
	};

	const handleChangeName = () => {
		navigate('/check/update-names');
	};

	const renderOverlay = () => (
		<div className='passport-update-other-overlay'>
			<img src={successTick} alt='Success Tick'></img>
			<br />
			<h2>Great!</h2>
			<p>All done, thank you!</p>
		</div>
	);

	const handleSubmit = () => {
		return apiMisc
			.resubmit()
			.then(() => {
				return handlePostOverallSubmission();
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	const handlePostOverallSubmission = () => {
		return apiMisc
			.setApplicationStatus('application-submitted')
			.then(() => {
				dispatch(setOverallSubmissionStatus('pending'));
				dispatch(setApplicationStatus('application-submitted'));
				setOverlayVisible(true);
				setTimeout(() => {
					setOverlayVisible(false);
					return navigate('/', {
						replace: true,
					});
				}, 2000);
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	return (
		<>
			<div className='passport-update-other'>
				<Header
					text='Do any other details need updating?'
					type='text'
					backFunction={handleBack}
					origin=''
					hideStepsCounter={true}
					hideDesktopSteps={true}
					updatePassport={true}
				/>
				<div className='passport-update-other-body'>
					<button
						className='margin-bottom-8 orka-identity-button blue-outline'
						onClick={handleAddNewAddress}
					>
						Add New Address
					</button>
					<button
						className='margin-bottom-8 orka-identity-button blue-outline'
						onClick={handleAddNewDisclosure}
					>
						Add New Disclosures
					</button>
					<button
						className='margin-bottom-8 orka-identity-button blue-outline'
						onClick={handleChangeName}
					>
						Add Name
					</button>
				</div>
				<PageFooter
					primaryButtonFunction={handleSubmit}
					primaryButtonText='No, Submit'
				/>
			</div>
			{overlayVisible && renderOverlay()}
		</>
	);
};

export default CheckPassportUpdateOther;
