import './App.css';

import React, { useEffect, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setIsMobile, setIsTablet } from './redux/General/general.actions';
import { RootState } from './types/root-state';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import * as api from './api/requests/misc.api';

import Login from './pages/Login/login.component';
// import CheckContainer from './components/check-container/check-container';
import Alert from './components/general/alert/alert.component';
import NotFound from './components/general/not-found/not-found.component';
import SignUp from './pages/Signup/sign-up.component';
import ResetPassword from './pages/Reset/reset-password.component';
import ForgotPassword from './pages/Forgot-password/forgot-password.component';
import ReactGA from 'react-ga';
import FullPageLoader from './components/general/full-page-loader/full-page-loader.component';
import PrivateRoute from './components/general/private-route/private-route.component';
import ActivityDocumentExamples from './pages/Example-documents/example-documents.component';
import SeamlessLogin from './pages/Seamless-login/seamless-login.component';
import RtwContainer from './components/rtw-container/rtw-container';
import CheckPassportContainer from './components/check-passport/check-passport-container/check-passport-container.component';
import CheckContainer from './components/check-container/check-container';
import ErrorBoundary from './components/general/error-boundary/error-boundary.component';
import Intercom from '@intercom/messenger-js-sdk';

const App: FC = () => {
	const dispatch = useDispatch();
	const ga = process.env.REACT_APP_GA;
	const navigate = useNavigate();

	if (ga) {
		ReactGA.initialize(ga, { debug: false });
	}
	const alertInfo = useSelector(
		(state: RootState) => state.general.alertInfo,
	);
	const isLoading = useSelector(
		(state: RootState) => state.general.isLoading,
	);

	const applicant = useSelector(
		(state: RootState) => state.applicant?.applicant,
	);
	if (applicant) {
		Intercom({
			app_id: 'h4y39z93',
			base_url: 'https://api-iam.eu.intercom.io',
			user_id: applicant.uuid,
			name: applicant.name,
			email: applicant.email,
			created_at: applicant.createdAt,
		});
	}

	useEffect(() => {
		const widths = [window.innerWidth];

		if (window.screen?.width) {
			widths.push(window.screen?.width);
		}

		if (screen?.width) {
			widths.push(window.screen?.width);
		}

		const width = Math.min(...widths);

		if (width >= 600) {
			dispatch(setIsMobile(false));
		} else {
			dispatch(setIsMobile(true));
		}
		if (width > 1000) {
			dispatch(setIsTablet(false));
		} else {
			dispatch(setIsTablet(true));
		}
	}, [dispatch]);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [dispatch]);

	const handleResize = () => {
		const widths = [window.innerWidth];

		if (window.screen?.width) {
			widths.push(window.screen?.width);
		}

		if (screen?.width) {
			widths.push(window.screen?.width);
		}

		const width = Math.min(...widths);
		if (width > 600) {
			dispatch(setIsMobile(false));
		} else {
			dispatch(setIsMobile(true));
		}

		if (width > 1000) {
			dispatch(setIsTablet(false));
		} else {
			dispatch(setIsTablet(true));
		}
	};

	return (
		<div className='App'>
			<ErrorBoundary applicantWorkerUuid='None'>
				{alertInfo.isVisible && <Alert alertData={alertInfo} />}
				{isLoading && <FullPageLoader loading={isLoading} />}

				<Routes>
					<Route path='/login' element={<Login />} />
					<Route path='/sign-up' element={<SignUp />} />
					<Route
						path='/reset-password/:token'
						element={<ResetPassword />}
					/>
					<Route
						path='/login/seamless/:token'
						element={<SeamlessLogin />}
					/>
					<Route
						path='/forgot-password'
						element={<ForgotPassword />}
					/>
					<Route
						path='/check/example-document'
						element={<ActivityDocumentExamples />}
					/>
					<Route
						path='/'
						element={<Navigate replace to='/check' />}
					/>

					<Route
						path='/check/*'
						element={
							<PrivateRoute>
								{applicant?.clearanceStatusHistory
									?.clearanceStatus === 'fully_compliant' ? (
									<CheckPassportContainer />
								) : (
									<CheckContainer />
								)}
							</PrivateRoute>
						}
					/>
					<Route
						path='/rtw/*'
						element={
							<PrivateRoute>
								<RtwContainer />
							</PrivateRoute>
						}
					/>
					<Route
						path='*'
						element={<NotFound type='noData' pageNotFound={true} />}
					/>
				</Routes>
			</ErrorBoundary>
		</div>
	);
};

export default App;
