import './licences-form.styles.scss';
import React, {
	Dispatch,
	FC,
	SetStateAction,
	useState,
	useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	addLicence,
	clearApplicantState,
} from '../../../redux/Applicant/applicant.actions';
import { RootState } from '../../../types/root-state';
import { fireGaEvent } from '../../../utils/ga-event';
import { ILicenceItem } from '../../../types/interfaces/applicant.interface';
import { clearAuth } from '../../../redux/Auth/auth.actions';
import { clearGeneralState } from '../../../redux/General/general.actions';
import { useNavigate } from 'react-router-dom';
import * as utils from '../../../utils/utilsFunctions';
import * as apiAuth from '../../../api/requests/auth.api';
import * as validations from '../../../utils/validations';
import * as api from '../../../api/requests/licence.api';

import Header from '../../header/header/header.component';
import Input from '../../general/input/input.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import ValidationLayer from '../../general/validation-layer/validation-layer.component';
import ListCard from '../../general/list-card/list-card.component';
import InfoCard from '../../general/info-card/info-card.component';

import errorIcon from '../../../assets/icons/misc/error.svg';
import successIcon from '../../../assets/icons/misc/success.svg';

type Props = {
	handleScreen: (origin: string) => void;
	setShowForm: Dispatch<SetStateAction<boolean>>;
	setShowConfirm: Dispatch<SetStateAction<boolean>>;
	selectedLicence: ILicenceItem | null;
};

const LicencesForm: FC<Props> = ({
	handleScreen,
	setShowForm,
	setShowConfirm,
	selectedLicence,
}) => {
	const navigate = useNavigate();
	const [isDisabled, setIsDisabled] = useState(false);
	const [continueClicked, setContinueClicked] = useState(false);
	const [formState, setFormState] = useState<Partial<ILicenceItem>>({
		number: '',
	});
	const [validInputs, setValidInputs] = useState([{ number: true }]);
	const isMobile = useSelector((state: RootState) => state.general.isMobile);
	const [isValidLicence, setIsValidLicence] = useState(false);
	const [listCardItem, setListCardItem] = useState<any>(null);
	const [foundDuplicateError, setFoundDuplicateError] = useState<string>('');
	const [hasDuplicate, setHasDuplicate] = useState(false);

	const dispatch = useDispatch();
	const licences = useSelector(
		(state: RootState) => state.applicant.licences,
	);

	const isSeamless = useSelector((state: RootState) => state.auth.isSeamless);

	const allLicence = licences.map((licence) => licence.number);

	fireGaEvent('Licences', 'Licences - Form Page');

	useEffect(() => {
		utils.instantScroll();
	}, []);

	useEffect(() => {
		setFoundDuplicateError('');
		setHasDuplicate(false);

		if (formState.number && allLicence.includes(formState.number)) {
			setFoundDuplicateError(`This SIA licence has already been added!`);
			setIsValidLicence(false);
			return;
		}

		if (formState.number && formState.number.length === 16) {
			fireGaEvent('Licences', 'Entered 16 digit number on form');
			api.checkLicenceValid(formState.number)
				.then((res) => {
					if (res.status !== 404 && res.status !== 409) {
						setFormState(res.data.data);
						setIsValidLicence(true);
						const item = {
							title: res.data.data.sector,
							lineOne: res.data.data.role,
							lineTwo: `Expires: ${utils.formatDateForUser(
								res.data.data.expiry_date,
							)}`,
						};
						setListCardItem(item);
					}
				})
				.catch((err: any) => {
					if (err.status === 409) {
						setIsValidLicence(false);
						setHasDuplicate(true);
					} else {
						throw err;
					}
				});
		} else if (
			formState.number &&
			(formState.number.length < 16 ||
				formState.number.length > 0 ||
				formState.number.length > 16)
		) {
			setIsValidLicence(false);
			setListCardItem(null);
		}
	}, [formState.number]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleGetInput = (val: any, input: string) => {
		setFormState({
			...formState,
			number: val,
		});
	};

	const handleAddLicence = () => {
		handleSuccess();
	};

	const handleSuccess = () => {
		dispatch(addLicence(formState));
		setShowForm(false);
		setShowConfirm(true);
	};

	const handleClickComplete = async () => {
		setContinueClicked(true);
		if (isDisabled) {
			return;
		} else {
			handleAddLicence();
		}
	};

	const handleLogout = () => {
		return apiAuth
			.logout()
			.then(() => {
				dispatch(clearApplicantState());
				dispatch(clearAuth());
				dispatch(clearGeneralState());
				localStorage.clear();
				return navigate('/login', { replace: true });
			})
			.catch((err: Error) => {
				dispatch(clearApplicantState());
				navigate('/', { replace: true });
				throw err;
			});
	};

	return (
		<div className='licence-form'>
			<ValidationLayer
				setIsDisabled={setIsDisabled}
				formState={formState}
				validInputs={validInputs}
				requiredFields={['number']}
				requiredChecks={['requiredFields', 'validInputs']}
			>
				{isMobile && (
					<Header
						text={
							selectedLicence
								? 'Edit Licence'
								: 'Add an SIA Licence'
						}
						type='text'
						backFunction={() => handleScreen('form')}
						origin='form'
						editMode={!!selectedLicence}
					/>
				)}
				<div className={isMobile ? '' : 'modal-content-scrollable'}>
					<div className='licence-input'>
						<div
							className={`licence-input-left ${
								foundDuplicateError.length || hasDuplicate
									? 'licence-error'
									: 'offset'
							}`}
						>
							<Input
								inputName='number'
								title='Licence number'
								placeholder='1234567812345678'
								value={formState.number}
								emitValue={handleGetInput}
								validationFunction={validations.siaValidation}
								validInputs={validInputs}
								setValidInputs={setValidInputs}
								required={true}
								continueClicked={continueClicked}
								numberOnly={true}
							/>
						</div>
						{formState.number && formState.number.length > 0 && (
							<img
								src={isValidLicence ? successIcon : errorIcon}
								alt='is valid number'
							/>
						)}
					</div>
					{foundDuplicateError && (
						<p className='duplicate-licence-error'>
							{`This SIA licence has already been added!`}
						</p>
					)}
					{hasDuplicate && (
						<div className='duplicate-error-container'>
							<br />
							<InfoCard
								size='small'
								icon={errorIcon}
								text='The SIA licence entered is already used with another account, which means you have probably signed up with Deploi previously.'
								subText='If this licence is yours, please log into your existing account.'
								type='validation-card'
							/>
						</div>
					)}
					<br />
					{hasDuplicate && isSeamless && (
						<p className='intercom-link'>
							Need help?{' '}
							<a
								href='https://intercom.help/deploi/en/collections/9332235-candidate-help'
								target='_blank'
								rel='noopener noreferrer'
							>
								Click Here
							</a>
						</p>
					)}
					{listCardItem && <ListCard item={listCardItem} />}
				</div>

				<div className='page-form-footer-outer-container'>
					<div className='page-form-footer-inner-container'>
						{hasDuplicate && !isSeamless ? (
							<OrkaButton
								buttonContent={'Log in to Another Account'}
								disabled={false}
								emitClicked={handleLogout}
								buttonClass={
									isMobile ? 'purple-outline' : 'blue-filled'
								}
							/>
						) : (
							<OrkaButton
								buttonContent={selectedLicence ? 'Edit' : 'Add'}
								disabled={
									foundDuplicateError.length > 0 ||
									hasDuplicate
								}
								emitClicked={handleClickComplete}
								buttonClass={
									isMobile ? 'purple-outline' : 'purple-filled'
								}
							/>
						)}
					</div>
				</div>
				{isMobile && <div className='margin-bottom-120' />}
			</ValidationLayer>
		</div>
	);
};

export default LicencesForm;
