// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react';
import { IDropdownStandard } from '../../types/interfaces/misc.interface';
import * as utils from '../../utils/utilsFunctions';

const useInitActivityDropdown: any = (selectedActivity: any) => {
	const [activityTypes, setActivityTypes] = useState<IDropdownStandard[]>([]);
	const [defaultActivity, setDefaultActivity] = useState<IDropdownStandard>();

	const [workPatterns, setWorkPatterns] = useState<IDropdownStandard[]>([]);
	const [defaultWorkPatten, setDefaultWorkPattern] =
		useState<IDropdownStandard>();

	const [careerBreakReasons, setCareerBreakReasons] = useState<
		IDropdownStandard[]
	>([]);
	const [defaultCareerBreakReason, setDefaultCareerBreakReason] =
		useState<IDropdownStandard>();

	const [livingInUk, setLivingInUk] = useState<IDropdownStandard[]>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [defaultLivingInUk, setDefaultLivingInUk] =
		useState<IDropdownStandard>();

	useEffect(() => {
		setActivityTypes(utils.getDropdownData('activityTypes', null));
		setWorkPatterns(utils.getDropdownData('workPatterns', null));
		setCareerBreakReasons(
			utils.getDropdownData('careerBreakOptions', null),
		);
		setLivingInUk(utils.getDropdownData('livingInUk', null));
	}, []);

	useEffect(() => {
		if (activityTypes.length > 0) {
			if (selectedActivity) {
				const filteredType = activityTypes.filter((activityType) => {
					return activityType.dbValue === selectedActivity.type;
				});
				setDefaultActivity(filteredType[0]);
			} else {
				setDefaultActivity(activityTypes[0]);
			}
		}
	}, [activityTypes.length, selectedActivity]);

	useEffect(() => {
		if (careerBreakReasons.length > 0 && activityTypes.length > 0) {
			if (selectedActivity) {
				const filteredCareerBreakReasons = careerBreakReasons.filter(
					(careerBreakReason) => {
						return (
							careerBreakReason.dbValue === selectedActivity.type
						);
					},
				);
				setDefaultCareerBreakReason(filteredCareerBreakReasons[0]);
				setDefaultActivity(activityTypes[5]);
			} else {
				setDefaultCareerBreakReason(careerBreakReasons[0]);
			}
		}
	}, [careerBreakReasons.length, selectedActivity, activityTypes.length]);

	useEffect(() => {
		if (workPatterns.length > 0) {
			if (selectedActivity) {
				const filteredType = workPatterns.filter((workPattern) => {
					return workPattern.dbValue === selectedActivity.isPartTime;
				});
				setDefaultWorkPattern(filteredType[0]);
			} else {
				setDefaultWorkPattern(workPatterns[1]);
			}
		}
	}, [workPatterns.length]);

	useEffect(() => {
		if (livingInUk.length > 0) {
			setDefaultLivingInUk(livingInUk[0]);
		}
	}, [livingInUk.length]);

	return {
		defaultActivityHook: defaultActivity,
		activityTypesHook: activityTypes,
		defaultWorkPattenHook: defaultWorkPatten
			? defaultWorkPatten
			: workPatterns[0],
		workPatternsHook: workPatterns,
		defaultCareerBreakReasonHook: defaultCareerBreakReason
			? defaultCareerBreakReason
			: careerBreakReasons[0],
		careerBreakReasonsHook: careerBreakReasons,
		livingInUkHook: livingInUk,
		defaultLivingInUkHook: defaultLivingInUk
			? defaultLivingInUk
			: livingInUk[0],
	};
};

export default useInitActivityDropdown;
