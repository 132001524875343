import './main-steps.styles.scss';
import React, { FC, useState } from 'react';
import chevronRight from '../../assets/icons/misc/chevron-right.svg';
import chevronUp from '../../assets/icons/misc/chevron-up.svg';
import chevronDown from '../../assets/icons/misc/chevron-down.svg';
import checkIcon from '../../assets/icons/misc/success-tick.svg';
import rejectedIcon from '../../assets/icons/misc/red-circle-cross-bold.svg';
type Props = {
	title: string;
	stepName: string;
	expandable: boolean;
	status?: string;
	clickFunction: (val: string) => void;
	children: React.ReactNode;
	disabled?: boolean;
};

const MainSteps: FC<Props> = ({
	title,
	stepName,
	expandable,
	children,
	status,
	clickFunction,
	disabled,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const handleClick = () => {
		if (disabled) {
			return;
		}
		if (!expandable) {
			clickFunction('');
			return;
		}
		setIsOpen(!isOpen);
	};

	return (
		<div
			className={`pointer ${disabled && 'disabled-step'}`}
			onClick={handleClick}
		>
			<div className='dropdown-row'>
				<div>
					<h3>{stepName}</h3>
					<div className='list-page-subheading'>{title}</div>
				</div>
				{status !== 'complete' && status !== 'rejected' && (
					<img
						src={
							!expandable
								? chevronRight
								: isOpen
									? chevronUp
									: chevronDown
						}
					/>
				)}
				{status === 'complete' && (
					<img src={checkIcon} alt='Step completed' />
				)}
				{status === 'rejected' && (
					<img src={rejectedIcon} alt='Step rejected' />
				)}
			</div>

			{isOpen && <div>{children}</div>}
		</div>
	);
};

export default MainSteps;
