import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../types/root-state';
import * as apiMisc from '../../api/requests/misc.api';
import * as apiActivity from '../../api/requests/activity.api';
import {
	setApplicationStatus,
	setStepsComplete,
	setOverallSubmissionStatus,
} from '../../redux/General/general.actions';
import { IApplicant } from '../../types/interfaces/applicant.interface';
import * as utils from '../../utils/utilsFunctions';
import { startCompliance } from '../../api/requests/misc.api';
import {
	setActivityHistory,
	setApplicant,
	updateApplicant,
} from '../../redux/Applicant/applicant.actions';
import { useNavigate } from 'react-router-dom';
import { getCandidateDetails } from '../../api/requests/applicant.api';
import { setRtwStatus, setIdCheckStatus, clearRTW } from '../../redux/RTW/rtw.actions';
import * as api from '../../api/requests/rtw.api';

type CustomHookReturnType = {
	applicationStatus: string;
	stepsComplete: { title: string; status: string; tag: string }[];
	applicant: IApplicant;
	overallSubmissionStatus: string;
};

const useApplicationInit = (): CustomHookReturnType => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const applicationStatus = useSelector(
		(state: RootState) => state.general.applicationStatus,
	);
	const applicant = useSelector(
		(state: RootState) => state.applicant.applicant,
	);
	const stepsComplete = useSelector(
		(state: RootState) => state.general.stepsComplete,
	);
	const overallSubmissionStatus = useSelector(
		(state: RootState) => state.general.overallSubmissionStatus,
	);
	const rtwStepStatus = useSelector(
		(state: RootState) => state.rtw.rtwStatus,
	);
	const idCheckStatus = useSelector(
		(state: RootState) => state.rtw.idCheckStatus,
	);

	useEffect(() => {
		const getApplicantDetails = () => {
			return getCandidateDetails()
				.then((res: any) => {
					dispatch(setApplicant(res.data));
				})
				.catch((err: Error) => {
					throw err;
				});
		};
		getApplicantDetails();
	}, []);

	useEffect(() => {
		const setApplicationStatusFunc = () => {
			return apiMisc
				.getApplicationStatus()
				.then((res: any) => {
					if (res.status === 404) {
						throw res;
					}
					dispatch(setApplicationStatus(res.data.status));
				})
				.catch((err: Error) => {
					dispatch(setApplicationStatus('not-started'));
					throw err;
				});
		};
		setApplicationStatusFunc();
	}, [applicationStatus]);

	useEffect(() => {
		dispatch(clearRTW());
		rtwStatuses();
	}, [applicant, applicationStatus]);

	useEffect(() => {
		if (applicant) {
			const setOverallSubmission = () => {
				return apiMisc
					.getOverallSubmission()
					.then((res: any) => {
						dispatch(
							setOverallSubmissionStatus(res?.data?.data?.status),
						);
					})
					.catch((err: Error) => {
						throw err;
					});
			};

			setOverallSubmission();
		}
	}, [applicant, overallSubmissionStatus]);

	useEffect(() => {
		if (
			overallSubmissionStatus === 'rejected' &&
			applicationStatus === 'application-submitted'
		) {
			const resetTerms = () => {
				return apiMisc
					.setApplicationStatus('terms-agreed')
					.then(() => {
						dispatch(setApplicationStatus('terms-agreed'));
						return navigate('/', { replace: true });
					})
					.catch((err: Error) => {
						throw err;
					});
			};
			resetTerms();
		}
	}, [applicationStatus, overallSubmissionStatus]);

	useEffect(() => {
		if (applicant) {
			const getActivityHistory = () => {
				return apiActivity
					.getActivityHistory()
					.then((res: any) => {
						dispatch(setActivityHistory(res.data));
					})
					.catch((err: Error) => {
						throw err;
					});
			};

			getActivityHistory();
		}
	}, [applicant, overallSubmissionStatus]);

	useEffect(() => {
		if (!applicant) {
			navigate('/login', { replace: true });
		}
	}, [applicant]);

	useEffect(() => {
		const setSteps = () => {
			return apiMisc
				.getStepsComplete()
				.then((res: any) => {
					dispatch(setStepsComplete(buildStepsArray(res.data)));
				})
				.catch((err: Error) => {
					throw err;
				});
		};

		setSteps();
	}, []);

	const rtwStatuses = () => {
		if (!applicant) {
			return;
		}
		api.getRtwStatuses(applicant.uuid)
			.then((res: any) => {
				const yotiRtwStatus = res.rtw?.yotiStatus || '';
				const manualUploadStatus = res.rtw?.manualUploadStatus || '';
				const shareCodeStatus = res.rtw?.shareCodeStatus || '';
				const onfido = res.rtw?.onfidoStatus || '';
				const yotiIdCheckStatus = res.idCheck?.yotiStatus || '';
				const ambassadorCheckStatus =
					res.idCheck?.ambassadorCheckStatus || '';

				const rtwStepStatusBuild = {
					page: '',
					checkName: '',
					status: 'incomplete',
				};
				const idStepStatusBuild = {
					page: '',
					checkName: '',
					status: 'incomplete',
				};

				if (manualUploadStatus) {
					rtwStepStatusBuild.page = `other-documents-${manualUploadStatus}`;
					rtwStepStatusBuild.status = manualUploadStatus;
					rtwStepStatusBuild.checkName = 'manualUploadStatus';
				}
				if (shareCodeStatus) {
					rtwStepStatusBuild.page = `share-code-${shareCodeStatus}`;
					rtwStepStatusBuild.status = shareCodeStatus;
					rtwStepStatusBuild.checkName = 'shareCodeStatus';
				}
				if (onfido) {
					rtwStepStatusBuild.page = `onfido-${onfido}`;
					rtwStepStatusBuild.status = onfido;
					rtwStepStatusBuild.checkName = 'onfido';
				}

				if (shareCodeStatus === 'rejected' && manualUploadStatus) {
					rtwStepStatusBuild.page = `other-documents-${manualUploadStatus}`;
					rtwStepStatusBuild.status = manualUploadStatus;
					rtwStepStatusBuild.checkName = 'manualUploadStatus';
				}

				if (yotiRtwStatus) {
					rtwStepStatusBuild.page = `passport-online-${yotiRtwStatus}`;
					rtwStepStatusBuild.status = yotiRtwStatus;
					rtwStepStatusBuild.checkName = 'yotiRtwStatus';
				}

				if (ambassadorCheckStatus) {
					idStepStatusBuild.page = `id-check-${ambassadorCheckStatus}`;
					idStepStatusBuild.status = ambassadorCheckStatus;
					idStepStatusBuild.checkName = 'ambassadorCheckStatus';
				}
				if (yotiIdCheckStatus) {
					idStepStatusBuild.page = `id-check-${yotiIdCheckStatus}`;
					idStepStatusBuild.status = yotiIdCheckStatus;
					idStepStatusBuild.checkName = 'yotiIdCheckStatus';
				}

				dispatch(setRtwStatus(rtwStepStatusBuild));
				dispatch(setIdCheckStatus(idStepStatusBuild));
			})
			.catch((err: Error) => {
				console.log(err);
			});
	};

	const buildStepsArray = (stepsObject: any) => {
		const stepsArray = [];
		for (const key in stepsObject) {
			const validatedKey = utils.getValidatedKey(key);
			const stepObject: any = {
				title: validatedKey,
				status: stepsObject[key],
			};
			if (validatedKey === 'Activity History') {
				stepObject.tag = 'longest section';
			}
			if (
				stepObject.title === 'General Information' &&
				stepObject.status === 'incomplete'
			) {
				stepObject.status = 'in-progress';
			}
			stepsArray.push(stepObject);
		}
		return stepsArray;
	};

	useEffect(() => {
		switch (applicationStatus) {
			case 'application-submitted':
				if (
					(rtwStepStatus.status === 'complete' &&
						idCheckStatus.status === 'complete') ||
					(rtwStepStatus.checkName === 'manualUploadStatus' &&
						idCheckStatus.status === 'complete')
				) {
					navigate('application-submitted', { replace: true });
				} else {
					navigate('', { replace: true });
				}

				break;
			case 'review-required':
				navigate('review-application', { replace: true });
				break;
			case 'terms-agreed':
				break;
			default:
				navigate('', { replace: true });
				return;
		}
	}, [applicationStatus, rtwStepStatus.status, idCheckStatus.status]);

	useEffect(() => {
		if (applicant && !applicant.complianceStarted) {
			startCompliance()
				.then(() => {
					dispatch(
						updateApplicant({
							complianceStarted: utils.formatDateForDb(
								new Date(),
							),
						}),
					);
				})
				.catch((err: Error) => {
					throw err;
				});
		}
	}, [applicant]);

	return {
		applicationStatus,
		stepsComplete,
		applicant,
		overallSubmissionStatus,
	};
};

export default useApplicationInit;
