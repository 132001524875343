import React, { FC, useEffect, useState } from 'react';
import './general-information.styles.scss';
import Header from '../../components/header/header/header.component';
import Layout from '../../components/general/layout-component/layout.component';
import InfoCard from '../../components/general/info-card/info-card.component';
import cardPencil from '../../assets/icons/info-card/pencil.svg';
import PageFooter from '../../components/general/page-footer/page-footer.component';
import Input from '../../components/general/input/input.component';
import * as validations from '../../utils/validations';
import ValidationLayer from '../../components/general/validation-layer/validation-layer.component';
import InnerLayout from '../../components/general/inner-layout/inner-layout.component';
import DropdownInfo from '../../components/general/dropdown-info/dropdown-info.component';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../types/root-state';
import * as api from '../../api/requests/applicant.api';
import * as utils from '../../utils/utilsFunctions';
import errorIcon from '../../assets/icons/misc/error.svg';
import { updateApplicant as updateApplicantStore } from '../../redux/Applicant/applicant.actions';
import { updateSteps } from '../../redux/General/general.actions';
import {
	IDropdownStandard,
	StepNamesEnum,
	StepStatusEnum,
} from '../../types/interfaces/misc.interface';
import Dropdown from '../../components/general/orka-dropdown/dropdown.component';
import { fireGaEvent } from '../../utils/ga-event';
import * as miscApi from '../../api/requests/misc.api';
import { useNavigate, useLocation } from 'react-router-dom';

const GeneralInformation: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { state } = useLocation();
	const applicant = useSelector(
		(state: RootState) => state.applicant.applicant,
	);
	const isMobile = useSelector((state: RootState) => state.general.isMobile);
	const isTablet = useSelector((state: RootState) => state.general.isTablet);

	const [formState, setFormState] = useState<any>({
		name: '',
		surname: '',
		contactNumber: '',
		gender: '',
		email: '',
		DOB: null,
		nationalInsuranceNumber: '',
	});
	const [isDisabled, setIsDisabled] = useState(false);
	const [pageName, setPageName] = useState<string | undefined>('');
	const [continueClicked, setContinueClicked] = useState(false);
	const [genders, setGenders] = useState<IDropdownStandard[]>([]);
	const [defaultGender, setDefaultGender] = useState<IDropdownStandard>();
	const [validInputs, setValidInputs] = useState([
		{ DOB: true, email: true, contactNumber: true, niNumber: true },
	]);
	const [submissionValidationError, setSubmissionValidationError] =
		useState(false);

	fireGaEvent('General Information', 'General Information - Page');

	useEffect(() => {
		utils.instantScroll();
		setPageName(utils.setPageName(state, navigate));
		if (applicant) {
			prePopulateForm();
		}
	}, []);

	const handleEmitInput = (val: string, input: string) => {
		if (input === 'nationalInsuranceNumber') {
			val = val.toUpperCase();
		}
		setFormState({
			...formState,
			[input]: val,
		});
	};

	useEffect(() => {
		setGenders(utils.getDropdownData('genders', null));
	}, []);

	useEffect(() => {
		if (genders.length > 0) {
			if (applicant && applicant.gender) {
				const filteredGender = genders.filter((gender) => {
					return gender.dbValue === applicant.gender;
				});
				filteredGender.length > 0
					? setDefaultGender(filteredGender[0])
					: setDefaultGender(genders[0]);
			} else {
				setDefaultGender(genders[0]);
			}
		}
	}, [genders.length]);

	const handleComplete = (): any => {
		utils.instantScroll();
		setContinueClicked(true);
		if (isDisabled) {
			fireGaEvent(
				'General Information',
				'click complete section (validation failed)',
			);
			setSubmissionValidationError(true);
			return;
		}
		fireGaEvent('General Information', 'click complete section');
		return updateApplicant()
			.then(() => {
				return submitGeneralInfoSection()
					.then(() => {
						return navigate('/', { replace: true });
					})
					.catch((err: Error) => {
						utils.handleAlerts('error', err.message, dispatch);
						throw err;
					});
			})
			.catch((err: Error) => {
				utils.handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	const updateApplicant = () => {
		const payload = {
			...formState,
			DOB: formState.DOB ? utils.formatDateForUser(formState.DOB) : null,
		};
		return api
			.updateCandidate(payload)
			.then(() => {
				dispatch(updateApplicantStore(formState));
			})
			.catch((err: Error) => {
				utils.handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	const submitGeneralInfoSection = () => {
		utils.instantScroll();
		return api
			.submitGeneralInfoSection(applicant.workerUuid)
			.then(() => {
				return miscApi
					.postStep({
						step: StepNamesEnum.generalInformation,
						status: StepStatusEnum.complete,
					})
					.then(() => {
						dispatch(
							updateSteps('General Information', 'complete'),
						);
						utils.handleAlerts(
							'success',
							'Successfully submitted section',
							dispatch,
						);
					})
					.catch((err: Error) => {
						throw err;
					});
			})
			.catch((err: Error) => {
				utils.handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	const prePopulateForm = () => {
		const populatedForm: any = {
			...formState,
		};
		let field: keyof typeof formState;
		for (field in formState) {
			populatedForm[field] = applicant[field] ? applicant[field] : '';
		}
		if (
			populatedForm.DOB &&
			populatedForm.DOB.match(/^\d{2}\/\d{2}\/\d{4}$/gm)
		) {
			populatedForm.DOB = new Date(
				parseDate(populatedForm.DOB.toString().substring(0, 10)),
			);
		}
		setFormState(populatedForm);
	};

	const parseDate = (stringDate: string) => {
		const [d, m, y] = stringDate.split('/');
		return `${y}/${m}/${d}`;
	};

	const getDropdownVal = (val: IDropdownStandard) => {
		if (typeof val.dbValue === 'string') {
			setFormState({
				...formState,
				gender: val.dbValue,
			});
			setDefaultGender(val);
		}
	};

	const handleBack = () => {
		return navigate('/', { replace: true });
	};

	return (
		<div className='general-information-page'>
			<ValidationLayer
				setIsDisabled={setIsDisabled}
				formState={formState}
				validInputs={validInputs}
				requiredFields={[
					'name',
					'surname',
					'contactNumber',
					'gender',
					'email',
					'DOB',
					'nationalInsuranceNumber',
				]}
				requiredChecks={['requiredFields', 'validInputs', 'niNumber']}
			>
				<Layout background=''>
					<Header
						text={pageName ? pageName : ''}
						type='text'
						backFunction={handleBack}
						origin='main'
						informationText={`We ask for this so we can verify who you are, and make sure we're looking at the right person when we perform various checks.`}
					>
						<InfoCard
							size='small'
							text='We’ve filled in some of your information based on your ID document'
							icon={cardPencil}
						/>
					</Header>

					<InnerLayout>
						<form>
							{isTablet && submissionValidationError && (
								<div className='submission-validation-error'>
									<InfoCard
										size='small'
										icon={errorIcon}
										text="You can't complete this section with missing fields."
										subText='If you wish to complete this section later you can tap the back arrow and your progress will be saved.'
										type='validation-card'
									/>
								</div>
							)}
							<Input
								inputName='name'
								title='First Name *'
								placeholder='John'
								value={formState.name}
								emitValue={handleEmitInput}
								required={true}
								continueClicked={continueClicked}
							/>

							<Input
								inputName='surname'
								title='Last Name *'
								placeholder='Nezzi'
								value={formState.surname}
								emitValue={handleEmitInput}
								required={true}
								continueClicked={continueClicked}
							/>

							<Input
								inputName='email'
								title='Email *'
								placeholder='example@example.com'
								value={formState.email}
								emitValue={handleEmitInput}
								validationFunction={validations.isValidEmail}
								validInputs={validInputs}
								setValidInputs={setValidInputs}
								required={true}
								continueClicked={continueClicked}
							/>

							<Input
								inputName='contactNumber'
								title='Phone Number *'
								placeholder='+447777777777'
								value={formState.contactNumber}
								emitValue={handleEmitInput}
								validationFunction={validations.isValidPhone}
								validInputs={validInputs}
								setValidInputs={setValidInputs}
								required={true}
								continueClicked={continueClicked}
							/>

							<Input
								inputName='nationalInsuranceNumber'
								title='Ni Number  *'
								placeholder='JR111111C'
								value={formState.nationalInsuranceNumber.toUpperCase()}
								emitValue={handleEmitInput}
								validationFunction={validations.niValidation}
								required={true}
								validInputs={validInputs}
								setValidInputs={setValidInputs}
								continueClicked={continueClicked}
							/>

							<div className='gender-dropdown margin-bottom-24'>
								<label>Preferred Gender</label>
								{defaultGender && (
									<Dropdown
										type='genders'
										dropdownData={genders}
										defaultData={defaultGender}
										getDropdownVal={getDropdownVal}
										width='327'
										position='top-center'
									/>
								)}
								{continueClicked && !formState.gender && (
									<div className='validation-error'>
										<p>This field is required</p>
									</div>
								)}
							</div>

							<Input
								inputName='DOB'
								title='Date of Birth *'
								placeholder='dd/mm/yyyy'
								value={
									formState.DOB
										? new Date(formState.DOB)
										: null
								}
								emitValue={handleEmitInput}
								isDate={true}
								validationFunction={validations.checkIsOver18}
								validInputs={validInputs}
								setValidInputs={setValidInputs}
								required={true}
								continueClicked={continueClicked}
							/>

							<PageFooter
								disabled={false}
								primaryButtonFunction={handleComplete}
								removeFixedFooter={isMobile}
							/>
						</form>
						<div>
							<DropdownInfo
								title={`Why do you need this information?`}
								text={`We ask for this so we can verify who you are, and make sure we're looking at the right person when we perform various checks.`}
								desktopVersion={true}
								handleClick={() =>
									fireGaEvent(
										'General Information',
										'Tapped why do you need this information dropdown',
									)
								}
							/>
							{!isTablet && submissionValidationError && (
								<InfoCard
									size='small'
									icon={errorIcon}
									text="You can't complete this section with missing fields."
									subText='If you wish to complete this section later you can tap the Deploi logo and your progress will be saved.'
									type='validation-card'
								/>
							)}
						</div>
					</InnerLayout>
				</Layout>
			</ValidationLayer>
			{!isMobile && <div className='margin-bottom-64' />}
		</div>
	);
};

export default GeneralInformation;
