import React, {
	useEffect,
	useState,
	useRef,
	useCallback,
	FC,
	RefObject,
} from 'react';
import './dropdown.styles.scss';
import chevronUp from '../../../assets/icons/misc/chevron-up.svg';
import chevronDown from '../../../assets/icons/misc/chevron-down.svg';

type Props = {
	type: string;
	defaultData?: any;
	dropdownData: any;
	getDropdownVal: any;
	width: string;
	height?: number;
	mobileResponsive?: boolean;
	position?: string;
	arrowOnly?: boolean;
	showValidationError?: boolean;
};

type StyleObject = {
	top: string;
	bottom: string;
};

const Dropdown: FC<Props> = ({
	type,
	defaultData,
	dropdownData,
	getDropdownVal,
	width = 327,
	height = 40,
	mobileResponsive = false,
	position = 'below-center',
	arrowOnly = false,
	showValidationError = false,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const triangleTopCenter = useRef<HTMLDivElement>(null);
	const triangleTopLeft = useRef<HTMLDivElement>(null);
	const triangleTopRight = useRef<HTMLDivElement>(null);
	const triangleBottomCenter = useRef<HTMLDivElement>(null);
	const triangleBottomLeft = useRef<HTMLDivElement>(null);
	const triangleBottomRight = useRef<HTMLDivElement>(null);
	const dropdownTop = useRef<HTMLDivElement>(null);

	// eslint-disable-next-line no-unused-vars
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [dropdownAreaRef, setDropdownAreaRef] = useState(null);

	const dropdownArea = useCallback(
		(node: any) => {
			setDropdownAreaRef(node);
			if (node !== null) {
				const elementHeight = node.clientHeight + Number(height);
				const elementWidth = node.clientWidth;

				const centerTranslate =
					(Number(elementWidth) - Number(width)) / 2;

				if (position.includes('top')) {
					node.style.top = `-${elementHeight}px`;
				}

				if (position.includes('center')) {
					node.style.left = `-${centerTranslate}px`;
				}

				if (position.includes('static')) {
					node.style.top = `-${elementHeight / 2}px`;
				}

				if (arrowOnly) {
					node.style.left = `-153px`;
				}

				if (type === 'actionList') {
					node.style.left = '-8px';
				}

				getTrianglePosition(position, elementWidth);
			}
		},
		[position, height, width],
	);

	const getTrianglePosition = (pos: string, width: number): void => {
		hideTriangles();
		const halfWidth = width / 2;

		switch (pos) {
			case 'top-center':
				repositionTriangles(
					triangleTopCenter,
					'-12px',
					`${halfWidth - 8}px`,
					'bottom',
				);
				return;
			case 'top-left':
				repositionTriangles(triangleTopLeft, '-12px', '16px', 'bottom');
				return;
			case 'top-right':
				repositionTriangles(
					triangleTopRight,
					'-12px',
					`${width - 28}px`,
					'bottom',
				);
				return;
			case 'bottom-center':
				repositionTriangles(
					triangleBottomCenter,
					'-12px',
					`${halfWidth - 8}px`,
					'top',
				);
				return;
			case 'bottom-left':
				repositionTriangles(triangleBottomLeft, '-12px', '16px', 'top');
				return;
			case 'bottom-right':
				repositionTriangles(
					triangleBottomRight,
					'-12px',
					`${width - 28}px`,
					'top',
				);
				return;
			default:
		}
	};

	const repositionTriangles = (
		ref: RefObject<HTMLDivElement>,
		top: string,
		left: string,
		position: keyof StyleObject,
	) => {
		if (ref.current) {
			ref.current.style.display = 'block';
			ref.current.style[position] = top;
			ref.current.style.left = left;
			return ref;
		}
	};

	const hideTriangles = () => {
		if (triangleTopCenter.current) {
			triangleTopCenter.current.style.display = 'none';
		}
		if (triangleTopLeft.current) {
			triangleTopLeft.current.style.display = 'none';
		}
		if (triangleTopRight.current) {
			triangleTopRight.current.style.display = 'none';
		}
		if (triangleBottomCenter.current) {
			triangleBottomCenter.current.style.display = 'none';
		}
		if (triangleBottomLeft.current) {
			triangleBottomLeft.current.style.display = 'none';
		}
		if (triangleBottomRight.current) {
			triangleBottomRight.current.style.display = 'none';
		}
	};

	const useOutsideAlerter = (ref: RefObject<HTMLDivElement>) => {
		useEffect(() => {
			/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
			const handleClickOutside = (event: any) => {
				if (ref.current && !ref.current.contains(event.target)) {
					setIsOpen(false);
				}
			};
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref]);
	};

	useOutsideAlerter(wrapperRef);

	useEffect(() => {
		if (dropdownRef.current) {
			dropdownRef.current.style.width = `${width}px`;
		}
	}, [dropdownRef, width, height]);

	useEffect(() => {
		if (dropdownTop.current) {
			dropdownTop.current.style.height = `${height}px`;
		}
	}, [height]);

	const handleOpenModal = () => {
		if (wrapperRef.current) {
			wrapperRef.current?.scrollIntoView();
		}
		setIsOpen(!isOpen);
	};

	/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
	const handleClick = (el: any) => {
		if (!el.disabled) {
			getDropdownVal(el);
			setIsOpen(false);
		}
	};

	const getTopClass = () => {
		if (mobileResponsive) {
			return `${defaultData.class} dropdown-top-content mobile`;
		} else if (arrowOnly) {
			return `${defaultData.class} dropdown-top-content arrow-only`;
		} else {
			return `${defaultData.class} dropdown-top-content`;
		}
	};

	const getTopContentClass = () => {
		if (mobileResponsive) {
			return `drop-top-img mobile`;
		} else if (arrowOnly) {
			return `drop-top-img arrow-only`;
		} else {
			return `drop-top-img`;
		}
	};

	const getActionClass = () => {
		switch (type) {
			case 'actionList':
				return 'dropdown-top action-list';
			default:
				return 'dropdown-top';
		}
	};

	return (
		<div className='dropdown-area-container' ref={wrapperRef}>
			<div
				className={getActionClass()}
				onClick={handleOpenModal}
				ref={dropdownRef}
			>
				<div className={getTopClass()} ref={dropdownTop}>
					<div className={getTopContentClass()}>
						{type === 'complianceStatus' && (
							<img src={defaultData.icon} alt='icon' />
						)}
						{!arrowOnly && (
							<p className='dropdown-header-text'>
								{defaultData.value}
							</p>
						)}
					</div>
					<img src={isOpen ? chevronUp : chevronDown} alt='arrow' />
				</div>
				{isOpen && (
					<div
						className={`dropdown-area ${position} mobile`}
						ref={dropdownArea}
					>
						<div
							className='top-center-triangle triangle triangle-reversed'
							ref={triangleTopCenter}
						/>
						<div
							className='top-left-triangle triangle triangle-reversed'
							ref={triangleTopLeft}
						/>
						<div
							className='top-right-triangle triangle triangle-reversed'
							ref={triangleTopRight}
						/>
						<div
							className='bottom-center-triangle triangle'
							ref={triangleBottomCenter}
						/>
						<div
							className='bottom-left-triangle triangle'
							ref={triangleBottomLeft}
						/>
						<div
							className='bottom-right-triangle triangle'
							ref={triangleBottomRight}
						/>
						<div className='dropdown-scrollable-area'>
							{dropdownData.map((el: any) => {
								return (
									<div
										className={
											el.disabled
												? 'dropdown-item disabled'
												: 'dropdown-item'
										}
										key={el.id}
										onClick={() => handleClick(el)}
									>
										<p
											className={
												type === 'searchBy' &&
												el.selected
													? 'selected'
													: ''
											}
										>
											{el.value}
										</p>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</div>
			{showValidationError && (
				<div className='validation-error'>
					<p>Please select an option</p>
				</div>
			)}
		</div>
	);
};

export default Dropdown;
