import React, { FC } from 'react';
import './grey-text-card.styles.scss';
type Props = {
	children: React.ReactNode;
};
const GreyTextCard: FC<Props> = ({ children }) => {
	return <article className='grey-text-card'>{children}</article>;
};

export default GreyTextCard;
