import {
	AUTH,
	CLEAR_AUTH,
	LOGIN,
	CLEAR_LOGGED_IN,
	IS_SEAMLESS,
} from './auth.types';

export const clearAuth = () => {
	return {
		type: CLEAR_AUTH,
	};
};

export const login = () => {
	return {
		type: LOGIN,
	};
};

export const clearLogin = () => {
	return {
		type: CLEAR_LOGGED_IN,
	};
};

export const isSeamless = () => {
	return {
		type: IS_SEAMLESS,
	};
};
