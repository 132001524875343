import React, { FC } from 'react';
import './review-application.styles.scss';
import Layout from '../../components/general/layout-component/layout.component';
import PageFooter from '../../components/general/page-footer/page-footer.component';
import { useDispatch, useSelector } from 'react-redux';
import {
	setApplicationStatus,
	setOverallSubmissionStatus,
} from '../../redux/General/general.actions';
import ReviewMain from '../../components/review-section/review-main/review-main.component';
import * as apiMisc from '../../api/requests/misc.api';
import { fireGaEvent } from '../../utils/ga-event';
import { RootState } from '../../types/root-state';
import TopBar from '../../components/general/top-bar/top-bar.component';
import { useNavigate, useLocation } from 'react-router-dom';

const ReviewApplication: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { state } = useLocation();
	const overallSubmissionStatus = useSelector(
		(state: RootState) => state.general.overallSubmissionStatus,
	);

	const checkUploadCount = () => {
		const [activityHistory] = useSelector(
			(state: RootState) => state.applicant.activityHistory,
		);

		if (activityHistory) {
			if (activityHistory.files?.length < 1) {
				return true;
			} else {
				return false;
			}
		}
		return true;
	};

	fireGaEvent('Review', 'Review Application - Page');
	const handleBack = () => {
		return apiMisc
			.setApplicationStatus('terms-agreed')
			.then(() => {
				dispatch(setApplicationStatus('terms-agreed'));
				return navigate('/', { replace: true });
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	const handleStartConsent = () => {
		return navigate('/check/consent', {
			state: {
				page: 'Consent',
			},
			replace: true,
		});
	};

	const handleResubmit = () => {
		return apiMisc
			.resubmit()
			.then(() => {
				return handlePostOverallSubmission();
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	const handlePostOverallSubmission = () => {
		return apiMisc
			.setApplicationStatus('application-submitted')
			.then(() => {
				dispatch(setOverallSubmissionStatus('pending'));
				dispatch(setApplicationStatus('application-submitted'));
				return navigate('check/application-submitted', {
					replace: true,
				});
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	const handleNext = () => {
		if (overallSubmissionStatus === 'rejected') {
			return handleResubmit();
		} else {
			return handleStartConsent();
		}
	};

	return (
		<Layout background='dark'>
			<TopBar />
			<ReviewMain />
			<PageFooter
				secondaryButtonFunction={handleBack}
				secondaryButtonText={'Back'}
				primaryButtonFunction={handleNext}
				primaryButtonText={
					overallSubmissionStatus === 'rejected'
						? 'Resubmit'
						: 'Submit'
				}
				fromSettings={!!state?.fromSettings}
				disabled={checkUploadCount()}
			/>
		</Layout>
	);
};

export default ReviewApplication;
