import { useEffect } from 'react';
import * as utils from '../utils/utilsFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../types/root-state';
import { StepNamesEnum } from '../types/interfaces/misc.interface';

const useResubmitStepStatus = (
	isDisabled: boolean,
	stepNameEnumTitle: StepNamesEnum,
	stepTitle: string,
): any => {
	const dispatch = useDispatch();
	const stepsComplete = useSelector(
		(state: RootState) => state.general.stepsComplete,
	);
	useEffect(() => {
		if (
			isDisabled &&
			stepsComplete.filter((step) => step.title === stepTitle)[0]
				.status === 'complete'
		) {
			utils.resetSectionSubmission(stepNameEnumTitle, dispatch);
		}
	}, [isDisabled]);
};

export default useResubmitStepStatus;
