import { ENDPOINTS } from '../utils/endpoints';
import { axiosInstance } from '../utils/api.axiosInstance';
import { getRequestURLFromParams } from '../utils/api.utils';
import { IActivityItem } from '../../types/interfaces/applicant.interface';

export const getActivityHistory = (): Promise<any> => {
	const url = getRequestURLFromParams(ENDPOINTS.ALL_ACTIVITY);
	return axiosInstance()
		.get(url)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const saveActivity = (activity: any): any => {
	const url = getRequestURLFromParams(ENDPOINTS.ACTIVITY);
	return axiosInstance()
		.post(url, activity)
		.catch((err) => {
			throw err;
		});
};

export const updateActivity = (activity: Partial<IActivityItem>): any => {
	const url = getRequestURLFromParams(
		ENDPOINTS.CANDIDATE_ACTIVITY_ITEM_BY_UUID,
		{
			uuid: activity.uuid,
		},
	);
	return axiosInstance()
		.patch(url, activity)
		.catch((err) => {
			throw err;
		});
};

export const getActivityByUuid = (
	activityId: string,
	workerUuid: string,
): any => {
	const url = getRequestURLFromParams(ENDPOINTS.ACTIVITY_ITEM_BY_UUID, {
		workerUuid,
		itemUuid: activityId,
	});
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const deleteActivity = (
	workerUuid: string,
	activityUuid: string,
): any => {
	const url = getRequestURLFromParams(ENDPOINTS.ACTIVITY_ITEM_BY_UUID, {
		workerUuid,
		uuid: activityUuid,
	});
	return axiosInstance()
		.delete(url)
		.catch((err) => {
			throw err;
		});
};

export const saveActivitySubmission = (): any => {
	const url = getRequestURLFromParams(ENDPOINTS.ACTIVITY_SUBMISSION);
	return axiosInstance()
		.post(url)
		.catch((err) => {
			throw err;
		});
};

export const getOrganisations = (search: string): any => {
	const url = getRequestURLFromParams(ENDPOINTS.ORGANISATIONS);

	return axiosInstance()
		.get(`${url}?search=${search}`)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};
