import React, { FC } from 'react';
import './header.styles.scss';
import trash from '../../../assets/icons/misc/trash.svg';
import DropdownInfo from '../../general/dropdown-info/dropdown-info.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import StepsDropdown from '../../general/steps-dropdown/steps-dropdown.component';
import deploiLogo from '../../../assets/logos/deploi-logo-white.svg';
import { fireGaEvent } from '../../../utils/ga-event';
import { useNavigate } from 'react-router-dom';
import backWhite from '../../../assets/icons/misc/back-white.svg';
import orkaPassportLogo from '../../../assets/logos/orka-passport-logo.svg';

type Props = {
	type: string;
	text?: React.ReactNode;
	backFunction: (origin: string) => void;
	origin: string;
	deleteFunction?: () => void;
	editMode?: boolean;
	hideStepsCounter?: boolean;
	hideDesktopSteps?: boolean;
	hideBackButton?: boolean;
	informationText?: string;
	children?: any;
	updatePassport?: boolean;
};

const Header: FC<Props> = ({
	text,
	deleteFunction,
	editMode,
	informationText,
	children,
	backFunction,
	hideStepsCounter,
	hideDesktopSteps,
	hideBackButton,
	updatePassport,
}) => {
	const navigate = useNavigate();
	const isTablet = useSelector((state: RootState) => state.general.isTablet);

	const handleBack = () => {
		fireGaEvent(`${text}`, 'Click back button');
		backFunction(origin);
	};

	const activitySteps = useSelector(
		(state: RootState) => state.general.stepsComplete,
	);

	const activityScreens = [
		...activitySteps,
		{ title: 'Consent', status: 'complete' },
	];

	const getActivityStepCount = (array: any[]): number => {
		const pathName = window.location.pathname;
		const [pageTitle] = pathName.split('/').reverse();
		const updatedArray = array.map((item, index) => {
			return { ...item, count: index + 1 };
		});

		const stepCount = updatedArray.filter((step) => {
			return step.title.toLowerCase().replace(/ /g, '-') === pageTitle;
		});

		return stepCount.length ? stepCount[0].count : 0;
	};

	return (
		<header className='secondary-header-container'>
			{isTablet ? (
				<div className='secondary-header-inner-container-tablet'>
					<div className='header-icons'>
						{!hideBackButton && (
							<button
								onClick={handleBack}
								className='back-button'
							>
								<img src={backWhite} alt='back' />
							</button>
						)}
						{editMode && deleteFunction && (
							<button
								onClick={deleteFunction}
								className='trash-icon'
							>
								<img src={trash} alt='delete' />
							</button>
						)}
						{!hideStepsCounter && (
							<div className='steps-counter'>
								<span>
									{getActivityStepCount(activityScreens)}{' '}
								</span>
								<span>/ {activityScreens.length}</span>
							</div>
						)}
					</div>

					<h1
						className={
							updatePassport
								? 'header-title header-margin'
								: 'header-title'
						}
					>
						{text}
					</h1>
					<div className='header-extra-info'>
						{informationText && (
							<DropdownInfo
								title={`Why do you need this information?`}
								text={informationText}
								handleClick={() =>
									fireGaEvent(
										`${text} Mobile`,
										'Tapped why do you need this information dropdown',
									)
								}
							/>
						)}
						{children}
					</div>
				</div>
			) : (
				<div className='secondary-header-inner-container'>
					<div className='desktop-header-standard'>
						<div className='desktop-header-standard-top'>
							<button
								className={'logo'}
								onClick={() => navigate('/', { replace: true })}
							>
								<img 
									src={deploiLogo}
									alt='logo'
									className='orka-logo'
								/>
							</button>
							{!hideDesktopSteps &&
								text !== 'Consent' &&
								text !== `It's important you read this!` && (
									<StepsDropdown />
								)}
						</div>
						<div
							className={
								updatePassport
									? 'desktop-header-standard-heading header-margin'
									: 'desktop-header-standard-heading'
							}
						>
							<h1>{text}</h1>
						</div>
					</div>
				</div>
			)}
		</header>
	);
};

export default Header;
