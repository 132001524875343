import { LicenceActionProps } from './licences.actions';
import { SAVE_SIA_DATA } from './licences.types';

const initialState = { data: [] };

const licenceReducer = (
	state: any = initialState,
	action: LicenceActionProps,
): void => {
	switch (action.type) {
		case SAVE_SIA_DATA:
			return {
				...state,
				data: [action.payload],
			};
		default:
			return state;
	}
};

export default licenceReducer;
