import React, {
	FC,
	Dispatch,
	SetStateAction,
	FormEvent,
	useState,
	useEffect,
} from 'react';
import './login-input.styles.scss';
import eyeIcon from '../../../assets/icons/login/eye.svg';
import eyeIconOff from '../../../assets/icons/login/eye-off.svg';

type Props = {
	name: any;
	value: string;
	getValue: (name: any, value: string) => void;
	placeholder: string;
	icon: string;
	type?: string;
	validationClass?: string;
	clearValidation?: Dispatch<SetStateAction<string>>;
};

const Input: FC<Props> = ({
	name,
	value,
	getValue,
	placeholder,
	icon,
	type,
	validationClass = '',
	clearValidation = null,
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const [inputType, setInputType] = useState(type);

	useEffect(() => {
		if (type === 'password' && showPassword) {
			setInputType('text');
		} else if (type === 'password' && !showPassword) {
			setInputType('password');
		} else {
			setInputType(type);
		}
	}, [type, showPassword]);

	const handleChange = (e: FormEvent<HTMLInputElement>) => {
		getValue([name], e.currentTarget.value);
	};

	const focus = () => {
		if (clearValidation) {
			clearValidation('');
		}
	};

	return (
		<div className={`login-input ${validationClass}`}>
			<img src={icon} alt='name' />
			{type === 'tel' && <p className='plus-44'>+44</p>}
			<input
				type={inputType}
				name={name}
				value={value}
				onChange={handleChange}
				placeholder={type === 'tel' ? '' : placeholder}
				className='login-stripped-input'
				onFocus={focus}
			/>
			{type === 'password' && (
				<button
					onClick={() => setShowPassword(!showPassword)}
					className='show-password-button'
					type='button'
				>
					<img
						src={showPassword ? eyeIcon : eyeIconOff}
						alt='show password'
					/>
				</button>
			)}
		</div>
	);
};

export default Input;
