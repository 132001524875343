import React, { FC } from 'react';
import Header from '../../../header/header/header.component';
import InfoCard from '../../../general/info-card/info-card.component';
import book from '../../../../assets/icons/info-card/book.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types/root-state';
import InnerLayout from '../../../general/inner-layout/inner-layout.component';
import * as utils from '../../../../utils/utilsFunctions';

type Props = {
	handleBack: (origin: string) => void;
	emitSelection: (origin: string) => void;
};

const ResidenceCard: FC<Props> = ({ handleBack, emitSelection }) => {
	const isTablet = useSelector((state: RootState) => state.general.isTablet);

	const openIntercomLink = () => {
		utils.intercomShareCodeLink();
	};

	return (
		<div>
			<Header
				text='Generate your right to work share code'
				type='text'
				backFunction={handleBack}
				origin='main'
				hideStepsCounter={true}
				hideDesktopSteps={true}
			></Header>
			<InnerLayout>
				<div>
					<p>
						We can’t accept biometric residence card or permit but
						you can generate a share code using your BRP or BRC.
					</p>
					<p className='margin-top-24'>
						Learn more about how to generate a share code here.{' '}
					</p>
					{isTablet && (
						<div
							onClick={openIntercomLink}
							className='margin-top-24'
						>
							<InfoCard
								size='small'
								icon={book}
								text='How to generate share code?'
							/>
						</div>
					)}

					<div className='identity-footer'>
						<button
							onClick={() => emitSelection('share-code')}
							className='margin-bottom-8 orka-identity-button primary-filled'
						>
							I generated a Share code
						</button>
					</div>
				</div>
				<div onClick={openIntercomLink} className='margin-top-24'>
					<InfoCard
						size='small'
						icon={book}
						text='How to generate share code?'
					/>
				</div>
			</InnerLayout>
		</div>
	);
};

export default ResidenceCard;
