import React, { useEffect, FC, Dispatch, SetStateAction, useState } from 'react';
import './bottom-sheet-modal.styles.scss';
import cross from '../../../assets/icons/misc/cross.svg';
import { CSSTransition } from 'react-transition-group';

type Props = {
	children: React.ReactNode;
	setShowModal: Dispatch<SetStateAction<boolean>>;
	showModal: boolean;
	titleEmphasisText?: string | null;
	closeFunction?: (origin: string) => void;
	pageOrigin?: string;
};

const BottomSheetModal: FC<Props> = ({ children, setShowModal, showModal, closeFunction, pageOrigin }) => {
	const [fadeIn, setFade] = useState<boolean>(false);
	useEffect(() => {
		if (showModal) {
			document.body.style.overflow = 'hidden';

			return () => {
				document.body.style.overflow = 'scroll';
			};
		}
	}, [showModal]);

	const handleEmitClose = () => {
		if (closeFunction && pageOrigin) {
			closeFunction(pageOrigin);
		} else {
			setShowModal(false);
		}
	};

	useEffect(() => {
		setTimeout(() => {
			setFade(true);
		}, 100);
	}, []);

	return (
		<div className='modal-container'>
			<CSSTransition in={fadeIn} timeout={1000} classNames='my-node'>
				<div>
					{fadeIn && (
						<>
							<div className='modal-content'>
								<div className='modal-header'>
									<button
										onClick={() => {
											setShowModal(false);
										}}
										className='close-modal-button'
										autoFocus
									>
										<img alt='close' src={cross} />
									</button>
								</div>
								<div className='modal-center'>{children}</div>
							</div>

							<div className={`bottom-sheet-modal-content-mobile`}>
								<div className='bottom-sheet-modal-header'>
									<button
										onClick={handleEmitClose}
										className='close-bottom-sheet-modal-button'
										autoFocus
									>
										<img alt='close' src={cross} />
									</button>
								</div>
								<div className='bottom-sheet-modal-center'>{children}</div>
							</div>
						</>
					)}
				</div>
			</CSSTransition>
		</div>
	);
};

export default BottomSheetModal;
