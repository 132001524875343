import React, { FC } from 'react';
import './steps-card.styles.scss';
import chevronRight from '../../../assets/icons/misc/chevron-right.svg';
import ProgressBar from '../../general/progress-bar/progress-bar.component';
import checkIcon from '../../../assets/icons/misc/success-tick.svg';
import rejectedIcon from '../../../assets/icons/misc/red-circle-cross-bold.svg';

type Props = {
	title: string;
	status: string;
	clickFunction: (val: string) => void;
	tag?: string;
	disabled?: boolean;
};

const StepsCard: FC<Props> = ({ title, status, clickFunction, disabled }) => {
	const cardAction = () => {
		if (disabled) {
			return;
		}
		clickFunction(title);
	};
	return (
		<div
			className={`steps-card-container ${
				status === 'complete' && 'complete'
			} ${status === 'rejected' && 'rejected'}
			${disabled && 'disabled'}`}
			onClick={cardAction}
		>
			<div className='steps-left'>
				{status !== 'rejected' && (
					<div className='steps-top'>
						<div className='steps-progress'>
							<ProgressBar status={status} />
						</div>
					</div>
				)}
				<div className='steps-center'>
					<p>{title}</p>
					{status !== 'complete' && status !== 'rejected' && (
						<img
							src={chevronRight}
							alt='arrow right'
							className='steps-action-image'
						/>
					)}
					{status === 'complete' && (
						<img src={checkIcon} alt='Step completed' />
					)}
					{status === 'rejected' && (
						<img src={rejectedIcon} alt='Step rejected' />
					)}
				</div>
			</div>
		</div>
	);
};

export default StepsCard;
