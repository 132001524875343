import React, {
	FC,
	useEffect,
	useState,
	SetStateAction,
	Dispatch,
} from 'react';
import './main-header.styles.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import TopBar from '../../general/top-bar/top-bar.component';
import useSetStepsComplete from '../../../utils/useSetStepsComplete';
import { useNavigate } from 'react-router-dom';
import { fireGaEvent } from '../../../utils/ga-event';
import MainHeaderTablet from '../main-header-tablet/main-header-tablet.component';
import MainHeaderDesktop from '../main-header-desktop/main-header-desktop.component';

type Props = {
	postSubmission?: boolean;
	informationText?: string;
	actionCardText: string | null;
	actionCardEmphasisText: string | null;
	actionCardSubText: string | null;
	children?: any;
	setShowProgress?: Dispatch<SetStateAction<boolean>>;
	showProgress?: boolean;
	setShowModal?: (showModal: boolean) => void;
	showModal?: boolean;
};

const MainHeader: FC<Props> = ({
	postSubmission,
	setShowProgress,
	showProgress,
	showModal,
	setShowModal,
	actionCardText = null,
	actionCardEmphasisText = null,
	actionCardSubText = null,
}) => {
	const steps = useSelector(
		(state: RootState) => state.general.stepsComplete,
	);
	const activityHistory = useSelector(
		(state: RootState) => state.applicant.activityHistory,
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [activitiesComplete, setActivitiesComplete] = useState(0);
	const [percentage, setPercentage] = useState('');
	const [postSubmissionStatus, setPostSubmissionStatus] = useState('');
	const [hasReference, setHasReference] = useState(false);
	const navigate = useNavigate();
	const isTablet = useSelector((state: RootState) => state.general.isTablet);
	const noComplete = useSetStepsComplete();

	const handleStartConsent = () => {
		fireGaEvent('Home', 'Taps start terms button');
		navigate('/check/terms', { replace: true });
	};

	useEffect(() => {
		const foundReference =
			activityHistory?.filter((element) => element.reference).length > 0;
		setHasReference(foundReference);
		if (postSubmission && foundReference) {
			const activitiesCompleteReduced = activityHistory.reduce(
				(activityCount, currentActivity) => {
					if (
						currentActivity?.reference?.status ===
						'All Evidence Received' ||
						currentActivity?.reference?.status ===
						'Documents Received'
					) {
						activityCount += 1;
					}
					return activityCount;
				},
				0,
			);

			const total = activityHistory.length;
			const percent = (activitiesCompleteReduced / total) * 100;
			setPercentage(`${percent}%`);
			setPostSubmissionStatus(
				`${activitiesCompleteReduced} out of ${total} activities verified`,
			);
		}
	}, [activitiesComplete, percentage, showProgress]);

	useEffect(() => {
		if (!postSubmission) {
			const numberComplete = steps.filter((step) => {
				return step.status === 'complete';
			}).length;
			const total = steps.length;
			const percent = (numberComplete / total) * 100;
			setPercentage(`${percent}%`);
		}
	}, [JSON.stringify(steps)]);

	return (
		<header className='header-container'>
			<TopBar />
			{isTablet ? (
				<MainHeaderTablet
					handleStartConsent={handleStartConsent}
					percentage={percentage}
					setShowProgress={hasReference ? setShowProgress : null}
					showProgress={showProgress}
					actionCardText={actionCardText}
					actionCardEmphasisText={actionCardEmphasisText}
					actionCardSubText={actionCardSubText}
					showModal={showModal}
					setShowModal={setShowModal}
				/>
			) : (
				<MainHeaderDesktop
					handleStartConsent={handleStartConsent}
					percentage={percentage}
					noComplete={noComplete}
					setShowProgress={setShowProgress}
					postSubmissionStatus={postSubmissionStatus}
					showProgress={showProgress}
					actionCardText={actionCardText}
					actionCardEmphasisText={actionCardEmphasisText}
					actionCardSubText={actionCardSubText}
					showModal={showModal}
					setShowModal={setShowModal}
				/>
			)}
		</header>
	);
};

export default MainHeader;
