import './licences-confirm.styles.scss';

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';

import * as utils from '../../../utils/utilsFunctions';
import ListCard from '../../general/list-card/list-card.component';
import Header from '../../header/header/header.component';

type Props = {
	handleScreen: (origin: string) => void;
	isPassport?: boolean;
};

const LicencesConfirm: FC<Props> = ({ handleScreen, isPassport }) => {
	const licences = useSelector(
		(state: RootState) => state.applicant.licences,
	);

	return (
		<div className='licences-confirm-container'>
			<Header
				text='SIA Licence Confirmed'
				type='text'
				backFunction={() => handleScreen('confirm')}
				origin='confirm'
				hideDesktopSteps={isPassport}
			/>
			{licences.length > 0 && (
				<div className='licences-card-inner'>
					{licences.map((licence, index) => {
						if (licences.length - 1 === index) {
							const listItem = {
								uuid: index.toString(),
								title: licence.name + ' ' + licence.surname,
								lineOne: licence.number,
								lineTwo: `Expires: ${utils.formatDateForUser(
									licence.expiryDate
										? licence.expiryDate
										: licence.expiry_date,
								)}`,
							};
							return (
								<div
									className='licence-card-container'
									key={licence.number}
								>
									<ListCard item={listItem} />
								</div>
							);
						}
					})}
					<br></br>
					<div className='margin-bottom-24'>
						<h2>
							Take a photo of the front and back of your SIA
							Licence
						</h2>
					</div>

					<p className='margin-bottom-24'>
						Please make sure your photo:
					</p>

					<p className='bullet-primary margin-bottom-24'>
						Is clear and in focus with all information easy to read
					</p>
					<p className='bullet-primary margin-bottom-24'>
						Has no glare
					</p>
					<p className='bullet-primary margin-bottom-24'>
						Shows the entire licence
					</p>
					<div style={{ paddingBottom: 105 }}></div>
					<div className='identity-footer'>
						<button
							onClick={() => handleScreen('upload-front')}
							className='margin-bottom-8 orka-identity-button primary-filled'
						>
							Take Photos
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default LicencesConfirm;
