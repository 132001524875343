import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './activity-documents.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { handleUploadDocuments } from '../../../utils/document-uploads';
import ActivityDocumentsUploadCard from '../activity-document-upload-card/activity-document-upload-card.component';
import ActivityDocumentExamples from './activity-document-example.component';
import {
	IActivityFormState,
	IActivityItem,
} from '../../../types/interfaces/applicant.interface';
import { fireGaEvent } from '../../../utils/ga-event';
import { handleAlerts, instantScroll } from '../../../utils/utilsFunctions';
import * as api from '../../../api/requests/activity.api';
import { setActivityHistory } from '../../../redux/Applicant/applicant.actions';
import * as docApi from '../../../api/requests/documents.api';
import { activityDocumentInfo } from '../../../utils/data';
import GuideCard from '../activity-guide/guide-card.component';
import doc from '../../../assets/icons/misc/doc.svg';
import tick from '../../../assets/icons/misc/tick.svg';

type Props = {
	handleBack: (origin: string) => void;
	setShowForm: Dispatch<SetStateAction<boolean>>;
	setShowList: Dispatch<SetStateAction<boolean>>;
	setShowUpload: Dispatch<SetStateAction<boolean>>;
	setShowReasons: Dispatch<SetStateAction<boolean>>;
	selectedActivity: IActivityItem;
	setSelectedActivity: Dispatch<
		SetStateAction<IActivityItem | null | IActivityFormState>
	>;
};

const ActivityDocuments: FC<Props> = ({
	selectedActivity,
	setSelectedActivity,
}) => {
	const dispatch = useDispatch();
	const applicant = useSelector(
		(state: RootState) => state.applicant.applicant,
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [activityDocTypeData, setActivityDocTypeData] = useState<any>([]);
	const [showDocExample, setShowDocExample] = useState<any>(false);

	fireGaEvent('Activity History', 'Activity History - Upload Document Page');

	useEffect(() => {
		instantScroll();
	}, []);

	useEffect(() => {
		const selectedData = activityDocumentInfo.filter((activity) => {
			let selectedActivityType = selectedActivity.type;
			if (selectedActivityType === 'career-break') {
				selectedActivityType = selectedActivity.careerBreakReasonType;
			}
			return activity.activity === selectedActivityType;
		})[0];
		setActivityDocTypeData(selectedData.documentTypes);
	}, []);

	const handleUpload = (file: any) => {
		fireGaEvent('Activity History', 'Clicked to upload document');
		return handleUploadDocuments(
			file,
			applicant.workerUuid,
			'documentStart',
			dispatch,
			'activityHistory',
			selectedActivity,
		)
			.then(() => {
				return fetchActivityHistory()
					.then((res: any) => {
						const activity = res.data.filter(
							(activity: IActivityItem) =>
								activity.uuid === selectedActivity.uuid,
						);
						setSelectedActivity(activity[0]);
					})
					.catch((err: Error) => {
						throw err;
					});
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	const fetchActivityHistory = () => {
		return api
			.getActivityHistory()
			.then((res) => {
				dispatch(setActivityHistory(res.data));
				return res;
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	const handleRemoveDocument = (fileUuid: string) => {
		fireGaEvent('Activity History', 'Clicked to remove activity document');
		return docApi
			.removeActivityDoc(fileUuid, selectedActivity.uuid)
			.then(() => {
				return fetchActivityHistory()
					.then((res: any) => {
						const activity = res.data.filter(
							(activity: IActivityItem) =>
								activity.uuid === selectedActivity.uuid,
						);
						setSelectedActivity(activity[0]);
					})
					.catch((err: Error) => {
						throw err;
					});
			})
			.catch((err: Error) => {
				handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	return (
		<>
			<section className='activity-documents-container'>
				<GuideCard
					title='Provide documents'
					titleIcon={doc}
					background='#FFE1A5'
					hasTag={false}
				>
					<p>
						We need documents that verify when you started and
						finished this activity.
					</p>
					<ul className='document-type-list'>
						<li>
							<img src={tick} alt='document type list' />
							<p>
								A document that shows the{' '}
								<strong>end date</strong>
							</p>
						</li>
						<li>
							<img src={tick} alt='document type list' />
							<p>
								A document that shows the{' '}
								<strong>start date</strong>
							</p>
						</li>
						<li>
							<img src={tick} alt='document type list' />
							<p>
								Activities over a year will need further
								documents. (Please send us any other documents
								you can find to help us)
							</p>
						</li>
						<li>
							<img src={tick} alt='document type list' />
							<p>
								Documents must be different from each other
								<a
									className='external-doc-link'
									href='/check/example-document'
									target='_blank'
									rel='noreferrer'
								>
									Show example document
								</a>
							</p>
						</li>
					</ul>
				</GuideCard>

				<ActivityDocumentsUploadCard
					handleUpload={handleUpload}
					documents={selectedActivity.files}
					requiredDocsNumber={3}
					isActivityCard={true}
					removeActivityDoc={handleRemoveDocument}
				/>
			</section>
			{showDocExample && (
				<ActivityDocumentExamples
					setShowDocExample={setShowDocExample}
				/>
			)}
		</>
	);
};

export default ActivityDocuments;
