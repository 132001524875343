import React, { FC, useState, useEffect } from 'react';
import './working-on-application.styles.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import ActivityComplete from '../activity-complete/activity-complete.component';
import ActivityItem from '../activity-item/activity-item.component';
import ActivityInfo from '../activity-info/activity-info.component';
import { IActivityItem } from '../../../types/interfaces/applicant.interface';

const WorkingOnApplication: FC = () => {
	const activityHistory = useSelector(
		(state: RootState) => state.applicant.activityHistory,
	);
	const [isComplete, setIsComplete] = useState<boolean[]>([]);

	const handleIsComplete = (activity: IActivityItem) => {
		if (activity && activity.reference) {
			return (
				activity.reference.status === 'All Evidence Received' ||
				activity.reference.status === 'Documents Received'
			);
		} else {
			return false;
		}
	};

	useEffect(() => {
		if (activityHistory.length > 0) {
			const completeArray = activityHistory.map((activity) => {
				return handleIsComplete(activity);
			});
			setIsComplete(completeArray);
		}
	}, [activityHistory]);
	return (
		<section className='submission-complete-container'>
			{isComplete.includes(false) && (
				<h4 className='submission-complete-header'>Working on</h4>
			)}
			{activityHistory.map((activity, index) => {
				return (
					<div key={index}>
						{!handleIsComplete(activity) && (
							<>
								<ActivityInfo activity={activity} type='info' />
								<ActivityItem
									activity={activity}
									activityNum={index + 1}
								/>
							</>
						)}
					</div>
				);
			})}
			{isComplete && (
				<div>
					{isComplete.includes(true) && (
						<h4 className='submission-complete-header'>Complete</h4>
					)}
					{activityHistory.map((activity, index) => {
						return (
							<div key={index}>
								{handleIsComplete(activity) && (
									<ActivityComplete
										activityNumber={index + 1}
									/>
								)}
							</div>
						);
					})}
				</div>
			)}
		</section>
	);
};

export default WorkingOnApplication;
