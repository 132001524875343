import {
	AUTH,
	CLEAR_AUTH,
	LOGIN,
	CLEAR_LOGGED_IN,
	IS_SEAMLESS,
} from './auth.types';

const INITIAL_STATE = {
	isLoggedIn: false,
	isSeamless: false,
};

const authReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AUTH:
			return {
				...state,
			};
		case CLEAR_AUTH:
			return INITIAL_STATE;
		case LOGIN:
			return {
				...state,
				isLoggedIn: true,
			};
		case CLEAR_LOGGED_IN:
			return INITIAL_STATE;
		case IS_SEAMLESS:
			return {
				...state,
				isSeamless: true,
			};
		default:
			return state;
	}
};

export default authReducer;
