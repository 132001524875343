import './check-passport-update-home.styles.scss';

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../types/root-state';
import { getPassportUpdateByDate } from '../../../utils/utilsFunctions';

import Header from '../../header/header/header.component';

const CheckPassportUpdateHome: FC = () => {
	const navigate = useNavigate();

	const clearanceStatusHistory = useSelector(
		(state: RootState) => state.applicant.applicant.clearanceStatusHistory,
	);

	const getPassportUpdateBy = () => {
		return getPassportUpdateByDate(
			clearanceStatusHistory?.lastFullyClearedDate,
		);
	};

	const handleUpdateClick = () => {
		navigate('/check/activity-update');
	};

	const handleWhyClick = () => {
		navigate('/check/update-why');
	};

	const handleBack = () => {
		navigate('/');
	};

	return (
		<div className='passport-update-home'>
			<Header
				text={`Your Deploi Passport needs updating by ${getPassportUpdateBy()}`}
				type='text'
				backFunction={handleBack}
				origin=''
				hideStepsCounter={true}
				hideDesktopSteps={true}
				updatePassport={true}
			/>
			<div className='passport-update-home-body'>
				<p>
					By giving us your updated activity history we can keep your
					Deploi Passport up to date, to make sure you&apos;re ready to
					work.
				</p>
				<p>
					You&apos;ll need to confirm your recent activity and upload
					proof.
				</p>
			</div>
			<div className='passport-update-home-button-container'>
				<button
					className='margin-bottom-16 orka-identity-button primary-filled'
					onClick={handleUpdateClick}
				>
					Update Manually
				</button>
				<button
					className='margin-bottom-16 orka-identity-button blue-outline'
					onClick={handleWhyClick}
				>
					Why?
				</button>
			</div>
		</div>
	);
};

export default CheckPassportUpdateHome;
