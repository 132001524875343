import React, { useState, FC, FormEvent, useEffect } from 'react';
import './input.styles.scss';
import { DateView, LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

type Props = {
	inputName: string;
	title: string;
	placeholder: string;
	value: any;
	emitValue?: any;
	handleBlur?: any;
	autocomplete?: string;
	type?: string;
	isDate?: boolean;
	isTextArea?: boolean;
	validationFunction?: (
		val1: string | Date | null,
		val2?: string | Date | null,
	) => { message: string; isValid: boolean };
	required?: boolean;
	validInputs?: any;
	setValidInputs?: any;
	continueClicked?: boolean;
	dateFormat?: DateView[];
	numberOnly?: boolean;
};

const Input: FC<Props> = ({
	inputName,
	title,
	placeholder,
	value,
	emitValue,
	handleBlur = null,
	autocomplete = 'on',
	type = 'text',
	isDate = false,
	isTextArea = false,
	validationFunction,
	required = false,
	validInputs,
	setValidInputs,
	continueClicked,
	dateFormat = ['year', 'month', 'day'],
	numberOnly = false,
}) => {
	const [hasChanged, setHasChanged] = useState<boolean>(false);
	const [validation, setValidation] = useState({
		message: '',
		isValid: true,
	});
	const [requiredError, setRequiredError] = useState(false);

	useEffect(() => {
		if (validInputs && setValidInputs) {
			const mappedValidInputs = validInputs.map((input: any) => {
				if (inputName === Object.keys(input)[0]) {
					return {
						[inputName]: validation.isValid,
					};
				}
				return input;
			});
			setValidInputs(mappedValidInputs);
		}
	}, [validation.isValid]);

	useEffect(() => {
		if (validationFunction && isDate) {
			setValidation({
				message: validationFunction(value).message,
				isValid: validationFunction(value).isValid,
			});
		}
	}, [value]);

	useEffect(() => {
		if (required && continueClicked && !value) {
			setRequiredError(true);
		} else {
			setRequiredError(false);
		}
	}, [value, continueClicked, required]);

	const handleChange = (
		e: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
	): void => {
		setHasChanged(true);
		emitValue(e.currentTarget.value, inputName);
		setValidation({
			message: '',
			isValid: true,
		});
	};

	const handleDateChange = (e: unknown): void => {
		emitValue(e, inputName);
		setValidation({
			message: '',
			isValid: true,
		});
	};

	const blur = (): void => {
		if (handleBlur && hasChanged) {
			handleBlur({ [inputName]: value });
		}
		setHasChanged(false);
		if (validationFunction) {
			setValidation({
				message: validationFunction(value).message,
				isValid: validationFunction(value).isValid,
			});
		}
	};

	return (
		<div className='input-container'>
			<p className='input-label'>{title}</p>
			{isDate === false && !isTextArea && (
				<div
					className={
						requiredError || (!validation.isValid && value)
							? 'input required-error'
							: 'input'
					}
				>
					<input
						autoComplete={autocomplete}
						type={numberOnly ? 'tel' : type}
						onChange={handleChange}
						value={value}
						placeholder={placeholder}
						onBlur={blur}
						aria-required={required}
						maxLength={title === 'Licence number' ? 16 : 524288}
					/>
				</div>
			)}
			{isTextArea && !isDate && (
				<div className='text-area'>
					<textarea
						onChange={handleChange}
						value={value}
						placeholder={placeholder}
						onBlur={blur}
						autoComplete={autocomplete}
						className={
							requiredError || (!validation.isValid && value)
								? 'required-error'
								: ''
						}
					/>
				</div>
			)}
			{isDate === true && (
				<div
					className={
						requiredError || (!validation.isValid && value)
							? 'edit-input-date required-error'
							: 'edit-input-date'
					}
				>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							format={
								dateFormat[0] === 'month'
									? 'MM/YYYY'
									: 'DD/MM/YYYY'
							}
							value={dayjs(value)}
							onChange={handleDateChange}
							views={dateFormat}
							className='edit-input-date-picker'
						/>
					</LocalizationProvider>
				</div>
			)}
			{!validation.isValid && value && (
				<div className='validation-error'>
					<p>{validation.message}</p>
				</div>
			)}
			{requiredError && (
				<div className='validation-error'>
					<p>This field is required</p>
				</div>
			)}
		</div>
	);
};

export default Input;
