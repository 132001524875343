import React, { FC } from 'react';
import './inner-layout.styles.scss';

type Props = {
	children: React.ReactNode[];
};

const InnerLayout: FC<Props> = ({ children }) => {
	return (
		<div className='inner-layout-container'>
			<div className='inner-layout-left'>{children[0]}</div>
			<div className='inner-layout-right'>{children[1]}</div>
		</div>
	);
};

export default InnerLayout;
