import './check-passport-update-name.styles.scss';

import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../types/root-state';
import { INameHistoryItem } from '../../../types/interfaces/applicant.interface';
import {
	IListCardItem,
	StepNamesEnum,
	StepStatusEnum,
} from '../../../types/interfaces/misc.interface';
import { setNameHistory } from '../../../redux/Applicant/applicant.actions';
import { setAlert, updateSteps } from '../../../redux/General/general.actions';
import * as api from '../../../api/requests/names.api';
import * as miscApi from '../../../api/requests/misc.api';
import * as utils from '../../../utils/utilsFunctions';
import useFetchData from '../../../utils/useFetchData';
import moment from 'moment';

import DropdownInfo from '../../general/dropdown-info/dropdown-info.component';
import Header from '../../header/header/header.component';
import InnerLayout from '../../general/inner-layout/inner-layout.component';
import ListCard from '../../general/list-card/list-card.component';
import Modal from '../../../components/modals/modal/modal.component';
import NameHistoryFormComponent from '../../../components/name-history/name-history-form/name-history-form.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import PageFooter from '../../general/page-footer/page-footer.component';

const CheckPassportName: FC = () => {
	const navigate = useNavigate();
	const [showForm, setShowForm] = useState(true);
	const [refetchNames, setRefetchNames] = useState(false);
	const [selectedName, setSelectedName] = useState<INameHistoryItem | null>(
		null,
	);
	const isMobile = useSelector((state: RootState) => state.general.isMobile);
	const prevPage = '/check/update-other';

	const dispatch = useDispatch();
	const nameHistory: INameHistoryItem[] = useFetchData(
		api.getNameHistory,
		setNameHistory,
		2,
		'nameHistory',
		refetchNames,
	);

	const [namesData, setNamesData] = useState<INameHistoryItem[]>([]);

	useEffect(() => {
		utils.instantScroll();
	}, []);

	useEffect(() => {
		const now = new Date();
		const names = nameHistory.filter((name) => {
			const createdAt = utils.getDaysLater(name.createdAt, 30);
			return moment(createdAt) > moment(now);
		});
		setNamesData(names);
		if (names.length > 0) {
			setShowForm(false);
		} else {
			setShowForm(true);
		}
	}, [JSON.stringify(nameHistory)]);

	const handleBack = (origin: string) => {
		if (origin === 'form' && namesData.length > 0) {
			setShowForm(false);
		} else {
			return navigate(prevPage, { replace: true });
		}
	};

	const handleSelectName = (name: IListCardItem) => {
		const filteredName = namesData.filter(
			(globalName) => globalName.uuid === name.uuid,
		)[0];
		setSelectedName(filteredName);
		setShowForm(true);
	};

	const handleComplete = () => {
		utils.instantScroll();
		return api
			.saveNameSubmission()
			.then(() => {
				return handleSubmitSection();
			})
			.catch((err: Error) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
				throw err;
			});
	};

	const handleSubmitSection = () => {
		return miscApi
			.postStep({
				step: StepNamesEnum.nameHistory,
				status: StepStatusEnum.complete,
			})
			.then(() => {
				handleSuccess();
			})
			.catch((err: Error) => {
				handleErrors(err);
			});
	};

	const handleErrors = (err: Error) => {
		utils.handleAlerts('error', err.message, dispatch);
	};

	const handleSuccess = () => {
		dispatch(
			setAlert({
				type: 'success',
				message: 'Successfully submitted section',
				isVisible: true,
			}),
		);
		dispatch(updateSteps('Name History', 'complete'));
		return handleBack('');
	};

	const handleAddAnother = () => {
		setShowForm(true);
		setSelectedName(null);
	};

	return (
		<div className='check-passport-name-history-container'>
			{!showForm && (
				<div className='name-history-list-container'>
					<Header
						text={'Add Name'}
						type='text'
						backFunction={handleBack}
						origin='main'
						informationText='We ask for this so we can perform our checks thoroughly. Also, your previous employers/organisations may know you by a different name, so we want to give them this information without bugging you again.'
						hideStepsCounter={true}
						hideDesktopSteps={true}
						updatePassport={true}
					></Header>
					<InnerLayout>
						{namesData.length > 0 && (
							<div className='name-history-cards'>
								{namesData.map((name) => {
									return (
										<div key={name.uuid}>
											<ListCard
												item={{
													uuid: name.uuid,
													title: utils.getFullNameHistory(
														name.forename,
														name.middleNames,
														name.surname,
													),
													lineOne:
														utils.capitalizeFirstLetter(
															name.nameType.replace(
																/Name/,
																' Name',
															),
														),
													lineTwo: `${moment(
														name.startAt,
													).format(
														'MMM YYYY',
													)} - ${moment(
														name.endAt,
													).format('MMM YYYY')}`,
												}}
												clickFunction={handleSelectName}
											/>
										</div>
									);
								})}
								<div className='add-another-button'>
									<OrkaButton
										buttonContent='Add Another'
										disabled={false}
										emitClicked={handleAddAnother}
										buttonClass='purple-outline'
									/>
								</div>
							</div>
						)}
						<div>
							<DropdownInfo
								title={`Why do you need this information?`}
								text={
									'We ask for this so we can perform our checks thoroughly. Also, your previous employers/organisations may know you by a different name, so we want to give them this information without bugging you again.'
								}
								desktopVersion={true}
							/>
						</div>
					</InnerLayout>
					<PageFooter
						primaryButtonText={'Submit'}
						primaryButtonFunction={handleComplete}
					/>
				</div>
			)}

			{showForm && isMobile && (
				<NameHistoryFormComponent
					title={selectedName ? 'Edit Name' : 'Add Name'}
					handleBack={handleBack}
					setShowForm={setShowForm}
					selectedName={selectedName}
					setSelectedName={setSelectedName}
					refetchNames={refetchNames}
					setRefetchNames={setRefetchNames}
				/>
			)}

			{!isMobile && showForm && (
				<Modal
					title={selectedName ? 'Edit Name' : 'Add Name'}
					setShowModal={setShowForm}
					showModal={false}
					pageOrigin='form'
					closeFunction={handleBack}
				>
					<NameHistoryFormComponent
						title={selectedName ? 'Edit Name' : 'Add Name'}
						handleBack={handleBack}
						setShowForm={setShowForm}
						selectedName={selectedName}
						setSelectedName={setSelectedName}
						refetchNames={refetchNames}
						setRefetchNames={setRefetchNames}
					/>
				</Modal>
			)}
		</div>
	);
};

export default CheckPassportName;
