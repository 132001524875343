import React, { FC, SetStateAction, Dispatch } from 'react';
import './main-header-tablet.styles.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import useSetStepsComplete from '../../../utils/useSetStepsComplete';
import * as utils from '../../../utils/utilsFunctions';
import ProgressBar from '../../general/progress-bar/progress-bar.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import ActionInfoCard from '../../general/action-info-card/action-info-card.component';

type Props = {
	handleStartConsent: () => void;
	percentage: string;
	actionCardText: string | null;
	actionCardEmphasisText: string | null;
	actionCardSubText: string | null;
	setShowProgress?: Dispatch<SetStateAction<boolean>> | null;
	showProgress?: boolean;
	showModal?: boolean;
	setShowModal?: (showModal: boolean) => void;
};

const MainHeaderTablet: FC<Props> = ({
	percentage,
	handleStartConsent,
	setShowProgress,
	showProgress,
	setShowModal,
	actionCardText = null,
	actionCardEmphasisText = null,
	actionCardSubText = null,
}) => {
	const noComplete = useSetStepsComplete();
	const applicationStatus = useSelector(
		(state: RootState) => state.general.applicationStatus,
	);
	const applicant = useSelector(
		(state: RootState) => state.applicant.applicant,
	);
	const clearanceStatusHistory = useSelector(
		(state: RootState) => state.applicant.applicant.clearanceStatusHistory,
	);
	const idCheckStatus = useSelector(
		(state: RootState) => state.rtw.idCheckStatus,
	);
	const rtwStepStatus = useSelector(
		(state: RootState) => state.rtw.rtwStatus,
	);

	const lastComplianceDate: Date = utils.getLastComplianceDate(
		applicant.complianceStarted,
		7,
	);
	const bs7858 = applicant.check ? applicant.check.bs7858 : 'undefined';
	const cvOnline =
		applicant.flow && applicant.flow === 'cv-online' ? true : false;

	const numberOfDaysLeft = utils.getNumberOfDaysLeft(lastComplianceDate);

	return (
		<div className='header-container-tablet'>
			<div className='your-background-check'>
				<h1 className='applicant-greeting'>
					<span className='greeting'>
						{utils.dayStatus()}
						<i className='icon-shape-line'></i>
					</span>
					<span className='applicant-name'>{applicant.name}</span>
				</h1>
				{applicationStatus !== 'application-submitted' && (
					<p>
						{`We suggest you complete your check by `}
						<span className='extra-bold-font'>
							{' '}
							{lastComplianceDate.toLocaleDateString('en-GB')} (
							{numberOfDaysLeft < 0 ? '0' : numberOfDaysLeft} days
							left)
						</span>
					</p>
				)}
			</div>

			{applicationStatus === 'application-submitted' &&
				clearanceStatusHistory?.provisionallyClearedDate &&
				clearanceStatusHistory?.clearanceStatus ===
				'partially_compliant' && (
					<ActionInfoCard
						text={actionCardText}
						emphasisText={actionCardEmphasisText}
						subText={actionCardSubText}
						clickFunction={setShowModal}
					/>
				)}

			{noComplete !== 0 && (
				<div
					className={`desktop-header-progress-container ${applicationStatus === 'application-submitted'
						? 'application-sent'
						: ''
						}`}
				>
					<div className='header-progress'>
						{percentage && (
							<ProgressBar
								status={percentage}
								percentage={true}
							/>
						)}
					</div>
				</div>
			)}

			{(bs7858 || bs7858 == 'undefined') &&
				!cvOnline &&
				applicationStatus === 'not-started' &&
				rtwStepStatus.status === 'complete' &&
				idCheckStatus.status === 'complete' && (
					<div className='start-agreeing-terms-button'>
						<OrkaButton
							buttonContent='Start background check'
							disabled={false}
							emitClicked={handleStartConsent}
							buttonClass='purple-filled'
						/>
					</div>
				)}
			{applicationStatus === 'application-submitted' &&
				setShowProgress &&
				!showProgress && (
					<div className='start-agreeing-terms-button'>
						<OrkaButton
							buttonClass='purple-filled'
							buttonContent='View progress'
							emitClicked={() => setShowProgress(true)}
							disabled={false}
						/>
					</div>
				)}
		</div>
	);
};

export default MainHeaderTablet;
