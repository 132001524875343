import React, { FC, useEffect } from 'react';
import './terms.styles.scss';
import ConsentPage from '../../components/terms/terms-page/terms-page.component';
import { fireGaEvent } from '../../utils/ga-event';
import * as utils from '../../utils/utilsFunctions';
import Layout from '../../components/general/layout-component/layout.component';
import PageFooter from '../../components/general/page-footer/page-footer.component';
import * as apiMisc from '../../api/requests/misc.api';
import { useDispatch, useSelector } from 'react-redux';
import { setApplicationStatus } from '../../redux/General/general.actions';
import { setConsentModalState } from '../../redux/Applicant/applicant.actions';
import Header from '../../components/header/header/header.component';
import InfoCard from '../../components/general/info-card/info-card.component';
import book from '../../assets/icons/info-card/book.svg';
import InnerLayout from '../../components/general/inner-layout/inner-layout.component';
import { RootState } from '../../types/root-state';
import { useNavigate } from 'react-router-dom';

const Terms: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isMobile = useSelector((state: RootState) => state.general.isMobile);

	useEffect(() => {
		utils.instantScroll();
	}, []);

	fireGaEvent('Terms', 'Terms - New User');

	const handleAgree = () => {
		fireGaEvent('Terms', 'Taps to agree terms');
		return apiMisc
			.setApplicationStatus('terms-agreed')
			.then(() => {
				dispatch(setApplicationStatus('terms-agreed'));
				dispatch(setConsentModalState(false));
				return navigate('/check/general-information', {
					state: { page: 'General Information' },
					replace: true,
				});
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	const handleSelectCard = () => {
		fireGaEvent('Terms', 'Taps what is BS7858');
		window.open(
			'https://intercom.help/deploi/en/articles/9352081-what-is-bs-7858',
			'_blank',
		);
	};

	return (
		<div className='terms-page'>
			<Layout background=''>
				<Header
					text={`It's important you read this!`}
					type='text'
					backFunction={() => navigate('/', { replace: true })}
					origin='main'
				></Header>
				<InnerLayout>
					<>
						<div className='tablet-bs7858-card'>
							<InfoCard
								size='small'
								icon={book}
								text='What is BS7858?'
								clickFunction={handleSelectCard}
							/>
						</div>
						<ConsentPage />
					</>
					<>
						{!isMobile && (
							<InfoCard
								size='small'
								icon={book}
								text='What is BS7858?'
								clickFunction={handleSelectCard}
							/>
						)}
					</>
				</InnerLayout>
				<PageFooter
					primaryButtonText='Agree and Start'
					primaryButtonFunction={handleAgree}
				/>
			</Layout>
		</div>
	);
};

export default Terms;
