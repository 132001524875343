import React, { FC } from 'react';
import './loading-spinner.styles.scss';
import checkLogo from '../../../assets/logos/deploi-spinner.png';

const LoadingSpinner: FC = () => {
	return (
		<div className='loader'>
			<div className='loading-anim'>
				<img src={checkLogo} alt='check' />
			</div>
		</div>
	);
};

export default LoadingSpinner;
