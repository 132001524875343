import axios, { AxiosInstance } from 'axios';
import { getDefaultHeaders, getUrls } from './api.utils';
import {
	clearGeneralState,
	setIsLoading,
} from '../../redux/General/general.actions';
import store from '../../redux/store';
import { clearApplicantState } from '../../redux/Applicant/applicant.actions';
import { clearAuth } from '../../redux/Auth/auth.actions';

const { GATEWAY_URL } = getUrls();
const ignoreArray = [
	'/worker/companies/references',
	'/candidate/locations/search',
];

export const axiosInstance = (): AxiosInstance => {
	const { dispatch } = store;

	const axiosInstance = axios.create({
		withCredentials: true,
		baseURL: GATEWAY_URL,
		headers: getDefaultHeaders(),
	});

	axiosInstance.interceptors.request.use(
		function (config) {
			const foundIgnore = ignoreArray.filter((el) => {
				return el === config.url?.substring(0, el.length);
			})[0];

			if (foundIgnore) {
				return config;
			}
			dispatch(setIsLoading(true));
			return config;
		},
		function (error) {
			dispatch(setIsLoading(false));
			return Promise.reject(error);
		},
	);

	axiosInstance.interceptors.response.use(
		function (response) {
			dispatch(setIsLoading(false));
			return response;
		},
		function ({ response }) {
			dispatch(setIsLoading(false));

			if (response && response.data && response.data.message) {
				const errorArray = [
					'Logon details incorrect.',
					'User not found.',
					'Incorrect verification code.',
					'Maximum verification attempt reached. Please contact the Deploi Team.',
					'contactNumber must be a valid phone number',
				];

				const errMessage =
					typeof response.data.message === 'string'
						? response.data.message
						: response.data.message[0];

			
					if(errorArray.includes(errMessage)){
						return Promise.reject({
							status: response.status,
							message: errMessage,
						});
					}else{
						return Promise.reject({
							status: response.status,
							message: response.data.message,
						});
					}
			}

			if (response && response.status) {
				if (response.status === 404) {
					return Promise.resolve({
						data: [],
						status: 404,
						message: response.message ? response?.message : response?.data?.message,
					});
				} else if (response.status === 401 || response.status === 403) {
					dispatch(clearApplicantState());
					dispatch(clearAuth());
					dispatch(clearGeneralState());
					localStorage.clear();
					window.location.href = '/login';
					return Promise.reject(response);
				}
			}

			return Promise.reject(response);
		},
	);

	return axiosInstance;
};
