import { ENDPOINTS } from '../utils/endpoints';
import { axiosInstance } from '../utils/api.axiosInstance';
import { getRequestURLFromParams } from '../utils/api.utils';
import { IAddressHistoryItemDb } from '../../types/interfaces/applicant.interface';

export const getAddressHistory = (): Promise<any> => {
	const url = getRequestURLFromParams(ENDPOINTS.ALL_ADDRESS);
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const saveAddress = (address: IAddressHistoryItemDb): any => {
	const url = getRequestURLFromParams(ENDPOINTS.ADDRESS_ITEM);
	return axiosInstance()
		.post(url, address)
		.catch((err) => {
			throw err;
		});
};

export const updateAddress = (address: Partial<IAddressHistoryItemDb>): any => {
	const url = getRequestURLFromParams(ENDPOINTS.CANDIDATE_ADDRESS_BY_UUID, {
		itemUuid: address.uuid,
	});
	return axiosInstance()
		.patch(url, address)
		.catch((err) => {
			throw err;
		});
};

export const deleteAddressItem = (itemUuid: string): any => {
	const url = getRequestURLFromParams(ENDPOINTS.ADDRESS_ITEM_BY_UUID, {
		itemUuid,
	});
	return axiosInstance()
		.delete(url)
		.catch((err) => {
			throw err;
		});
};

export const saveAddressSubmission = (): any => {
	const url = getRequestURLFromParams(ENDPOINTS.ADDRESS_SUBMISSION);
	return axiosInstance()
		.post(url)
		.catch((err) => {
			throw err;
		});
};

export const getAddressFromGoogle = (query: string): Promise<any> => {
	const url = getRequestURLFromParams(ENDPOINTS.ADDRESS_SEARCH);
	return axiosInstance()
		.get(`${url}?query=${query}&country='UK'`)
		.catch((err) => {
			throw err;
		});
};

export const getPlaceById = (placeId: string): Promise<any> => {
	const url = getRequestURLFromParams(ENDPOINTS.ADDRESS_PLACE, {
		placeId,
	});
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};
