import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './licences-list.styles.scss';
import {
	ICheckboxAnswer,
	ICheckboxData,
	StepNamesEnum,
	StepStatusEnum,
} from '../../../types/interfaces/misc.interface';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { useNavigate } from 'react-router-dom';
import {
	ILicenceItem,
	ISiaLicenceData,
} from '../../../types/interfaces/applicant.interface';
import { updateSteps } from '../../../redux/General/general.actions';
import { fireGaEvent } from '../../../utils/ga-event';

import * as utils from '../../../utils/utilsFunctions';
import * as miscApi from '../../../api/requests/misc.api';
import * as api from '../../../api/requests/licence.api';
import Header from '../../header/header/header.component';
import ButtonList from '../../general/button-list/button-list.component';
import ListCard from '../../general/list-card/list-card.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import PageFooter from '../../general/page-footer/page-footer.component';
import InnerLayout from '../../general/inner-layout/inner-layout.component';
import DropdownInfo from '../../general/dropdown-info/dropdown-info.component';
import InfoCard from '../../general/info-card/info-card.component';
import errorIcon from '../../../assets/icons/misc/error.svg';
import { saveSIAData } from '../../../redux/Licences/licences.actions';

type Props = {
	pageName: string;
	handleScreen: (origin: string) => void;
	checkboxData: ICheckboxData;
	handleSelection: (
		checkBoxAnswer: ICheckboxAnswer,
		question: string,
	) => void;
	setShowForm: Dispatch<SetStateAction<boolean>>;
	setSelectedLicence: Dispatch<SetStateAction<ILicenceItem | null>>;
	setCheckboxData: any;
	isLicenceImageRequired: boolean;
	isPassport?: boolean;
};

const LicencesList: FC<Props> = ({
	pageName,
	handleScreen,
	checkboxData,
	handleSelection,
	setShowForm,
	setCheckboxData,
	isLicenceImageRequired,
	isPassport,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const licences = useSelector(
		(state: RootState) => state.applicant.licences,
	);
	const [submissionValidationError, setSubmissionValidationError] =
		useState(false);
	const isTablet = useSelector((state: RootState) => state.general.isTablet);
	const [showValidation, setShowValidation] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);

	fireGaEvent('Licences', 'Licences List Page');

	useEffect(() => {
		utils.instantScroll();
		if (licences.length === 0) {
			checkboxData.answers[0].selected = false;
			checkboxData.answers[1].selected = false;
			setCheckboxData([checkboxData]);
		}
	}, []);

	useEffect(() => {
		if (
			!checkboxData.answers[0].selected &&
			!checkboxData.answers[1].selected &&
			licences.length === 0
		) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [JSON.stringify(checkboxData), licences.length]);

	const handleAddAnother = () => {
		fireGaEvent('Licences', 'Clicks add another licence');
		setShowForm(true);
	};

	const handleComplete = () => {
		if (isDisabled) {
			fireGaEvent(
				'Licences',
				'Taps submit licence section (validation failed)',
			);
			setSubmissionValidationError(true);
			setShowValidation(true);
			setTimeout(() => {
				setShowValidation(false);
			}, 2000);
			return;
		}

		utils.instantScroll();
		fireGaEvent('Licences', 'Taps submit licence section');
		return api
			.saveLicenceSubmission()
			.then(() => {
				handleSubmitSection();
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	const handleSubmitSection = () => {
		return miscApi
			.postStep({
				step: StepNamesEnum.licences,
				status: StepStatusEnum.complete,
			})
			.then(() => {
				dispatch(updateSteps('Licences', 'complete'));
				utils.handleAlerts(
					'success',
					'Successfully submitted section',
					dispatch,
				);
				return navigate('/', { replace: true });
			})
			.catch((err: Error) => {
				utils.handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	const handleListCardClick = (listItem: Record<string, unknown>) => {
		const licenceNumber = listItem.lineOne as string;
		const licence = licences.find(
			(licence) => licence.number === licenceNumber,
		);

		if (licence) {
			const siaLicenceData: ISiaLicenceData = {
				number: licenceNumber,
			};
			dispatch(saveSIAData(siaLicenceData));
			handleScreen('view-licence');
		}
	};

	return (
		<div className='licences-list-container'>
			<Header
				text={pageName ? pageName : ''}
				type='text'
				backFunction={handleScreen}
				origin='main'
				informationText={`BS7858 Screening only needs to be done for officers with a valid license. We check this information to make sure you're on the right path, and are able to work as a security officer after screening.`}
				hideDesktopSteps={isPassport}
			/>
			<InnerLayout>
				<div className='licence-list-container-inner-left'>
					{isTablet && submissionValidationError && (
						<div className='submission-validation-error'>
							<InfoCard
								size='small'
								icon={errorIcon}
								text="You can't complete this section until you add a valid SIA licence or selected 'No'."
								subText='If you wish to complete this section later you can tap the back arrow and your progress will be saved.'
								type='validation-card'
							/>
						</div>
					)}
					<div className='select-has-licence'>
						{licences.length === 0 && (
							<ButtonList
								checkboxData={checkboxData}
								handleSelection={handleSelection}
							/>
						)}
						{licences.length > 0 && (
							<>
								<br />
								{licences.map((licence /*index*/) => {
									if (
										licence.uuid &&
										licence.status != 'Expired'
									) {
										const listItem = {
											// uuid: index.toString(),
											uuid: licence.uuid,
											title:
												licence.name +
												' ' +
												licence.surname,
											lineOne: licence.number,
											lineTwo: `Expires: ${utils.formatDateForUser(
												licence.expiryDate
													? licence.expiryDate
													: licence.expiry_date,
											)}`,
											lineThree:
												'Click here to upload licence images',
										};

										return (
											<div key={licence.number}>
												<ListCard
													item={listItem}
													clickFunction={() =>
														handleListCardClick(
															listItem,
														)
													}
													licenceImage={
														licence.fileNameFront &&
														licence.fileNameBack
															? true
															: false
													}
												/>
											</div>
										);
									}
								})}
								<div className='add-another-button'>
									<OrkaButton
										buttonContent='Add Another SIA Licence'
										disabled={false}
										emitClicked={handleAddAnother}
										buttonClass='purple-outline'
									/>
								</div>
							</>
						)}
					</div>
					{showValidation && (
						<div className='validation-error'>
							<p>Please select yes or no</p>
						</div>
					)}
					<PageFooter
						primaryButtonFunction={handleComplete}
						disabled={isLicenceImageRequired}
					/>
					<div className='margin-bottom-120' />
				</div>
				<>
					<DropdownInfo
						title={`Why do you need this information?`}
						text={`BS7858 Screening only needs to be done for officers with a valid license. We check this information to make sure you're on the right path, and are able to work as a security officer after screening.`}
						desktopVersion={true}
						handleClick={() =>
							fireGaEvent(
								'Licences',
								'Tapped why do you need this information dropdown',
							)
						}
					/>
					{!isTablet && submissionValidationError && (
						<div className='submission-validation-error'>
							<InfoCard
								size='small'
								icon={errorIcon}
								text="You can't complete this section until you add a valid SIA licence or selected 'No'."
								subText='If you wish to complete this section later you can tap the back arrow and your progress will be saved.'
								type='validation-card'
							/>
						</div>
					)}
				</>
			</InnerLayout>
		</div>
	);
};

export default LicencesList;
