import './advisory-check-card.styles.scss';
import React, { FC, SetStateAction, Dispatch } from 'react';

// Assets
import leftChevronIcon from '../../assets/icons/misc/leftChevronIcon.svg';

type Props = {
	children: React.ReactNode;
	title?: string;
	headerImageSource?: string;
	buttonLabel?: string;
	setShowAdvisory: Dispatch<SetStateAction<boolean>>;
	onClick?: React.MouseEventHandler;
};

const AdvisoryCheckCard: FC<Props> = ({
	children,
	title,
	buttonLabel,
	headerImageSource,
	setShowAdvisory,
	onClick,
}) => {
	const closeAdvisoryCard = (): void => {
		setShowAdvisory(false);
	};

	return (
		<div className='advisory-check-container'>
			<div className='advisory-check-content'>
				<header className='advisory-check-header'>
					<nav className='advisory-check-header-nav'>
						<button
							className='back-button'
							onClick={closeAdvisoryCard}
						>
							<img src={leftChevronIcon} alt='back' />
						</button>
					</nav>
					<div className='advisory-header-content'>
						{headerImageSource && (
							<img
								className='cover-image'
								src={headerImageSource}
								alt='cover image'
							/>
						)}
						{title && (
							<h2 className='advisory-check-title'>{title}</h2>
						)}
					</div>
				</header>
				<main className='advisory-check-body'>{children}</main>
				<footer className='advisory-check-footer'>
					<button className='advisory-check-button' onClick={onClick}>
						{buttonLabel}
					</button>
				</footer>
			</div>
		</div>
	);
};

export default AdvisoryCheckCard;
