import React, { FC, useEffect, useState } from 'react';
import './activity-history.styles.scss';
import Layout from '../../components/general/layout-component/layout.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../types/root-state';
import ActivityList from '../../components/activity/activity-list/activity-list.component';
import ActivityForm from '../../components/activity/activity-form/activity-form.component';
import ActivityDocuments from '../../components/activity/activity-documents/activity-documents.component';
import ActivityReasons from '../../components/activity/activity-reasons/activity-reasons.component';
import Modal from '../../components/modals/modal/modal.component';
import VModal from '../../components/modals/modal/vmodal.component';
import * as api from '../../api/requests/activity.api';
import { setActivityHistory } from '../../redux/Applicant/applicant.actions';
import * as utils from '../../utils/utilsFunctions';
import ActivitySubmission from '../../components/activity/activity-submission/activity-submission.component';
import { updateSteps } from '../../redux/General/general.actions';
import { fireGaEvent } from '../../utils/ga-event';
import * as miscApi from '../../api/requests/misc.api';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	StepNamesEnum,
	StepStatusEnum,
} from '../../types/interfaces/misc.interface';
import ActivityGuide from '../../components/activity/activity-guide/activity-guide.component';
import { useScrollLock } from '../../hooks';
import AdvisoryCheckCard from '../../components/advisory-card/advisory-check-card.component';
import docIcon from '../../assets/icons/misc/doc-pair.svg';
import ActivityView from '../../components/activity/activity-view/activity-view.component';
import { ActivityProvider } from '../../context/ActivityContext';

const ActivityHistory: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { state } = useLocation();
	const isMobile = useSelector((state: RootState) => state.general.isMobile);
	const activityHistory = useSelector(
		(state: RootState) => state.applicant.activityHistory,
	);
	const [showForm, setShowForm] = useState(false);
	const [showUpload, setShowUpload] = useState(false);
	const [showList, setShowList] = useState(true);
	const [showReasons, setShowReasons] = useState(false);
	const [showSubmission, setShowSubmission] = useState(false);
	const [showInformation, setShowInformation] = useState(false);
	const [showGuide, setShowGuide] = useState<boolean>(true);
	const [advisoryChecked, setAdvisoryChecked] = useState<boolean>(false);
	const [showAdvisory, setShowAdvisory] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [pageName, setPageName] = useState<string | undefined>('');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isCurrent, setIsCurrent] = useState(true);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [pageOrigin, setPageOrigin] = useState('');
	const [isDisabled, setIsDisabled] = useState(false);
	const [selectedActivity, setSelectedActivity] = useState<any>(null);
	const { enableScroll, disableScroll } = useScrollLock();

	const activityGuideStorage = localStorage.getItem('guideState');

	const displayGuide = () => {
		if (showGuide && !activityGuideStorage) {
			disableScroll();
			return <ActivityGuide setShowGuide={setShowGuide} />;
		} else {
			enableScroll();
			return;
		}
	};

	useEffect(() => {
		localStorage.setItem(
			'do-not-show-advisory',
			JSON.stringify(advisoryChecked),
		);
	}, [advisoryChecked]);

	useEffect(() => {
		const localStorageAdvisory = localStorage.getItem(
			'do-not-show-advisory',
		);

		if (localStorageAdvisory) {
			setAdvisoryChecked(JSON.parse(localStorageAdvisory));
		}
	}, []);

	const handleCheckbox = (e: any) => {
		setAdvisoryChecked(e.target.checked);
	};

	useEffect(() => {
		utils.instantScroll();
		setPageName(utils.setPageName(state, navigate));
		api.getActivityHistory()
			.then((res) => {
				dispatch(
					setActivityHistory(res.status === 404 ? [] : res.data),
				);
			})
			.catch((err: Error) => {
				throw err;
			});
	}, []);

	useEffect(() => {
		const hasFiles = activityHistory.every((activity) => {
			if (activity.type === 'gap') return true;
			if (activity.files) return activity.files.length >= 1;
			return true;
		});

		setIsDisabled(!hasFiles);
	}, [activityHistory]);

	const handleComplete = () => {
		utils.instantScroll();
		fireGaEvent(
			'Activity History',
			'Clicked submit activity history section (validation failed)',
		);
		return api
			.saveActivitySubmission()
			.then(() => {
				handleSubmitSection();
			})
			.catch((err: Error) => {
				utils.handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};
	const handleSubmitSection = () => {
		return miscApi
			.postStep({
				step: StepNamesEnum.activityHistory,
				status: StepStatusEnum.complete,
			})
			.then(() => {
				handleSuccess();
			})
			.catch((err: Error) => {
				utils.handleAlerts('error', err.message, dispatch);
				throw err;
			});
	};

	const handleSuccess = () => {
		utils.handleAlerts(
			'success',
			'Successfully posted activity submission',
			dispatch,
		);
		setShowSubmission(false);
		dispatch(updateSteps('Activity History', 'complete'));
		return navigate('/', { replace: true });
	};

	const handleBack = (origin: string) => {
		setPageOrigin('');
		switch (origin) {
			case 'form':
				setShowForm(false);
				setShowUpload(false);
				setShowReasons(false);
				setShowList(true);
				setShowInformation(false);
				break;
			case 'reasons':
				setShowForm(true);
				setShowUpload(false);
				setShowReasons(false);
				setShowList(false);
				setShowInformation(false);
				break;
			case 'edit':
				setShowForm(true);
				setShowUpload(false);
				setShowReasons(false);
				setShowList(false);
				setShowInformation(false);
				break;
			case 'upload':
				setShowForm(false);
				setShowUpload(false);
				setShowReasons(false);
				setShowList(true);
				setShowInformation(false);
				break;
			case 'submission':
				setShowForm(false);
				setShowUpload(false);
				setShowReasons(false);
				setShowList(true);
				setShowSubmission(false);
				break;
			case 'information':
				setShowForm(false);
				setShowUpload(false);
				setShowReasons(false);
				setShowList(true);
				setShowSubmission(false);
				setShowInformation(false);
				break;
			default:
				return navigate('/', { replace: true });
		}
	};

	const handleNextAction = () => {
		if (!advisoryChecked) {
			setShowAdvisory(!showAdvisory);
		} else {
			handleNext();
		}
	};

	const handleNext = () => {
		fireGaEvent(
			'Activity History',
			'Clicked from upload documents, back to list',
		);
		setShowList(true);
		setShowForm(false);
		setShowUpload(false);
		setShowAdvisory(false);
		setShowInformation(false);
	};

	const checkDocumentUploaded = () => {
		if (selectedActivity) {
			if (selectedActivity.files?.length >= 1) {
				return false;
			}
			return true;
		}
		return true;
	};

	return (
		<ActivityProvider>
			<div className='activity-history-container'>
				<Layout background=''>
					{showList && (
						<ActivityList
							handleBack={handleBack}
							pageOrigin={'main'}
							pageName='What have you been up to for the last 5 years?'
							setShowForm={setShowForm}
							setShowList={setShowList}
							setSelectedActivity={setSelectedActivity}
							setShowUpload={setShowUpload}
							handleComplete={handleComplete}
							setShowSubmission={setShowSubmission}
							setViewInformation={setShowInformation}
							isDisabled={isDisabled}
						/>
					)}
					{isMobile && (
						<>
							{showForm && (
								<ActivityForm
									handleBack={handleBack}
									setShowForm={setShowForm}
									selectedActivity={selectedActivity}
									setShowUpload={setShowUpload}
									setShowReasons={setShowReasons}
									setSelectedActivity={setSelectedActivity}
								/>
							)}

							{showUpload && (
								<VModal
									title='Upload documents'
									setShowModal={setShowUpload}
									showModal={showUpload}
									backButton
									backButtonEvent={() => handleBack('edit')}
									rightButtonEvent={handleNextAction}
									leftButtonLabel={`I'll do it later`}
									leftButtonEvent={handleBack}
									disabled={checkDocumentUploaded()}
									pageOrigin='upload'
									rightButtonLabel='Complete'
								>
									<ActivityDocuments
										handleBack={handleBack}
										setShowForm={setShowForm}
										setShowList={setShowList}
										setShowUpload={setShowUpload}
										setShowReasons={setShowReasons}
										selectedActivity={selectedActivity}
										setSelectedActivity={
											setSelectedActivity
										}
									/>
								</VModal>
							)}
							{showSubmission && (
								<VModal
									title={''}
									setShowModal={setShowSubmission}
									showModal={showSubmission}
									closeFunction={handleBack}
									pageOrigin='submission'
									leftButtonLabel={`Submit anyway`}
									leftButtonEvent={() => handleComplete()}
									rightButtonLabel={`Go back`}
									rightButtonEvent={() => handleBack('submission')}
								>
									<ActivitySubmission
										setShowModal={setShowSubmission}
										handleSubmitActivities={handleComplete}
										handleBack={handleBack}
									/>
								</VModal>
							)}
							{showReasons && (
								<ActivityReasons
									handleBack={handleBack}
									setShowForm={setShowForm}
									setShowList={setShowList}
									setShowUpload={setShowUpload}
									setShowReasons={setShowReasons}
									selectedActivity={selectedActivity}
									setSelectedActivity={setSelectedActivity}
								/>
							)}
							{showInformation && (
								<ActivityView
									activity={selectedActivity}
									headerTitle='Add Activity'
									onClick={() => handleBack('information')}
								/>
							)}
						</>
					)}

					{!isMobile && (
						<>
							{showForm && (
								<Modal
									title={
										selectedActivity
											? 'Edit Activity'
											: 'Add Activity'
									}
									setShowModal={setShowForm}
									showModal={showForm}
									closeFunction={handleBack}
									pageOrigin='form'
								>
									<ActivityForm
										handleBack={handleBack}
										setShowForm={setShowForm}
										selectedActivity={selectedActivity}
										setShowUpload={setShowUpload}
										setShowReasons={setShowReasons}
										setSelectedActivity={
											setSelectedActivity
										}
									/>
								</Modal>
							)}

							{showUpload && (
								<VModal
									title='Upload documents'
									setShowModal={setShowUpload}
									showModal={showUpload}
									backButton
									backButtonEvent={() => handleBack('edit')}
									rightButtonEvent={handleNextAction}
									leftButtonLabel={`I'll do it later`}
									leftButtonEvent={handleBack}
									pageOrigin='upload'
									rightButtonLabel='Complete'
									disabled={checkDocumentUploaded()}
								>
									<ActivityDocuments
										handleBack={handleBack}
										setShowForm={setShowForm}
										setShowList={setShowList}
										setShowUpload={setShowUpload}
										setShowReasons={setShowReasons}
										selectedActivity={selectedActivity}
										setSelectedActivity={
											setSelectedActivity
										}
									/>
								</VModal>
							)}

							{showSubmission && (
								<VModal
									title={''}
									setShowModal={setShowSubmission}
									showModal={showSubmission}
									closeFunction={handleBack}
									pageOrigin='submission'
									leftButtonLabel={`Submit anyway`}
									leftButtonEvent={() => handleComplete()}
									rightButtonLabel={`Go back`}
									rightButtonEvent={() => handleBack('submission')}
								>
									<ActivitySubmission
										setShowModal={setShowSubmission}
										handleSubmitActivities={handleComplete}
										handleBack={handleBack}
									/>
								</VModal>
							)}

							{showReasons && (
								<Modal
									title='Why did you leave this job?'
									setShowModal={setShowReasons}
									showModal={showReasons}
									closeFunction={handleBack}
									pageOrigin='reasons'
								>
									<ActivityReasons
										handleBack={handleBack}
										setShowForm={setShowForm}
										setShowList={setShowList}
										setShowUpload={setShowUpload}
										setShowReasons={setShowReasons}
										selectedActivity={selectedActivity}
										setSelectedActivity={
											setSelectedActivity
										}
									/>
								</Modal>
							)}
							{showInformation && (
								<ActivityView
									activity={selectedActivity}
									headerTitle='Add Activity'
									onClick={() => handleBack('information')}
								/>
							)}
						</>
					)}
				</Layout>
			</div>
			{displayGuide()}
			{showAdvisory && (
				<AdvisoryCheckCard
					title='Are the documents you have uploaded different from each other?'
					buttonLabel='Done'
					headerImageSource={docIcon}
					setShowAdvisory={setShowAdvisory}
					onClick={handleNext}
				>
					<div className='advisory-content'>
						<p>
							The documents for the start and end dates must be
							different from each other.
						</p>
						<p>
							<a
								href='/check/example-document'
								target='_blank'
								rel='noreferrer'
							>
								Show example document
							</a>
						</p>

						<div className='checkbox-container'>
							<div className='advisory-checkbox-control'>
								<input
									id='advisory-checkbox'
									type='checkbox'
									name='advisory-checkbox'
									checked={advisoryChecked}
									aria-hidden={true}
									onChange={handleCheckbox}
								/>
							</div>
							<label htmlFor='advisory-checkbox'>
								Do not show me this again
							</label>
						</div>
					</div>
				</AdvisoryCheckCard>
			)}
		</ActivityProvider>
	);
};

export default ActivityHistory;
