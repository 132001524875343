import moment from 'moment';

export const checkIsDisabled = (
	checks,
	requiredFields,
	formState,
	validInputs,
	setDateValidation,
) => {
	if (checks.includes('requiredFields')) {
		if (hasRequiredFields(requiredFields, formState)) {
			return true;
		}
	}
	if (checks.includes('niNumber')) {
		if (!niValidation(formState.nationalInsuranceNumber).isValid) {
			return true;
		}
	}

	if (checks.includes('dateComparison')) {
		if (
			checkDateComparisons(
				formState.startAt,
				formState.endAt,
				setDateValidation,
			)
		) {
			return true;
		}
	}

	if (checks.includes('validInputs')) {
		if (checkValidInputs(validInputs)) {
			return true;
		}
	}

	if (checks.includes('over18')) {
		if (checkIsOver18(formState.DOB).isValid) {
			return true;
		}
	}
	return false;
};

export const hasRequiredFields = (requiredFields, formState) => {
	let disabled = false;
	for (let i = 0; i < requiredFields.length; i++) {
		if (
			formState[requiredFields[i]] === '' ||
			formState[requiredFields[i]] === null ||
			formState[requiredFields[i]] === undefined
		) {
			disabled = true;
			break;
		}
	}
	return disabled;
};

export const checkDateValid = (date, dateFormat) => {
	const checkDate = moment(date, dateFormat ? dateFormat : null);
	if (checkDate && checkDate.isValid()) {
		return true;
	} else {
		return false;
	}
};

export const checkIsOver18 = (dob) => {
	const validDate = checkDateValid(dob, 'DD/MM/YYYY');
	if (!validDate) {
		return {
			isValid: false,
			message: '',
		};
	}
	const years = moment().diff(moment(dob), 'years', true);
	return getAgeMessage(years);
};

const getAgeMessage = (years: number) => {
	if (years > 18) {
		return {
			isValid: true,
			message: '',
		};
	}
	if (years < 0) {
		return {
			isValid: false,
			message: 'Date of birth can not be in the future',
		};
	}
	if (years < 18 && years > 0) {
		return {
			isValid: false,
			message: 'You must be 18 or over to become a worker',
		};
	}
	return {
		isValid: true,
		message: '',
	};
};

export const checkDateComparisons = (startAt, endAt, setDateValidation) => {
	setDateValidation(dateComparison(startAt, endAt).message);
	return !dateComparison(startAt, endAt).isValid;
};

export const checkValidInputs = (validInputs) => {
	const hasInvalidInput = validInputs.filter((el) => {
		if (!Object.values(el)[0]) {
			return el;
		}
	}).length;
	return hasInvalidInput > 0;
};

export const isValidEmail = (email) => {
	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const removedWhiteSpace = email.replace(/\s/, '');
	return {
		isValid: regex.test(removedWhiteSpace),
		message: regex.test(removedWhiteSpace)
			? ''
			: 'Please enter a valid email address',
	};
};

export const isValidPhone = (phone) => {
	const regex = /^(\+447)(\d{9})$/;
	const removedWhiteSpace = phone.replace(/\s/, '');
	return {
		isValid: regex.test(removedWhiteSpace),
		message: regex.test(removedWhiteSpace)
			? ''
			: 'Please enter a valid phone number beginning +44',
	};
};

export const niValidation = (string) => {
	const removedWhiteSpace = string.replace(/\s/, '').toUpperCase();
	const regex =
		/^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/;
	return {
		isValid: regex.test(removedWhiteSpace),
		message: regex.test(removedWhiteSpace) ? '' : 'Ni number is not valid',
	};
};

export const dateValidation = (date) => {
	if (date) {
		const validDate = checkDateValid(date);
		if (!validDate) {
			return {
				isValid: false,
				message: '',
			};
		}
	}

	if (moment(date) > moment()) {
		return {
			isValid: false,
			message: 'Date cannot be in the future',
		};
	} else if (moment(date) < moment('1900-01-01')) {
		return {
			isValid: false,
			message: 'Date is too early',
		};
	} else {
		return {
			isValid: true,
			message: '',
		};
	}
};

export const dateComparison = (startDate, endDate) => {
	if (!endDate) {
		return {
			isValid: true,
			message: '',
		};
	} else if (moment(endDate) < moment(startDate)) {
		return {
			isValid: false,
			message: 'Start date must be before end date',
		};
	} else {
		return {
			isValid: true,
			message: '',
		};
	}
};

export const isDisabled = (requiredFields, formState) => {
	let disabled = false;
	requiredFields.forEach((field) => {
		if (!formState[field]) {
			disabled = true;
		}
	});
	return disabled;
};

export const siaValidation = (licence) => {
	const regex = /^\d{16}$/;
	const removedWhiteSpace = licence.replace(/\s/, '');
	return {
		isValid: regex.test(removedWhiteSpace),
		message: regex.test(removedWhiteSpace)
			? ''
			: 'Licence needs to be 16 digits',
	};
};
export const shareCodeValidation = (shareCode) => {
	const regex = /^[A-Za-z0-9]*$/;
	const removedWhiteSpace = shareCode.replace(/\s/, '');
	if (shareCode.length !== 9) {
		return {
			isValid: false,
			message: 'Share code must be 9 characters.',
		};
	} else {
		return {
			isValid: regex.test(removedWhiteSpace),
			message: regex.test(removedWhiteSpace)
				? ''
				: 'Share code must contain only letters and numbers.',
		};
	}
};
