import React, { FC } from 'react';
import './orka-button.styles.scss';

type Props = {
	buttonContent: string;
	disabled: boolean;
	emitClicked: any;
	buttonClass: string;
	type?: 'submit' | 'reset' | 'button';
};

const OrkaButton: FC<Props> = ({
	buttonContent,
	disabled,
	emitClicked,
	buttonClass,
	type = 'button',
}) => {
	const handleClick = () => {
		emitClicked();
	};

	return (
		<div className='orka-button-container'>
			<button
				onClick={handleClick}
				disabled={disabled}
				className={buttonClass}
				type={type}
			>
				{buttonContent}
			</button>
		</div>
	);
};

export default OrkaButton;
