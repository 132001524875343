import React, { FC } from 'react';
import LoadingSpinner from '../loading-spinner/loading-spinner.component';
import './full-page-loader.styles.scss';

type Props = {
	loading: boolean;
};

const FullPageLoader: FC<Props> = ({ loading }) => {
	return (
		<>
			{loading && (
				<div className='full-page-loader'>
					<LoadingSpinner />
				</div>
			)}
		</>
	);
};

export default FullPageLoader;
