import './check-passport-leave-activity.styles.scss';

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetterOfEach } from '../../../utils/utilsFunctions';
import {
	IActivityFormState,
	IActivityItem,
} from '../../../types/interfaces/applicant.interface';
import { useDispatch } from 'react-redux';
import { updateActivityItem } from '../../../redux/Applicant/applicant.actions';
import { IOrganization } from '../../../types/interfaces/misc.interface';
import * as validations from '../../../utils/validations';
import * as utils from '../../../utils/utilsFunctions';
import * as api from '../../../api/requests/activity.api';
import * as activityFormHelpers from '../../activity/activity-form/activity-form.helpers';

import Header from '../../header/header/header.component';
import Input from '../../general/input/input.component';
import ValidationLayer from '../../general/validation-layer/validation-layer.component';

const CheckPassportLeaveActivity: FC<{
	latestActivity: IActivityItem;
}> = ({ latestActivity }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const activityItemToFormState = (
		activityItem: IActivityItem,
	): IActivityFormState => {
		return {
			...activityItem,
			address: activityItem?.address?.streetAddress,
			city: activityItem?.address?.city,
			postcode: activityItem?.address?.postcode,
			region: activityItem?.address?.region,
			country: activityItem?.address?.country,
		};
	};

	const [organisationSelected, setOrganisationSelected] =
		useState<IOrganization | null>(null);

	const [formState, setFormState] = useState<IActivityFormState>(
		activityItemToFormState(latestActivity),
	);
	const [validInputs, setValidInputs] = useState([{ endAt: true }]);
	const [continueClicked, setContinueClicked] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);

	const handleBack = () => {
		navigate('/check/activity-update');
	};

	const handleNext = () => {
		setContinueClicked(true);
		if (isDisabled) {
			return;
		}
		handleUpdateActivity();
		navigate('/check/activity-update-document', {
			state: { comingFromLeaveActivity: true },
		});
	};

	const handleEndAtChange = (endDate: string) => {
		setFormState({ ...formState, endAt: endDate });
	};

	const handleUpdateActivity = () => {
		return api
			.updateActivity(
				activityFormHelpers.formatPayload(
					formState,
					organisationSelected,
				),
			)
			.then(() => {
				const newItem = {
					...activityFormHelpers.formatPayload(
						formState,
						organisationSelected,
					),
				};

				dispatch(updateActivityItem(newItem));
				utils.handleAlerts(
					'success',
					'Successfully updated end date',
					dispatch,
				);
			})
			.catch((err: Error) => {
				utils.handleAlerts(
					'error',
					'unable to update activity',
					dispatch,
				);
				throw err;
			});
	};

	const styledText = (text: string) => (
		<span className='check-passport-activity-type'>{text}</span>
	);

	const activityMessage = useMemo(() => {
		if (!latestActivity) {
			return '';
		}

		const getActivityMessage = (activityType: string) => {
			const formattedActivityType = activityType.replace('-', ' ');
			switch (activityType) {
				case 'employed':
					return (
						<>
							When did you leave{' '}
							{styledText(
								capitalizeFirstLetterOfEach(
									latestActivity.name,
								),
							)}
							?
						</>
					);
				case 'self-employed':
					return (
						<>
							When did you stop being{' '}
							{styledText(formattedActivityType)}?
						</>
					);
				case 'studying':
				case 'volunteering':
				case 'travelling':
					return (
						<>
							When did you finish{' '}
							{styledText(formattedActivityType)}?
						</>
					);
				case 'job-seeking':
				case 'medical-reasons':
				case 'parental-cover':
				case 'self-study':
					return (
						<>
							When did you end your career break for{' '}
							{styledText(
								activityType === 'self-study'
									? 'self studies'
									: formattedActivityType,
							)}
							?
						</>
					);
				case 'carer':
					return (
						<>
							When did you end your career break as a{' '}
							{styledText(formattedActivityType)}?
						</>
					);
				case 'another-reason':
					return (
						<>When did you end your {styledText('career break')}?</>
					);
				default:
					return '';
			}
		};

		return <>{getActivityMessage(latestActivity.type)}</>;
	}, [latestActivity]);

	useEffect(() => {
		setFormState(activityItemToFormState(latestActivity));
	}, [latestActivity]);

	useEffect(() => {
		if (latestActivity) {
			const organisation = {
				company: latestActivity.name,
				email: latestActivity.email,
				address: latestActivity.address?.streetAddress,
				city: latestActivity.address?.city,
				country: latestActivity.address?.country,
				postcode: latestActivity.address?.postcode,
				region: latestActivity.address?.region,
			};
			setOrganisationSelected(organisation);
		}
	}, [latestActivity]);

	return (
		<div className='check-passport-leave-activity-wrapper'>
			<Header
				text={activityMessage}
				type='text'
				backFunction={handleBack}
				origin=''
				hideStepsCounter={true}
				hideDesktopSteps={true}
				updatePassport={true}
			/>

			<ValidationLayer
				setIsDisabled={setIsDisabled}
				formState={formState}
				validInputs={validInputs}
				requiredChecks={['requiredFields', 'validInputs']}
				requiredFields={['endAt']}
			>
				<div className='check-passport-leave-activity-body'>
					<Input
						inputName='endAt'
						title=''
						placeholder='dd/mm/yyyy'
						value={formState.endAt}
						emitValue={handleEndAtChange}
						isDate={true}
						validInputs={validInputs}
						setValidInputs={setValidInputs}
						validationFunction={validations.dateValidation}
						continueClicked={continueClicked}
						required={true}
					/>
				</div>
				<div className='check-passport-leave-activity-button-container'>
					<button
						className='margin-bottom-8 orka-identity-button primary-filled'
						onClick={handleNext}
					>
						Next
					</button>
				</div>
			</ValidationLayer>
		</div>
	);
};

export default CheckPassportLeaveActivity;
