import {
    SET_ALERT_INFO,
    CLEAR_ALERT_INFO,
    SET_IS_MOBILE,
    SET_APPLICATION_STATUS,
    UPDATE_STEPS,
    SET_STEPS_COMPLETE,
    SET_OVERALL_SUBMISSION,
    SET_IS_LOADING,
    CLEAR_GENERAL_STATE,
	SET_IS_TABLET,
} from './general.types';

const INITIAL_STATE = {
    alertInfo: {
        type: '',
        isVisible: false,
        message: ''
    },
    isLoading: false,
    isMobile: false,
	isTablet: false,
    applicationStatus: '',
    stepsComplete: [],
    overallSubmissionStatus: '',
    isLoading: false,
}

const generalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case SET_ALERT_INFO:
            return {
               ...state,
               alertInfo: action.alertInfo
            };
        case CLEAR_ALERT_INFO:
            return {
                ...state, 
                alertInfo: {
                    type: '',
                    isVisible: false,
                    message: ''
                }

            }
        case SET_IS_MOBILE:
            return {
                ...state,
                isMobile: action.isMobile
            }
		case SET_IS_TABLET:
			return {
				...state,
				isTablet: action.isTablet
			}
        case SET_APPLICATION_STATUS: 
            return {
                ...state,
                applicationStatus: action.applicationStatus
            }
        case SET_STEPS_COMPLETE:
            return {
                ...state,
                stepsComplete: action.stepsComplete,
            }
        case UPDATE_STEPS: 
            const newSteps = state.stepsComplete.map(step => {
                if (step.title === action.title) {
                    step.status = action.status
                }
                return step;
            })
            return {
                stepsComplete: newSteps,
                ...state,
            }
        case SET_OVERALL_SUBMISSION:
            return {
                ...state,
                overallSubmissionStatus: action.overallSubmissionStatus
            }
        case SET_IS_LOADING: 
            return {
                ...state,
                isLoading: action.isLoading,
            }
        case CLEAR_GENERAL_STATE: 
            return INITIAL_STATE;
        default:
            return state;
    }
}


export default generalReducer;