import React, { FC, Fragment } from 'react';
import './review-main.styles.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import ReviewCard from '../review-card/review-card.component';
import ReviewCardItem from '../review-card-item/review-card-item.component';
import * as utils from '../../../utils/utilsFunctions';
import useFetchData from '../../../utils/useFetchData';
import { getLicences } from '../../../api/requests/licence.api';
import {
	setLicences,
	setNameHistory,
	setDisclosures,
	setAddressHistory,
	setActivityHistory,
} from '../../../redux/Applicant/applicant.actions';
import {
	IDisclosure,
	ILicenceItem,
	INameHistoryItem,
	IAddressHistoryItemDb,
	IActivityItem,
} from '../../../types/interfaces/applicant.interface';
import { getNameHistory } from '../../../api/requests/names.api';
import { getCriminalRecordData } from '../../../api/requests/disclosures.api';
import { getAddressHistory } from '../../../api/requests/address.api';
import { getActivityHistory } from '../../../api/requests/activity.api';
import useGetHistoryDocs from '../../../utils/useGetHistoryDocs';
import { IFileItem } from '../../../types/interfaces/applicant.interface';
import documentIcon from '../../../assets/icons/document/document-icon.svg';
import InfoCard from '../../general/info-card/info-card.component';
import infoCardDoc from '../../../assets/icons/info-card/document.svg';

const ReviewMain: FC = () => {
	const generalInformation = useSelector(
		(state: RootState) => state.applicant.applicant,
	);

	const licences: ILicenceItem[] = useFetchData(
		getLicences,
		setLicences,
		1,
		'licences',
	);
	const nameHistory: INameHistoryItem[] = useFetchData(
		getNameHistory,
		setNameHistory,
		2,
	);
	const disclosures: IDisclosure[] = useFetchData(
		getCriminalRecordData,
		setDisclosures,
		2,
	);
	const addressHistory: IAddressHistoryItemDb[] = useFetchData(
		getAddressHistory,
		setAddressHistory,
		2,
		'addressHistory',
	);

	const activityHistory: IActivityItem[] = useFetchData(
		getActivityHistory,
		setActivityHistory,
		1,
		'activityHistory',
	);
	const addressDocuments: IFileItem[] = useGetHistoryDocs(true);

	const displayMissingDocumentCard = () => {
		const [activityData] = activityHistory;
		if (activityData) {
			if (activityData.files?.length < 1) {
				return true;
			} else {
				return false;
			}
		}
		return true;
	};

	return (
		<div className='review-main-container'>
			<h2 className='review-main-heading'>
				Please take a moment to review your application
			</h2>

			{displayMissingDocumentCard() && (
				<div className='review-info-card-container'>
					<InfoCard
						icon={infoCardDoc}
						size='small'
						text={`You won't be able to submit your application until you've uploaded at least 2 documents.`}
					/>
				</div>
			)}

			<ReviewCard title='General Information'>
				<ReviewCardItem>
					<p className='label'>First Name</p>
					<p>{generalInformation.name}</p>
				</ReviewCardItem>
				<ReviewCardItem>
					<p className='label'>Last Name</p>
					<p>{generalInformation.surname}</p>
				</ReviewCardItem>
				<ReviewCardItem>
					<p className='label'>Email</p>
					<p>{generalInformation.email}</p>
				</ReviewCardItem>
				<ReviewCardItem>
					<p className='label'>Phone Number</p>
					<p>{generalInformation.contactNumber}</p>
				</ReviewCardItem>
				<ReviewCardItem>
					<p className='label'>Gender</p>
					<p>{generalInformation.gender}</p>
				</ReviewCardItem>
				<ReviewCardItem>
					<p className='label'>Date of birth</p>
					<p>{generalInformation.DOB}</p>
				</ReviewCardItem>
				<ReviewCardItem>
					<p className='label'>NI Number</p>
					<p>{generalInformation.nationalInsuranceNumber}</p>
				</ReviewCardItem>
			</ReviewCard>

			<ReviewCard title='Licences'>
				{licences.map((licence, index) => {
					return (
						<div key={index} className='review-card-mapped-item'>
							<div className='review-card-mapped-item-left'>
								<p className='label'>{`Licence ${index + 1
									}`}</p>
							</div>

							<div className='review-card-mapped-item-right'>
								<p>{licence.number}</p>
								<p>{licence.type}</p>
								<p>
									Expiry{' '}
									{utils.formatDateForUser(
										licence.expiryDate
											? licence.expiryDate
											: licence.expiry_date,
									)}
								</p>
							</div>
						</div>
					);
				})}
			</ReviewCard>

			<ReviewCard title='Name History'>
				{nameHistory.map((nameItem, index) => {
					const { forename, middleNames, surname } = nameItem;

					return (
						<div className='review-card-mapped-item' key={index}>
							<div className='review-card-mapped-item-left'>
								<p className='label'>{`Name ${index + 1}`}</p>
							</div>
							<div className='review-card-mapped-item-right'>
								<p>
									{utils.getFullNameHistory(
										forename,
										middleNames,
										surname,
									)}
								</p>
								<p>
									{utils.formatDateForUser(nameItem.startAt)}
									{'-'}
									{utils.formatDateForUser(nameItem.endAt)}
								</p>
							</div>
						</div>
					);
				})}
			</ReviewCard>

			<ReviewCard title='Disclosures'>
				{disclosures.map((disclosure, index) => {
					return (
						<div className='review-card-mapped-item' key={index}>
							<div className='review-card-mapped-item-left'>
								<p className='label'>{`Disclosure ${index + 1
									}`}</p>
							</div>
							<div className='review-card-mapped-item-right'>
								<p>{disclosure.details}</p>
								<p>{disclosure.sentence}</p>
								<p>
									{utils.formatDateForUser(disclosure.date)}
								</p>
							</div>
						</div>
					);
				})}
			</ReviewCard>

			<ReviewCard title='Address History'>
				{addressHistory.length > 0 &&
					addressHistory.map((address, index) => {
						return (
							<div
								className='review-card-mapped-item'
								key={index}
							>
								<div className='review-card-mapped-item-left'>
									<p className='label'>{`Address ${index + 1
										}`}</p>
								</div>
								{address.type === 'gap' ? (
									<div className='review-card-mapped-item-right'>
										<p>Gap</p>
										<p>
											{utils.formatDateForUser(
												address.startAt,
											)}
											{' - '}
											{utils.formatDateForUser(
												address.endAt,
											)}
										</p>
									</div>
								) : (
									<div className='review-card-mapped-item-right'>
										<p>{address.address.streetAddress}</p>
										<p>{address.address.city}</p>
										<p>{address.address.country}</p>
										<p>{address.address.postcode}</p>
										<p>
											{utils.formatDateForUser(
												address.startAt,
											)}
											{' - '}
											{utils.formatDateForUser(
												address.endAt,
											)}
										</p>
									</div>
								)}
							</div>
						);
					})}
				<div className='review-card-mapped-item'>
					<div className='review-card-mapped-item-left' />
					{addressDocuments.length > 0 &&
						addressDocuments.map(
							(addressDocument, index: number) => {
								return (
									<div
										key={index}
										className='review-card-mapped-item-right'
									>
										<div className='review-doc-item'>
											<img
												src={documentIcon}
												alt='document'
											/>
											<a
												href={addressDocument.readUrl}
												target='_blank'
												rel='noreferrer'
											>
												Address Document 1
											</a>
										</div>
									</div>
								);
							},
						)}
					{addressDocuments.length === 0 && (
						<div className='review-card-mapped-item-right'>
							<div className='review-doc-item'>
								<img src={documentIcon} alt='document' />
								<p className='missing-document'>
									Missing Document
								</p>
							</div>
							<InfoCard
								size='small'
								text='Upload a supporting document to speed up reviewing your application.'
								icon={infoCardDoc}
							/>
						</div>
					)}
				</div>
			</ReviewCard>

			<ReviewCard title='Activity History'>
				{activityHistory &&
					activityHistory?.length > 0 &&
					activityHistory?.map((activity: IActivityItem, index) => {
						return (
							<div
								key={index}
								className='review-card-mapped-item'
							>
								<div className='review-card-mapped-item-left'>
									<p className='label'>{`Activity ${index + 1
										}`}</p>
								</div>
								<div className='review-card-mapped-item-right'>
									<p>
										{utils.capitalizeFirstLetterOfEach(
											utils.removeHyphen(activity.type),
										)}
									</p>
									<p>{activity.name}</p>
									<p>{activity.careerBreakReasonType}</p>
									<p>{activity.jobTitle}</p>
									<p>{activity.address?.streetAddress}</p>
									<p>{activity.address?.city}</p>
									<p>
										{activity.address?.postcode &&
											activity.address?.postcode.toUpperCase()}
									</p>
									<p>{activity.address?.country}</p>
									<p>{activity.email}</p>
									{activity.type === 'employed' && (
										<p className='review-font-grey'>
											{activity.canContactEmployer ===
												false && 'Do Not Contact'}
										</p>
									)}
									{activity.type === 'employed' &&
										activity.endAt && (
											<>
												<p>
													Reason for leaving{' '}
													{activity.reasonForLeaving}
												</p>
												<p>
													{
														activity.reasonForLeavingText
													}
												</p>
											</>
										)}
									<p>
										{utils.formatDateForUser(
											activity.startAt,
										)}
										{' - '}
										{activity.endAt
											? utils.formatDateForUser(
												activity.endAt,
											)
											: 'Current'}
									</p>

									{activity.files &&
										activity.files.length > 0 &&
										activity.files.map(
											(fileItem, fileIndex: number) => {
												return (
													<Fragment
														key={fileItem.uuid}
													>
														<div className='review-doc-item'>
															<img
																src={
																	documentIcon
																}
																alt='document'
															/>
															<a
																href={
																	fileItem.fileUrl
																}
																target='_blank'
																rel='noreferrer'
															>
																Activity{' '}
																Document{' '}
																{fileIndex + 1}
															</a>
														</div>
													</Fragment>
												);
											},
										)}
									{activity.files &&
										activity?.files?.length < 1 && (
											<Fragment>
												<div className='review-doc-item'>
													<img
														src={documentIcon}
														alt='document'
													/>
													<p className='missing-document'>
														Missing Documents
													</p>
												</div>
												<InfoCard
													size='small'
													text='Upload 2 supporting documents to speed up reviewing your application.'
													icon={infoCardDoc}
												/>
											</Fragment>
										)}
								</div>
							</div>
						);
					})}
			</ReviewCard>
			<div className='margin-bottom-120' />
		</div>
	);
};

export default ReviewMain;
