import React, { Component, ErrorInfo, ReactNode } from 'react';
import { logData } from '../../../utils/utilsFunctions';
import { LogLevel } from '@deploi-uk/package-monitoring/lib/enums';

type Props = {
	children: React.ReactNode;
	applicantWorkerUuid?: string;
};

interface State {
	hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
	state = { hasError: false };

	static getDerivedStateFromError(): { hasError: boolean } {
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		const errorData = {
			error,
			errorInfo,
		};
		logData(
			error.message,
			LogLevel.ERROR,
			'ed175a5f-b763-4d33-bfeb-f9c1f63e0ae7',
			errorData,
			this.props.applicantWorkerUuid,
		);
		this.setState({ hasError: true });
	}

	render(): ReactNode {
		if (this.state.hasError) {
			return (
				<div>
					<h1>Something went wrong.</h1>
					<p>Please go back and refresh.</p>
				</div>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
