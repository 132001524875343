import { ENDPOINTS } from '../utils/endpoints';
import { axiosInstance } from '../utils/api.axiosInstance';
import { getRequestURLFromParams } from '../utils/api.utils';
import axios from 'axios';
import store from '../../redux/store';
import { setIsLoading } from '../../redux/General/general.actions';
import { IUploadTypes } from '../../types/interfaces/misc.interface';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getDocUploadUrl = (
	workerUuid: string,
	contentType: string,
	type: IUploadTypes,
): Promise<any> => {
	let endpointUrl = '';
	switch (type) {
		case 'activityHistory':
			endpointUrl = ENDPOINTS.ACTIVITY_UPLOAD_URL;
			break;
		case 'workerDocuments':
			endpointUrl = ENDPOINTS.UPLOAD_URL;
			break;
		case 'rtwUpload':
			endpointUrl = ENDPOINTS.RTW_MANUAL_UPLOAD;
			break;

		default:
			break;
	}
	const url = getRequestURLFromParams(endpointUrl, { workerUuid });
	return axiosInstance()
		.post(url, { contentType })
		.catch((err) => {
			throw err;
		});
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const saveDocumentItem = (fileData: any): Promise<any> => {
	const url = getRequestURLFromParams(ENDPOINTS.DOCUMENTS_SAVE);
	return axiosInstance()
		.post(url, fileData)
		.catch((err) => {
			throw err;
		});
};

export const getDocumentsByTag = (tagName: string): any => {
	const url = getRequestURLFromParams(ENDPOINTS.GET_FILES_BY_TAG, {
		tagName,
	});

	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const deleteDocument = (fileUuid: string, itemUuid: string): any => {
	const url = getRequestURLFromParams(ENDPOINTS.DELETE_ADDRESS_DOCUMENT, {
		documentUuid: fileUuid,
		uuid: itemUuid,
	});

	return axiosInstance()
		.delete(url)
		.catch((err) => {
			throw err;
		});
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const uploadToGoogle = (file: any, url?: string): Promise<any> => {
	const { dispatch } = store;
	dispatch(setIsLoading(true));
	let writeUrl;
	if (url) {
		writeUrl = url;
	} else {
		writeUrl = file.writeUrl;
	}
	const headers = {
		'content-type': file.type,
	};
	return axios.put(writeUrl, file, { headers }).catch((err) => {
		throw err;
	});
};

export const saveActivityDocumentItem = (
	payload: { name: string; tags: string },
	activityUuid: string,
): Promise<any> => {
	const url = getRequestURLFromParams(ENDPOINTS.ACTIVITY_DOCUMENT_SAVE, {
		activityUuid,
	});

	return axiosInstance()
		.put(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const removeActivityDoc = (
	fileUuid: string,
	activityUuid: string,
): Promise<any> => {
	const url = getRequestURLFromParams(ENDPOINTS.ACTIVITY_DOCUMENT_REMOVE, {
		fileUuid,
		activityUuid,
	});

	return axiosInstance()
		.delete(url)
		.catch((err) => {
			throw err;
		});
};
