import './check-passport-address-document.styles.scss';

import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { handleUploadDocuments } from '../../../utils/document-uploads';
import { setAddressDocuments } from '../../../redux/Applicant/applicant.actions';
import * as docsApi from '../../../api/requests/documents.api';
import * as utils from '../../../utils/utilsFunctions';
import useGetHistoryDocs from '../../../utils/useGetHistoryDocs';

import ActivityDocumentsUploadCard from '../../activity/activity-document-upload-card/activity-document-upload-card.component';
import Header from '../../header/header/header.component';

type Props = {
	setShowForm: Dispatch<SetStateAction<boolean>>;
	setShowUpload: Dispatch<SetStateAction<boolean>>;
	handleComplete: () => void;
	setHasFile: Dispatch<SetStateAction<boolean>>;
	pageOrigin: string;
};

const CheckPassportAddressDocument: FC<Props> = ({
	handleComplete,
	setShowUpload,
	setHasFile,
}) => {
	const dispatch = useDispatch();

	const applicant = useSelector(
		(state: RootState) => state.applicant.applicant,
	);
	const addressHistory = useSelector(
		(state: RootState) => state.applicant.addressHistory,
	);
	const addressDocuments = useSelector(
		(state: RootState) => state.applicant.addressDocuments,
	);
	const isMobile = useSelector((state: RootState) => state.general.isMobile);

	const [refetchAddressDocs, setRefetchAddressDocs] = useState(false);

	useGetHistoryDocs(refetchAddressDocs);

	const handleBack = () => {
		handleComplete();
	};

	const handleUpload = (file: any) => {
		return handleUploadDocuments(
			file,
			applicant.workerUuid,
			'proofOfAddress',
			dispatch,
			'workerDocuments',
			undefined,
		)
			.then((res: any) => {
				dispatch(setAddressDocuments([res.data.data]));
				setShowUpload(true);
				setHasFile(true);
			})
			.catch((err: Error) => {
				throw err;
			});
	};

	const handleRemoveDocument = () => {
		const filteredAddress = addressHistory.filter((address) => {
			const keys = Object.keys(address);
			return keys.includes('uuid');
		});

		if (
			addressHistory &&
			addressHistory.length > 0 &&
			filteredAddress.length > 0
		) {
			if (typeof filteredAddress[0].uuid === 'string') {
				return docsApi
					.deleteDocument(
						addressDocuments[0].uuid,
						filteredAddress[0].uuid,
					)
					.then(() => {
						setRefetchAddressDocs(!refetchAddressDocs);
						utils.handleAlerts('success', 'Removed File', dispatch);
					})
					.catch((err: Error) => {
						handleErrors(err);
					});
			}
		}
	};

	const handleErrors = (err: Error) => {
		utils.handleAlerts('error', err.message, dispatch);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className='check-passport-address-document-wrapper'>
			{isMobile && (
				<Header
					text='Upload Documents'
					type='text'
					backFunction={handleBack}
					origin='form'
					hideStepsCounter={true}
					hideDesktopSteps={true}
				/>
			)}
			<div className='check-passport-address-document-body'>
				<p>Please provide documents to prove your current address.</p>
			</div>
			<ActivityDocumentsUploadCard
				handleUpload={handleUpload}
				documents={addressDocuments}
				requiredDocsNumber={1}
				isActivityCard={false}
				removeActivityDoc={handleRemoveDocument}
			/>
			<div className='check-passport-address-document-body'>
				<p>What counts as proof?</p>
				<ul>
					<li>Utility bill (from the last 3 months)</li>
					<li>Bank statement (from the last 3 months)</li>
					<li>Drivers licence</li>
					<li>Credit card statement (from the last 3 months)</li>
					<li>Council tax bill (from the last 12 months)</li>
					<li>P45/P60 (from the last 3 months)</li>
					<li>Mortgage statement (from the last 3 months)</li>
				</ul>
			</div>
			{isMobile && (
				<div className='check-passport-address-document-footer'>
					<button
						className='margin-bottom-8 orka-identity-button primary-filled'
						onClick={handleBack}
					>
						Submit
					</button>
				</div>
			)}
		</section>
	);
};

export default CheckPassportAddressDocument;
