import './accordion.styles.scss';
import React, { FC, useState, useRef } from 'react';
import chevron from '../../assets/icons/misc/back-blue.svg';

type Props = {
	title: string;
	children: React.ReactNode;
};

const Accordion: FC<Props> = ({ title, children }) => {
	const [collapsed, setCollapsed] = useState<boolean>(false);

	const handleClick = () => {
		setCollapsed(!collapsed);
	};

	const contentDivRef = useRef<HTMLDivElement>(null);
	const contentDivHeight =
		contentDivRef.current !== null && contentDivRef.current.scrollHeight;

	return (
		<div className='accordion-container' onClick={handleClick}>
			<div className='accordion-title'>
				<h4>{title}</h4>
				<div className='accordion-button-icon'>
					<img src={chevron} alt='collapse button' />
				</div>
			</div>
			<div
				className='accordion-content'
				ref={contentDivRef}
				style={{
					maxHeight: `${collapsed ? contentDivHeight + 'px' : 0}`,
				}}
			>
				{children}
			</div>
		</div>
	);
};

export default Accordion;
