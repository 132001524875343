import React, { useState, FC } from 'react';
import './reset-password-input.styles.scss';
import Input from '../login-input/login-input.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import * as apiAuth from '../../../api/requests/auth.api';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/General/general.actions';
import passwordIcon from '../../../assets/icons/login/password.svg';
import { useNavigate, useParams } from 'react-router-dom';

const ResetPasswordInput: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token = '' } = useParams();

	const [passwordErrorClass, setPasswordErrorClass] = useState<string>('');
	const [val, setVal] = useState<string>('');

	const validationHelper = (val: string) => {
		const validation =
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[^a-zA-Z\d\s:]).{8,}$/;
		const regex = new RegExp(validation);
		return regex.test(val) ? true : false;
	};

	const handleNewPassword = () => {
		if (!validationHelper(val)) {
			setPasswordErrorClass('password-validation-error');
			return;
		}

		const payload = {
			password: val,
		};

		return apiAuth
			.resetPassword(payload, token)
			.then(() => {
				dispatch(
					setAlert({
						type: 'success',
						message: 'Successfully reset password',
						isVisible: true,
					}),
				);
				setVal('');
				navigate('/login', { replace: true });
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
			});
	};

	const getValue = (name: string, value: string) => {
		setPasswordErrorClass('');
		setVal(value);
	};

	return (
		<div className='reset-password-input-container'>
			<Input
				name='password'
				getValue={getValue}
				value={val}
				placeholder='New Password'
				icon={passwordIcon}
				type='password'
			/>
			<div className={`validation-info ${passwordErrorClass}`}>
				<p>
					Password must contain at least 1 number, 1 capital letter, 1
					1 non - alphanumeric character and be between 8 and 16
					characters long.
				</p>
			</div>

			<div className='reset-password-input-button'>
				<OrkaButton
					buttonContent='Update'
					emitClicked={handleNewPassword}
					disabled={!val}
					buttonClass='purple-filled'
				></OrkaButton>
			</div>
		</div>
	);
};

export default ResetPasswordInput;
