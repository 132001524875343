import './check-passport-update-home.styles.scss';

import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../header/header/header.component';

const CheckPassportUpdateWhy: FC = () => {
	const navigate = useNavigate();

	const handleBack = () => {
		navigate('/check/update-home');
	};

	return (
		<div className='passport-update-home'>
			<Header
				text='Why do I have to update my passport?'
				type='text'
				backFunction={handleBack}
				origin=''
				hideStepsCounter={true}
				hideDesktopSteps={true}
				updatePassport={true}
			/>
			<div className='passport-update-home-body'>
				<p>
					The screening standards we follow require you to update your
					activity history every 90 days.
				</p>
				<p>
					This process ensures that your Deploi Passport remains
					current and up-to-date, which ensures that you can always
					use your Deploi Passport to get into work.
				</p>
			</div>
			<div className='passport-update-home-button-container'>
				<button
					className='margin-bottom-8 orka-identity-button primary-filled'
					onClick={handleBack}
				>
					Okay
				</button>
			</div>
		</div>
	);
};

export default CheckPassportUpdateWhy;
