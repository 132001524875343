import './licences-view.styles.scss';

import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { ILicenceItem } from '../../../types/interfaces/applicant.interface';
import * as utils from '../../../utils/utilsFunctions';

import Header from '../../header/header/header.component';
import InnerLayout from '../../general/inner-layout/inner-layout.component';

type Props = {
	handleScreen: (origin: string) => void;
	isPassport?: boolean;
};

const LicencesView: FC<Props> = ({ handleScreen, isPassport }) => {
	const licences = useSelector(
		(state: RootState) => state.applicant.licences,
	);

	const licenceImageData = useSelector((state: RootState) => state.licences);

	const [toggleState, setToggleState] = useState(1);
	const [isLicenceImageRequired, setIsLicenceImageRequired] = useState(false);

	const toggleTab = (index: number) => {
		setToggleState(index);
	};

	useEffect(() => {
		const currentLicence = licenceImageData.data[0];

		const isImageRequired = licences.some(
			(licence) =>
				licence.number === currentLicence.number &&
				(!licence.fileNameFront || !licence.fileNameBack),
		);

		setIsLicenceImageRequired(isImageRequired);
	}, [licences, licenceImageData]);

	const renderLicenceDetails = (licence: ILicenceItem) => {
		if (licence.number !== licenceImageData.data[0].number) {
			return null;
		}

		return (
			<div key={licence.number}>
				<h1 className='header-title'>
					{licence.name + ' ' + licence.surname}
				</h1>
				<p>{licence.number}</p>
				<div className='licence-detail-wrapper'>
					<p className='licence-type'>{licence.sector}</p>
					<p className='licence-expire'>
						{`Expires: ${utils.formatDateForUser(
							licence.expiryDate
								? licence.expiryDate
								: licence.expiry_date,
						)}`}
					</p>
				</div>
				<div className='grey-line'></div>
				{isLicenceImageRequired ? (
					<div className='container'>
						<br />
						<p>No images uploaded yet.</p>
					</div>
				) : (
					<div className='container'>
						<div className='bloc-tabs'>
							<button
								className={
									toggleState === 1
										? 'tabs active-tabs'
										: 'tabs'
								}
								onClick={() => toggleTab(1)}
							>
								Front
							</button>
							<button
								className={
									toggleState === 2
										? 'tabs active-tabs'
										: 'tabs'
								}
								onClick={() => toggleTab(2)}
							>
								Back
							</button>
						</div>

						<div className='content-tabs'>
							<div
								className={
									toggleState === 1
										? 'content  active-content'
										: 'content'
								}
							>
								<img
									className='center-image'
									src={licence.frontReadUrl}
								></img>
							</div>

							<div
								className={
									toggleState === 2
										? 'content  active-content'
										: 'content'
								}
							>
								<img
									className='center-image'
									src={licence.backReadUrl}
								></img>
							</div>
						</div>
						<div
							style={{
								paddingBottom: 105,
							}}
						></div>
					</div>
				)}
			</div>
		);
	};

	return (
		<div className='licences-view-container'>
			<Header
				text='SIA Licence'
				type='text'
				backFunction={() => handleScreen('form')}
				origin='form'
				hideDesktopSteps={isPassport}
			/>
			<InnerLayout>
				<div>
					<br></br>
					{licences.length > 0 && (
						<>{licences.map(renderLicenceDetails)}</>
					)}

					<footer className='page-footer-outer-container'>
						<div className='page-footer-container'>
							<div className='orka-button-container'>
								<button
									onClick={() => handleScreen('form')}
									className={
										isLicenceImageRequired
											? 'blue-outline'
											: 'blue-filled'
									}
									type='button'
								>
									Close
								</button>
								{isLicenceImageRequired && (
									<button
										onClick={() =>
											handleScreen('upload-start')
										}
										className='blue-filled'
										type='button'
									>
										Upload Images
									</button>
								)}
							</div>
						</div>
					</footer>
				</div>
				<div></div>
			</InnerLayout>
		</div>
	);
};

export default LicencesView;
