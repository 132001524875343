import React, { Dispatch, FC, SetStateAction } from 'react';
import './organisation-card.styles.scss';
import { IOrganization } from '../../../types/interfaces/misc.interface';

type Props = {
	organisation: IOrganization;
	setOrganisationSelected: Dispatch<SetStateAction<IOrganization | null>>;
};

const OrganisationCard: FC<Props> = ({
	organisation,
	setOrganisationSelected,
}) => {
	const handleSelectOrganisation = () => {
		setOrganisationSelected(organisation);
	};

	return (
		<div
			className='organisation-card-container'
			onClick={handleSelectOrganisation}
		>
			<div>
				<p>
					<span className='name'>{organisation.company}</span>
					:&nbsp;
					{organisation.region}
				</p>
			</div>
			<div>
				<p>
					<span className='city'>{organisation.city}</span>
					&nbsp;
					{organisation.contactNumber}
				</p>
			</div>
		</div>
	);
};

export default OrganisationCard;
