import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './submission-complete.styles.scss';
import Layout from '../../components/general/layout-component/layout.component';
import MainHeader from '../../components/header/main-header/main-header.component';
import { fireGaEvent } from '../../utils/ga-event';
import WorkingOnApplication from '../../components/submission-complete/working-on-application/working-on-application.component';
import NotStarted from '../../components/submission-complete/not-started/not-started.component';
import BottomSheetModal from '../../components/modals/bottom-sheet-modal/bottom-sheet-modal.component';
import { getNumberOfDaysLeftFromClearedDate } from '../../utils/utilsFunctions';
import { RootState } from '../../types/root-state';
import LimitedClearanceExpiry from '../../components/submission-complete/limited-clearance-expiry/limited-clearance-expiry.component';

const SubmissionComplete: FC = () => {
	const [showProgress, setShowProgress] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [modalTitleText, setModalTitleText] = useState<string | null>(null);
	const [modalTitleEmphasisText, setModalTitleEmphasisText] = useState<
		string | null
	>(null);

	const actionCardSubText =
		'If we cannot clear you in this time, you will not be able to work with us.';

	const clearanceStatusHistory = useSelector(
		(state: RootState) => state.applicant.applicant.clearanceStatusHistory,
	);

	const handleSetShowModal = (showModal: boolean) => {
		setShowModal(showModal);
	};

	useEffect(() => {
		fireGaEvent('Submission', 'Submission - What Happens Next Page');
	}, []);

	useEffect(() => {
		if (
			clearanceStatusHistory?.provisionallyClearedDate &&
			clearanceStatusHistory?.clearanceStatus === 'partially_compliant'
		) {
			setModalTitleText('Limited clearance expiring in');
			setModalTitleEmphasisText(
				`${getNumberOfDaysLeftFromClearedDate(
					clearanceStatusHistory.provisionallyClearedDate,
				)} DAYS`,
			);
		}
	}, [clearanceStatusHistory]);

	return (
		<Layout background=''>
			<MainHeader
				postSubmission={true}
				setShowProgress={setShowProgress}
				showProgress={showProgress}
				actionCardText={modalTitleText}
				actionCardEmphasisText={modalTitleEmphasisText}
				actionCardSubText={actionCardSubText}
				showModal={showModal}
				setShowModal={handleSetShowModal}
			/>
			<div className='submission-complete-container'>
				{showProgress && (
					<div className='submission-complete-breadcrumbs'>
						<a
							onClick={() => setShowProgress(false)}
							className='breadcrumb link'
						>
							Home
						</a>
						<p className='breadcrumb'>{'>'}</p>
						<p className='breadcrumb'>Progress</p>
					</div>
				)}
			</div>
			{showProgress && <WorkingOnApplication />}
			{!showProgress && <NotStarted />}
			{showModal && (
				<div className='bottom-sheet-modal-wrapper'>
					<BottomSheetModal
						setShowModal={setShowModal}
						showModal={showModal}
					>
						<LimitedClearanceExpiry
							daysLeft={getNumberOfDaysLeftFromClearedDate(
								clearanceStatusHistory?.provisionallyClearedDate,
							)}
							setShowModal={setShowModal}
							setShowProgress={setShowProgress}
						/>
					</BottomSheetModal>
				</div>
			)}
		</Layout>
	);
};

export default SubmissionComplete;
