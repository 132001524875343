import {
	IDisclosure,
	INameHistoryItem,
	IAddressHistoryItem,
	IActivityItem,
	ILicenceItem,
} from './applicant.interface';

export interface IGcpLogData {
	name: string;
	level: string;
	data: string;
}

export interface ILinkState {
	organisations: boolean;
	tasks: boolean;
	staff: boolean;
}

export interface ICheckboxData {
	uuid?: string;
	question: string;
	answers: ICheckboxAnswer[];
	disclosures?: IDisclosure[];
	type?: string;
}

export interface ICheckboxAnswer {
	id: number;
	label: string;
	selected: boolean;
	info?: string;
	disabled?: boolean;
}

export interface IListCardItem {
	uuid: string;
	title: string;
	lineOne: string;
	lineTwo: string | Date;
	lineThree?: string;
	endAt?: string;
}

export interface IDropdownStandard {
	id: number;
	value: string;
	dbValue: string | boolean;
	class: string;
	selected?: boolean;
}

export interface IOrganization {
	id?: number;
	uuid?: string;
	company: string;
	address?: string;
	city: string;
	country: string;
	postcode: string;
	region?: string;
	notes?: string;
	contactNumber?: string;
	email: string;
	deletedAt?: string | Date | null;
	createdAt?: string | Date | null;
	updatedAt?: string | Date | null;
}

export interface IStepPayload {
	status: StepStatusEnum;
	step: StepNamesEnum | string;
}

export enum StepStatusEnum {
	incomplete = 'incomplete',
	inProgress = 'in-progress',
	complete = 'complete',
}

export enum StepNamesEnum {
	generalInformation = 'generalInformation',
	nameHistory = 'nameHistory',
	addressHistory = 'addressHistory',
	activityHistory = 'activityHistory',
	declaration = 'declaration',
	licences = 'licences',
}

export interface IHistory {
	nameHistory: INameHistoryItem[];
	addressHistory: IAddressHistoryItem[];
	activityHistory: IActivityItem[];
	declaration: IDisclosure[];
	licences: ILicenceItem[];
}

export type IUploadTypes = 'activityHistory' | 'workerDocuments' | 'rtwUpload';
