import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './name-history-list.styles.scss';
import Header from '../../header/header/header.component';
import book from '../../../assets/icons/info-card/book.svg';
import {
	ICheckboxAnswer,
	ICheckboxData,
	IListCardItem,
	StepNamesEnum,
	StepStatusEnum,
} from '../../../types/interfaces/misc.interface';
import InfoCard from '../../general/info-card/info-card.component';
import PageFooter from '../../general/page-footer/page-footer.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import ListCard from '../../general/list-card/list-card.component';
import * as utils from '../../../utils/utilsFunctions';
import moment from 'moment';
import { INameHistoryItem } from '../../../types/interfaces/applicant.interface';
import ButtonList from '../../general/button-list/button-list.component';
import InnerLayout from '../../general/inner-layout/inner-layout.component';
import DropdownInfo from '../../general/dropdown-info/dropdown-info.component';
import errorIcon from '../../../assets/icons/misc/error.svg';
import * as api from '../../../api/requests/names.api';
import * as miscApi from '../../../api/requests/misc.api';
import { setAlert, updateSteps } from '../../../redux/General/general.actions';
import { fireGaEvent } from '../../../utils/ga-event';
import useFetchData from '../../../utils/useFetchData';
import { setNameHistory } from '../../../redux/Applicant/applicant.actions';
import useResubmitStepStatus from '../../../utils/useResubmitStepStatus';
import { useNavigate } from 'react-router-dom';

type Props = {
	pageName: string;
	handleBack: (origin: string) => void;
	checkboxData: ICheckboxData;
	handleSelection: (
		checkBoxAnswer: ICheckboxAnswer,
		question: string,
	) => void;
	setShowForm: Dispatch<SetStateAction<boolean>>;
	setSelectedName: Dispatch<SetStateAction<INameHistoryItem | null>>;
	setCheckboxData: Dispatch<SetStateAction<any>>;
};
const NameHistoryList: FC<Props> = ({
	pageName,
	handleBack,
	checkboxData,
	handleSelection,
	setShowForm,
	setSelectedName,
	setCheckboxData,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [submissionValidationError, setSubmissionValidationError] =
		useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const isTablet = useSelector((state: RootState) => state.general.isTablet);

	const nameHistory: INameHistoryItem[] = useFetchData(
		api.getNameHistory,
		setNameHistory,
		2,
		'nameHistory',
	);

	fireGaEvent('Other Names', 'Names - List Page');

	useEffect(() => {
		utils.instantScroll();
		if (nameHistory.length === 0) {
			checkboxData.answers[0].selected = false;
			checkboxData.answers[1].selected = false;
			setCheckboxData([checkboxData]);
		} else {
			setIsDisabled(false);
			setSubmissionValidationError(false);
		}
	}, [nameHistory.length]);

	useEffect(() => {
		if (
			nameHistory.length === 0 &&
			checkboxData.answers[0].selected === false &&
			checkboxData.answers[1].selected === false
		) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
			setSubmissionValidationError(false);
		}
	}, [JSON.stringify(checkboxData), nameHistory.length]);

	const handleAddAnother = () => {
		fireGaEvent('Other Names', 'Clicked add another name');
		setShowForm(true);
		setSelectedName(null);
	};

	const handleSelectName = (name: IListCardItem) => {
		fireGaEvent('Other Names', 'Clicked select / edit name');
		const filteredName = nameHistory.filter(
			(globalName) => globalName.uuid === name.uuid,
		)[0];
		setSelectedName(filteredName);
		setShowForm(true);
	};

	useResubmitStepStatus(
		isDisabled,
		StepNamesEnum.nameHistory,
		'Name History',
	);

	const handleComplete = () => {
		utils.instantScroll();
		if (isDisabled) {
			fireGaEvent(
				'Other Names',
				'Clicked complete name submission (validation failed)',
			);
			setSubmissionValidationError(true);
			return;
		}
		fireGaEvent('Other Names', 'Clicked complete name submission');
		return api
			.saveNameSubmission()
			.then(() => {
				return handleSubmitSection();
			})
			.catch((err: Error) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
				throw err;
			});
	};

	const handleSubmitSection = () => {
		return miscApi
			.postStep({
				step: StepNamesEnum.nameHistory,
				status: StepStatusEnum.complete,
			})
			.then(() => {
				handleSuccess();
			})
			.catch((err: Error) => {
				handleErrors(err);
			});
	};

	const handleErrors = (err: Error) => {
		utils.handleAlerts('error', err.message, dispatch);
	};

	const handleSuccess = () => {
		dispatch(
			setAlert({
				type: 'success',
				message: 'Successfully submitted section',
				isVisible: true,
			}),
		);
		dispatch(updateSteps('Name History', 'complete'));
		return navigate('/', { replace: true });
	};

	return (
		<div className='name-history-list-container'>
			<Header
				text={pageName ? pageName : ''}
				type='text'
				backFunction={handleBack}
				origin='main'
				informationText='We ask for this so we can perform our checks thoroughly. Also, your previous employers/organisations may know you by a different name, so we want to give them this information without bugging you again.'
			>
				<InfoCard
					size='small'
					icon={book}
					text='This could even be a nickname eg. Ellie > Eleanor. The most common reasons are marriage, or preferring to go by your middle name'
				/>
			</Header>
			<InnerLayout>
				<>
					{isTablet && submissionValidationError && (
						<div className='submission-validation-error'>
							<InfoCard
								size='small'
								icon={errorIcon}
								text="You can't complete this section until you add previous names or select 'No'."
								subText='If you wish to complete this section later you can tap the back arrow and your progress will be saved.'
								type='validation-card'
							/>
						</div>
					)}
					{nameHistory.length === 0 && (
						<div className='name-history-button-list'>
							<ButtonList
								checkboxData={checkboxData}
								handleSelection={handleSelection}
							/>
						</div>
					)}

					{nameHistory.length > 0 && (
						<div className='name-history-cards'>
							{nameHistory.map((name) => {
								return (
									<div key={name.uuid}>
										<ListCard
											item={{
												uuid: name.uuid,
												title: utils.getFullNameHistory(
													name.forename,
													name.middleNames,
													name.surname,
												),
												lineOne:
													utils.capitalizeFirstLetter(
														name.nameType.replace(
															/Name/,
															' Name',
														),
													),
												lineTwo: `${moment(
													name.startAt,
												).format(
													'MMM YYYY',
												)} - ${moment(
													name.endAt,
												).format('MMM YYYY')}`,
											}}
											clickFunction={handleSelectName}
										/>
									</div>
								);
							})}
							<div className='add-another-button'>
								<OrkaButton
									buttonContent='Add Another'
									disabled={false}
									emitClicked={handleAddAnother}
									buttonClass='purple-outline'
								/>
							</div>
						</div>
					)}

					<PageFooter primaryButtonFunction={handleComplete} />
					<div className='margin-bottom-120' />
				</>
				<>
					<DropdownInfo
						title={`Why do you need this information?`}
						text={
							'We ask for this so we can perform our checks thoroughly. Also, your previous employers/organisations may know you by a different name, so we want to give them this information without bugging you again.'
						}
						desktopVersion={true}
						handleClick={() =>
							fireGaEvent(
								'Other Names',
								'Tapped why do you need this information dropdown',
							)
						}
					/>

					<InfoCard
						size='small'
						icon={book}
						text='This could even be a nickname eg. Ellie > Eleanor. The most common reasons are marriage, or preferring to go by your middle name'
					/>
					{!isTablet && submissionValidationError && (
						<div className='submission-validation-error'>
							<InfoCard
								size='small'
								icon={errorIcon}
								text="You can't complete this section until you add previous names or select 'No'."
								subText='If you wish to complete this section later you can tap the back arrow and your progress will be saved.'
								type='validation-card'
							/>
						</div>
					)}
				</>
			</InnerLayout>
		</div>
	);
};

export default NameHistoryList;
