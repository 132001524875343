import React, { FC } from 'react';
import './guide-card.styles.scss';

type Props = {
	title: string;
	titleIcon?: string;
	children?: React.ReactNode;
	background?: string;
	foreground?: string;
	hasTag: boolean;
	tagTitle?: string;
	tagIcon?: string;
	tagBackground?: string;
	tagForeground?: string;
};

const GuideCard: FC<Props> = ({
	title,
	titleIcon,
	children,
	background,
	foreground,
	hasTag,
	tagTitle,
	tagIcon,
	tagBackground,
	tagForeground,
}) => {
	return (
		<article
			className='guide-card'
			style={{ backgroundColor: `${background}` }}
		>
			{hasTag && (
				<span
					className='guide-card-tag'
					style={{
						background: `${tagBackground}`,
						color: `${tagForeground}`,
					}}
				>
					{tagIcon && (
						<img
							src={tagIcon}
							alt={tagTitle}
							className='tag-icon'
						/>
					)}
					{tagTitle}
				</span>
			)}
			<h3 className='guide-card-title' style={{ color: `${foreground}` }}>
				{titleIcon && <img src={titleIcon} alt={title} />}{' '}
				<span>{title}</span>
			</h3>
			<div
				style={{ color: `${foreground}` }}
				className='guide-card-content'
			>
				{children}
			</div>
		</article>
	);
};

export default GuideCard;
