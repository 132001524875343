import React, { FC, Fragment } from 'react';
import './page-footer.styles.scss';
import OrkaButton from '../orka-button/orka-button.component';
import InfoCard from '../info-card/info-card.component';
import rocket from '../../../assets/images/rocket.png';

type Props = {
	buttonText?: string;
	handleComplete?: any;
	completeClass?: string;
	disabled?: boolean;
	handleEditApplication?: () => void;
	primaryButtonText?: string;
	primaryButtonFunction?: any;
	secondaryButtonText?: string;
	secondaryButtonFunction?: () => void;
	fromSettings?: boolean;
	editApplication?: boolean;
	removeFixedFooter?: boolean;
};

const PageFooter: FC<Props> = ({
	primaryButtonText = 'Complete Section',
	primaryButtonFunction,
	secondaryButtonFunction,
	secondaryButtonText,
	completeClass = 'purple-filled',
	disabled = false,
	fromSettings = false,
	editApplication,
	removeFixedFooter = false,
}) => {
	return (
		<>
			<footer
				className={
					secondaryButtonText && !fromSettings
						? `page-footer-outer-container review-outer ${
								editApplication && 'review-outer-small'
							} ${removeFixedFooter && 'remove-fixed-footer'}`
						: `page-footer-outer-container ${
								removeFixedFooter && 'remove-fixed-footer'
							}`
				}
			>
				<div
					className={
						secondaryButtonText
							? 'page-footer-container review'
							: 'page-footer-container'
					}
				>
					{secondaryButtonText ? (
						<>
							{secondaryButtonText !== 'edit' &&
								editApplication !== true && (
									<div className='edit'>
										<OrkaButton
											buttonContent={secondaryButtonText}
											buttonClass='purple-outline'
											emitClicked={
												secondaryButtonFunction
											}
											disabled={false}
										/>
									</div>
								)}
							{!fromSettings && (
								<Fragment>
									<div className={'complete'}>
										<OrkaButton
											buttonContent={primaryButtonText}
											disabled={disabled}
											emitClicked={primaryButtonFunction}
											buttonClass={completeClass}
										/>
									</div>
									<InfoCard
										size='small'
										text='A member of the team will start working on your application'
										icon={rocket}
										withPointer={true}
									/>
								</Fragment>
							)}
						</>
					) : (
						<OrkaButton
							buttonContent={primaryButtonText}
							disabled={disabled}
							emitClicked={primaryButtonFunction}
							buttonClass={completeClass}
						/>
					)}
				</div>
			</footer>
		</>
	);
};

export default PageFooter;
