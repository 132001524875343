export const SET_NAME_HISTORY = 'SET_NAME_HISTORY';
export const ADD_NAME_HISTORY = 'NAME_HISTORY';
export const UPDATE_NAME_HISTORY = 'UPDATE_NAME_HISTORY';
export const REMOVE_NAME_ITEM = 'REMOVE_NAME_ITEM';

export const LICENCES = 'LICENCES';
export const ADD_LICENCE = 'ADD_LICENCE';
export const UPDATE_LICENCE = 'UPDATE_LICENCE';
export const REMOVE_LICENCE = 'REMOVE_LICENCE';

export const SET_ADDRESS_HISTORY = 'SET_ADDRESS_HISTORY';
export const UPDATE_ADDRESS_HISTORY = 'UPDATE_ADDRESS_HISTORY';
export const REMOVE_ADDRESS_ITEM = 'REMOVE_ADDRESS_ITEM';
export const ADD_ADDRESS_ITEM = 'ADD_ADDRESS_ITEM';

export const SET_ACTIVITY_HISTORY = 'SET_ACTIVITY_HISTORY';
export const UPDATE_ACTIVITY_HISTORY = 'UPDATE_ACTIVITY_HISTORY';
export const REMOVE_ACTIVITY_ITEM = 'REMOVE_ACTIVITY_ITEM';
export const ADD_ACTIVITY_ITEM = 'ADD_ACTIVITY_ITEM';

export const SET_DISCLOSURES = 'SET_DISCLOSURES';
export const UPDATE_DISCLOSURE = 'UPDATE_DISCLOSURE';
export const REMOVE_DISCLOSURE = 'REMOVE_DISCLOSURE';
export const ADD_DISCLOSURE = 'ADD_DISCLOSURE';

export const APPLICANT = 'APPLICANT';
export const UPDATE_APPLICANT = 'UPDATE_APPLICANT';

export const SET_CONSENT_SUBMITTED = 'SET_CONSENT_SUBMITTED';
export const SET_CONSENT_MODAL_STATE = 'SET_CONSENT_MODAL_STATE';

export const SET_ADDRESS_DOCUMENTS = 'SET_ADDRESS_DOCUMENTS';

export const CLEAR_APPLICANT_STATE = 'CLEAR_APPLICANT_STATE';
