import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as utils from '../utils/utilsFunctions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useFetchData = (
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	fetchFunction: any,
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	reduxAction: any,
	responseLevel: number,
	historyType?: string,
	refetchData?: boolean,
): any => {
	const dispatch = useDispatch();
	const [data, setData] = useState([]);

	useEffect(() => {
		const fetchData = () => {
			return fetchFunction()
				.then((res: any) => {
					if (res.status === 404) {
						dispatch(reduxAction([]));
						throw res;
					}
					let data = responseLevel === 2 ? res.data.data : res.data;

					if (historyType === 'licences') {
						data = data.map((element: any) => {
							element.expiryDate = element.expiryDate.replace(
								/-/gm,
								'/',
							);
							return element;
						});
					}

					if (
						historyType === 'activityHistory' ||
						historyType === 'addressHistory' ||
						historyType === 'nameHistory'
					) {
						data = utils.sortByDate(data);
					}

					dispatch(reduxAction(data));
					setData(data);
				})
				.catch((err: Error) => {
					throw err;
				});
		};
		fetchData();
	}, [refetchData]);
	return data;
};

export default useFetchData;
