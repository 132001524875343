import React, { useEffect, FC } from 'react';
import './alert.styles.scss';
import { useDispatch } from 'react-redux';
import { clearAlert } from '../../../redux/General/general.actions';
import * as utils from '../../../utils/utilsFunctions';
import success from '../../../assets/icons/misc/success.svg';
import error from '../../../assets/icons/misc/error.svg';

const AlertComponent: FC<any> = ({ alertData }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		setTimeout(() => {
			dispatch(clearAlert());
		}, 2000);
	}, [dispatch, alertData.isVisible]);

	return (
		<div className={`alert-component ${alertData.type}`}>
			<div className='alert-header'>
				<h2>{utils.capitalizeFirstLetter(alertData.type)}</h2>
				<img
					className={
						alertData.type === 'success' ? 'success' : 'error'
					}
					src={alertData.type === 'success' ? success : error}
					alt='alert'
				/>
			</div>

			<div className='alert-info'>
				<p>{alertData.message}</p>
			</div>
		</div>
	);
};

export default AlertComponent;
