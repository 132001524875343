import './check-container.styles.scss';

import React, { FC, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/root-state';

import NotFound from '../general/not-found/not-found.component';
import ApplicationProgress from '../../pages/Application-Progress/application-progress.component';
import GeneralInformation from '../../pages/General-information/general-information.component';
import NameHistory from '../../pages/Name-history/name-history.component';
import Disclosures from '../../pages/Disclosures/disclosures.component';
import Consent from '../../pages/Consent/consent.component';
import Licences from '../../pages/Licences/licences.component';
import AddressHistory from '../../pages/Address-history/address-history.component';
import ActivityHistory from '../../pages/Activity-history/activity-history.component';
import SubmissionComplete from '../../pages/Submission-complete/submission-complete.component';
import ReviewApplication from '../../pages/Review-application/review-application.component';
import useApplicationInit from './useApplicationInit.component';
import ErrorBoundary from '../general/error-boundary/error-boundary.component';
import Terms from '../../pages/Terms/terms.component';

const CheckContainer: FC = () => {
	const clearanceStatusHistory = useSelector(
		(state: RootState) => state.applicant.applicant?.clearanceStatusHistory,
	);
	const applicantWorkerUuid = useSelector(
		(state: RootState) => state.applicant.applicant?.workerUuid,
	);
	if (clearanceStatusHistory?.clearanceStatus === 'fully_compliant') {
		return null;
	}

	const { stepsComplete, applicant } = useApplicationInit();

	useEffect(() => {
		if (!window.history.state.usr) {
			window.history.pushState({ page: 1 }, '', '');
		}
	}, []);

	return (
		<div className='check-container'>
			<ErrorBoundary applicantWorkerUuid={applicantWorkerUuid}>
				{applicant && stepsComplete && stepsComplete.length > 0 && (
					<Routes>
						<Route path='/' element={<ApplicationProgress />} />
						<Route path='/terms' element={<Terms />} />
						<Route
							path='/general-information'
							element={<GeneralInformation />}
						/>
						<Route path='/name-history' element={<NameHistory />} />
						<Route path='/declaration' element={<Disclosures />} />
						<Route path='/licences' element={<Licences />} />
						<Route
							path='/address-history'
							element={<AddressHistory />}
						/>
						<Route
							path='/activity-history'
							element={<ActivityHistory />}
						/>
						<Route path='/consent' element={<Consent />} />
						<Route
							path='/application-submitted'
							element={<SubmissionComplete />}
						/>
						<Route
							path='/review-application'
							element={<ReviewApplication />}
						/>
						<Route
							path='*'
							element={
								<NotFound type='noData' pageNotFound={true} />
							}
						/>
					</Routes>
				)}
			</ErrorBoundary>
		</div>
	);
};

export default CheckContainer;
