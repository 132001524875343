import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../types/root-state';
import {
	IStepPayload,
	StepStatusEnum,
} from '../types/interfaces/misc.interface';
import * as miscApi from '../api/requests/misc.api';
import { updateSteps } from '../redux/General/general.actions';
import * as utils from '../utils/utilsFunctions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGetPendingSteps = () => {
	const dispatch = useDispatch();
	const addressHistory = useSelector(
		(state: RootState) => state.applicant.activityHistory,
	);
	const licences = useSelector(
		(state: RootState) => state.applicant.licences,
	);
	const nameHistory = useSelector(
		(state: RootState) => state.applicant.nameHistory,
	);
	const activityHistory = useSelector(
		(state: RootState) => state.applicant.activityHistory,
	);
	const disclosures = useSelector(
		(state: RootState) => state.applicant.disclosures,
	);
	const stepsComplete = useSelector(
		(state: RootState) => state.general.stepsComplete,
	);
	useEffect(() => {
		stepsComplete.forEach((step) => {
			if (step.status !== 'complete') {
				const stepTitle = step.title;
				const stepPayload = {
					step: stepTitle,
					status: StepStatusEnum.inProgress,
				};

				setStepPending(stepPayload, getHistory(stepTitle));
			}
		});
	}, []);

	const getHistory = (stepTitle: string) => {
		switch (stepTitle) {
			case 'Licences':
				return licences;
			case 'Address History':
				return addressHistory;
			case 'Name History':
				return nameHistory;
			case 'Activity History':
				return activityHistory;
			case 'Disclosures':
				return disclosures;
		}
	};

	const setStepPending = (step: IStepPayload, history: any) => {
		if (history && history.length > 0) {
			return miscApi
				.postStep({
					step: utils.validateStepForDb(step.step),
					status: StepStatusEnum.inProgress,
				})
				.then(() => {
					dispatch(updateSteps(step.step, 'in-progress'));
				})
				.catch((err: Error) => {
					throw err;
				});
		}
	};
};

export default useGetPendingSteps;
