import './image-modal.styles.scss';
import React, { FC, useState } from 'react';
import crossIcon from '../../../assets/icons/misc/crossIcon.svg';

type Props = {
	imageSource: string;
	alt?: string;
};

const ImageModal: FC<Props> = ({ imageSource, alt }) => {
	const [showImageModal, setShowImageModal] = useState<boolean>(false);

	const handleShowImageModal = (): void => {
		setShowImageModal(!showImageModal);
	};

	return (
		<div className='img-modal-container'>
			<img src={imageSource} alt={alt} onClick={handleShowImageModal} />
			<span className='img-modal-cta' onClick={handleShowImageModal}>
				Tap to zoom in
			</span>
			{showImageModal && (
				<div
					className='img-modal-dialog'
					onClick={handleShowImageModal}
				>
					<button className='close-modal-btn'>
						<img src={crossIcon} alt='close' />
					</button>
					<img
						src={imageSource}
						alt={alt}
						onClick={handleShowImageModal}
					/>
				</div>
			)}
		</div>
	);
};

export default ImageModal;
