import React, { FC } from 'react';
import './checkbox.styles.scss';
import checkboxEmpty from '../../../assets/icons/misc/checkbox-empty.svg';
import checkboxTicked from '../../../assets/icons/misc/checkbox-filled.svg';
import InfoCard from '../info-card/info-card.component';
import {
	ICheckboxAnswer,
	ICheckboxData,
} from '../../../types/interfaces/misc.interface';

type Props = {
	alignment: string;
	checkboxData: ICheckboxData;
	infoCardData?: { size: string; text: string; icon: string };
	emitSelection: (checkBoxAnswer: ICheckboxAnswer, question: string) => void;
	withButtons?: boolean;
	required?: boolean;
	continueClicked?: boolean;
};

const Checkbox: FC<Props> = ({
	alignment,
	checkboxData,
	infoCardData,
	emitSelection,
	required,
	continueClicked,
}) => {
	const handleSelection = (item: any) => {
		if (item.selected || item.disabled) {
			return;
		}
		emitSelection(item, checkboxData.question);
	};
	return (
		<div className='checkbox-container'>
			{alignment === 'horizontal' && (
				<div className='checkbox-container-horizontal'>
					<label>{checkboxData.question}</label>
					{infoCardData && (
						<InfoCard
							size={infoCardData.size}
							text={infoCardData.text}
							icon={infoCardData.icon}
						/>
					)}
					<div className='horizontal-checkbox-section'>
						{checkboxData.answers.map((item: any) => {
							return (
								<div
									key={item.id}
									className={`horizontal-checkbox-item ${
										item.disabled && 'disabled'
									}`}
								>
									<img
										src={
											item.selected
												? checkboxTicked
												: checkboxEmpty
										}
										alt='checkbox'
										onClick={() => handleSelection(item)}
									/>
									<p>{item.label}</p>
								</div>
							);
						})}
					</div>
				</div>
			)}

			{alignment === 'vertical' && (
				<div className='vertical-checkbox-container'>
					<label>{checkboxData.question}</label>
					<div className='vertical-checkbox-section'>
						{checkboxData.answers.map((item: any) => {
							return (
								<div
									key={item.id}
									className='vertical-checkbox-item'
								>
									<img
										src={
											item.selected
												? checkboxTicked
												: checkboxEmpty
										}
										alt='checkbox'
										onClick={() => handleSelection(item)}
									/>
									<div className='vertical-checkbox-text'>
										<p className='vertical-checkbox-label'>
											{item.label}
										</p>
										<p className='vertical-checkbox-info'>
											{item.info}
										</p>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			)}
			{!checkboxData.answers[0].selected &&
				!checkboxData.answers[1].selected &&
				continueClicked &&
				required && (
					<div className='validation-error'>
						<p>Please make sure one checkbox is selected</p>
					</div>
				)}
		</div>
	);
};

export default Checkbox;
