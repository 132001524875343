import { ENDPOINTS } from '../utils/endpoints';
import { axiosInstance } from '../utils/api.axiosInstance';
import { getRequestURLFromParams } from '../utils/api.utils';
import { IStepPayload } from '../../types/interfaces/misc.interface';
import axios from 'axios';

export const startCompliance = (): Promise<any> => {
	const url = getRequestURLFromParams(ENDPOINTS.START_COMPLIANCE);
	return axiosInstance()
		.post(url)
		.catch((err) => {
			throw err;
		});
};

export const getOverallSubmission = (): any => {
	const url = getRequestURLFromParams(ENDPOINTS.OVERALL_SUBMISSION);
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const resubmit = (): any => {
	const url = getRequestURLFromParams(ENDPOINTS.OVERALL_SUBMISSION);
	return axiosInstance()
		.patch(url, { status: 'pending' })
		.catch((err) => {
			throw err;
		});
};

export const getApplicationStatus = (): any => {
	const url = getRequestURLFromParams(ENDPOINTS.APPLICATION_STATUS);
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const setApplicationStatus = (status: string): any => {
	const url = getRequestURLFromParams(ENDPOINTS.APPLICATION_STATUS);
	return axiosInstance()
		.patch(url, { status })
		.catch((err) => {
			throw err;
		});
};

export const getStepsComplete = (): any => {
	const url = getRequestURLFromParams(ENDPOINTS.STEPS);
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const postStep = (payload: IStepPayload): Promise<any> => {
	const url = getRequestURLFromParams(ENDPOINTS.STEPS_UPDATE);
	return axiosInstance()
		.post(url, payload)
		.catch((err) => {
			throw err;
		});
};

export const sendLog = (payload: string): Promise<any> => {
	const headers = {
		'content-type': 'application/json',
		authorization: process.env.REACT_APP_LOGGING_MS_KEY,
	};
	return axios
		.post(`${process.env.REACT_APP_LOGGING_URL}`, payload, {
			headers,
		})
		.then(({ data }) => {
			return data;
		})
		.catch(() => {
			return;
		});
};
